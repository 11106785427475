import { getCookieValue, removeCookie } from "@/libs/Cookies"
import { ShippingContextList } from "@/models/scope/shipping/ShippingContextList";
import { BUNDLE_TYPE_PRODUCT, SOLD_BY_MKP } from "@/utils/constants";

export const isValidValueRegex = (regexValue, inputValue) => {
    const regex = new RegExp(regexValue, 'gi');
    return regex.test(inputValue);
}
export const autoCompleteOff = (id) => {
    $("#" + id).attr("autocomplete", "new-" + id);
}
export const sortPromessDates = (promessDates) => {
    return Object.entries(promessDates).sort((week1, week2) => parseInt(week1[0]) - parseInt(week2[0]))
        .map(promessDatesByWeek => promessDatesByWeek[1])
}
export const formattedDate = (value) => {
    const date = new Date(value);
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' à ' + date.getHours() + 'h' + date.getMinutes();
}
export const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price)
}
export const formatPriceWithoutTax = (price) => {
    return new Intl.NumberFormat("fr-FR", {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(price)
}
export const redirectTo = (url) => {
    window.location.href = url
}
export const convertDateStringToDate = (date) => {
    let arrayDate = date.split(/[\/ :]/);
    return arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0];
}

export const addressAutocomplete = (address) => {
    var self = this;
    $('#' + address.zipCode.includingFormId).dqe({
        zip: '#' + address.zipCode.id,
        compl: '#' + address.compl.id,
        zipcity: '#' + address.zipCode.id,
        city: '#' + address.city.id,
        street: '#' + address.street.id,
        hasTwoAddresses: address.hasTwoAddresses,
        shippingCity: '#' + address.shippingCity.id,
        shippingZipCode: '#' + address.shippingZipCode.id,
        shippingStreet: '#' + address.shippingStreet.id,
        cedex: false,
        autocompleteInternalFunction: function () {
            fetchInseeCodeByZipCodeAndCity(address.zipCode.id, address.city.id, 'zipCode');
            self.zipCodeAndCityAutocompleteWithInternalApi(address.city.id, address.zipCode.id, 'city');

        }
    });
    $("#" + address.zipCode.id).attr("autocomplete", "new-zipCode");
    $("#" + address.city.id).attr("autocomplete", "new-city");
}

export const prepareDataForRequest = (data) => {
    const customerData = new URLSearchParams();
    customerData.append('view', 'full');
    customerData.append('context', 'newCustomerSpace');
    customerData.append('profile[email]', data.email);
    customerData.append('profile[firstname]', data.firstname);
    customerData.append('profile[lastname]', data.lastname);
    customerData.append('profile[civility]', data.civility);
    customerData.append('billingAddress[streetAddress]', data.billingAddress['streetAddress'] ? data.billingAddress['streetAddress'] : '');
    customerData.append('billingAddress[streetAddressComplement]', data.billingAddress['streetAddressComplement']);
    customerData.append('billingAddress[zipCode]', data.billingAddress['zipCode']);
    customerData.append('billingAddress[city]', data.billingAddress['city']);
    customerData.append('billingAddress[countryName]', data.billingAddress['countryName']);
    customerData.append('billingAddress[isoCountryCode]', data.billingAddress['isoCountryCode']);
    customerData.append('billingAddress[latitude]', data.billingAddress['latitude'] ? data.billingAddress['latitude'] : '');
    customerData.append('billingAddress[longitude]', data.billingAddress['longitude'] ? data.billingAddress['longitude'] : '');
    customerData.append('billingAddress[isBilling]', 1);
    customerData.append('billingAddress[isShipping]', data.billingAddressIsShippingAddress ? 1 : 0);
    customerData.append('billingAddress[customerAddressId]', data.billingAddress['customerAddressId']);
    customerData.append('billingAddress[inseeCode]', data.billingAddress['inseeCode'] ? data.billingAddress['inseeCode'] : $('#billingInseeCode').val());
    customerData.append('billingAddress[normalizationStatus]', data.billingAddress['normalizationStatus'] ? data.billingAddress['normalizationStatus'] : 0);
    customerData.append('hasTwoAddresses', data.billingAddressIsShippingAddress ? 0 : 1);
    if (data.shippingAddress) {
        customerData.append('shippingAddress[customerAddressId]', data.shippingAddress['customerAddressId']);
        customerData.append('shippingAddress[firstname]', data.shippingAddress['firstname']);
        customerData.append('shippingAddress[lastname]', data.shippingAddress['lastname']);
        customerData.append('shippingAddress[streetAddress]', data.shippingAddress['streetAddress'] ? data.shippingAddress['streetAddress'] : '');
        customerData.append('shippingAddress[streetAddressComplement]', data.shippingAddress['streetAddressComplement']);
        customerData.append('shippingAddress[countryName]', data.shippingAddress['countryName']);
        customerData.append('shippingAddress[isoCountryCode]', data.shippingAddress['isoCountryCode']);
        customerData.append('shippingAddress[zipCode]', data.shippingAddress['zipCode']);
        customerData.append('shippingAddress[city]', data.shippingAddress['city']);
        customerData.append('shippingAddress[latitude]', data.shippingAddress['latitude']);
        customerData.append('shippingAddress[longitude]', data.shippingAddress['longitude']);
        customerData.append('shippingAddress[civility]', data.shippingAddress['civility']);
        customerData.append('shippingAddress[inseeCode]', data.shippingAddress['inseeCode'] ? data.shippingAddress['inseeCode'] : $('#shippingInseeCode').val());
        customerData.append('shippingAddress[isBilling]', 0);
        customerData.append('shippingAddress[isShipping]', 1);
        customerData.append('shippingAddress[latitude]', data.shippingAddress['latitude'] ? data.shippingAddress['latitude'] : '');
        customerData.append('shippingAddress[longitude]', data.shippingAddress['longitude'] ? data.shippingAddress['longitude'] : '');
        customerData.append('shippingAddress[normalizationStatus]', data.shippingAddress['normalizationStatus'] ? data.shippingAddress['normalizationStatus'] : 0);
    }
    return customerData;
}
export const createCustomerMetaInfos = (tab) => {
    let codes = [];
    let items = [];
    let item = null;

    for (let key in tab) {
        codes.push(key);
        item = { 'code': key, 'value': tab[key] }
        items.push(item);
    }
    return { 'metaInfos': { 'codes': codes, 'items': items } }
}
export const getFormatedCurrentDate = () => {
    let today = new Date();
    let day = (today.getDate() >= 10) ? today.getDate() : "0" + today.getDate();
    let currentMonth = today.getMonth() + 1;
    let month = (currentMonth >= 10) ? currentMonth : "0" + currentMonth;
    let year = today.getFullYear();
    return year + "-" + month + "-" + day;
}
export const validateJson = (json) => {
    try {
        return (JSON.parse(json));
    } catch (e) {
        return false;
    }
}
export const prepareShopCircle = () => {
    if (Cookies('availabilityShopCircle')) {
        let shopCircleData = JSON.parse(Cookies('availabilityShopCircle'));
        if (shopCircleData["circleShops"]) {
            let shopCircleQuery = {};
            shopCircleQuery.shopCircle = shopCircleData['circleShops'];
            shopCircleQuery.primaryShopId = shopCircleData['primaryShop']
            return shopCircleQuery;
        }
        return null;
    }
    return null;
}
export const position = (obj) => {
    let currenttop = 0;
    if (obj.offsetParent) {
        do {
            currenttop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [currenttop];
    }
}

/**
 * validation des champs formulaire prendre rdv
 * @param params
 * @param onlyAppointmentChecked
 * @returns {null|jsonObject}
 */
export const isValidAppointmentDataRegistrationForm = (params, onlyAppointmentChecked) => {
    if (onlyAppointmentChecked) {
        if (params.appointments[0].date && params.appointments[0].scheduleId && params.appointments[0].scheduleId != '-1') {
            return params
        }
    } else {
        const appointmentsChecked = params.appointments;
        if (appointmentsChecked.length > 0) {
            for (let i = 0, l = appointmentsChecked.length; i < l; i++) {
                if (appointmentsChecked[i].scheduleId == '-1' || appointmentsChecked[i].scheduleId === '') {
                    return null
                }
            }
            return params;
        }
    }
    return null;
}
/**
 * Preparer les lignes des commandes du rdv unique
 * @param data
 * @param items
 * @param mainRecipient
 * @param context
 * @returns {jsonObject}
 */
export const prepareDataRegisterAppointment = (data, items, mainRecipient) => {
    const typeRdv = "rdv_depot";
    const appointmentData = {};
    appointmentData['shopId'] = data.shopId
    appointmentData['origine'] = data.origine
    appointmentData['appointments[0][scheduleId]'] = data.appointments[0].scheduleId
    appointmentData['appointments[0][wpCustomerId]'] = data.appointments[0].wpCustomerId
    appointmentData['appointments[0][customerId]'] = data.appointments[0].customerId
    appointmentData['appointments[0][nosCustomerId]'] = data.appointments[0].nosCustomerId
    appointmentData['appointments[0][mail]'] = mainRecipient.email
    appointmentData['appointments[0][phone]'] = mainRecipient.phone
    appointmentData['appointments[0][firstName]'] = data.appointments[0].firstName
    appointmentData['appointments[0][lastName]'] = data.appointments[0].lastName
    appointmentData['appointments[0][civility]'] = data.appointments[0].civility
    appointmentData['appointments[0][date]'] = data.appointments[0].date
    appointmentData['appointments[0][type]'] = typeRdv
    appointmentData['appointments[0][order][0][documentNo]'] = data.appointments[0].order[0].documentNo
    appointmentData['appointments[0][order][0][orderId]'] = data.appointments[0].order[0].orderId
    appointmentData['appointments[0][order][0][id]'] = data.appointments[0].order[0].id
    appointmentData['appointments[0][order][0][idCrypt]'] = data.appointments[0].order[0].idCrypt
    appointmentData['appointments[0][order][0][orderDate]'] = data.appointments[0].order[0].orderDate
    items.forEach((element, i) => {
        appointmentData['appointments[0][order][0][orderLines][' + i + '][id]'] = element.id
        appointmentData['appointments[0][order][0][orderLines][' + i + '][productId]'] = element.productId
        appointmentData['appointments[0][order][0][orderLines][' + i + '][ean13]'] = element.ean13
        appointmentData['appointments[0][order][0][orderLines][' + i + '][qtyAppointment]'] = element.qtyAppointment
        appointmentData['appointments[0][order][0][orderLines][' + i + '][packageId]'] = element.package['id']
    })
    return appointmentData;
}
export const formatDimension = (dimension) => {
    return new Intl.NumberFormat('fr-FR',).format(dimension)
}
/**
 * Preparer les lignes des commandes du rdv multiple
 * @param data
 * @param mainRecipient
 * @param context
 */
export const prepareDataRegisterAppointments = (data, mainRecipient) => {
    const appointmentData = {};
    const typeRdv = "rdv_depot";
    const appointmentsChecked = data.appointments.filter(appointment => appointment.date && appointment.scheduleId)
    let appointmentsObject = {};
    appointmentsChecked.forEach(element => {
        const key = element.date + '::' + element.scheduleId
        if (appointmentsObject[key]) {
            let orderLines = appointmentsObject[key].order[0].orderLines;
            orderLines.push(element.order[0].orderLines[0]);
            appointmentsObject[key].order[0].orderLines = orderLines
        } else {
            appointmentsObject[key] = element;
        }
    })
    const appointmentsArray = Object.values(appointmentsObject)
    appointmentData['shopId'] = data.shopId
    appointmentData['origine'] = data.origine
    appointmentsArray.forEach((appointment, i) => {
        appointmentData['appointments[' + i + '][scheduleId]'] = appointment.scheduleId
        appointmentData['appointments[' + i + '][wpCustomerId]'] = appointment.wpCustomerId
        appointmentData['appointments[' + i + '][customerId]'] = appointment.customerId
        appointmentData['appointments[' + i + '][nosCustomerId]'] = appointment.nosCustomerId
        appointmentData['appointments[' + i + '][mail]'] = mainRecipient.email
        appointmentData['appointments[' + i + '][phone]'] = mainRecipient.phone
        appointmentData['appointments[' + i + '][firstName]'] = appointment.firstName
        appointmentData['appointments[' + i + '][lastName]'] = appointment.lastName
        appointmentData['appointments[' + i + '][civility]'] = appointment.civility
        appointmentData['appointments[' + i + '][date]'] = appointment.date
        appointmentData['appointments[' + i + '][type]'] = typeRdv
        appointmentData['appointments[' + i + '][order][0][documentNo]'] = appointment.order[0].documentNo
        appointmentData['appointments[' + i + '][order][0][orderId]'] = appointment.order[0].orderId
        appointmentData['appointments[' + i + '][order][0][id]'] = appointment.order[0].id
        appointmentData['appointments[' + i + '][order][0][idCrypt]'] = appointment.order[0].idCrypt
        appointmentData['appointments[' + i + '][order][0][orderDate]'] = appointment.order[0].orderDate
        appointment.order[0].orderLines.forEach((line, j) => {
            appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][id]'] = line.id
            appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][productId]'] = line.productId
            appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][ean13]'] = line.ean13
            appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][qtyAppointment]'] = line.qtyAppointment
            appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][packageId]'] = line.package['id']
        })
    })
    return appointmentData;
}

export const validateEmail = (email) => {
    var regExp = /^(?:[a-z0-9!#$%&'*+\/=?^_'{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_'{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    return regExp.test(email);
}
export const pluralize = (count, name) => {
    return count <= 1 ? `${name}` : `${name}s`;
}
export const validatePhone = (phone) => {
    var phoneRegExp = /^(06|07|\+336|\+337)[0-9]{8}$/
    if (!phone.match(phoneRegExp)) {
        return false
    }
    return true;
}

/**
 * Formater une date dd/mm/yyyy
 * @param date
 * @returns {string}
 */
export const formatDate = (date) => {
    const d = new Date(date)
    return ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear()
}

export const removeSpecialCharAndAccents = (s, replaceFunction = '') => {
    let str = this.removeAccents(s);
    let regex = new RegExp(/([^A-Za-z0-9\-\_\.\ ])/);
    if (regex.test(str)) {
        return str.replace(/[^A-Za-z0-9\-\_\.\ ]/gi, replaceFunction)
    }
    return str;
};
export const replaceAll = (str, obj) => {
    for (const elt in obj) {
        str = str.replace(new RegExp(elt, 'g'), obj[elt]);
    }
    return str;
};
export const formatDateWithTime = (date) => {
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    const millisecond = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;
}
export const getOfferIdFromUrl = () => {
    let url = window.location.href
    let match = url.match(/#(\d+)/)
    if (match && match[1]) return match[1]
    return null
}

export const getDecimalFormat = (number) => {
    const numberFlot = parseFloat(number)
    return numberFlot.toFixed(2).replace(".", ",");
}

export const initializeLocalStorageShopCircle = (shopCircleVersion) => {
    if (getCookieValue('shopCircle') && JSON.parse(getCookieValue('shopCircle')).version == shopCircleVersion) {
        localStorage.setItem('shopCircle', getCookieValue('shopCircle'));
    }
    removeCookie('shopCircle');
}

export const isValidShopCircle = () => {
    if (localStorage.getItem('shopCircle') && getCookieValue('availabilityShopCircle')) {
        let shopCircle = JSON.parse(localStorage.getItem('shopCircle'));
        let date = new Date(shopCircle.modificationDate);
        let dateYearLater = new Date(date.setMonth(date.getMonth() + 12));
        let now = new Date();
        let shopCircleVersion = localStorage.getItem('shopCircleVersion');
        if (shopCircle.version != shopCircleVersion || (dateYearLater <= now)) {
            localStorage.removeItem('shopCircle');
            return false;
        }
        return true;
    } else {
        localStorage.removeItem('shopCircle');
    }
    return false;
}

export const getUserIdFromCookie = () => {
    let CookieAsArray = [];
    let groupAndIdArray = [];
    let formattedCookieArray = [];
    formattedCookieArray['userId'] = 'dXNlcklkPS0xO2dyb3VwPXdlYnVzZXI='; /* token with no userId */
    if (getCookieValue('navigationToken')) {
        CookieAsArray = atob(getCookieValue('navigationToken')).split(':');
        groupAndIdArray = CookieAsArray[1].split(';');
        groupAndIdArray.forEach((element) => {
            formattedCookieArray[element.split("=")[0]] = element.split("=")[1];
        });
    }
    return formattedCookieArray['userId'];
}

export const getShopCircleFromSource = () => {
    return isValidShopCircle() ? localStorage.getItem('shopCircle') : null;
}

export const getShopCirleDataFromLocalStorage = () => {
    let shopCircleByContext = getShopCircleFromSource();
    let shopCircle = []
    if (shopCircleByContext) {
        let shopCircleData = JSON.parse(shopCircleByContext);
        if (shopCircleData) {
            shopCircle = shopCircleData;
        }
    }
    return shopCircle;
}

export const calculateProductAvailabilityParamsForProductSheet = (productId) => {
    let shopCircleData = getShopCirleDataFromLocalStorage();
    const shopCircle = shopCircleData?.["circleShops"] || [];
    return calculateProductAvailabilityParams(shopCircle, productId, shopCircleData.zipCode, shopCircleData.city, 'ProductSheet')
}

export const calculateProductAvailabilityParams = (shopCircle, productId, zipCode, city, view) => {
    if (shopCircle?.length > 0) {
        let shopIdList = [];
        let counter = 1;
        shopIdList.push(shopCircle[0].id);
        const stockLocatorShopsCount = localStorage.getItem('stockLocatorShopsCount');
        while (shopCircle[counter] && shopIdList.length < parseInt(stockLocatorShopsCount || '0')) {
            if (shopCircle[counter].isMerchant === true) {
                shopIdList.push(shopCircle[counter].id);
            }
            counter++;
        }
        let shopIdListParam = (shopIdList.join()) ? "&ShopIds=" + shopIdList.join() : "";
        return "ProductIds=" + productId + ",1" + shopIdListParam + "&ZipCode=" + zipCode + "&City=" + city + "&View=" + view;
    } else {
        return "ProductIds=" + productId + ",1" + "&ZipCode=" + zipCode + "&City=" + city + "&View=" + view;
    }
}

export const getBasketData = (items) => {
    let basketLinesItems = []
    let basketLinestotalAmount = 0;
    items.map(item => {
        basketLinesItems.push({

            "ProductId": item.productId,
            "Quantity": item.quantity,
            "Price": item.unitPrice * item.quantity
        })
        basketLinestotalAmount += parseFloat(item.unitPrice * item.quantity);
    })

    return {
        "BasketLines": basketLinesItems,
        "Field": "EAN_13",
        "TotalAmount": parseFloat(basketLinestotalAmount.toFixed(2))
    }
}

export const getProductTitle = (frontLabel, title, markLabel, type) => {
    return type === BUNDLE_TYPE_PRODUCT ? title : `${markLabel || ""} ${frontLabel || ""}`;
}
export const getProductImages = (type, images, imageSize, id, bundleImagecode, imagePrefix = "produit") => {
    return images.facets.map((facet) => {
        if (type === BUNDLE_TYPE_PRODUCT && bundleImagecode) {
            return images.urlPattern
                .replace('%s', `${imagePrefix}-${imageSize}`)
                .replace('%s_%s', bundleImagecode);
        }
        return images.urlPattern
            .replace('%s', `${imagePrefix}-${imageSize}`)
            .replace('%s', id)
            .replace('%s', facet);
    })
}

export const getProductPrice = (soldBy, productData, winningOffer) => {
    return soldBy === SOLD_BY_MKP ? (winningOffer && {
        price: winningOffer.price,
        priceWithoutTax: winningOffer.taxFreePrice,
        crossedOutPrice: winningOffer.unitOriginPrice,
        crossedOutPriceWithoutTax: winningOffer.crossedOutPriceWithoutTax,
        discountDetails: winningOffer.discountDetails,
        priceEco: winningOffer.deaAmount + winningOffer.deeeAmount + winningOffer.ecoContributionsAmount + winningOffer.copyTaxMp,
    }) : {
        price: productData.price,
        priceWithoutTax: productData.priceWithoutTax,
        crossedOutPrice: productData.crossedOutPrice,
        crossedOutPriceWithoutTax: productData.crossedOutPriceWithoutTax,
        discountDetails: productData.discountDetails,
        priceEco: parseFloat(productData.ecoFurniturePrice || "0") + parseFloat(productData.ecoContributionPrice || "0"),
    }
}

export const getSaleabilityProduct = (availability) => {
    let saleability = undefined;
    if (availability.genericAvailability) {
        saleability = availability.genericAvailability.saleability;
    }
    return saleability;
}

export const addClassToResult = (result, value, className) => {
    const inputValue = new RegExp(
        value
            .split('')
            .map(function (x) {
                return x.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            })
            .join('[-\\s.]*'),
        'ig',
    );
    return result.replace(inputValue, `<span class=${className}>$&</span>`);
}

const sortShopCircleByDistance = (circleShops, primaryShop) => {
    let sortedShops = [];
    let shopCircleIds = [];
    circleShops.map(shop => {
        sortedShops.push([shop.id, shop.distance])
    })
    sortedShops.sort(function (shop1, shop2) {
        return shop1[1] - shop2[1];
    });
    shopCircleIds.push(primaryShop);
    for (let i = 0; i < 5; i++) {
        if (sortedShops[i][0] != primaryShop) {
            shopCircleIds.push(sortedShops[i][0])
        }
    }
    return shopCircleIds.slice(0, 4);
}

export const getAvailabilityShopCircleData = (shopCircleData) => {
    return {
        "modificationDate": shopCircleData.modificationDate,
        "primaryShop": shopCircleData.primaryShop.id,
        "version": shopCircleData.version,
        "circleShops": sortShopCircleByDistance(shopCircleData.circleShops, shopCircleData.primaryShop.id)
    }
}

export const getDistanceToShop = (shopId, Shopcircle) => {
    if (Shopcircle.length > 0) {
        let shopData = Shopcircle?.find(shop => shop.id == shopId);
        return (shopData && shopData.distance !== undefined) ? shopData.distance : '';
    } else {
        let distanceToShop = '';
        let shopCircle = [];
        if (isValidShopCircle()) {
            shopCircle = JSON.parse(localStorage.getItem('shopCircle'));
            shopCircle = shopCircle["circleShops"]
        }
        if (shopCircle) {
            shopCircle.forEach(function (shop) {
                if (shop.id == shopId.toString()) {
                    distanceToShop = shop.distance;
                }
            });
        }
        return distanceToShop;
    }
}

export const isNumber = (str) => {
    return /^\d+$/.test(str);
}

export const getHistoricShopCirleDataFromLocalStorage = () => {
    const shopCircleRawData = localStorage.getItem('historicSearchShopCircle');
    if (shopCircleRawData) {
        const shopCircleData = JSON.parse(shopCircleRawData);
        if (shopCircleData) return shopCircleData;
    }
    return {};
}

export const getZipCodeCitySearchFromHistoricLocalStorage = () => {
    let historicShopCircle = getShopCirleDataFromLocalStorage();
    if (historicShopCircle && historicShopCircle.zipCode && historicShopCircle.city) {
        return `${historicShopCircle.zipCode} ${historicShopCircle.city}`
    }
    return "";
}

export const getZipCodeCitySearchFromLocalStorage = () => {
    let shopCircle = getShopCirleDataFromLocalStorage();
    if (shopCircle && shopCircle.zipCode && shopCircle.city) {
        return {zipCode : shopCircle.zipCode, cityName: shopCircle.city}
    }
    return;
}
export const getHistoricZipCodeCitySearchFromLocalStorage = () => {
    let historicshopCircle = getShopCirleDataFromLocalStorage();
    if (historicshopCircle && historicshopCircle.zipCode && historicshopCircle.city) {
        return `${historicshopCircle.zipCode} ${historicshopCircle.city}`
    }
    return "";
}

export const getOffsetTopByParentElement = (parentElement) =>{
    return document.getElementById(parentElement).offsetTop;
}

export const isDomTomZipCode = (zipCode) => {
    if ((/9[7-8][0-9]{3}/).test(zipCode)) {
        return true;
    }
    return false;
}