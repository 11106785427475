import CustomerAccountWrapperContainer from "./CustomerAccountWrapperContainer"

const CustomerAccountLayout = (props) => {
    return (
        <CustomerAccountWrapperContainer  templateVersion={props.deviceType} pathName={props.pathName} context={props.context}>
            {props.children}
        </CustomerAccountWrapperContainer>
    )
}
export default CustomerAccountLayout
