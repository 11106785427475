import {memo} from "react";
import {dispatchEventSTPUSHUpdate} from "@/animations/Mobile/commonPage";

const menuContact = memo(() => {

    return (
        <a href="#" id="Menu_Picto_Contact_smart_tribune" onClick={(event) => {
            event.preventDefault();
            dispatchEventSTPUSHUpdate(['classic-5560']);
        }} className="nav__header__link">
            <div className="nav__header__link__animation slideInUp">
                <div className="nav__header__link__icone px-2">
                    <img src="/assets/images/Mobile/svg/aide-icon.svg" alt="icon aide"/>
                </div>
                <span>Aide</span>
            </div>
        </a>
    );
})

export default menuContact;