import {memo} from 'react';


export const AboveMenuBlock = memo(({aboveMenuItems, clicAboveItemMenu}) => {

    return (
        Array.isArray(aboveMenuItems) && aboveMenuItems?.map((menuItem, index) => {
            if (menuItem[7].length > 0) {
                return (
                    <li key={index} className="services">{menuItem[1] != '' ? menuItem[1] : menuItem[0]}

                        <ul>{
                            menuItem[7].map((childMenuItem, index) => {
                                return (<li key={index} className="services__default">
                                    <a
                                        href={childMenuItem[3]}
                                        dangerouslySetInnerHTML={{__html: (childMenuItem[1] != '') ? childMenuItem[1] : childMenuItem[0]}}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            clicAboveItemMenu(childMenuItem[0], childMenuItem[3], childMenuItem[5])
                                        }}></a>
                                </li>)
                            })}
                        </ul>

                    </li>)
            } else {
                return (<li><a
                    href={menuItem[3]}
                    dangerouslySetInnerHTML={{__html: (menuItem[1] !='') ? menuItem[1] : menuItem[0]}}
                    onClick={(event) => {
                        event.preventDefault();
                        clicAboveItemMenu(menuItem[0], menuItem[3], menuItem[5])
                    }}></a></li>)
            }
        })
    )
});

export default AboveMenuBlock;