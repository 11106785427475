import {MENUDATA_FETCHED} from '../actionsTypes'


const initialState = {
    menuItems : [],
    isDefaultMenu : false
};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case MENUDATA_FETCHED :
            return {
                ...state,
                menuItems: action.payload.menuItems,
                isDefaultMenu: action.payload.isDefaultMenu,
            }
        default:
            return state;
    }
};

export default menuReducer;
