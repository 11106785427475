import React, {memo} from "react";
import Head from 'next/head'
import {PRODUCT_LIST_PAGE} from "@/utils/constants";

const HeadDetails = (props) => {
 // TODO remmetre memo quand on va switcher le composant en typeScript ( erreur ts generee suite integration composant dans un fichier ts)
//todo change meta in children pour chaque context
// NB chaque changement dans ce composant il faut tester le filtre prix pour la Dp et la Recherche
    return (
        <Head>
            <title>{props.title}</title>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="language" content="fr"/>
            <meta name="robots" content="index,follow"/>
            <meta name="description"
                  content={props.description}/>
            <meta name="keywords"
                  content={props.keywords}/>
            {  props?.linkConfigMap?.length > 0 && props.linkConfigMap.map(item => (
                        <link rel={'stylesheet'} href={item}/>
                    )
            )}
            <link rel="canonical" href={props.canonical}/>
            {props.context && props.context === 'productSheet' &&
            <meta property="fb:app_id" content="124615274355461"/>}
            {props.context && props.context === 'productSheet' &&   <meta property="og:url" content={props.canonical}/>}
            {props.context && props.context === 'productSheet' &&   <meta property="og:title" content={props.title}/>}
            {props.context && props.context === 'productSheet' &&   <meta property="og:description" content={props.description}/>}
            {props.context && props.context === 'productSheet' &&   <meta property="og:type" content="article"/>}
            {props.images && props.images.mainImage && props.images.mainImage.length > 0 &&
            <meta property="og:image" content={props.images.mainImage}/>
            }
            <meta property="og:site_name" content="www.but.fr"/>
            {props.context && props.context === PRODUCT_LIST_PAGE &&
            props.previousURL && <link rel="prev" href={props.previousURL}/>}
            {props.nextURL && <link rel="next" href={props.nextURL}/>}
            {props.children}
        </Head>

    )


}
export default HeadDetails