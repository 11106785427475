export const displayElement = (el, type) => {
    $(el).css('display', type);
}

export const closeFancyBox = () => {
    $.fancybox.close();
}

export const showHideBlocks = (selectedElement, element) => {
    $(element).fadeIn();
    $(selectedElement).fadeOut();
}
export const openRelayPointPopin = (popinId) => {
    $.fancybox.open([{
        href: popinId
    }], {padding: 0})
}
export const setProviderLocationData = (adrLat, adrLong) => {
    $(adrLat).val($('#adrLat').val())
    $(adrLong).val($('#adrLong').val())
}