import React from "react";

const ClientAccount = (props) => {
    let iconClass = props.userConnected ? " valid" : "";
    return(
        <div className="col text-center">
            <a href={props.userConnected ? '/Customer/Account/Dashboard/Id/' + props.userId : '/Customer/Account/Identification'}
               className={`top-bar__nav__icone${iconClass}`}>
                <i  className="icon-3x icon-compte-v2"/>
            </a>
        </div>
    )
}
export default ClientAccount;