import ApiCaller from "@/services/ApiCaller/apiCallerDefault";
import Utils from "@/utils/Default/Utils";
import {
    openFancyBoxPopinShippingMode,
    showLoader,
    showItemBlocLoader,
    hideLoader,
    hideItemBlocLoader
} from "@/animations/Default/cartPage";
import {openRelayPointPopin, displayElement} from "@/animations/Default/relayPointPage";
import OctipasMerch from "@/utils/Default/OctipasMerch";

import {
    SHIPPING_CART_ITEMS_UPDATED,
    PREPARE_CLICK_AND_COLLECT,
    PROVIDER_TO_FETCH,
    RELAY_POINTS_FETCHED,
    RELAY_POINT_SELECTED,
    RELAY_POINT_FETCHED,
    SET_ERROR_CART_MESSAGES,
    ERROR_CREATE_ORDER,
    DISCOUNT_CODE_APPLIED,
    REMOVE_DISCOUNT_CODE,
    ERROR_BILLING_ADDRESS,
    ERROR_SHIPPING_ADDRESS,
    UPDATE_RELAY_POINT_DATA,
    CART_GLD_UPDATED,
    SELECTED_DAY_DATE,
    SELECTED_SCHEDULE_ID,
    SHIPPING_TRANSPORTERS_FETCHED
} from '../actionsTypes.js';

export function prepareClickAndCollectHoraireData(ScheduleItem) {
    return function (dispatch) {

        dispatch({
            type: PREPARE_CLICK_AND_COLLECT,
            payload: {ScheduleItem: ScheduleItem}
        });


    }

}

export function selectClickAndCollectDate(cartItemId, code, date, dayMonth, period, rdvAuto, cartItems) {
    let seletedClickAndCollectMode = prepareClickAndCollectSelectedShippingInfos(cartItems)
    const data = {}
    data.action = "addProductsShippingParams"
    if (seletedClickAndCollectMode.length === 1) {
        data.items = [{
            id: cartItemId,
            service: {
                code: code,
                params: {
                    date: date,
                    dayMonth: dayMonth,
                    period: period,
                    rdvAuto: rdvAuto
                }
            }
        }];
    } else {
        let listSelectedPickupHour = [];
        let selectedPickupHours = {}
        seletedClickAndCollectMode.map((item, index) => {
            selectedPickupHours =
                {
                    id: item.productId,
                    service: {
                        code: code,
                        params: {
                            date: date,
                            dayMonth: dayMonth,
                            period: period,
                            rdvAuto: rdvAuto
                        }
                    }

                }
            listSelectedPickupHour.push(selectedPickupHours)
        })
        data.items = listSelectedPickupHour;
    }
    return function (dispatch) {
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {
                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
                openFancyBoxPopinShippingMode()
            })
    }


}


export function selectRDVSansContactDate(cartItemId, code, date, dayMonth, scheduleId, slotTime, capacity, cartItems) {
    let selectedClickAndCollectMode = prepareRdvContactLessPickupSelectedShippingInfos(cartItems)
    const data = {}
    data.action = "addProductsShippingParams"
    if (selectedClickAndCollectMode.length === 1) {
        data.items = [{
            id: cartItemId,
            service: {
                code: code,
                params: {
                    date: date,
                    dayMonth: dayMonth,
                    scheduleId: scheduleId,
                    slotTime: slotTime,
                    capacity: capacity
                }
            }
        }];
    } else {
        let listSelectedPickupHour = [];
        let selectedPickupHours = {}
        selectedClickAndCollectMode.map((item, index) => {
            selectedPickupHours =
                {
                    id: item.productId,
                    service: {
                        code: code,
                        params: {
                            date: date,
                            dayMonth: dayMonth,
                            scheduleId: scheduleId,
                            slotTime: slotTime,
                            capacity: capacity
                        }
                    }

                }
            listSelectedPickupHour.push(selectedPickupHours)
        })
        data.items = listSelectedPickupHour;
    }
    return function (dispatch) {
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {
                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping,
                        slotTime: slotTime,
                        scheduleId: scheduleId
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
                openFancyBoxPopinShippingMode()
            })
    }


}

export function deleteProductFromCart(productId) {
    return function (dispatch) {

        const data = {}
        data.action = "removeProducts"
        data.items = [{id: productId}];
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {
                //close delete product modal
                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
                OctipasMerch.createOrReplaceBasket(Utils.getBasketData(response.data.items))
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
                openFancyBoxPopinShippingMode()
            })
    }
}

export function selectShippingMode(productId, shippingCode, shippingAction, promessDate = null) {
    return function (dispatch) {
        const data = {}
        data.action = shippingAction
        data.items = [{
            id: productId,
            service: {
                code: shippingCode,
                promessDate: promessDate
            }
        }];
        showItemBlocLoader(productId);
        showLoader(productId);
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {
                hideItemBlocLoader(productId);
                hideLoader(productId);
                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
                let hasSelectedClickAndCollect;
                response.data.items.map(item => {
                    if (item.itemId === productId) {
                        hasSelectedClickAndCollect = (item.selectedServices !== null && typeof item.selectedServices.shipping !== "undefined" && typeof item.selectedServices.shipping.date !== "undefined" && item.selectedServices.shipping.code === 'ClickAndCollect')
                    }
                })
                if (shippingCode === "ClickAndCollect" && shippingAction === "addProductsShipping" && !hasSelectedClickAndCollect) {
                }
                if (response && response.data && response.data.shopId) {
                    UpdateShopCircleInDbAndCookie(response.data.shopId, "AroundShop")
                }
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
                openFancyBoxPopinShippingMode()
            })
    }

}
export function initializeSelectedShippingMethod(cartItems) {
    return function (dispatch) {
        const data = {}
        data.action = 'removeProductsShipping'
        data.items = cartItems?.map(item => ({
            id: item.ean13,
            service: {code: ''}
        }))
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {
                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
                $.fancybox.close();
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function UpdateShopCircleInDbAndCookie(shopId, calculationMethod) {
    const url = "/WebUsers/" + appData.webUserId + "/Shops"
    ApiCaller.put(url, {
        CalculationParams: shopId,
        CalculationMethod: calculationMethod
    }).then(response => {
        localStorage.setItem('shopCircle', JSON.stringify(response.data));
        Cookies.set('availabilityShopCircle', JSON.stringify(Utils.getAvailabilityShopCircleData(response.data)), {
            path: "/",
            domain: appData.cookieDomain,
            expires: 365
        })
    })
        .catch((error) => {
            //TODO SES thow exception
            console.log(error)
        })
}

export function selectShippingOption(productId, shippingCode, checkedOption) {
    return function (dispatch) {
        const data = {}
        data.action = "addProductsShippingParams"
        data.items = [{
            id: productId,
            service: {
                code: shippingCode,
                params: {
                    options: checkedOption
                }
            }
        }];
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {

                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
                openFancyBoxPopinShippingMode()
            })
    }


}

export function selectAfterWorkShipping(afterWorkItemsWithChosenPromessDate) {
    return function (dispatch) {
        const data = {}
        data.action = "addProductsShippingParams"
        data.items = afterWorkItemsWithChosenPromessDate
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {

                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
                openFancyBoxPopinShippingMode()
            })
    }

}

export function updateCustomerAddress(CustomerAddressId, AddressData, modalName, redirectCallback = null, addressRNVPCheck = false) {

    return function (dispatch) {
        ApiCaller.put("/Customers/" + appData.webUserId + "/Addresses/" + CustomerAddressId, AddressData)
            .then(response => {
                if (addressRNVPCheck) {
                    redirectCallback();
                } else {
                    ApiCaller.get("/WebUsers/" + appData.webUserId + "/Cart?View=shipping")
                        .then(response => {
                            dispatch({
                                type: SHIPPING_CART_ITEMS_UPDATED,
                                payload: {
                                    cartObject: response.data,
                                    billingFirstname: response.data.billingAddress.firstname,
                                    billingPhone: response.data.billingAddress.phone,
                                    billingLastname: response.data.billingAddress.lastname,
                                    billingZipCode: response.data.billingAddress.zipCode,
                                    billingCity: response.data.billingAddress.city,
                                    billingStreetAddress: response.data.billingAddress.streetAddress,
                                    billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                                    billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                                    billingCivility: response.data.billingAddress.civility,
                                    billingIsBilling: response.data.billingAddress.isBilling,
                                    billingIsShipping: response.data.billingAddress.isShipping,
                                    shippingFirstname: response.data.shippingAddress.firstname,
                                    shippingPhone: response.data.shippingAddress.phone,
                                    shippingLastname: response.data.shippingAddress.lastname,
                                    shippingZipCode: response.data.shippingAddress.zipCode,
                                    shippingCity: response.data.shippingAddress.city,
                                    shippingStreetAddress: response.data.shippingAddress.streetAddress,
                                    shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                                    shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                                    shippingCivility: response.data.shippingAddress.civility,
                                    shippingIsBilling: response.data.shippingAddress.isBilling,
                                    shippingIsShipping: response.data.shippingAddress.isShipping,
                                    normalisationDataObject: null
                                }
                            });
                        })
                }

            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400) {
                        let errorsAddress = error.response.data
                        if (modalName === "modalFacturation") {
                            dispatch({
                                type: ERROR_BILLING_ADDRESS,
                                payload: {errorsBillingAddress: errorsAddress}
                            });
                        } else {
                            dispatch({
                                type: ERROR_SHIPPING_ADDRESS,
                                payload: {errorsShippingAddress: errorsAddress}
                            });
                        }

                    }
                }
            })
    }

}

export function updateCustomerShippingAddress(shippingCustomerAddressId, shippingData,
                                              hasShippingAddress, modalName, redirectCallback, addressRNVPCheck) {
    return function (dispatch) {
        if (hasShippingAddress) {
            dispatch(updateCustomerAddress(shippingCustomerAddressId, shippingData, modalName, redirectCallback, addressRNVPCheck))
        } else {
            dispatch(insertCustomerAdress(shippingCustomerAddressId, shippingData, modalName, redirectCallback, addressRNVPCheck))
        }
    }
}

export function insertCustomerAdress(shippingCustomerAddressId, shippingData, modalName, redirectCallback = null, addressRNVPCheck = false) {

    return function (dispatch) {
        ApiCaller.post("/Customers/" + appData.webUserId + "/Addresses", shippingData)
            .then(response => {
                if (addressRNVPCheck) {
                    redirectCallback();
                } else {
                    ApiCaller.get("/WebUsers/" + appData.webUserId + "/Cart?View=shipping")
                        .then(response => {
                            dispatch({
                                type: SHIPPING_CART_ITEMS_UPDATED,
                                payload: {
                                    cartObject: response.data,
                                    billingFirstname: response.data.billingAddress.firstname,
                                    billingPhone: response.data.billingAddress.phone,
                                    billingLastname: response.data.billingAddress.lastname,
                                    billingZipCode: response.data.billingAddress.zipCode,
                                    billingCity: response.data.billingAddress.city,
                                    billingStreetAddress: response.data.billingAddress.streetAddress,
                                    billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                                    billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                                    billingCivility: response.data.billingAddress.civility,
                                    billingIsBilling: response.data.billingAddress.isBilling,
                                    billingIsShipping: response.data.billingAddress.isShipping,
                                    shippingFirstname: response.data.shippingAddress.firstname,
                                    shippingPhone: response.data.shippingAddress.phone,
                                    shippingLastname: response.data.shippingAddress.lastname,
                                    shippingZipCode: response.data.shippingAddress.zipCode,
                                    shippingCity: response.data.shippingAddress.city,
                                    shippingStreetAddress: response.data.shippingAddress.streetAddress,
                                    shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                                    shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                                    shippingCivility: response.data.shippingAddress.civility,
                                    shippingIsBilling: response.data.shippingAddress.isBilling,
                                    shippingIsShipping: response.data.shippingAddress.isShipping,
                                    normalisationDataObject: null
                                }
                            });
                        })
                }

            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400) {
                        let errorsAddress = error.response.data
                        dispatch({
                            type: ERROR_SHIPPING_ADDRESS,
                            payload: {errorsShippingAddress: errorsAddress}
                        });
                    }
                }
            })
    }

}

export function getRelayPointsByCalculationMethod(calculationMethod = 'ByZipCodeCity', calculationParams, promessDate, providersListData = null, providers = "", openRelayPointsPopin = false, cartItem = null) {

    let providersData = cartItem && cartItem.shippingByTransporters ? getRelayPointsPromessDateData(cartItem) : providersListData;
    let parameters = "?CalculationMethod=" + calculationMethod;
    parameters += "&CalculationParams=" + calculationParams
    parameters += "&Providers=" + JSON.stringify(providersData)
    let zipCodeCity = (calculationMethod == 'ByZipCodeCity') ? calculationParams.split(",") : null;
    let zipCode = (zipCodeCity && zipCodeCity[0]) ? zipCodeCity[0] : null;
    let city = (zipCodeCity && zipCodeCity[1]) ? zipCodeCity[1] : null;

    let url = "/Localizations/RelayPoints";
    return function (dispatch, getState) {
        ApiCaller.get(url + parameters)
            .then(response => {
                displayElement('#popin-point-relaisB .result', 'none');
                if (openRelayPointsPopin) {
                    dispatch({
                        type: PROVIDER_TO_FETCH,
                        payload: {
                            provider: providers ? appData.PR_CCV : '',
                            promessDate: promessDate
                        }
                    });
                    openRelayPointPopin("#popin-point-relaisB");
                }
                let relayPointsTransporters = []
                relayPointsTransporters = getRelayPointsAndTransporters(response.data)
                let relayPointsTransportersFastestPromessDate = getFastestPromessDateByTransporter(response.data, relayPointsTransporters.transporters);
                dispatch({
                    type: RELAY_POINTS_FETCHED,
                    relayPoints: relayPointsTransporters.relayPoints,
                    relayPointZipCode: zipCode ? zipCode : getState().cart.relayPointZipCode,
                    relayPointCity: city ? city : getState().cart.relayPointCity,
                    relayPointsTransporters: relayPointsTransporters.transporters,
                    errorMessage: "",
                    providersPromessDate: providersData,
                    relayPointsTransportersFastestPromessDate: relayPointsTransportersFastestPromessDate,
                    relayPointMessages: providers == appData.PR_CCV ? {} : getState().cart.relayPointMessages,
                    provider: providers ? appData.PR_CCV : ''
                });
            })
            .catch((error) => {
                if (openRelayPointsPopin && (providers != appData.PR_CCV || (providers == appData.PR_CCV && error.response && error.response.status == 404))) {
                    dispatch({
                        type: PROVIDER_TO_FETCH,
                        payload: {
                            provider: providers ? appData.PR_CCV : '',
                            promessDate: promessDate
                        }
                    });
                    openRelayPointPopin("#popin-point-relaisB");
                }
                dispatch({
                    type: RELAY_POINTS_FETCHED,
                    relayPoints: [],
                    relayPointsTransporters: [],
                    relayPointZipCode: zipCode ? zipCode : getState().cart.relayPointZipCode,
                    relayPointCity: city ? city : getState().cart.relayPointCity,
                    errorMessage: "HÉLAS, NOUS N’AVONS PAS TROUVÉ DE POINT DE RETRAIT <br/> À PROXIMITÉ DE VOTRE COMMUNE",
                    providersPromessDate: providers ? getState().cart.ccvPromessDate : getState().cart.relayPointsPromessDate,
                    relayPointsTransportersFastestPromessDate: [],
                    provider: providers ? appData.PR_CCV : '',
                    relayPointMessages: providers && (error.response && error.response.status != 404) ? {
                        type: 1,
                        code: 'relayPointError',
                        message: wordingManager.__('RELAYPOINT_CCHEZ_VOUS_ERROR')
                    } : {}
                });
                if (cartItem && cartItem.selectedServices && cartItem.selectedServices.shipping && providers == appData.PR_CCV && error.response && error.response.status != 404) {
                    dispatch(selectShippingMode(cartItem.selectedServices.shipping.itemId, cartItem.selectedServices.shipping.code, "removeProductsShipping"));
                }
            })
    }

}

export function getRelayPointsId(id) {
    let url = "/Localizations/RelayPoints/" + id;
    return function (dispatch) {
        ApiCaller.get(url)
            .then(response => {
                dispatch({
                    type: RELAY_POINT_FETCHED,
                    relayPoint: response.data,

                });
            })
            .catch((error) => {
                dispatch({});
            })
    }

}

export function selectRelayPointShipping(productId, relayPointData, shippingCode, cartItems, provider = '') {
    return function (dispatch, getState) {
        let url = "WebUsers/" + appData.webUserId + "/Cart?View=shipping";
        let productIds = []
        let shipping = []
        let transporters = relayPointData.transporters.filter(item => item.id == relayPointData.id)
        let transporterSelected = transporters[0].transporter

        cartItems.map((elem, index) => {
            if (elem.selectedServices && elem.selectedServices.shipping && elem.selectedServices.shipping.code === shippingCode) {
                productIds.push(elem.itemId);
            }
            if (elem.availableServices.shippingCollection && provider != appData.PR_CCV) {
                shipping = elem.availableServices.shippingCollection.filter(item => item.uniqueId == transporterSelected)
            }
        });

        let code = provider == appData.PR_CCV ? shippingCode : shipping[0].code

        let action = "addProductsShippingParams";
        let data = {
            action: action,
            items: []
        };
        productIds.forEach(function (productId) {
            data.items.push({
                id: productId,
                service: {
                    code: code,
                    params: {
                        date: Utils.getFormatedDate(relayPointData.deliveryDate),
                        relayPointData: {
                            id: relayPointData.id,
                            locality: relayPointData.locality,
                            name: relayPointData.name,
                            address: relayPointData.address.address1 + ' ' + relayPointData.address.address2 + ' ' + relayPointData.address.address3 + ' ' + relayPointData.address.address4,
                            zipcode: relayPointData.zipcode,
                            transporter: transporterSelected
                        }
                    }
                }
            });
        });
        data.action = action;
        showItemBlocLoader(productId);
        showLoader(productId);
        ApiCaller.put(url, data)
            .then(response => {
                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                })
                if ((provider == appData.PR_CCV && getState().cart.isCCVProviderSelected.isSelected == false) || (provider != appData.PR_CCV && getState().cart.isRelayPointProviderSelected.isSelected == false)) {
                    dispatch({
                        type: RELAY_POINT_SELECTED,
                        selectedRelayPointProvider: {provider: provider == appData.PR_CCV ? provider : '', counter: 1}
                    })
                }
                hideItemBlocLoader(productId);
                hideLoader(productId);
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}


export function fetchErrorCartMessages(errorCartMessage) {
    return function (dispatch) {
        dispatch({
            type: SET_ERROR_CART_MESSAGES,
            payload: {errorCartMessage: errorCartMessage}
        });
    }

}

export const goToPayment = () => {
    return function (dispatch) {
        let data = {}
        ApiCaller.post("/Customers/" + appData.webUserId + "/Orders?cartId=" + appData.cartObject.cartId, data)
            .then(response => {
                if (response.status === 201) {
                    Utils.redirectTo("/Purchase/Order/PaymentPage");
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400 || error.response.status == 409) {
                        let errorCreateOrder = error.response.data
                        dispatch({
                            type: ERROR_CREATE_ORDER,
                            payload: {errorCreateOrder: errorCreateOrder}
                        });
                    }
                    if (error.response.status == 403) {
                        Utils.redirectTo("Purchase/Cart/Summary")
                    }
                    console.log(error);
                }
            })
    }
}

export function setErrorCartMessages(cartItems) {

    let errorCartMessage = [];
    cartItems.map((elem, index) => {
        let cartInfos = {}
        if (!elem.selectedServices || elem.selectedServices != null && !elem.selectedServices.hasOwnProperty('shipping')) {
            cartInfos.msg = wordingManager.__('Shipping_ShippingRequired')
            cartInfos.productId = elem.itemId
            errorCartMessage.push(cartInfos);
        } else if (elem.selectedServices != null && elem.selectedServices.hasOwnProperty('shipping') && elem.selectedServices.shipping.hasOwnProperty('code')) {
            elem.availableServices.shipping.map((item, indice) => {
                if (elem.selectedServices.shipping.code == item.code) {
                    if (item.type == "AfterWork") {
                        if (elem.selectedServices.shipping && !elem.selectedServices.shipping.hasOwnProperty('date')) {
                            cartInfos.code = "AfterWork"
                            cartInfos.productId = elem.itemId
                            cartInfos.msg = wordingManager.__('Shipping_ClickAndCollectDateRequired')
                            errorCartMessage.push(cartInfos);
                        }
                    }

                    if (item.type == "ShippingRelayPoint") {
                        if (!elem.selectedServices.shipping.hasOwnProperty('relayPointData')) {
                            cartInfos.code = "ShippingRelayPoint"
                            cartInfos.productId = elem.itemId
                            cartInfos.msg = wordingManager.__('Shipping_RelayPointRequired')
                            errorCartMessage.push(cartInfos);
                        }
                    }

                    if (item.type == "ClickAndCollect") {
                        if (elem.selectedServices.shipping && !elem.selectedServices.shipping.hasOwnProperty('date') && (elem.selectedServices.shipping && !elem.selectedServices.shipping.hasOwnProperty('rdvAuto'))) {
                            cartInfos.code = "ClickAndCollect"
                            cartInfos.productId = elem.itemId
                            cartInfos.msg = wordingManager.__('Shipping_ClickAndCollectDateRequired')
                            errorCartMessage.push(cartInfos);
                        }
                    }


                }

            })

        }

    })
    return errorCartMessage

}

export function prepareClickAndCollectSelectedShippingInfos(cartItems) {

    let shippingModeSelectedInfos = [];
    cartItems.map((elem, index) => {
        let shippingModeInfos = {}
        if (elem.selectedServices != null && elem.selectedServices.hasOwnProperty('shipping')
            && elem.selectedServices.shipping.hasOwnProperty('code')) {
            elem.availableServices.shipping.map((item, indice) => {
                if (elem.selectedServices.shipping.code == item.code) {
                    if (item.type == "ClickAndCollect") {
                        if (elem.selectedServices.shipping) {
                            shippingModeInfos.productId = elem.itemId
                            shippingModeInfos.rdvAuto = (elem.rdvAuto) ? 1 : 0
                            shippingModeSelectedInfos.push(shippingModeInfos);
                        }
                    }
                }
            })

        }
    })
    return shippingModeSelectedInfos

}

export function prepareRdvContactLessPickupSelectedShippingInfos(cartItems) {

    let shippingModeSelectedInfos = [];
    cartItems.map((elem, index) => {
        let shippingModeInfos = {}
        if (elem.selectedServices != null && elem.selectedServices.hasOwnProperty('shipping')
            && elem.selectedServices.shipping.hasOwnProperty('code')) {
            elem.availableServices.shipping.map((item, indice) => {
                if (elem.selectedServices.shipping.code == item.code) {
                    if (item.type == "RdvContactlessPickup") {
                        if (elem.selectedServices.shipping) {
                            shippingModeInfos.productId = elem.itemId
                            shippingModeSelectedInfos.push(shippingModeInfos);
                        }
                    }
                }
            })

        }
    })
    return shippingModeSelectedInfos

}

export function applyDiscountCode(discountCode) {
    return function (dispatch) {
        let url = "/WebUsers/" + appData.webUserId + "/Cart?View=shipping";
        let data = {
            value: discountCode,
            action: "applyDiscountCode"
        };
        ApiCaller.put(url, data)
            .then(response => {
                let isDiscountCodeApplied = ""

                for (var i = 0; i < response.data.consolidationMessages.length; i++) {
                    let code = response.data.consolidationMessages[i].code

                    if (code == "discountcode_applied" || code == "discountcode_refused") {
                        isDiscountCodeApplied = code
                        break
                    }
                }
                dispatch({
                    type: DISCOUNT_CODE_APPLIED,
                    payload: {
                        isDiscountCodeApplied: isDiscountCodeApplied,
                        discountAmount: response.data.totalDiscount,
                        cartObject: response.data
                    }
                });
            })
            .catch((error) => {
                dispatch({
                    type: DISCOUNT_CODE_APPLIED,
                    payload: {isDiscountCodeAccepted: false}
                });
            })
    }
}

export function removeDiscountCode(discountCode) {
    return function (dispatch) {
        let url = "/WebUsers/" + appData.webUserId + "/Cart?View=shipping";
        let data = {
            value: discountCode,
            action: "removeDiscountCode"
        };
        ApiCaller.put(url, data)
            .then(response => {
                dispatch({
                    type: REMOVE_DISCOUNT_CODE,
                    payload: {cartObject: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}


export function setSelectedDayDate(day, date) {
    return function (dispatch) {
        dispatch({
            type: SELECTED_DAY_DATE,
            payload: {day: day, date: date}
        });

    }
}

export function setSelectedScheduleId(scheduleId) {
    return function (dispatch) {
        dispatch({
            type: SELECTED_SCHEDULE_ID,
            payload: {scheduleId: scheduleId}
        });

    }
}

export function updateProductsWarranty(productId, code, gldAction) {
    return function (dispatch) {
        const data = {}
        data.action = gldAction
        data.items = [{id: productId, service: {code: code}}];
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=shipping", data)
            .then(response => {
                dispatch({
                    type: CART_GLD_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function getRelayPointsAndTransporters(relayPoints) {
    let relayPointsTransporters = {'relayPoints': [], 'transporters': []}

    relayPoints.map(relayPoint => {
        relayPoint.transporters.map(item => {
            if (relayPointsTransporters.transporters.indexOf(item.transporter) == -1)
                relayPointsTransporters.transporters.push(item.transporter)

            if (relayPoint.id != item.id) {
                let newRelayPoint = {...relayPoint}
                newRelayPoint.id = item.id
                if (!relayPointsTransporters.relayPoints.find(element => element.id === newRelayPoint.id)) {
                    relayPointsTransporters.relayPoints.push(newRelayPoint)
                }
            } else {
                if (!relayPointsTransporters.relayPoints.find(element => element.id === relayPoint.id)) {
                    relayPointsTransporters.relayPoints.push(relayPoint)
                }
            }
        })
    })

    return relayPointsTransporters
}

export function getRelayPointsPromessDateData(cartItem) {
    let providersData = []
    cartItem.shippingByTransporters.map((shippingTransporter) => {
        providersData.push({
            'provider': shippingTransporter.transporter.uniqueId,
            'promessDate': Utils.getFormatedDate(shippingTransporter.shippingInfos.shippingDurationInfos.date)
        })
    })
    return providersData
}

export function getFastestPromessDateByTransporter(relayPoints, relayPointsTransporters) {
    let transportersFastestPromessDate = []
    relayPointsTransporters.map(transporter => {
        let transporterRelayPoints = []
        relayPoints.map(relayPoint => {
            relayPoint.transporters.map(item => {
                if (item.transporter == transporter) {
                    transporterRelayPoints.push(relayPoint)
                }
            })
        })
        let fastestDate = transporterRelayPoints.sort((a, b) => (new Date(Utils.getFormatedDate(a.deliveryDate)) > new Date(Utils.getFormatedDate(b.deliveryDate))) ? 1 : -1)[0].deliveryDate;
        transportersFastestPromessDate.push({'transporter': transporter, 'fastestPromessDate': fastestDate})
    })
    return transportersFastestPromessDate
}

export function relayPointSelectedItem(cartItem, shippingCode, provider) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_RELAY_POINT_DATA,
            currentRelayPointData: {cartItem: cartItem, shippingCode: shippingCode}
        })
        dispatch({
            type: SHIPPING_TRANSPORTERS_FETCHED,
            payload: {
                shippingByTransporters: cartItem.shippingByTransporters,
                provider: provider == appData.PR_CCV ? provider : ''
            }
        });
    }

}

