import {
    fetchSocialMediaDataRequest,
    getUserInfoProviders, mergeUserReachFiveIds, providerDissociatedHandler,
    removeProvider,
    updateReachFiveId
} from "@/services/socialMediaServices";
import {LOAD_SOCIAL_MEDIA_INFOS} from "../actionsTypes";

export const TYPES = {
    LOGIN_ASSOCIATION: 'LOGIN_ASSOCIATION',
    PROVIDE_NUMBER_CHANGED: 'PROVIDE_NUMBER_CHANGED'
};

export const fetchSocialMediaData = (userId) => {
    return function (dispatch) {
        fetchSocialMediaDataRequest(userId)
            .then(response => {
                localStorage.setItem("reachFiveClientId", response.clientId)
                localStorage.setItem("reachFiveDomain", response.domain)
                localStorage.setItem("reachFiveId", response.reachFiveId)
                localStorage.setItem("reachFiveToken", response.token)
                localStorage.setItem("userId", userId)
                dispatch({
                    type: LOAD_SOCIAL_MEDIA_INFOS,
                    payload: {socialMedia: response}
                });
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    window.location.href = "/Customer/Account/Identification"
                }
                console.error('ERROR', "/Customers/{userId}/SocialMedia Api responded failed : " + error)
            })
    }
}

export const getUserInfoProvidersList = (reachFiveDomain, reachFiveId, token) => {
    return (dispatch) => {
        getUserInfoProviders(reachFiveDomain, reachFiveId, token)
            .then(response => {
                if (response.data) {
                    dispatch({
                        type: TYPES.LOGIN_ASSOCIATION,
                        payload: {
                            data: response.data,
                        }
                    });
                }
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{reachFiveId} Api reachFive responded failed : " + error)
            })
    };
}


export const socialMediaDissociatedHandler = (reachFiveDomain, userInfo, token, provider, userId) => {
    return (dispatch) => {
        providerDissociatedHandler(reachFiveDomain, userInfo, token, provider)
            .then(response => {
                updateReachFiveId("", userId);
                dispatch({
                    type: TYPES.PROVIDE_NUMBER_CHANGED,
                    payload: {
                        provider: provider
                    }
                });
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{userInfo} Api reachFive responded failed : " + error)
            })
    }
}

export const removeProviderSocialMedia = (reachFiveApiDomain, provider, reachFiveId, token) => {
    return (dispatch) => {

        removeProvider(reachFiveApiDomain, provider, reachFiveId, token)
            .then(response => {
                dispatch({
                    type: TYPES.PROVIDE_NUMBER_CHANGED,
                    payload: {
                        provider: provider
                    }
                });
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{reachFiveId}/providers/{provider} Api reachFive responded failed : " + error)
            })
    }
}

export const mergeReachFiveIds = (reachFiveDomain, reachFiveId1, reachFiveId2, token) => {
    return function (dispatch) {
        mergeUserReachFiveIds(reachFiveDomain, reachFiveId1, reachFiveId2, token)
            .then(response => {
                dispatch(getUserInfoProvidersList(reachFiveDomain, response.data.id, token))
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{reachFiveId1}/merge/{reachFiveId2} Api reachFive responded failed : " + error)
            })
    }
}



