import ApiCaller from './ApiCaller/apiCaller'
import {
    handleFailedResponse,
    handleOpenIncidentSuccessfulResponse,
    handleSendMessageSuccessfulResponse,
    sendRequestToMirakl
} from "@/redux/reducers/responsive/order/orderActions";
import Utils from "@/utils/Default/Utils";
import {redirectTo} from "@/utils/Mobile/utils";


const httpRedirectCodes = [301, 302, 307];
export const getOrdersListByUserId = (userId, offset, size) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Customers/" + userId + "/Orders?offset=" + offset + "&size=" + size)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('ERROR', "Api/Rest/Customers/{id}/Orders Api responded failed : " + error)
                reject(error);
            });
    });
};

export const getOrderDetails = (userId, orderId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Customers/' + userId + '/Orders/' + orderId + '/Items')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    window.location.href = "/Customer/Account/Orders/Id/" + userId
                }
                console.log('ERROR', "Api/Rest/Customers/{Id}/Orders/{OId}/Items Api responded failed : " + error)
                reject(error);
            });
    });
};

export const confirmReceptionMarketPlaceOrder = (childOrderId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put("Marketplace/LogisticOrders/" + childOrderId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('ERROR', "Marketplace/LogisticOrders/{childOrderId} Api responded failed : " + error)
                reject(error);
            });
    });
}
export const confirmInStoreOrder = (orderId, context) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("Order/InStoreOrderDetails?OrderId=" + orderId + '&View=' + context)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                let headers = new Headers(error.response.headers);
                const navigateTo = headers.get('navigateTo');
                if (httpRedirectCodes.includes(error.response.status) && navigateTo) {
                    redirectTo(navigateTo);
                } else {
                    console.error('ERROR', "Order/InStoreOrderDetails?OrderId=" + orderId + " Api responded failed : " + error)

                }
            });
    });
}

export const getMessages = (customerId, logisticOrderId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Marketplace/Customers/' + customerId + '/LogisticOrders/' + logisticOrderId + '/Messages')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('ERROR', "Api/Rest/Marketplace/Customers/{customerId}/LogisticOrders/{logisticOrderId}/Messages Api responded failed : " + error)
                reject(error);
            });
    });
}

export const sendRequest = (url, data, params) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put(url, data, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.log('ERROR', error)
                reject(error);
            });
    });
}

export const uploadFileS3 = (url, data, params) => {
    return new Promise((resolve, reject) => {
        ApiCaller.post(url, data, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.log('ERROR', error)
                reject(error);
            });
    });
}

export const getReasons = (reasonType) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Marketplace/Message/Reasons/' + reasonType)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('ERROR', "Api/Rest/Marketplace/Message/Reasons/{reasonType} Api responded failed : " + error)
                reject(error);
            });
    });
}

export const sendMessageContent = (logisticOrderId, messageData, context, selectItemInfos) => {
    return new Promise((resolve, reject) => {
        let url = '/Marketplace/LogisticOrders/' + logisticOrderId + '/Message';
        if (selectItemInfos?.ean13 && selectItemInfos?.orderId && (context == 'operatorMessage')) {
            url += '?ean_13=' + selectItemInfos?.ean13 + '&order_id=' + selectItemInfos?.orderId;
        }
        ApiCaller.post(url, messageData)
            .then(response => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log('ERROR', "/Marketplace/LogisticOrders/{logisticOrderId}/Message Api responded failed : " + error)
                reject(error);
            });
    });
}

export const createIncident = (url, data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.post(url, data)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                console.log('ERROR', "/Marketplace/LogisticOrders/{logisticOrderId}/Items/orderLineId}/IncidentOpened Api responded failed : " + error)
                reject(error);
            });
    });
}

export const uploadFilesToMiraklAPi = (url, data, params) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put(url, data, params)
            .then(response => {
                resolve(response)
            })
            .catch((error) => {
                console.log('ERROR', error)
                reject(error);
            });
    });
}

export const sendMessageWithFile = (customerId, files, logisticOrderId, requestData, context, canOpenIncident, sellerName, uploadFilesToMiraklUrl, awsS3Url) => {
    let sendRequest = true;
    for (const item of requestData?.body) {
        if (item.required && item.value.length === 0) {
            sendRequest = false;
            break
        }
    }
    if (sendRequest) {
        return function (dispatch) {
            return new Promise((resolve, reject) => {
                getPresignedPostData(files, awsS3Url).then(presignedPostData => {
                    Promise.all(uploadFilesToS3(presignedPostData.data.body.data, files))
                        .then(response => {
                            for (let i = 0; i < response.length; i++) {
                                if (response[i].status !== 204) {
                                    throw new Error("Une erreur est survenue");
                                }
                            }
                        })
                        .then(() => {
                            sendRequestToMirakl(customerId, files, logisticOrderId, requestData, context, canOpenIncident, sellerName, uploadFilesToMiraklUrl)
                                .then((response) => {
                                    if (response?.status === 201) {
                                        if (context == 'sellerMessageWithFile') {
                                            dispatch(
                                                handleSendMessageSuccessfulResponse(customerId, context, logisticOrderId, canOpenIncident, 'sellerMessageWithFile', sellerName)
                                            );
                                        } else {
                                            dispatch(
                                                handleOpenIncidentSuccessfulResponse(context, logisticOrderId, customerId, sellerName)
                                            )
                                        }
                                    } else {
                                        throw "Une erreur est survenue.";
                                    }
                                    resolve(response);
                                });
                        })
                        .catch(e => {
                            dispatch(
                                handleFailedResponse(logisticOrderId, context, e)
                            );
                            throw new Error(e.message);
                        })
                })
            });
        }
    } else {
        return function (dispatch) {
            dispatch(handleFailedResponse(logisticOrderId, context, null));
        }
    }
};

export const getPresignedPostData = (selectedFiles, awsS3Url) => {
    let data = []
    for (let i = 0; i < selectedFiles.length; i++) {
        data.push({
            name: Utils.removeSpecialCharAndAccents(selectedFiles[i].name,
                (correspondance) => {
                    return correspondance.charCodeAt(0)
                }),
            type: selectedFiles[i].type
        })
    }
    return ApiCaller.post(awsS3Url, data, {overrideHeaders: true, withoutDefaultContentType: true, rowData: true})
};

export const uploadFilesToS3 = (data, files) => {
    let promises = []
    for (let i = 0; i < files.length; i++) {
        promises.push(uploadFileToS3(data[i], files[i]))
    }
    return promises;
}

export const uploadFileToS3 = (presignedPostData, file) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });
    formData.append("file", file);
    return ApiCaller.post(presignedPostData.url, formData,
        {
            overrideHeaders: true,
            withoutDefaultContentType: true,
            rowData: true,
            headers: {
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Methods": "OPTIONS"
            }
        })
};

export const closeIncidentTopic = (logisticOrderId, commandLineId, incidentCloseData) => {
    return ApiCaller.put("/Marketplace/LogisticOrders/" + logisticOrderId + "/Items/" + commandLineId + "/IncidentClosed", incidentCloseData)
};
