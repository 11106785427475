import {
    CARTDATA_FETCHED, CART_DATA_UPDATE, IDENTITY_FETCHED,
    PRIMARY_SHOP_DATA_FETCHED,
    SHOP_DATA_FETCHED,
    UPDATE_SHOP_ID,
    CART_DATA_UPDATE_ERROR,
    UPDATE_LOAD_DQE,
    UPDATE_LOAD_DQE_LOCATE_ME,
    UPDATE_LOAD_JQUERY_UI,
    UPDATE_INPUT_SELECTED_IDS,
    CART_ITEMS,
    CART_ITEMS_UPDATED
} from '@/redux/reducers/Mobile/actionsTypes';


const initialState = {
    cartProductsTotalQuantity: 0,
    userData: {},
    hasShop: false,
    shopId: '',
    shopName: '',
    shopUrl: '',
    timeToDisconnect: 0,
    shopData: null,
    shopEventListData: null,
    cartUpdateError: false,
    isZipCodeInputClicked:false,
    isLocateMeClicked:false,
    jqueryUiIsLoaded:false,
    inputSelectedDqeIds : [],
    cartItems: []
};

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CARTDATA_FETCHED :
            return {
                ...state,
                cartProductsTotalQuantity: action.payload.totalQuantity,
                cartItems: action.payload.items,
                shopId: action.payload.shopId,
            }
        case CART_DATA_UPDATE :
            return {
                ...state,
                cartProductsTotalQuantity: action.payload.totalQuantity,
                shopId: action.payload.shopId,
                cartUpdateError: false,
            }
        case CART_DATA_UPDATE_ERROR:
            return {
                ...state,
                cartUpdateError: true,
            }
        case IDENTITY_FETCHED :
            return {
                ...state,
                webUserId: action.payload.webUserId,
                navigationToken: action.payload.navigationToken,
                userData: action.payload.userData,
                shopId: action.payload.shopId,
                shopName: action.payload.shopName,
                timeToDisconnect: action.payload.timeToDisconnect,
                shopUrl: action.payload.shopUrl,
                hasShop: action.payload.hasShop,
            }
        case PRIMARY_SHOP_DATA_FETCHED :
            return {
                ...state,
                shopData: action.payload.shopData.shop,
                shopEventListData: action.payload.shopData.eventList
            }
        case SHOP_DATA_FETCHED:
            return {
                ...state,
                shopId: action.payload.shopId,
                shopName: action.payload.shopName,
                shopUrl: action.payload.shopUrl,
                hasShop: true,
                shopAndWharehouseData: action.payload.shopAndWharehouseData,
                openingWharehouseSchedule: action.payload.openingWharehouseSchedule,
                openingShopSchedule: action.payload.openingShopSchedule,
                shopNextDayOpen: action.payload.shopNextDayOpen,
                shopNextDayOpenSchedule: action.payload.shopNextDayOpenSchedule,
            }
        case CART_ITEMS_UPDATED:
            return {
                ...state,
                cartProductsTotalQuantity : action.payload.totalQuantity
            }
        case UPDATE_SHOP_ID :
            return {
                ...state,
                shopId: action.payload.shopId
            }
        case  UPDATE_LOAD_DQE:
            return {
                ...state,
                isZipCodeInputClicked : action.payload.isZipCodeInputClicked
            }
        case  UPDATE_LOAD_DQE_LOCATE_ME:
            return {
                ...state,
                isLocateMeClicked : action.payload.isLocateMeClicked
            }
        case  UPDATE_LOAD_JQUERY_UI:
            return {
                ...state,
                jqueryUiIsLoaded : action.payload.jqueryUiIsLoaded
            }
        case  UPDATE_INPUT_SELECTED_IDS:
            return {
                ...state,
                inputSelectedDqeIds : action.payload.inputSelectedDqeIds
            }
        case CART_ITEMS:
            return {
                ...state,
                cartItems : action.payload.cartItems
            }
        default:
            return state;
    }
};

export default headerReducer;
