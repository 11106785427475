import {
    CARTDATA_FETCHED, CART_ITEMS_UPDATED, CART_DATA_UPDATE, USER_IDENTITY_FETCHED,
    PRIMARY_SHOP_DATA_FETCHED,
    SHOP_EVENT_LIST_DATA_FETCHED,
    UPDATE_SHOPCIRCLE_LIST,
    UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE,
    SHOP_EVENT_EXECEPTIONNAL_EVENT,
    SHOW_SHOP_LIST_BLOC,
    UPDATE_SHOP,
    IDENTITY_FETCHED,
    UPDATE_SHOW_PRICE_WITHOUT_TAX,
    CART_DATA_UPDATE_ERROR,
    SHIPPING_CART_ITEMS_UPDATED,
    UPDATE_LOAD_DQE,
    UPDATE_LOAD_DQE_INPUT_KEY_UP,
    UPDATE_INPUT_SELECTED_IDS,
    UPDATE_LOAD_JQUERY_UI,
    UPDATE_LOAD_DQE_LOCATE_ME
} from '@/redux/reducers/Default/actionsTypes'

const initialState = {
    shop: {},
    customer: {},
    cart: {items: []},
    shopEventList: [],
    showBanners: false,
    bannersBlocList: [],
    shopExpEventTxt: '',
    showShopList: false,
    showRattachementBloc: false,
    circleShopList: [],
    logoVersion:'',
    timeToDisconnect: 0,
    showPriceWithoutTax: false,
    cartUpdateError: false,
    isZipCodeInputClicked : false,
    isZipCodeInputKeyUp : false,
    inputSelectedDqeIds : [],
    jqueryUiIsLoaded:false,
    isLocateMeClicked:false
};

const HeaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case CARTDATA_FETCHED:
            return {
                ...state,
                cart: action.payload.cart,
            }
        case  CART_ITEMS_UPDATED:
            return {
                ...state,
                cart: action.payload.cart,
            }
        case CART_DATA_UPDATE:
            return {
                ...state,
                cart: action.payload.cart,
                cartUpdateError: false,
            }
        case CART_DATA_UPDATE_ERROR:
            return {
                ...state,
                cartUpdateError: true,
            }
        case UPDATE_SHOP:
            return {
                ...state,
                shop: action.payload.shop
            }
        case  USER_IDENTITY_FETCHED :
            return {
                ...state,
                customer: action.payload.userData,
                timeToDisconnect: action.payload.userData.timeToDisconnect,

            }
        //  TODO Change USER_IDENTITY_FETCHED to IDENTITY_FETCHED after S2M
        case  IDENTITY_FETCHED :
            return {
                ...state,
                customer: action.payload.userData,
                timeToDisconnect: action.payload.timeToDisconnect,

            }
        case  PRIMARY_SHOP_DATA_FETCHED :
            return {
                ...state,
                shop: action.payload.shopData,
                showRattachementBloc: false
            }
        case SHOP_EVENT_LIST_DATA_FETCHED :
            return {
                ...state,
                shopEventList: action.payload.shopData.items[0].eventList ? [action.payload.shopData.items[0].eventList[0]] : []
            }
        case SHOP_EVENT_EXECEPTIONNAL_EVENT :
            return {
                ...state,
                shopExpEventTxt: action.payload.shopExpEventTxt
            }
        case SHOW_SHOP_LIST_BLOC :
            return {
                ...state,
                showShopList: action.payload.value
            }
        case UPDATE_SHOPCIRCLE_LIST :
            return {
                ...state,
                circleShopList: action.payload.circleShopList
            }
        case UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE :
            return {
                ...state,
                showRattachementBloc: action.payload.value,
                showShopList: false
            }
        case UPDATE_SHOW_PRICE_WITHOUT_TAX :
            return {
                ...state,
                showPriceWithoutTax : action.payload.value
            }
        case  UPDATE_LOAD_DQE:
            return {
                ...state,
                isZipCodeInputClicked : action.payload.isZipCodeInputClicked
            }
        case  UPDATE_LOAD_DQE_INPUT_KEY_UP:
            return {
                ...state,
                isZipCodeInputKeyUp : action.payload.isZipCodeInputKeyUp
            }
        case  UPDATE_INPUT_SELECTED_IDS:
            return {
                ...state,
                inputSelectedDqeIds : action.payload.inputSelectedDqeIds
            }
        case  UPDATE_LOAD_JQUERY_UI:
            return {
                ...state,
                jqueryUiIsLoaded : action.payload.jqueryUiIsLoaded
            }
        case  UPDATE_LOAD_DQE_LOCATE_ME:
            return {
                ...state,
                isLocateMeClicked : action.payload.isLocateMeClicked
            }
        case SHIPPING_CART_ITEMS_UPDATED:
            return {
                ...state,
                cartObject: action.payload.cartObject
            }
        default:
            return state;
    }

};


export default HeaderReducer;