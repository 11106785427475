import React, {memo} from "react";
import Script from "next/script";
import {getBuildId} from "@/utils/communUtil";
import {connect} from "react-redux";

const Scripts = memo((props) => {
    const {isZipCodeInputClicked, jqueryUiIsLoaded, isLocateMeClicked} = props;
    return (
        <>
            <Script src={"/assets/js/jquery.min.js?v=" + getBuildId()} strategy="beforeInteractive"/>
            {(isZipCodeInputClicked || jqueryUiIsLoaded) &&
                <Script src={"/assets/js/jquery-ui-light.min.js?v=" + getBuildId()}/>}
            <Script src="/assets/js/polyfill.js" strategy="beforeInteractive"/>
            <Script src={"/assets/js/wording-manager.js?v=" + getBuildId()} strategy="beforeInteractive"/>
            <Script src={"/assets/js/actionTrigger.min.js?v=" + getBuildId()} strategy="beforeInteractive"/>
            <Script src={"/assets/js/runDeferTag.min.js?v=" + getBuildId()} strategy="beforeInteractive"/>
            <Script src="/assets/js/jquery.fancybox.js" strategy="beforeInteractive"/>
            <Script src={"/assets/js/Default/consentCookiesManager.js?v=" + getBuildId()} strategy="beforeInteractive"/>
            {isZipCodeInputClicked && !isLocateMeClicked &&
                <Script src={"/assets/js/Default/jquery.dqe.min.js?v=" + getBuildId()}/>}
            <Script src="/assets/js/owl.carousel.min.js" strategy="beforeInteractive"/>
            {props.children}
        </>
    )


})
const mapStateToProps = (state) => {
    return {
        isZipCodeInputClicked: state.header.isZipCodeInputClicked,
        jqueryUiIsLoaded: state.header.jqueryUiIsLoaded,
        isLocateMeClicked: state.header.isLocateMeClicked,
    };
};
export default connect(mapStateToProps, null)(Scripts);