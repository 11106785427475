import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import {combineReducers} from "redux";
import {mobileReducers,defaultReducers} from './reducers/combinedReducers'
import {MOBILE_TEMPLATE_VERSION} from "@/utils/constants";


const dynamicReducers = {}
// creation des Store pour la version mobile et la version desktop
const mobileStore = createStore(createReducer(mobileReducers, dynamicReducers), composeWithDevTools(applyMiddleware(thunk)));
const defaultStore = createStore(createReducer(defaultReducers, dynamicReducers), composeWithDevTools(applyMiddleware(thunk)));

//Permet de récupérer le store par type de device
export const getAppStore = (deviceType) => {
    return deviceType.toLowerCase() === MOBILE_TEMPLATE_VERSION.toLowerCase() ? mobileStore : defaultStore;
}

// Permet d'injecter une liste des reducers dans le store
export const injectAppReducer = (deviceType, dynamicReducersList) => {
    if (dynamicReducersList) {
        Object.entries(dynamicReducersList).map(reducer => {
            if (reducer[0] && !dynamicReducers[reducer[0]]) {
                dynamicReducers[reducer[0]] = reducer[1]
            }
        })
        deviceType.toLowerCase() === MOBILE_TEMPLATE_VERSION.toLowerCase() ?
            mobileStore.replaceReducer(createReducer(mobileReducers, dynamicReducers)) :
            defaultStore.replaceReducer(createReducer(defaultReducers, dynamicReducers));
    }
}

//Permet de supprimer une liste des reducers du store
export const removeReducer = (deviceType, dynamicReducersList) => {
    dynamicReducersList.map(reducer => {
        if (reducer.key && dynamicReducers[reducer.key]) {
            delete dynamicReducers[reducer.key]
        }
    })
    deviceType.toLowerCase() === MOBILE_TEMPLATE_VERSION.toLowerCase() ?
        mobileStore.replaceReducer(createReducer(mobileReducers, dynamicReducers)) :
        defaultStore.replaceReducer(createReducer(defaultReducers, dynamicReducers));
}

// Permet de créer un combineReducer
function createReducer(staticReducers, asyncReducers) {
    return combineReducers({
        ...staticReducers,
        ...asyncReducers
    })
}