import {
    CARTDATA_FETCHED,
    SEARCH_RESULTS_FETCHED,
    TOP_SEARCHES_RESULTS_FETCHED,
    PRIMARY_SHOP_DATA_FETCHED,
    SHOP_DATA_FETCHED,
    IDENTITY_FETCHED, CART_ITEMS
} from '../actionsTypes'
import {getCartData} from '@/services/cartServices'
import {getKeywordsResults, getTopSearchesResults} from '@/services/headerSearchBarServices'
import {getPrimaryShopDataByShopId, getShopDataByShopId, updateShopCircle} from '@/services/shopServices'
import {handleToken} from "@/services/userServices";
import {initializeLocalStorageShopCircle, isValidShopCircle, getUserIdFromCookie} from "@/utils/Mobile/utils"
import {
    COMPACT_VIEW,
    MOBILE_TEMPLATE_VERSION
} from "@/utils/constants";
import ApiCaller from "@/services/ApiCaller/apiCaller";
import {MENUDATA_FETCHED} from "../../Default/actionsTypes";
import Cookies from "js-cookie";
import {getAvailabilityShopCircleData} from "../../../../utils/Mobile/utils";

export const fetchCartData = () => {
    return (dispatch, getState) => {
        getCartData(getState().identity.webUserId, COMPACT_VIEW)
            .then((response) => {
                dispatch({
                    type: CARTDATA_FETCHED,
                    payload: {totalQuantity: response.totalQuantities, shopId: response.shopId, items: response?.items}
                });
                dispatch({
                    type: CART_ITEMS,
                    payload: {cartItems: response.items}
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const fetchIdentity = (callback = null, isPrivateSale = false) => {
    return (dispatch) => {
        handleToken()
            .then((response) => {
                dispatch({
                    type: IDENTITY_FETCHED,
                    payload: {
                        webUserId: response.userId,
                        navigationToken: response.token,
                        userData: response,
                        hasShop: response.hasShop,
                        timeToDisconnect: response.timeToDisconnect,
                        shopId: (response.hasShop) ? response.primaryShopId : '',
                        shopName: (response.hasShop) ? response.primaryShopName : '',
                        shopUrl: (response.hasShop) ? response.primaryShopUrl : '',

                    }
                });
                if(isPrivateSale){
                    if(response?.group !== 'WebCustomers') {
                        window.location.href = "/Customer/Account/Identification"
                    } else if(!response?.eligibilityToPrivateSale){
                        window.location.href = "/Customer/Account/Dashboard/Id/"+response.userId
                    }
                }
                callback();
            })
            .catch((error) => {
                console.log(error);
            });
    };
};


export const fetchKeywordsResults = (keywordValue) => {
    return (dispatch, getState) => {
        getKeywordsResults(keywordValue)
            .then(response => {
                //TODO SES if not 200 --> do something
                /*                if(typeof tagSearchSuggestionUpdated !== 'undefined') {
                                tagSearchSuggestionUpdated();
                            }*/
                dispatch({
                    type: SEARCH_RESULTS_FETCHED,
                    payload: {keywords: keywordValue, searchResults: response}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };
}

export const fetchTopSearchesResults = () => {

    return function (dispatch) {
        getTopSearchesResults()
            .then(response => {
                dispatch({
                    type: TOP_SEARCHES_RESULTS_FETCHED,
                    payload: {topSearchesResults: response.topSearches}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })

    }

}

export const fetchShopData = (shopId,isSliding) => {
    return (dispatch) => {
        getShopDataByShopId(shopId,isSliding)
            .then(response => {

                let shopData = {
                    "shopId": response.data.shopInfos.id,
                    "shopName": response.data.shopInfos.name,
                    "shopUrl": response.data.shopInfos.url,
                    "shopAndWharehouseData": response.data,
                    "openingWharehouseSchedule": response.data.warehouseInfos.schedule,
                    "openingShopSchedule": response.data.shopInfos.schedule,
                    "shopNextDayOpen": response.data.shopInfos.shopNextDayOpen,
                    "shopNextDayOpenSchedule": response.data.shopInfos.shopNextDayOpenSchedule
                }
                dispatch({
                    type: SHOP_DATA_FETCHED,
                    payload: shopData
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };
}
export const getPrimaryShopData = (shopId) => {
    return (dispatch) => {
        getPrimaryShopDataByShopId(shopId)
            .then(response => {
                dispatch({
                    type: PRIMARY_SHOP_DATA_FETCHED,
                    payload: {shopData: response.items[0]}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };
}

export const synchronizeShopCircle = (shopCircleVersion) => {
    return new Promise((resolve, reject) => {
        localStorage.setItem('shopCircleVersion', shopCircleVersion)
        initializeLocalStorageShopCircle(shopCircleVersion);
        let data = {
            "shopCircle": isValidShopCircle() && !Cookies.get('shopCircleSource') ? localStorage.getItem('shopCircle') : ''
        }
        updateShopCircle(getUserIdFromCookie(), data)
            .then(response => {
                if (response?.data?.shopCircle?.primaryShop) {
                    if (Cookies.get('shopCircleSource') && !Cookies.get('availabilityShopCircleCloudFrontHeader')) {
                        Cookies.set('shopCircleCloudFrontHeader', JSON.stringify(response.data.shopCircle))
                        Cookies.set('availabilityShopCircleCloudFrontHeader', JSON.stringify(getAvailabilityShopCircleData(response.data.shopCircle)))
                    }
                    localStorage.setItem('shopCircle', JSON.stringify(response.data.shopCircle))
                    Cookies.set('availabilityShopCircle', JSON.stringify(getAvailabilityShopCircleData(response.data.shopCircle)), {
                        path: "/",
                        domain: "",
                        expires: 365
                    })
                } else {
                    localStorage.removeItem('shopCircle')
                }
                resolve(response);
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    })
}

export const getDefaultMenu = (deviceType, userAgent) => {
    const wordingVersion = MOBILE_TEMPLATE_VERSION;
    return function (dispatch) {
        ApiCaller.defaults.headers['User-Agent'] = userAgent;
        ApiCaller.get("V1/CMS/Menu", {params: {wordingVersion: wordingVersion}})
            .then(response => {
                dispatch({
                    type: MENUDATA_FETCHED,
                    payload: {menuItems: response.data.menuItems, isDefaultMenu: true}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
