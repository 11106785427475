import CommonAnimation from "./common";

const fiche = {
    init() {
        $('footer').css('padding-bottom', '40px');

        $('#ficheResumeCarousel .item .contentViewer3D .loader').css('display', 'none');
        var iframe = document.getElementById( 'api-frame' );
        var uid = '';
        var client = null;

        function loadmodel(){
            var iframeSrcExist = $('#api-frame').attr('src').length;

            if(iframeSrcExist === 0){
                $('#ficheResumeCarousel .item .contentViewer3D .loader').css('display', 'flex');
                uid = appData.view3D;
                document.addEventListener('load', () => console.log( 'viewerready' ))

                // By default, the latest version of the viewer API will be used.
                var client = new Sketchfab( iframe );

                // Alternatively, you can request a specific version.
                // var client = new Sketchfab( '1.0.0', iframe );

                client.init( uid, {
                    ui_infos: 0,
                    ui_help: 0,
                    ui_inspector: 0,
                    ui_settings: 0,
                    ui_vr: 1,
                    ui_watermark_link: !!0,
                    ui_watermark: !!0,
                    success: function onSuccess( api ){
                        //console.log( 'Success' );
                        $('#ficheResumeCarousel .item .contentViewer3D .loader').css('display', 'none');
                        api.load();
                        //api.start();

                        api.addEventListener( 'viewerready', function() {
                            //console.log( 'Viewer is ready' );
                            // once the viewer is ready, show the iframe
                            let $apiFrame = document.getElementById( 'api-frame' );
                            $apiFrame.classList.remove( 'hidden' ); // Remove hidden class
                        } );
                    },
                    error: function onError( callback ){
                        $('#ficheResumeCarousel .item .contentViewer3D .loader').css('display', 'none');
                    }
                });
            }
        }

        $('#ficheResumeCarousel').slick({
            arrows: false,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        })

        $('#switch-fiche').on('change', function () {
            if ($(this).prop('checked')) {
                $('.little-euro-ht').removeClass('d-none')
                $('.fiche-resume-ht-switch').removeClass('lightgray').addClass('lightblue')
            } else {
                $('.little-euro-ht').addClass('d-none')
                $('.fiche-resume-ht-switch').removeClass('lightblue').addClass('lightgray')
            }
        })
        $('#switch-sellers').on('change', function () {
            if ($(this).prop('checked')) {
                $('.little-euro-ht-sellers').removeClass('d-none')
                $('#switch-sellers-label').removeClass('black').addClass('navyblue')
            } else {
                $('.little-euro-ht-sellers').addClass('d-none')
                $('#switch-sellers-label').removeClass('navyblue').addClass('black')
            }
        })

        const initAndRefreshCarousels = () => {
            $('.ficheModalCarousel').each(function () {
                if (! $(this).hasClass('slick-initialized')) {
                    $(this).slick({
                        infinite: false,
                        arrows: false,
                        adaptiveHeight: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true
                    })
                }
                $(this).slick('refresh')
            })
        }
// eslint-disable-next-line no-undef
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    $('.sticky-info-title').addClass('sticky-info--visible')
                } else {
                    $('.sticky-info-title').removeClass('sticky-info--visible')
                }
            })
        })
        $('#modalFicheV2').on('show.bs.modal', function () {
            initAndRefreshCarousels()
            observer.observe($('#modal-title')[0])
        })
            .on('hide.bs.modal', function () {
                // Détruire ou nettoyer les carrousels slick lorsque la modal se ferme
                $('.ficheModalCarousel').each(function () {
                    if ($(this).hasClass('slick-initialized')) {
                        $(this).slick('unslick');
                    }
                });

                // ... other cleanup code
                $('.sticky-info').removeClass('sticky-info--visible');
            });

        $('#ficheResumeCarousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
            if($(slick.$slides[currentSlide].firstElementChild).hasClass('contentViewer3D')){
                loadmodel();
            }
        });

        $('.color__item').each(function () {
            if ($(this).find('input').prop('checked')) {
                $(this).addClass('color__item--active')
            }
        })
        $('.color__item').on('click', function (e) {
            e.preventDefault()
            $(this).find('input').prop('checked', !$(this).find('input').prop('checked'))
            $('.color__item').removeClass('color__item--active')
            $(this).addClass('color__item--active')
        })

        $('#ficheProductCarousel').slick({
            arrows: false,
            adaptiveHeight: true,
            centerMode: true,
            enterPadding: '20px',
            slidesToShow: 2,
            slidesToScroll: 1
        })

        $('#packProductCarousel3').slick({
            arrows: false,
            adaptiveHeight: false,
            centerMode: false,
            centerPadding: '0',
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            variableWidth: true
        })

        $('#packProductCarousel').slick({
            arrows: false,
            adaptiveHeight: true,
            centerMode: false,
            centerPadding: '20px',
            infinite: false,
            slidesToShow: 2,
            slidesToScroll:2
        })

        /* Calcul largeur block "Completez votre achat" */
        if ($('.fiche-bundle__table .fiche-bundle__table__item').length > 0) {
            var largeurBlockCurrent = 0
            $('.fiche-bundle__table .fiche-bundle__table__item').each(function () {
                largeurBlockCurrent += $(this).outerWidth()
            })
            $('.fiche-bundle__table').css('width', largeurBlockCurrent)
        }
        /* LABEL CHECKED */
        $('.livraison-list__row__radio input').on('change', function () {
            $('.livraison-list__row__radio label').removeClass('label--checked')
            $(this).next('label').addClass('label--checked')
        })
        /*  */
        $('.input-cp').on('keyup', function () {
            if ($(this).val() !== '') {
                $('#cp_list').fadeIn()
            } else {
                $('#cp_list').fadeOut()
            }
        })
        $('#zipCity').on('keyup', function () {
            if ($(this).val() !== '') {
                $('ul[id^="ui-id-"]').fadeIn()
            } else {
                $('ul[id^="ui-id-"]').fadeOut()
            }
        })

        var mzOptions = {
            onZoomReady: function() {
                /!* délais sur libellé info viewer  *!/
                setInterval(function() {
                    $(".MagicZoom .mz-hint").addClass('mz-hint-hidden');
                }, 5000);
            }
        };
        mzOptions.onZoomReady();

        if(CommonAnimation.isSafariAndiOS()){
            $('img.lazy[data-src]').each(function(){
                $(this).attr('src',$(this).attr('data-src'))
            })
        }

        if (document.referrer.split('/')[2] == location.hostname) {
            $('#go-back-button').show();
            $('#go-back-button').click(function (event) {
                event.preventDefault();
                history.go(-1);
            })
        } else {
            $('#go-back-button').hide();
        }
    }
}

export default fiche
