import {
    getUserIdFromCookie,
    getAvailabilityShopCircleData,
    getProductAvailabilityApiParams,
    getDataFromShopCircle
} from "@/utils/Mobile/utils";
import {hideLoader,closeStockLocatorModal,hideModal} from "@/animations/Mobile/productSheetPage";
import {UPDATE_GEO_ERROR_BLOC_STATUS,
    STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED,
    SHIPPING_AVAILABILITY_DATA_FETCHED,
    AVAILABILITY_DATA_FETCHED,
    SEARCH_SHOP_RESULT_FETCHED,
    CITY_SEARCHRESULTS_FETCHED,
    UPDATE_SHOP_ID,
    SHIPPING_CART_ITEMS_UPDATED,
    SHOW_ATTACHMENT_BLOCK
} from "../actionsTypes";
import {getProductAvailabilities} from "@/services/productServices";
import {fetchShopCircle, updatePrimaryShop} from "@/services/shopServices";
import Cookies from 'js-cookie';
import {fetchShopData} from "../header/headerActions";
import * as shopAnimations from "@/animations/Mobile/shopPage";
import * as geolocationAnimations from "@/animations/Mobile/geolocation";
import {getInputElementValue} from "@/animations/Mobile/geolocation";
import {updateShopInCartViewShipping} from "@/services/cartServices";
import {getLocationByCity,getLocationByZipCode} from "@/services/geolocalisationServices";
import {fechFiltredProductListItems} from "../productList/productListActions"



export const fetchProductAvailabilities = (params, context = null) => {
    return function (dispatch) {
        getProductAvailabilities(getProductAvailabilityApiParams(params.allShops, params.productId, context))
            .then(response => {
                let availabilityData = {};
                let genericAvailabilitytData = {};

                if (response.data[0] && response.data[0].detailedAvailablity) {
                    availabilityData = response.data[0].detailedAvailablity;
                    genericAvailabilitytData = response.data[0].genericAvailability;
                } else if (response.data[0] && response.data[0].availabilityByShops && response.data[0].availabilityByShops[0]) {
                    availabilityData = response.data[0];
                    genericAvailabilitytData = response.data[0].genericAvailability;
                }

                if (params.didShopChanged) {
                    hideLoader()
                }
                if (params.allShops) {
                    if (response.data[0] && response.data[0].availabilityByShops) {
                        dispatch({
                            type: UPDATE_GEO_ERROR_BLOC_STATUS,
                            element: {value: false}
                        });
                    } else {
                        dispatch({
                            type: UPDATE_GEO_ERROR_BLOC_STATUS,
                            element: {value: true}
                        });
                    }
                    dispatch({
                        type: STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED,
                        payload: {stockLocatorAvailabilityData: response.data[0].availabilityByShops}
                    });
                } else {
                    if (params.contexte === 'shippingCartItem') {
                        dispatch({
                            type: SHIPPING_AVAILABILITY_DATA_FETCHED,
                            payload: {
                                shippingAvailabilityData: availabilityData,
                                didShopChanged: params.didShopChanged,
                                productId: params.productId
                            }
                        });
                    } else {
                        dispatch({
                            type: AVAILABILITY_DATA_FETCHED,
                            payload: {
                                availabilityData: availabilityData,
                                genericAvailabilitytData: genericAvailabilitytData,
                                didShopChanged: params.didShopChanged,
                                productId: params.productId
                            }
                        });
                    }
                }

            })

            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export const updateGeoErrorBlocStatus = () => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_GEO_ERROR_BLOC_STATUS,
            element: {value: true}
        });
    }
}

const setLocalStorageShopCircleValue = (calculationMethod, shopCircle) => {
    let currentZipCode = shopCircle.zipCode;
    let currentCity = shopCircle.city;
    let zipCode = getDataFromShopCircle('zipCode');
    let city = getDataFromShopCircle('city');
    if (zipCode.length > 0 && city.length > 0 && calculationMethod == 'AroundShop' && currentZipCode == '' && currentCity == '') {
        shopCircle.zipCode = zipCode;
        shopCircle.city = city;
    }
    localStorage.setItem('shopCircle', JSON.stringify(shopCircle));
}

const getAvailabilityFilterIsApplied = (productListParams) => {
    if(productListParams.currentFiltersValue){
        if(productListParams.currentFiltersValue['AvailabilityShopIdList']){
            return productListParams.currentFiltersValue['AvailabilityShopIdList'].length > 0
        }
        return false
    }
    return false
}
export const updatePrimaryShopInDbAndInCookie = (callParam, calculationMethod, context, productId, sourcePage, locationsData = null, geolocCallBack = null, productListParams = null, cookieDomain = null,currentPage) => {
    let putParams = {
        CalculationParams: callParam,
        CalculationMethod: calculationMethod
    }
    if(context === 'locateShopResult') {
        putParams.View = 'Heavy'
        putParams.EveryShop = '1'
    }
   let userId = getUserIdFromCookie() ;
    return function (dispatch) {
        updatePrimaryShop(userId,putParams).then(
            response => {
                let shopCircle = response.data;
                if(geolocCallBack) {
                    geolocCallBack(shopCircle)
                }
                if (locationsData !== null) {
                    shopCircle.zipCode = locationsData.zipCode;
                    shopCircle.city = locationsData.city;
                }
                setLocalStorageShopCircleValue(calculationMethod, response.data);
                Cookies.set('availabilityShopCircle', JSON.stringify(getAvailabilityShopCircleData(response.data)), {
                    path: "/",
                    domain: cookieDomain,
                    expires: 365
                });
                if (context !== "locateShop" && context !== "locateShopResult") {
                    appData.actionTrigger.dispatch({
                        trigger: "userAttachedToShop",
                    });
                }
                hideModal('localModal');
                if (context == "productSheet" || (context == "headerLocationModal" && currentPage == 'productSheet')) {
                    closeStockLocatorModal()
                    dispatch(fetchProductAvailabilities({
                        didShopChanged: true,
                        allShops: null,
                        productId: productId,
                        contexte: "productSheet"
                    }))
                    dispatch({
                        type: UPDATE_SHOP_ID,
                        payload: {shopId: shopCircle.primaryShop.id}
                    });
                } else if (context == "stockLocator") {
                    dispatch(fetchProductAvailabilities({
                        didShopChanged: true,
                        allShops: true,
                        productId: productId,
                        contexte: "stockLocator"
                    }))
                    dispatch({
                        type: UPDATE_SHOP_ID,
                        payload: {shopId: shopCircle.primaryShop.id}
                    });
                } else if (context === "productsListFiltersBlock" || context === 'availabilityLocalisationModal' || (context === "headerLocationModal" && currentPage === 'ProductsList') || (context === "headerLocationModal" && currentPage === 'ProductsSearchList')) {
                    if ((context === 'productsListFiltersBlock') || (context === 'availabilityLocalisationModal')) {
                        appData.actionTrigger.dispatch({
                            trigger: "updateShopCircleSuccess",
                            payload: {
                                label: context === 'productsListFiltersBlock' ? 'filtre_géolocalisation' : 'filtre_géolocalisastion_pop_in'
                            }
                        });
                    }
                    if(context === 'availabilityLocalisationModal'){
                        hideModal('dispoMagasinModal2');
                    }
                    if(context === 'productsListFiltersBlock'){
                        dispatch({
                            type: SHOW_ATTACHMENT_BLOCK,
                            showAttachmentBlock: false
                        });
                    }
                    let availabilityFilterIsApplied = (context === "productsListFiltersBlock") ? getAvailabilityFilterIsApplied(productListParams) : null
                    dispatch(fechFiltredProductListItems(productListParams.currentSort, productListParams.currentFiltersValue, productListParams.categoryId,null,null, availabilityFilterIsApplied, productListParams.madIsActifInFiltredProductList))
                }

                if (calculationMethod == "AroundShop") {
                    if (productId === undefined || productId === null) {
                        return true;
                    } else if (sourcePage == 'shippingPage') {
                       // dispatch(loadCartApiResponse('shipping'))
                    } else {
                        dispatch(fetchProductAvailabilities({
                            didShopChanged: true,
                            allShops: null,
                            productId: productId,
                            contexte: "AroundShop1"
                        }))
                    }
                } else {
                    if (context === 'locateShopResult') {
                        dispatch({
                            type: SEARCH_SHOP_RESULT_FETCHED,
                            payload: {shopCircleData: shopCircle}
                        });
                        dispatch(fetchShopData(response.data.primaryShop.id))
                        shopAnimations.isBlocErrorVisible() && shopAnimations.displayLocateShopError('hideError')
                        shopAnimations.hideKeyboard('zipCity')
                        shopAnimations.setInputElementValue('#zipCity', shopCircle.zipCode + ' ' + shopCircle.city)
                        if (sourcePage !== "sourceGeolocSearchShop") {
                            geolocationAnimations.resetElementValue('latitude');
                            geolocationAnimations.resetElementValue('longitude');
                        }
                    } else if (context === 'locateShop'){
                        let url = '/magasins/resultats-magasins?zip_code=' + shopCircle.zipCode + '&city=' + shopCircle.city
                        if (sourcePage === "sourceGeolocSearchShop") { /*if click "voir les mag autour de moi" */
                            url = "/magasins/resultats-magasins?latitude=" + getInputElementValue('#latitude') + "&longitude=" + getInputElementValue('#longitude')
                        } else if (shopCircle.insee) {
                            url += '&insee=' + shopCircle.insee
                        }
                        window.location.href = url
                    } else if (context == 'headerLocationModal' && currentPage !== "productSheet") {
                        if (currentPage == "cart") {
                           // dispatch(loadCartApiResponse('cart'))
                        }
                        dispatch(fetchShopData(response.data.primaryShop.id))
                    } else if (currentPage !== "shipping" && currentPage !== "productSheet" && context !=='locateShop' && context !=='locateShopResult' && context !== "productsListFiltersBlock" && context !== 'availabilityLocalisationModal') {
                        dispatch(fetchProductAvailabilities({
                            didShopChanged: true,
                            allShops: true,
                            productId: productId,
                            contexte: "AroundShop2"
                        }))
                    } else if (context === "productsListFiltersBlock" || context === 'availabilityLocalisationModal'){
                        dispatch({
                            type: SEARCH_SHOP_RESULT_FETCHED,
                            payload: {shopCircleData: shopCircle}
                        });
                        dispatch(fetchShopData(response.data.primaryShop.id))
                    }
                }

            }).catch((error) => {
            if (context === 'stockLocator' || (error.response && error.response.status === 404)) {
                dispatch(updateGeoErrorBlocStatus())
            }
            geolocationAnimations.showLocalisationErrorMessage(context)
            console.log(error);
        })
    }

}


export function updateShopInCart(shopId) {
    tunnelPageAnimation.showLoader();
    return function (dispatch) {
        const data = {}
        data.action = "updateShop"
        data.value = shopId
        updateShopInCartViewShipping(getUserIdFromCookie(),data).then(response => {
                tunnelPageAnimation.hideLoader();
                dispatch({
                    type: SHIPPING_CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: response.data,
                        billingFirstname: response.data.billingAddress.firstname,
                        billingPhone: response.data.billingAddress.phone,
                        billingLastname: response.data.billingAddress.lastname,
                        billingZipCode: response.data.billingAddress.zipCode,
                        billingCity: response.data.billingAddress.city,
                        billingStreetAddress: response.data.billingAddress.streetAddress,
                        billingStreetAddressComplement: response.data.billingAddress.streetAddressComplement,
                        billingCustomerAddressId: response.data.billingAddress.customerAddressId,
                        billingCivility: response.data.billingAddress.civility,
                        billingIsBilling: response.data.billingAddress.isBilling,
                        billingIsShipping: response.data.billingAddress.isShipping,
                        shippingFirstname: response.data.shippingAddress.firstname,
                        shippingPhone: response.data.shippingAddress.phone,
                        shippingLastname: response.data.shippingAddress.lastname,
                        shippingZipCode: response.data.shippingAddress.zipCode,
                        shippingCity: response.data.shippingAddress.city,
                        shippingStreetAddress: response.data.shippingAddress.streetAddress,
                        shippingStreetAddressComplement: response.data.shippingAddress.streetAddressComplement,
                        shippingCustomerAddressId: response.data.shippingAddress.customerAddressId,
                        shippingCivility: response.data.shippingAddress.civility,
                        shippingIsBilling: response.data.shippingAddress.isBilling,
                        shippingIsShipping: response.data.shippingAddress.isShipping
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export const getShopCircle = (param, productId, calculationParam = 'ByInsee', context, cookieDomain = null) => {
    return function (dispatch) {
        fetchShopCircle(calculationParam,param).then(
            response => {
                let shopCircle = response.data;
                var availabilityData = [];
                if (shopCircle["circleShops"]) {
                    Cookies.set('stockLocatorShopCircle', JSON.stringify(shopCircle), {
                        path: "/",
                        domain: cookieDomain,
                        expires: 365
                    });
                }
                dispatch({
                    type: UPDATE_SHOP_ID,
                    payload: {shopId: shopCircle.primaryShop.id}
                });
                dispatch(fetchProductAvailabilities({
                    didShopChanged: false,
                    allShops: true,
                    productId: productId,
                    contexte: context
                }, context))
            })
            .catch((error) => {
                //TODO SES thow exception
                if (context === 'stockLocator' || error.response.status == 404) {
                    dispatch(updateGeoErrorBlocStatus())
                }
                geolocationAnimations.showLocalisationErrorMessage(context)
                console.log(error)
            })

    }
}

export const searchLocationByCity = cityNameFragment => {
    return function (dispatch) {
        getLocationByCity(cityNameFragment).then(
            response => {
                let results = response.data.locationInfosList.map((location) => {
                    return {
                        zipcode: location.cp,
                        city: location.lib,
                        insee: location.insee
                    }
                })
                dispatch({
                    type: CITY_SEARCHRESULTS_FETCHED,
                    payload: {locationsData: results, keywords: cityNameFragment}
                });
            })
    }

}

export const searchLocationByZipCode = cityNameFragment => {
    return function (dispatch) {
        getLocationByZipCode(cityNameFragment).then(
            response => {
                let results = response.data.locationInfosList.map((location) => {
                    return {
                        zipcode: location.cp,
                        city: location.lib,
                        insee: location.insee
                    }
                })
                dispatch({
                    type: CITY_SEARCHRESULTS_FETCHED,
                    payload: {locationsData: results, keywords: cityNameFragment}
                });
            })
    }

}





