import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import DestockedProductList from "./DestockedProductList"
import Map from "../Common/Map"
import * as actionsDestocked from "@/redux/reducers/Mobile/shop/destockedProductListActions"
import {INCREMENT_REVIEWS_PAGE_SIZE} from "@/redux/reducers/Mobile/actionsTypes"
import PropTypes from 'prop-types'
import * as  shopPageAnimation from "@/animations/Mobile/shopPage";


const ShopPageMap = (props) => {
    const {
        shopAndWharehouseData,
        googleMapId,
        totalReviewsPageSize,
        productshowedType,
        googleMapsKey,
        destockedProductsData
    } = props;
    const [mapsIsLoaded, setMapsIsLoaded] = useState(false);
    const [staticMode, setStaticMode] = useState(true);

    useEffect(() => {
        willMount();
        didMount();
    }, [])
    const didMount = () => {
        if (shopAndWharehouseData && shopAndWharehouseData.shopInfos.id) {
            shopPageAnimation.init();
            if (!destockedProductsData) {
                props.getDestockedProductsByShop(shopAndWharehouseData.shopInfos.id);
            }
        }
    }

    const willMount = () => {
        let matches = document.querySelectorAll("script[data-maps]");
        if (matches.length === 0) {
            new Promise((resolve, reject) => {
                let resolved = false,
                    errored = false,
                    fileRef = document.createElement('script');
                fileRef.setAttribute("type", "text/javascript");
                fileRef.setAttribute("data-maps", "true");
                fileRef.setAttribute("src", "https://maps.googleapis.com/maps/api/js?v=3&key=" + googleMapsKey);
                fileRef.setAttribute("defer", "true");
                fileRef.setAttribute("async", "true");
                fileRef.onreadystatechange = function () {
                    if (resolved) return handleLoad();
                    if (errored) return handleReject();
                    const state = fileRef.readyState;
                    if (state === 'complete') {
                        handleLoad()
                    } else if (state === 'error') {
                        handleReject()
                    }
                }
                const handleLoad = (evt) => {
                    resolved = true;
                    resolve();
                }
                const handleReject = (evt) => {
                    errored = true;
                    reject()
                }

                fileRef.addEventListener('load', handleLoad)
                fileRef.addEventListener('error', handleReject);
                document.getElementsByTagName("head")[0].appendChild(fileRef);
            }).then(() => {
                setMapsIsLoaded(true);
            });
        } else {
            setMapsIsLoaded(true);
        }
    }

    const getProductReviews = (id, pageSize) => {
        props.getProductReviews(id, pageSize);
    }
    const getCaracProduct = (id) => {
        props.getCaracProduct(id);
    }

    const incrementTotalReviewsPageSize = () => {
        props.incrementTotalReviewsPageSize();
    }

    const handleSeeLocItineraire = () => {
        shopPageAnimation.tagSeeStoreLocItineraire();
    }

    const handleSeeStoreLocWaze = () => {
        shopPageAnimation.tagSeeStoreLocWaze();
    }

    let latitude = '';
    let longitude = '';
    if (shopAndWharehouseData && shopAndWharehouseData.shopInfos) {
        latitude = shopAndWharehouseData.shopInfos.geolocation.latitude;
        longitude = shopAndWharehouseData.shopInfos.geolocation.longitude;
    }
    let mapOptions = {
        url: "https://maps.googleapis.com/maps/api/staticmap?",
        center: latitude + ',' + longitude,
        zoom: 15,
        maptype: "roadmap",
        size: "414x177",
        icon: "https://www.but.fr/animations/images/magasins/picto-cible.png",
        key: googleMapsKey
    }
    let statusUrlMap = mapOptions.url + "center=" + mapOptions.center + "&zoom=" + mapOptions.zoom + "&size=" + mapOptions.size + "&maptype=" + mapOptions.maptype + "&markers=anchor:center|icon:" + mapOptions.icon + "|" + mapOptions.center + "&key=" + mapOptions.key
    return (
        <>
            {
                (destockedProductsData && destockedProductsData.length > 0 && productshowedType) ?
                    <DestockedProductList destockedProductsData={destockedProductsData}
                                          shopAndWharehouseData={shopAndWharehouseData}
                                          productReviewData={props.productReviewData}
                                          getProductReviews={(id, pageSize) => getProductReviews(id, totalReviewsPageSize)}
                                          caracProductData={props.caracProductData}
                                          getCaracProduct={(id) => getCaracProduct(id)}
                                          productshowedType={productshowedType}
                                          incrementTotalReviewsPageSize={() => incrementTotalReviewsPageSize()}
                                          totalReviewsPageSize={totalReviewsPageSize}/>
                    : null
            }
            {mapsIsLoaded && googleMapId ?
                <div className="store-fiche-map">
                    <span className="store-fiche-map__title">Nous rendre visite</span>
                    <div className="store-fiche-map__map">
                        {!staticMode ? <Map divId={googleMapId} longitude={longitude}
                                            latitude={latitude} style={{height: "175px"}} zoom={15}/> :
                            <img id="google_map_img" src={statusUrlMap} onClick={() => {
                                setStaticMode(false);
                            }}/>
                        }
                    </div>
                    <div className="store-fiche-map__btns">
                        <div className="row">
                            <div className="col-5">
                                <a target="_blank"
                                   href={"https://www.google.fr/maps/dir//" + latitude + "," + longitude}
                                   onClick={handleSeeLocItineraire}
                                   className="link-i"><span className="icon-geoloc"></span>
                                    <div><span>Itinéraire</span></div>
                                </a>
                            </div>
                            <div className="col-7">
                                <a href={"waze://?ll=" + latitude + "," + longitude + "&navigate=yes"}
                                   onClick={handleSeeStoreLocWaze}
                                   className="link-waze">
                                    <img src={"/assets/images/Mobile/waze_logo.png"} alt=""/>
                                    <div><span>Afficher dans waze</span></div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> : null
            }
        </>
    );
}

ShopPageMap.propTypes = {
    shopAndWharehouseData: PropTypes.object,
    destockedProductsData: PropTypes.array,
    productReviewData: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    caracProductData: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    totalReviewsPageSize: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
    return {
        shopAndWharehouseData: state.header.shopAndWharehouseData ? state.header.shopAndWharehouseData : ownProps.shopAndWharehouseData,
        destockedProductsData: state.shop.destockedProductsData,
        productReviewData: state.shop.productReviewData,
        caracProductData: state.shop.caracProductData,
        totalReviewsPageSize: state.shop.totalReviewsPageSize,
        googleMapsKey: ownProps.googleMapsKey,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getDestockedProductsByShop: (currentShopId) => dispatch(actionsDestocked.getDestockedProductsByShop(currentShopId)),
        getProductReviews: (destockedProductId, pageSize) => dispatch(actionsDestocked.getProductReviews(destockedProductId, pageSize)),
        getCaracProduct: (destockedProductId) => dispatch(actionsDestocked.getCaracProduct(destockedProductId)),
        incrementTotalReviewsPageSize: () => dispatch({type: INCREMENT_REVIEWS_PAGE_SIZE})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageMap);