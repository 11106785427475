import React, {Fragment, memo, useState} from 'react'
import StoreSchedule from "./StoreSchedule";
import {Collapse} from "react-bootstrap";


const ShopTiming = (props) => {
    if(props.shop.shopNextDayOpenSchedule) {
        if (props.shop.shopNextDayOpen == props.shop.shopAndWharehouseData.shopInfos.currentDay) {
            return <span className="title green"> Aujourd'hui : {props.shop.shopNextDayOpenSchedule}</span>
        } else {
            return  <span className="title red"> {props.shop.shopAndWharehouseData.shopInfos.weekDays[props.shop.shopNextDayOpen-1]}: {props.shop.shopNextDayOpenSchedule}</span>
        }
    } else if (props.shop.shopAndWharehouseData.warehouseInfos.warehouseNextDayOpenSchedule) {

        if (props.shop.shopAndWharehouseData.shopInfos.isMerchant == 1 &&
           (props.shop.shopAndWharehouseData.shopInfos.isRdvContactLessPickup == 1 || props.shop.shopAndWharehouseData.shopInfos.isClickAndCollect == 1)) {
            return <span className="shop-linking__state shop-linking__state--close"><span className="red ">Magasin fermé</span><span className="green"> - Retrait en dépôt possible</span></span>
        } else {
            return <span className="red">Magasin fermé</span>
        }
    } else {
        return <span className="red">Magasin fermé</span>
    }

};


const ShopDetails = memo((props) => {
    const [collapseStoreHours, setCollapseStoreHours] = useState(false);
    return (
        <Fragment>
            <div id="magasin" className="tab-pane fade">
                <div className="store-fiche-tab-content__title">
                    <span className="city">{props.shopAndWharehouseData.shopInfos.name}</span>
                </div>
                <span className="store-fiche-tab-content__address">{props.shopAndWharehouseData.shopInfos.address.streetAddress}
                    <br/>{props.shopAndWharehouseData.shopInfos.address.zipCode} {props.shopAndWharehouseData.shopInfos.address.city}</span>
                <div className="store-fiche-tab-content__horaires">
                    <ShopTiming shop={props}/>
                    <a onClick={() => setCollapseStoreHours(!collapseStoreHours)}
                       className={collapseStoreHours ? "link" : "link collapsed"}
                       aria-controls="collapseShopHours"
                       aria-expanded={collapseStoreHours}><span>Tous les horaires</span>
                        <span className="icon-expand"></span></a>
                </div>

                <Collapse in={collapseStoreHours}>
                    <div id="collapseShopHours">
                        { (props.openingShopSchedule) &&<StoreSchedule openingSchedule={props.openingShopSchedule}/>  }
                    </div>
                </Collapse>
                {  (props.shopAndWharehouseData.shopInfos.contact.managerName) ?
                    <span className="store-fiche-tab-content__info"> Directeur: {props.shopAndWharehouseData.shopInfos.contact.managerName}</span>
                    : null}
            </div>
        </Fragment>
    )
})
export default ShopDetails;