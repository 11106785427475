import React, {useEffect} from 'react';

export const Tracking = ({trackingData}) => {
    useEffect(() => {
       /* appData.actionTrigger.dispatch({
            trigger: "JsDeferToJavaScript"
        }); */
    }, [trackingData])
    return (
        <div dangerouslySetInnerHTML={{__html: trackingData}}/>
    )
};

export default Tracking;