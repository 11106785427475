export const dispatchProductSheetLayersHandleClick = (action, label) => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickBlocLayer",
        payload: {
            eventAction: action,
            eventLabel: label
        }
    })
}

export const dispatchProductSheetQuestionsResponsesHandleClick = (label) => {
    appData.actionTrigger.dispatch({
        trigger: "ClickReviewOrQuestionUsers",
        payload: {
            eventLabel: label
        }
    })
}

export const dispatchProductSheetSellerNameHandleClick = (action) => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickNomVendeur",
        payload: {
            eventAction: action
        }
    })
}

export const dispatchServicesSellersPartnersHandleClick = () => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickServicesVendeurPartenaire",
    })
}

export const dispatchProductSheetReturnConditionsHandleClick = () => {
    appData.actionTrigger.dispatch({
        trigger: "seeReturnConditions",
    })
}

export const dispatchProductSheetMoreShippingHandleClick = (action) => {
    appData.actionTrigger.dispatch({
        trigger: "seeOfferShippingInfos",
        payload: {
            eventAction: action
        }
    })
}
export const dispatchProductSheetVideoAmbassadorHandleClick = (action,label) => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickAmbassadorVideo",
        payload: {
            eventAction: action,
            eventLabel: label
        }
    })
}
export const dispatchWithoutTaxePriceHandleClick = () => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickWithoutTaxPrice",
        payload: {
            eventAction: "Activation prix HT",
            eventLabel: "Prix HT"
        }
    })
}

export const dispatchCreditBlockHandleClick = () => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickPayInMultipleTimes",
        payload: {
            eventAction: "Clic bloc payer plusieurs fois",
            eventLabel: "Payer en plusieurs fois"
        }
    })
}

export const dispatchSpecialOfferHandleClick = (label,url) => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickSpecialOffer",
        payload: {
            eventAction: "Ouverture de l'offre promo FP",
            eventLabel: label,
            eventUrl: url
        }
    })
}

export const dispatchChooseBoxHandleClick = () => {
    appData.actionTrigger.dispatch({
        trigger: "chooseBoxClicked",
    })
}


export const triggerEcoConception = () => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickBlocLayer",
        payload: {
            eventAction: "Bloc eco-conception",
            eventLabel: "En savoir plus"
        }
    })
};
export const dispatchAmbassadorVideoHandleClick = () => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickAmbassadorVideo",
        payload: {
            eventAction: "Video ambassadeur",
            eventLabel: "Vidéo ambassadeur"
        }
    })
};

export const dispatchChoiceShopHandleClick = () => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickBlocMagasin",
        payload: {
            eventAction: "stock locator",
            eventLabel: "choisir ce magasin"
        }
    })
};

export const dispatchStockLocatorHandleClick = (label) => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickBlocStockLocator",
        payload: {
            eventAction: "stock locator",
            eventLabel: label
        }
    })
};

export function addToCartProductSuccessTracking(trackingData, quantity) {
    trackingData && appData.actionTrigger.dispatch({
        trigger: "addToCartProductSheet",
        payload: {
            eventLabel: trackingData.eventLabel,
            productVendorType: trackingData.productVendorType,
            productVendorName: trackingData.productVendorName,
            productVendorId: trackingData.productVendorId,
            productOffersNb: trackingData.productOfferNb,
            product_id: trackingData.product_id,
            price: trackingData.price,
            quantity: quantity
        }
    });
}

export function addToCartProductFailledTracking(trackingData) {
    trackingData && appData.actionTrigger.dispatch({
        trigger: "addToCartFailed"
    });
}

export const dispatchBundleItemHandleClick = (ProductName) => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickBundleItems",
        payload: {
            eventAction: "bundle",
            eventLabel: ProductName
        }
    })
};