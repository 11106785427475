import fiche from './fiche.js';

import decounterPictoSettings from "../../libs/decounterPictoSettings";


export const productSheetInit = () => {
    fiche.init()
}

export const getOfferIdFromUrl = () => {
    let url = window.location.href
    let match = url.match(/#(\d+)/)
    if (match && match[1]) return match[1]
    return null
}
export const showLoader = () => {
    $('.loader').css('display', 'flex');
}
export const checkOlapicBloc = () => {
    if ($('#olapic-item').length > 0) {
        return true
    }
    return false
};

export const showLocalisationErrorMessage = () => {
    $('.submit-btn').click(function (e) {
        e.preventDefault();
        if ($('#inseeCode').val() == "") {
            $('.display-error').show();
        }
    });
}
export const showMoreLivraisonOptions=()=>{
    $('#button-skill-information').click(function (e) {
        e.preventDefault();
        $(this).hide()
    });
}
export const bindMkpCollapsedInfos = (offerId) => {
    let collapseElmentId =  $("#infos-"+offerId) ;
    if (collapseElmentId.hasClass('show')) {
        collapseElmentId.removeClass('show')
        $("#row__infos__collapse-"+offerId).addClass('collapsed')
    } else {
        collapseElmentId.addClass('show')
        $("#row__infos__collapse-"+offerId).removeClass('collapsed')
    }

}
export const showMoreInfoButton = (modalBloc,offerId) => {
    let moreInfosButtonElmentId =  $("#pannel-seller__more__info-"+offerId) ;
    let moreInfosElmentId =  $("#"+modalBloc+"-modalInfos-"+offerId) ;
    if (moreInfosElmentId.hasClass('show')) {
        moreInfosElmentId.removeClass('show')
        moreInfosButtonElmentId.addClass('collapsed')
        moreInfosButtonElmentId.text('Voir plus d’informations sur l’offre')
    } else {
        moreInfosElmentId.addClass('show')
        moreInfosButtonElmentId.removeClass('collapsed')
        moreInfosButtonElmentId.text('Voir moins d’informations sur l’offre')
    }

}
export const bindMkpContainerClick = () => {
    $('#btn-seller').on('click', function (e) {
        e.preventDefault()
        $('.section-store--overlay').addClass('open')
    })
    $('.pannel-seller__close').on('click', function (e) {
        e.preventDefault()
        $('.section-store--overlay').removeClass('open')
    })
}


export const hideLoader = () => {
    $('.loader').css('display', 'none');
}

export const showModal = (id) => {
    $('#' + id).modal();
}
export const hideModal = (id) => {
    if ($(".modal-backdrop").length > 1) {
        $(".modal-backdrop").remove();
    }
    $('#' + id).modal('hide');
}
export const showModalCart = () => {
    $('#list-panier__row_model_add').modal();
}

export const bindAvailabilityButtons = () => {
    $('.info-livraison-btn,.info-livraison-back__btn').on('click', function (e) {
        e.preventDefault()
        $('body').addClass('modal--open')
        var href = $(this).attr('href')
        $(href).parent().find('.info-livraison-col').removeClass('info-livraison-col--active')
        $(href).addClass('info-livraison-col--active')
    })

    $('a[data-cible]').click(function (e) {
        e.preventDefault()
        var cible = $(this).data('cible')
        $(cible).fadeIn()
    })

    $('a[data-close],button[data-close]').click(function (e) {
        e.preventDefault()
        $('.modalfull').fadeOut()
        $('body').removeClass('modal--open')
    })
}

export const openPopin = () => {
    $('#openPopin').click();
}

export const closeStockLocatorModal = () => {
    $('#magasinModal').modal('hide');
}

export const formatShippingDuration = (shipping,context) => {
    let message = ''
    if(context ==='productSheet') {
        message = shipping.availabilityMessage.durationMessage;
    } else {
        message = shipping.duration.message.durationMessage;
    }
    return message;
}

export const FreeShipingCommercialStickersRendred = () => {
    return $('#fiche-panel-delivery').length == 0
}

export const renderNumberOfSteakers = (commercialOperations) => {
    return $('.fiche-panel-black').length == commercialOperations
}

export const removeCommertialSteaker = () => {
    $("#fiche-panel-black-1").remove();
}

export const isValidForm = (id) => {
    return $('#' + id).valid();
}

export const resetValidateForm = (id) => {
    $('#'+id+' .mat-field').removeClass('mat-field-error').removeClass('mat-field-valid');
}

export const openModalChat = () => {
    $('.section-chat--overlay').addClass('open')
}

export const closeModalChat = () => {
    $('.section-chat--overlay').removeClass('open')
}

export const productDetails = () => {
    $('.section-chat--overlay').removeClass('open')
    $('html, body').animate({ scrollTop: $('body').offset().top - 60 }, 600)
}


export const activateContextuelShippingInfo = (id, index) => {
    if (index == '-1') {
        $(id).addClass('info-livraison-col--active')
    } else {
        $(id).removeClass('info-livraison-col--active')
        $(id).addClass('info-livraison-col')
    }
}

export const getInputElementValue = (element) => {
    return $(element).val()
}

export const isValidInputValue = (inseeCode) => {
    return inseeCode && inseeCode.length > 4;
}

export const closePopinQuestion = () => {
    $('html, body').animate({ scrollTop: $('#all_qst').offset().top - 110 }, 600)
    $('.fiche__alert').fadeIn().delay(2000).fadeOut('slow')
}

export const closeErrorQuestionModal = () => {
    $('.fiche__alert--red').fadeOut()
}
export const hasTimerInPictoFalshSale = (selector) => {
    return $(selector).attr('data-time') ;
}
export const setTimerForFlashSale = () => {
    $('[id^=clockdiv-]').each(function(i, obj) {
        let clockId = $(this).attr("id");
        if(hasTimerInPictoFalshSale("#"+clockId)){
            let dateTimer = hasTimerInPictoFalshSale("#"+clockId)
            let dateTimeSplited = dateTimer.split(" ");
            let dateSplited = dateTimeSplited[0].split("-");
            let timeSplited = dateTimeSplited[1].split(":");
            const deadline = new Date(dateSplited[0],dateSplited[1]-1,dateSplited[2],timeSplited[0],timeSplited[1],timeSplited[2]); // gestion du délais jours * heures * minutes * secondes
            decounterPictoSettings.initializeClock(clockId, deadline);
        }
    });
}

export const isLightProductSheetPage = (productId) => {
    return appData.mvcUrl === 'Catalog/Product/LightDetails'
}

export const showPromotionalOffersPopin = () => {
    $('#modalFicheV2').modal().slideDown();
    $(".modal-backdrop").fadeIn();
}

export const hidePromotionalOffersPopin = () => {
    $('#modalFicheV2').modal().slideUp();
    $(".modal-backdrop").fadeOut();
}

export const hideSlickDots = () => {
    $('.hide-dots .slick-dots').hide();
}

export const initializeCarouselPromotionalOffersPopin = () => {
    $('.ficheModalCarousel').each(function () {
        if (! $(this).hasClass('slick-initialized')) {
            $(this).slick({
                infinite: false,
                arrows: false,
                adaptiveHeight: false,
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true
            });
        }
        $(this).slick('refresh');
    });
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const stickyInfoTitle = $('.sticky-info-title')[0];
            if (stickyInfoTitle) {
                stickyInfoTitle.style.setProperty('display', entry.isIntersecting ? '' : 'block', 'important');
            }
        });
    });
    observer.observe($('#modal-title')[0])
}
export const openPromoBundleOfferLayer = (eventName, eventCategory, eventAction, eventLabel, productPromoUrl) => {
    window.dataLayer.push({
        'event': eventName,
        'eventCategory': eventCategory,
        'eventAction': eventAction,
        'eventLabel': eventLabel,
        'product_promo_url': productPromoUrl
    });
}
export const displayPromoBundleOfferLayer = (eventName, pagePath) => {
    window.dataLayer.push({
        'event': eventName,
        'pagePath': pagePath,
    });
}