import React, {memo} from 'react';

const renderFirstName = (firstName) => {
    if (firstName) {
        return firstName;
    } else {
        return "Connecté(e)";
    }
}
const CustomerLink = memo((props) => {
    if (props.showCustomerZone !== undefined  && props.showCustomerZone == 1) {
        return (
            <a href={"/Customer/Account/Dashboard/Id/" + props.userId} title="Mon compte" data-popin="account" id="user"
               className="logged">{renderFirstName(props.firstName)}</a>
        );
    } else if ( props.showCustomerZone !== undefined  && props.showCustomerZone == 2) {
        return (
            <a data-gtm-libelle="compte" href="/Customer/Account/Identification" title="Me connecter" id="user"
               className="user gtm-header">{renderFirstName(props.firstName)}</a>
        );
    } else {
        return (
            <a data-gtm-libelle="compte" href="/Customer/Account/Identification" title="Me connecter" id="user"
               className="user gtm-header">Me connecter</a>
        );
    }
})
export default CustomerLink;