import $ from 'jquery'

export const showRelayPointMarkerInformation = (relayPoint, markerSelected, context) => {
    $(".modal-body__relais__maps").hide();
    $('.modal-body__relais-scrollwrapper').hide()
    $(".modal-body__carte").show();
    let relayPointImg = markerSelected.icon.split('/').pop()
    let relayPointName = relayPointImg.split('.')[0]
    let mapWidth = Math.floor($("#google_Map").width())
    let mapOptions = {
        url: appData.googleMapsUrl,
        center: markerSelected.getPosition().lat() + ',' + markerSelected.getPosition().lng(),
        zoom: 13,
        maptype: "roadmap",
        size: context === "homePoint" ? mapWidth+"x300" : mapWidth+"x170",
        icon: "http://www.but.fr/Kawa/Sources/Mobilev2/images/Markers/" + relayPointName + ".png",
        key: appData.googleMapsKey
    }
    let staticUrlMap = mapOptions.url + "center=" + mapOptions.center + "&zoom=" + mapOptions.zoom + "&size=" + mapOptions.size + "&maptype=" + mapOptions.maptype + "&markers=anchor:center|icon:" + mapOptions.icon + "|" + mapOptions.center + "&key=" + mapOptions.key
    $('#google_Map').html("<img id='google_map_img' src=" + staticUrlMap + " />");

}
export const showMap = (id, mapProp) => {
    let map = new google.maps.Map($('#' + id)[0], mapProp);
    return map
}
