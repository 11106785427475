import React, {memo} from 'react'
import Head from "next/head";

const MetaTags = memo((props) => {
    const {description, keywords, canonical, context, nextURL, previousURL, images,title} = props
    const prepareProductSheetMetaInfos = () => {
        return(
            <>
                <meta property="fb:app_id" content="124615274355461" />
                <meta property="og:url" content={canonical} />
                <meta property="og:title" content={ title } />
                <meta property="og:description" content={description} />
                <meta property="og:type" content="article" />
                { images && images.mainImage && images.mainImage.length > 0 &&
                <meta property="og:image" content={images.mainImage } />
                }
                <meta property="og:site_name" content="www.but.fr" />
            </>
        )
    }

    const prepareProductsListMetaInfos = () => {
        return(
            <>
                {previousURL && <link rel="prev" href={previousURL} />}
                {nextURL && <link rel="next" href={nextURL} />}
            </>
        )
    }

    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="language" content="fr"/>
            <meta name="robots" content=""/>
            <meta name="description"
                  content={description}/>
            <meta name="keywords"
                  content={keywords}/>
            <link rel="canonical" href={canonical}/>
            {context && context === 'productSheet' &&
                prepareProductSheetMetaInfos()}
            {context && context === 'productsList' &&
                prepareProductsListMetaInfos()}
        </Head>
    );
})

export default MetaTags;