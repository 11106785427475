import {
    APPLYFILTERSBLOCK,
    APPLYFILTERS,
    APPLYSORT,
    RESETFILTERS,
    HANDLENAVIGATION,
    HANDLEAPISTATUSPENDING,
    FETCHITEMFROMNAVIGATION,
    PRODUCTLIST_FETCHED,
    PRODUCTS_AVAILABILITY_DATA_FETCHED,
    HANDLE_RATTACHEMENT_MODAL_DATA,
    CURRENTFILTERS_VALUES,
    CHECK_AVAILABILITY_FILTER_DISPLAY, HANDLE_IS_PAGINATION_LOADING, PRODUCTLIST_EMPTY_ERROR
} from '../actionsTypes'


const initialState = {
    productsListData: [],
    filters: [],
    changeFromClient: false,
    itemsCount: 0,
    appliedFiltersValues: [],
    currentFiltersValue : [],
    productsAvailabilityData: [],
    rattachementModal: {
        modalShow : false,
        modalTitle : '',
    },
    checkAvailabilityFilterDisplay: false,
    isEmptyProductList: false,
    isFirstLoad: false,

    productListItems: null,
    htmlRelatedContent: '',
    merchListItems: null,
    category: null,
    metaInfos: null,
    currentSort: null,
    sortsCriteria: null,
    pager: null,
    previousPager: null,
    //currentFiltersValue: null,
    keyWords: null,
    label: '',
    lastLevelLabel: '',
    isProductListDataLoaded: false,
    octipasPayload: '',
    apiStatusPending: false,
    isPaginationLoading: false,
};

const productListReducer = (state = initialState, action) => {
    switch (action.type) {
        case APPLYFILTERS:
            return {
                ...state,
                productsListData: action.payload.productsListData,
                filters: action.payload.filters,
                itemsCount: action.payload.itemsCount,
                changeFromClient: true,
                appliedFiltersValues: action.payload.appliedFiltersValues
            }
        case APPLYFILTERSBLOCK:
            return {
                ...state,
                filters: action.payload.filters,
                productsListData: action.payload.productsListData ? action.payload.productsListData : state.productsListData,
                itemsCount: action.payload.itemsCount,
                changeFromClient: true,
                appliedFiltersValues: action.payload.appliedFiltersValues
            }
            case HANDLE_RATTACHEMENT_MODAL_DATA:
            return {
                ...state,
                rattachementModal: action.payload.rattachementModal
            }
        case APPLYSORT:
            return {
                ...state,
                changeFromClient: true,
                productsListData: action.payload.productsListData,
                filters: action.payload.filters,
                itemsCount: action.payload.itemsCount
            }
        case RESETFILTERS:
            return {
                ...state,
                filters: action.payload.filters,
                changeFromClient: true,
                productsListData: action.payload.productsListData,
                currentFiltersValue: action.payload.currentFiltersValue,
                appliedFiltersValues: action.payload.currentFiltersValue,
                itemsCount: action.payload.itemsCount
            }
        case HANDLENAVIGATION:
            return {
                ...state,
                changeFromClient: true,
                productsListData: action.payload.productsListData,
                pager: action.payload.pager,
                previousPager: action.payload.previousPager,
                apiStatusPending: action.payload.apiStatusPending,
                isPaginationLoading: action.payload.isPaginationLoading,
                isSeeMoreLoader: false,
            }
        case HANDLEAPISTATUSPENDING:
            return {
                ...state,
                apiStatusPending: action.payload.apiStatusPending
            }
        case HANDLE_IS_PAGINATION_LOADING:
            return {
                ...state,
                isPaginationLoading: action.payload.isPaginationLoading,
                isSeeMoreLoader: action.payload.isSeeMoreLoader,
            }
        case FETCHITEMFROMNAVIGATION:
            return {
                ...state,
                productsListData: action.payload.productsListData,
                appliedFiltersValues: action.payload.appliedFiltersValues,
                filters: action.payload.filters,
                changeFromClient: true,
                itemsCount: action.payload.itemsCount
            }
        case PRODUCTLIST_FETCHED:
            return {
                ...state,
                productsListData: action.payload.productsListData,
                appliedFiltersValues: action.payload.appliedFiltersValues,
                filters: action.payload.filters,
                isFirstLoad: action.payload.isFirstLoad ? action.payload.isFirstLoad : false,
                changeFromClient: true,
                isEmptyProductList: false,
                isProductListDataLoaded: true,
                itemsCount: action.payload.itemsCount,
                pager: action.payload.pager,
                previousPager: action.payload.pager,
                keyWords: action.payload.keyWords ? action.payload.keyWords : null,
                navigationMetaInfosConfigs: action.payload?.navigationMetaInfosConfigs,
                octipasPayload: action.payload?.octipasPayload,
                trackingUrlFapi: action.payload?.trackingUrlFapi
            }
        case PRODUCTS_AVAILABILITY_DATA_FETCHED:
            return {
                ...state,
                productsAvailabilityData: action.payload.productsAvailabilityData
            }
        case CURRENTFILTERS_VALUES:
            return {
                ...state,
                currentFiltersValue : action.payload.currentFiltersValue,
            }
        case CHECK_AVAILABILITY_FILTER_DISPLAY:
            return {
                ...state,
                checkAvailabilityFilterDisplay: action.payload.value,
            }
        case PRODUCTLIST_EMPTY_ERROR:
            return {
                ...state,
                isFirstLoad: false,
                isEmptyProductList: true,
            }
        default:
            return state;
    }
}


export default productListReducer;