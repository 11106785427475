import ApiCaller from '../../../../services/ApiCaller/apiCallerDefault';
import {SEARCH_RESULTS_FETCHED, TOP_SEARCHES_RESULTS_FETCHED} from '../actionsTypes'

export const fetchKeywordsResults = (keywordValue) => {

    return function (dispatch) {
        ApiCaller.get('/SearchEngine/Suggestions?KeyWords=' + keywordValue)
            .then(response => {
                dispatch({
                    type: SEARCH_RESULTS_FETCHED,
                    payload: {keywords: keywordValue, searchResults: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })

    }
}

export const fetchTopSearchesResults = () => {

    return function (dispatch) {
        ApiCaller.get('/SearchEngine/Suggestions')
            .then(response => {
                dispatch({
                    type: TOP_SEARCHES_RESULTS_FETCHED,
                    payload: {topSearchesResults: response.data.topSearches}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })

    }
}