export const pagesRequiredAuth = [
    '/customerAccount/orders',
    '/customerAccount/dashboard',
    '/customerAccount/profil',
    '/customerAccount/newsLetter',
    '/customerAccount/newPassword',
    '/customerAccount/socialMedia',
    '/customerAccount/orderDetails',
    '/customerAccount/kitchenProjects',
    '/inStore/purchase/shipping',
]
