import {
    getMetaInfosValueByCode,
    pushContextUrl,
    pushState,
    prepareShopCircle, getFetchingProductsAvailabilitiesIsAllowed,
    getProductsAvailabilityApiParamsForProductList
} from "@/utils/Mobile/utils";
import {NOT_CALL_API_AND_NOT_FRONT_DISPLAY_MODE, PRODUCT_LIST_PAGE, SEARCH_PAGE} from "@/utils/constants";
import ProductListAnimations from "@/animations/Mobile/productListPage"
import {activeShowPreviousPage} from "@/animations/Mobile/productListAnimations"
import {
    APPLYSORT,
    HANDLENAVIGATION,
    CURRENTFILTERSVALUE,
    APPLYFILTERS,
    APPLYFILTERSBLOCK,
    RESETFILTERS,
    PRODUCTLIST_FETCHED,
    PRODUCTS_AVAILABILITY_DATA_FETCHED,
    SHOW_ATTACHMENT_BLOCK,
    UPDATE_INPUT_SELECTED_IDS
} from '../../Mobile/actionsTypes'
import {
    getItemsFromNavigation,
    productListApplySort,
    fetchProductsListAvailabilityData
} from '../../../../services/productListServices'
import Router from "next/router";
import {MOBILE_TEMPLATE_VERSION, SEE_MORE_VALUE} from "@/utils/constants";
import productListAnimation from "@/animations/Mobile/productListPage";


export const applySort = (currentSort, appliedFiltersValues, categoryId, categoryView = null) => {
    ProductListAnimations.showLoader();
    let keyWords = null;
    let separator = "?";
    let isSearchPage = false;
    let url = "V1/Catalog/Categories/c" + categoryId + "/ProductsList";
    if(categoryView) {
        url += "?view=" + categoryView;
    }
    if (window.location.pathname == "/Common/Search/SearchProductsList") {
        //Attention : ne pas utiliser URLSearchParams en descktop car ne fonctionne pas sur IE
        const urlSearchParams = new URLSearchParams(window.location.search);
        keyWords = urlSearchParams.has("KeyWords") ? urlSearchParams.get("KeyWords") : null
        url = "V1/SearchEngine/ProductsList?KeyWords=" + keyWords;
        separator = '&';
    }

    let data = {};
    // Sort
    if (currentSort && currentSort.value != "" && currentSort.value != null) {
        data.SortedBy = JSON.stringify({"code": currentSort.value, "direction": "desc"});
    }
    data.wordingVersion = MOBILE_TEMPLATE_VERSION;

    // Filters
    if (appliedFiltersValues) {
        let tmp = [];
        Object.keys(appliedFiltersValues).map((filter, i) => {
            if (appliedFiltersValues[filter] && appliedFiltersValues[filter].length > 0) {
                let info = {"code": filter, "values": appliedFiltersValues[filter]};
                tmp.push(info);
            }
        });

        if (tmp.length > 0) {
            data.Filters = JSON.stringify(tmp);
        }

        // keyords de la page recherche
        data.keyWords = null;
        if (keyWords) {
            data.keyWords = keyWords;
            isSearchPage = true;
            //todo here change with getState, eclater les state  et ne pas passer   data.Context = 'SSR'
            data.Context = 'SSR'
        }
    }

    data.ShopCircle = prepareShopCircle()

    return (dispatch, getState) => {
        productListApplySort(MOBILE_TEMPLATE_VERSION, url, data)
            .then((response) => {
                if (isSearchPage) {
                    pushState(data.SortedBy, data.Filters, data.PageIndex, data.keyWords);
                } else {
                    if (data.PageIndex) {
                        let originContextUrl = getMetaInfosValueByCode("contextURL", response.metaInfos)
                        let indexOfPageIndex = originContextUrl.indexOf("PageIndex=");
                        let contextURL = originContextUrl.substr(0, indexOfPageIndex) + "PageIndex=" + data.PageIndex;
                        pushContextUrl(contextURL);
                    } else {
                        let originContextUrl = getMetaInfosValueByCode("contextURL", response.metaInfos)
                        pushContextUrl(originContextUrl);
                    }
                    /* let originContextUrl = getMetaInfosValueByCode("contextURL", response.metaInfos);
                     let n = originContextUrl.indexOf("?PageSize=30");
                     let contextURL = originContextUrl.substr(0, n);
                     pushContextUrl(contextURL);*/
                }
                ProductListAnimations.hideLoader();
                ProductListAnimations.scrollToDivAnimation("header-content");
                dispatch({
                    type: APPLYSORT,
                    payload: {
                        currentFiltersValue: appliedFiltersValues,
                        filters: response.keyWords ? response.productList.filters : response.filters,
                        itemsCount: response.keyWords ? response.productList.itemsCount : response.itemsCount,
                        appliedFiltersValues: response.keyWords ? response.productList.appliedFiltersValues : response.appliedFiltersValues,
                        productsListData: response.keyWords ? response.productList : response,
                        pager: response.keyWords ? response.productList.pager : response.pager,
                        octipasPayload : response.octipasPayload
                    }
                });
                let listItems = response.keyWords ? response.productList.listItems : response.listItems
                let filters = response.keyWords ? response.productList.filters : response.filters
                let madIsActifInFiltredProductList = response.keyWords ? response.productList.madIsActifInFiltredProductList : response.madIsActifInFiltredProductList
                if (listItems && listItems.values && listItems.values.length > 0 && getFetchingProductsAvailabilitiesIsAllowed(filters.values, madIsActifInFiltredProductList)) {
                    dispatch(fetchProductsAvailabilityData(listItems))
                } else {
                    dispatch({
                        type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                        payload: {
                            productsAvailabilityData: []
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export const handleNavigation = (currentSort, appliedFiltersValues, context, pager,previousPager,currentPageIndex, listItems, merchListItems, listItemsCount, productsAvailabilityData, categoryId, categoryView = null) => {
    let data = {};
    let keyWords = null
    let url = "V1/Catalog/Categories/c" + categoryId + "/ProductsList" + (categoryView ? "?view=" + categoryView : '');
    if (window.location.pathname == "/Common/Search/SearchProductsList") {
        //Attention : ne pas utiliser URLSearchParams en descktop car ne fonctionne pas sur IE
        const urlSearchParams = new URLSearchParams(window.location.search);
        keyWords = urlSearchParams.has("KeyWords") ? urlSearchParams.get("KeyWords") : null
        url = "V1/SearchEngine/ProductsList?KeyWords=" + keyWords;
    }
    // Sort
    if (currentSort && currentSort.value != "" && currentSort.value != null) {
        data.SortedBy = JSON.stringify({"code": currentSort.value, "direction": "desc"});
    }
    data.wordingVersion = MOBILE_TEMPLATE_VERSION;
    // Filters
    if (appliedFiltersValues) {
        let tmp = [];
        Object.keys(appliedFiltersValues).map((filter, i) => {
            if (appliedFiltersValues[filter] && appliedFiltersValues[filter].length > 0) {
                var info = {"code": filter, "values": appliedFiltersValues[filter]};
                tmp.push(info);
            }
        });

        if (tmp.length > 0) {
            data.Filters = JSON.stringify(tmp);
        }
    }

    // Page
    data.PageIndex = currentPageIndex;
    // keyords de la page recherche
    if (keyWords) {
        data.KeyWords = keyWords
        //todo here change with getState, eclater les state  et ne pas passer   data.Context = 'SSR'
        data.Context = 'SSR'
    }
    return (dispatch, getState) => {
        productListApplySort(MOBILE_TEMPLATE_VERSION, url, data)
            .then(response => {
                if (response.listItems) {
                    let filters = response.filters
                    let madIsActifInFiltredProductList = response.madIsActifInFiltredProductList
                    if (getFetchingProductsAvailabilitiesIsAllowed(filters.values, madIsActifInFiltredProductList)) {
                        dispatch(fetchProductsAvailabilityData(response.listItems, productsAvailabilityData, context))
                        if (response.keyWords) {
                            response.productList.listItems.values = context === SEE_MORE_VALUE ? listItems.values.concat(response.productList.listItems.values) : response.productList.listItems.values.concat(listItems.values);
                        } else {
                            response.listItems.values = context === SEE_MORE_VALUE ? listItems.values.concat(response.listItems.values) : response.listItems.values.concat(listItems.values);
                        }
                        response.merchListItems = merchListItems;
                    } else {
                        dispatch({
                            type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                            payload: {
                                productsAvailabilityData: []
                            }
                        });
                    }
                }
                if (response.productList && response.productList.listItems && response.productList.listItems.values) {
                    let filters = response.productList.filters
                    let madIsActifInFiltredProductList = response.productList.madIsActifInFiltredProductList
                    if (getFetchingProductsAvailabilitiesIsAllowed(filters.values, madIsActifInFiltredProductList)) {
                        dispatch(fetchProductsAvailabilityData(response.productList.listItems, productsAvailabilityData, context))
                        response.productList.listItems.values = context === SEE_MORE_VALUE ?  listItems.values.concat(response.productList.listItems.values) : response.productList.listItems.values.concat(listItems.values);
                    } else {
                        dispatch({
                            type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                            payload: {
                                productsAvailabilityData: []
                            }
                        });
                    }
                }
                pushState(data.SortedBy, data.Filters, data.PageIndex, data.KeyWords);
                ProductListAnimations.hideSmallLoader();
                let divToScroll = ((listItemsCount % 2 === 0) ? listItemsCount - 2 : listItemsCount - 1)
                    + (merchListItems && merchListItems.positions ? merchListItems.positions.length : 0);
                ProductListAnimations.scrollToDivAnimation(divToScroll)
                activeShowPreviousPage(true)
                dispatch({
                    type: HANDLENAVIGATION,
                    payload: {
                        currentFiltersValue: appliedFiltersValues,
                        productsListData: response.keyWords ? response.productList : response,
                        filters: response.keyWords ? response.productList.filters : response.filters,
                        itemsCount: response.keyWords ? response.productList.itemsCount : response.itemsCount,
                        pager: context === SEE_MORE_VALUE ?  response.keyWords ? response.productList.pager : response.pager : pager,
                        previousPager: context !== SEE_MORE_VALUE ? response.keyWords ? response.productList.pager : response.pager : previousPager,
                        octipasPayload : response.octipasPayload
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };

}

export const getCurrentFiltersValue = (filters) => {
    return (dispatch, getState) => {
        let currentFiltersValue = {};
        if (filters.appliedFiltersValues) {
            filters.appliedFiltersValues.map((filter, i) => {
                    if (!currentFiltersValue[filter.filterCode]) {
                        currentFiltersValue[filter.filterCode] = [];
                    }
                    currentFiltersValue[filter.filterCode].push(filter.value);
                }
            );
        }
        if (!(Object.keys(getState().blockFilter.currentFiltersValue).length == 0 && Object.keys(currentFiltersValue).length == 0)) {
            dispatch({
                type: CURRENTFILTERSVALUE,
                payload: {currentFiltersValue: currentFiltersValue}
            });
        }
    }
}

export function fechFiltredProductListItems(currentSort, appliedFiltersValues, categoryId, productsList, viewMode,filterItem = null, availabilityFilterIsApplied = null, madIsActifInFiltredProductList = null, categoryView = null) {
    ProductListAnimations.showLoader();
    let keyWords = null;
    let separator = "?";
    let isSearchPage = false;

    let url = "V1/Catalog/Categories/c" + categoryId + "/ProductsList";
    if (viewMode) {
        url += "?View=" + viewMode;
    }
    if(categoryView) {
        url += viewMode ? "&view=" + categoryView : "?view=" + categoryView;
    }
    if (window.location.pathname == "/Common/Search/SearchProductsList") {
        //Attention : ne pas utiliser URLSearchParams en descktop car ne fonctionne pas sur IE
        const urlSearchParams = new URLSearchParams(window.location.search);
        keyWords = urlSearchParams.has("KeyWords") ? urlSearchParams.get("KeyWords") : null
        url = "V1/SearchEngine/ProductsList?KeyWords=" + keyWords;
        if (viewMode) {
            url = "V1/SearchEngine/ProductsList?View=" + viewMode + "&KeyWords=" + keyWords;
        }
        isSearchPage = true;
    }

    let data = {};
    // Sort
    if (currentSort && currentSort.value != "") {
        data.SortedBy = JSON.stringify({"code": currentSort.value, "direction": "desc"});
    }
    data.wordingVersion = MOBILE_TEMPLATE_VERSION;
    // Filters
    if (appliedFiltersValues) {
        let tmp = [];
        Object.keys(appliedFiltersValues).map((filter, i) => {
                if (appliedFiltersValues[filter] && appliedFiltersValues[filter].length > 0 && !(filter === appData.availabilityShopIdListFilter && availabilityFilterIsApplied)) {
                    let info = {"code": filter, "values": appliedFiltersValues[filter]};
                    tmp.push(info);
                }
            }
        );

        if (tmp.length > 0) {
            data.Filters = JSON.stringify(tmp);
        }

    }

    if (keyWords) {
        data.keyWords = keyWords;
        //todo here change with getState, eclater les state  et ne pas passer   data.Context = 'SSR'
        data.Context = 'SSR'
    }

    data.ShopCircle = prepareShopCircle()
    return function (dispatch) {
        productListApplySort(MOBILE_TEMPLATE_VERSION, url, data)
            .then(response => {
                if (isSearchPage) {
                    pushState(data.SortedBy, data.Filters, data.PageIndex, data.keyWords);
                } else {
                    let originContextUrl = getMetaInfosValueByCode("contextURL", response.metaInfos);
                    let n = originContextUrl.indexOf("?PageSize=30");
                    if (n > -1) {
                        originContextUrl = originContextUrl.substr(0, n);
                    }
                    pushContextUrl(originContextUrl);
                }
                ProductListAnimations.hideLoader();
                if (viewMode && viewMode == "FiltersBlock") {
                    if (keyWords) {
                        dispatch({
                            type: APPLYFILTERSBLOCK,
                            payload: {
                                currentFiltersValue: appliedFiltersValues,
                                filters: response.keyWords ? response.productList.filters : response.filters,
                                itemsCount: response.keyWords ? response.productList.itemsCount : response.itemsCount,
                                appliedFiltersValues: response.keyWords ? response.productList.appliedFiltersValues : response.appliedFiltersValues,
                                octipasPayload : ''
                            }
                        });
                    } else {
                        dispatch({
                            type: APPLYFILTERSBLOCK,
                            payload: {
                                currentFiltersValue: appliedFiltersValues,
                                productsListData: response,
                                filters: response.keyWords ? response.productList.filters : response.filters,
                                itemsCount: response.keyWords ? response.productList.itemsCount : response.itemsCount,
                                appliedFiltersValues: response.keyWords ? response.productList.appliedFiltersValues : response.appliedFiltersValues,
                                octipasPayload : ''
                            }
                        });
                    }
                } else {
                    dispatch({
                        type: APPLYFILTERS,
                        payload: {
                            currentFiltersValue: appliedFiltersValues,
                            productsListData: response.keyWords ? response.productList : response,
                            filters: response.keyWords ? response.productList.filters : response.filters,
                            itemsCount: response.keyWords ? response.productList.itemsCount : response.itemsCount,
                            appliedFiltersValues: response.keyWords ? response.productList.appliedFiltersValues : response.appliedFiltersValues,
                            pager: response.keyWords ? response.productList.pager : response.pager,
                            octipasPayload : response.octipasPayload
                        }
                    });
                    dispatch({
                        type: UPDATE_INPUT_SELECTED_IDS,
                        payload: {
                            inputSelectedDqeIds: []
                        }
                    });
                }
                if (availabilityFilterIsApplied) {
                    let currentFiltersValue = []
                    let filters = response.keyWords ? response.productList.filters : response.filters
                    let availabilityFilter = filters.values.filter(filterItem => filterItem[1] === 'AvailabilityShopIdList')
                    let availabilityFilterItem = availabilityFilter && availabilityFilter[0] && availabilityFilter[0][5] ? availabilityFilter[0][5].filter(item => item[0] == 'AvailabilityShopIdList' && item[2] == 'all') : null;
                    if (availabilityFilterItem.length > 0) {
                        currentFiltersValue['AvailabilityShopIdList'] = availabilityFilterItem[0][3].split('/')
                        availabilityFilterIsApplied = null
                        dispatch(fechFiltredProductListItems(currentSort, currentFiltersValue, categoryId, null, 'FiltersBlock'))
                    }
                } else {
                    let listItems = response.keyWords ? response.productList.listItems : response.listItems
                    let filters = response.keyWords ? response.productList.filters : response.filters
                    let madIsActifInFiltredProductList = response.keyWords ? response.productList.madIsActifInFiltredProductList : response.madIsActifInFiltredProductList
                    if (listItems && listItems.values && listItems.values.length > 0 && getFetchingProductsAvailabilitiesIsAllowed(filters.values, madIsActifInFiltredProductList)) {
                        dispatch(fetchProductsAvailabilityData(listItems))
                    } else {
                        dispatch({
                            type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                            payload: {
                                productsAvailabilityData: []
                            }
                        });
                    }
                    let showT2S = appliedFiltersValues ? Object.keys(appliedFiltersValues).length <= 1 : false;
                    if (window.location.pathname !== "/Common/Search/SearchProductsList") {
                        let productListItemsT2S = response.keyWords ? null : response.listItems;
                        let filterItemTMP = {}
                        if (filterItem) {
                            filterItemTMP.filterCode = filterItem?.filterCode ?? filterItem[0];
                            filterItemTMP.isApplied = filterItem?.isApplied ?? filterItem[5] ?? false;
                        } else {
                            filterItemTMP = filterItem;
                        }
                        productListAnimation.refreshT2S({
                            currentSort: [null, currentSort.label, null, currentSort.value],
                            filterItemTMP
                        }, productListItemsT2S, showT2S);
                    }
                }

            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }

}


export const resetFilters = (currentSort, categoryId, madIsActifInFiltredProductList = true, categoryView = null) => {
    ProductListAnimations.showLoader();
    let keyWords = null;
    let separator = "?";
    let isSearchPage = false;
    let url = "V1/Catalog/Categories/c" + categoryId + "/ProductsList" + (categoryView ? "?view=" + categoryView : '');
    if (window.location.pathname == "/Common/Search/SearchProductsList") {
        //Attention : ne pas utiliser URLSearchParams en descktop car ne fonctionne pas sur IE
        const urlSearchParams = new URLSearchParams(window.location.search);
        keyWords = urlSearchParams.has("KeyWords") ? urlSearchParams.get("KeyWords") : null
        url = "V1/SearchEngine/ProductsList?KeyWords=" + keyWords;
    }

    let data = {};
    // Sort
    if (currentSort && currentSort.value != "") {
        data.SortedBy = JSON.stringify({"code": currentSort.value, "direction": "desc"});
    }
    data.wordingVersion = MOBILE_TEMPLATE_VERSION;
    // keyords de la page recherche
    data.keyWords = null;
    if (keyWords) {
        data.keyWords = keyWords;
        isSearchPage = true;
        //todo here change with getState, eclater les state  et ne pas passer   data.Context = 'SSR'
        data.Context = 'SSR'
    }

    data.ShopCircle = prepareShopCircle()
    return (dispatch, getState) => {
        productListApplySort(MOBILE_TEMPLATE_VERSION, url, data)
            .then(response => {
                if (isSearchPage) {
                    pushState(data.SortedBy, data.Filters, data.PageIndex, data.keyWords);
                } else {
                    let originContextUrl = getMetaInfosValueByCode("contextURL", response.metaInfos);
                    let n = originContextUrl.indexOf("?PageSize=30");
                    let contextURL = originContextUrl.substr(0, n);
                    pushContextUrl(contextURL);
                }
                ProductListAnimations.hideLoader();
                // close collapse div
                ProductListAnimations.closeCollapses();
                ProductListAnimations.scrollToDivAnimation("header-content");
                dispatch({
                    type: RESETFILTERS,
                    payload: {
                        filters: response.keyWords ? response.productList.filters : response.filters,
                        productsListData: response.keyWords ? response.productList : response,
                        currentFiltersValue: response.keyWords ? response.productList.appliedFiltersValues : response.appliedFiltersValues,
                        itemsCount: response.keyWords ? response.productList.itemsCount : response.itemsCount,
                        pager: response.keyWords ? response.productList.pager : response.pager,
                        octipasPayload : response.octipasPayload
                    }
                });
                dispatch({
                    type: SHOW_ATTACHMENT_BLOCK,
                    showAttachmentBlock: false
                })
                let listItems = response.keyWords ? response.productList.listItems : response.listItems
                let filters = response.keyWords ? response.productList.filters : response.filters
                let madIsActifInFiltredProductList = response.keyWords ? response.productList.madIsActifInFiltredProductList : response.madIsActifInFiltredProductList
                if (listItems && listItems.values && listItems.values.length > 0 && getFetchingProductsAvailabilitiesIsAllowed(filters.values, madIsActifInFiltredProductList)) {
                    dispatch(fetchProductsAvailabilityData(listItems))
                } else {
                    dispatch({
                        type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                        payload: {
                            productsAvailabilityData: []
                        }
                    });
                }
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };

}
export const fetchProductsListData = (deviceType, pageIndex, currentSort, appliedFiltersValues, keyWords,context) => async (dispatch) => {
    const productListData = await getItemsFromNavigation(deviceType, null, pageIndex, currentSort, appliedFiltersValues, null, null, keyWords, context)
    if (keyWords && productListData.status === 302) {
        Router.push(productListData.headers.navigateto);
    } else {
        console.log('productListData=>=>=>',productListData)
        return dispatch({
            type: PRODUCTLIST_FETCHED,
            payload: {
                productsListData: productListData.productList,
                navigationMetaInfosConfigs: productListData.navigationMetaInfosConfigs,
                octipasPayload: productListData.octipasPayload,
                trackingUrlFapi: productListData.trackingUrlFapi,
                filters: productListData.productList.filters,
                itemsCount: productListData.productList.itemsCount,
                appliedFiltersValues: productListData.productList.appliedFiltersValues,
                pager: productListData.productList.pager,
            }
        });
    }

}

export function fetchProductsAvailabilityData(productListItems, productsAvailabilityData = [], context = '') {
    return function (dispatch) {
        if ([SEARCH_PAGE, PRODUCT_LIST_PAGE].includes(appData.context) && appData.availabilityDisplayMode === NOT_CALL_API_AND_NOT_FRONT_DISPLAY_MODE) {
            dispatch({
                type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                payload: {
                    productsAvailabilityData: []
                }
            });
        } else {
            fetchProductsListAvailabilityData(MOBILE_TEMPLATE_VERSION, '/Catalog/Products/Availabilities?' + getProductsAvailabilityApiParamsForProductList(productListItems.values))
                .then(response => {
                    response.data = productsAvailabilityData.length > 0 ? context === SEE_MORE_VALUE ? productsAvailabilityData.concat(response.data) : response.data.concat(productsAvailabilityData) : response.data;
                    dispatch({
                        type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                        payload: {
                            productsAvailabilityData: response.data
                        }
                    });
                })
                .catch((error) => {
                    //TODO SES thow exception
                    console.log(error);
                    dispatch({
                        type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                        payload: {
                            productsAvailabilityData: []
                        }
                    });
                })
        }
    }
}
