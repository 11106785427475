import {
    HANDLE_SHOP_OVERLAY,
    DESTOCKED_PRODUCTS_INFOS_FETCHED,
    GET_PRODUCT_REVIEWS,
    GET_CARAC_PRODUCT,
    INCREMENT_REVIEWS_PAGE_SIZE,
    SHOP_DATA_FETCHED
} from '../actionsTypes'


const initialState = {
    shopAndWharehouseData: null,
    openingWharehouseSchedule: null,
    openingShopSchedule: null,
    shopNextDayOpen: null,
    shopNextDayOpenSchedule: null,
    destockedProductsData: null,
    productReviewData: '',
    caracProductData: '',
    reviewPageSize: 0,
    totalReviewsPageSize: 0,
    opened: false
};

const ShopReducer = (state = initialState, action) => {
    switch (action.type) {
        case DESTOCKED_PRODUCTS_INFOS_FETCHED:
            return {
                ...state,
                destockedProductsData: action.payload.destockedProductsData
            }
        case GET_PRODUCT_REVIEWS:
            return {
                ...state,
                productReviewData: action.payload.productReviewData
            }
        case GET_CARAC_PRODUCT:
            return {
                ...state,
                caracProductData: action.payload.caracProductData
            }
        case SHOP_DATA_FETCHED:
            return {
                ...state,
                shopId: action.payload.shopId,
                shopName: action.payload.shopName,
                shopUrl: action.payload.shopUrl,
                shopAndWharehouseData: action.payload.shopAndWharehouseData,
                openingWharehouseSchedule: action.payload.openingWharehouseSchedule,
                openingShopSchedule: action.payload.openingShopSchedule,
                shopNextDayOpen: action.payload.shopNextDayOpen,
                shopNextDayOpenSchedule: action.payload.shopNextDayOpenSchedule,
            }
        case INCREMENT_REVIEWS_PAGE_SIZE:
            return {
                ...state,
                totalReviewsPageSize: state.totalReviewsPageSize + state.reviewPageSize
            }
        case HANDLE_SHOP_OVERLAY:
            return {
                ...state,
                opened: action.payload.opened
            }
        // case AVAILABILITY_DATA_FETCHED:
        //     if (action.payload.didShopChanged) {
        //         let shop = action.payload.availabilityData.availabilityByShops[0].shop
        //         return {
        //             ...state,
        //             shopAndWharehouseData: shop,
        //             openingWharehouseSchedule: shop.warehouseInfos.schedule,
        //             openingShopSchedule: shop.shopInfos.schedule,
        //             shopNextDayOpen: shop.shopNextDayOpen,
        //             shopNextDayOpenSchedule: shop.shopNextDayOpenSchedule,
        //             wharehouseNextDayOpen: shop.wharehouseNextDayOpen,
        //             wharehouseNextDayOpenSchedule: shop.wharehouseNextDayOpenSchedule
        //         }
        //     } else return state;
        // case STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED:
        //     let shop = action.payload.stockLocatorAvailabilityData[0].shop
        //     return {
        //         ...state,
        //         shopAndWharehouseData: shop,
        //         openingWharehouseSchedule: shop.warehouseInfos.schedule,
        //         openingShopSchedule: shop.shopInfos.schedule,
        //         shopNextDayOpen: shop.shopNextDayOpen,
        //         shopNextDayOpenSchedule: shop.shopNextDayOpenSchedule,
        //         wharehouseNextDayOpen: shop.wharehouseNextDayOpen,
        //         wharehouseNextDayOpenSchedule: shop.wharehouseNextDayOpenSchedule
        //     }
        default:
            return state;
    }
};

export default ShopReducer;

