import React from 'react';
import {dispatchEventSTPUSHUpdate} from "@/animations/Default/commonPage";

export const ServicesShops = () => {

    return (
        <div id="services_magasins_footer">
            <ul>
                <li className="click_collect">
                    <a href="/Services/Page/Retrait-2h">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.508 37.318">
                                <g id="icon_click_collect_2h" transform="translate(-282.007 -401.841)">
                                    <g id="Calque_1" data-name="Calque 1">
                                        <path id="trace_98" data-name="trace 98"
                                              d="M284.13,422.959a14.206,14.206,0,0,1,14.183-14.184,14.037,14.037,0,0,1,4.4.726,1.008,1.008,0,0,0,1.279-.624h0c.006-.016.011-.033.016-.05a1.026,1.026,0,0,0-.664-1.291h-.009a17.412,17.412,0,0,0-4.038-.776v-1.554H302.4a.517.517,0,0,0,.518-.515v-2.332a.518.518,0,0,0-.518-.518h-8.282a.518.518,0,0,0-.518.518h0v2.329a.517.517,0,0,0,.516.518h3.108v1.553a16.221,16.221,0,0,0-15.188,17.193h0a16.223,16.223,0,0,0,6.025,11.639l1.191-1.708A14.215,14.215,0,0,1,284.13,422.959Zm-.052-11.492a.5.5,0,0,0,.707.018h0l.017-.017,1.967-1.968a.5.5,0,0,0,.018-.706v0l-.017-.017-.828-.828a.5.5,0,0,0-.707-.018h0l-.017.017-1.967,1.967a.5.5,0,0,0-.018.707v0l.017.017Zm13.769,14.6,3.313-2.02a6.145,6.145,0,0,0,3.313-5.332c0-3-2.329-5.539-6.16-5.539-3.986,0-6.263,2.744-6.263,5.746a6.748,6.748,0,0,0,.155,1.192l3.468.1a2.93,2.93,0,0,1-.155-.932,2.581,2.581,0,0,1,2.3-2.839,2.462,2.462,0,0,1,.446-.008,2.332,2.332,0,0,1,2.574,2.061,2.391,2.391,0,0,1,.014.268c0,1.191-.569,2.071-2.019,2.951l-2.847,1.708a7.786,7.786,0,0,0-4.038,7.3h12.682V427.56h-8.282a3.5,3.5,0,0,1,1.5-1.5Zm11.8-6.47h1.294v-6.574h-1.294v2.64h-2.591v-2.64h-1.294v6.574h1.294V416.9h2.588Z"
                                              fill="#ffffff"/>
                                    </g>
                                    <g className="anim">
                                        <path id="el4"
                                              d="M290.238,437.088a16.519,16.519,0,0,0,6.729,2.071v-2.071a14.693,14.693,0,0,1-5.591-1.708Z"
                                              fill="#ffffff"/>
                                        <path id="el3"
                                              d="M307.32,433.878l1.191,1.708a15.979,15.979,0,0,0,4.141-4.969l-1.915-.828a14.7,14.7,0,0,1-3.417,4.092Z"
                                              fill="#ffffff"/>
                                        <path id="el2"
                                              d="M299.607,437.088v2.071a16.523,16.523,0,0,0,6.73-2.071l-1.191-1.708A14.436,14.436,0,0,1,299.607,437.088Z"
                                              fill="#ffffff"/>
                                        <path id="el1"
                                              d="M313.48,421.921a1.038,1.038,0,0,0-1.035,1.035,14.07,14.07,0,0,1-.725,4.452l1.915.828a16.384,16.384,0,0,0,.88-5.28,1.038,1.038,0,0,0-1.035-1.032Z"
                                              fill="#ffffff"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <p>
                            Click &amp; Collect
                        </p>
                    </a>
                </li>
                <li className="home_delivery">
                    <a href="/Services/Page/Livraison-domicile">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.072 42.421">
                                <g id="icon_livraison_domicile" transform="translate(-20.817 -19.127)">
                                    <path id="trace_106" data-name="trace 106"
                                          d="M401.425,418.476l-18.48-14.442a1.015,1.015,0,0,0-1.294,0l-18.48,14.442a1.062,1.062,0,0,0-.362,1.139,1.012,1.012,0,0,0,.984.673h4.555v3.779a1.035,1.035,0,0,0,2.071,0v-4.814a1.039,1.039,0,0,0-1.035-1.035h-2.588l15.529-12.061L397.8,418.269h-2.588a1.039,1.039,0,0,0-1.035,1.035v13.614a1.035,1.035,0,0,0,2.071,0V420.34H400.8a1.012,1.012,0,0,0,.984-.673A1.114,1.114,0,0,0,401.425,418.476Z"
                                          transform="translate(-341.948 -384.674)" fill="#ffffff"/>
                                    <path className="anim"
                                          d="M393.189,450.253l-9.369-4.711a.99.99,0,0,0-.932,0s-9.473,4.711-9.473,4.762a1.017,1.017,0,0,0-.466.88v9.369a.977.977,0,0,0,.569.932l9.421,4.711a1.1,1.1,0,0,0,.932,0h0l9.369-4.711a.977.977,0,0,0,.569-.932v-9.369A1.278,1.278,0,0,0,393.189,450.253Zm-13.51,4.969,2.64,1.294v7.092l-2.64-1.294Zm3.675-.466-2.329-1.191,7.04-3.572,2.381,1.191Zm0-7.092,2.381,1.191-7.04,3.572-2.433-1.191Zm-8.386,5.228,2.64,1.294v7.092l-2.64-1.294Zm16.72,7.04-7.3,3.675v-7.092l7.3-3.624Z"
                                          transform="translate(-346.86 -404.752)" fill="#ffffff"/>
                                </g>
                            </svg>
                        </div>
                        <p>
                            Livraison à domicile
                        </p>
                    </a>
                </li>
                <li className="expert_advice">
                    <a href="#" id="Footer_HorsTunnel_Picto_Contact_smart_tribune" onClick={(event) => {
                        event.preventDefault();
                        dispatchEventSTPUSHUpdate(['classic-5560']);
                    }}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.687 42.777">
                                <g id="icon_contact_tel" transform="translate(-10.456 -23.082)">
                                    <g transform="translate(27.468 24.898)">
                                        <path d="M-467.154-324.346a1.048,1.048,0,0,0-.329-1.445,1.048,1.048,0,0,0-1.431.306,3.655,3.655,0,0,1-2.485,1.6,4.246,4.246,0,0,1-1.6-.155,1,1,0,0,0-1.284.592l-.01.029a1.043,1.043,0,0,0,.621,1.294,5.55,5.55,0,0,0,1.812.311,3.149,3.149,0,0,0,.725-.052A5.292,5.292,0,0,0-467.154-324.346Z" transform="translate(484.721 339.462)" fill="#ffffff"/>
                                        <path d="M-468.589-322.337c-.725-3.882-2.744-6.574-5.8-7.765a12.117,12.117,0,0,0,2.743-16.916,12.118,12.118,0,0,0-16.917-2.744,12.117,12.117,0,0,0-5.031,9.824,12.2,12.2,0,0,0,5.021,9.835c-3.054,1.191-5.125,3.882-5.8,7.765a1.01,1.01,0,0,0,.8,1.185l.031.005h.207a1.1,1.1,0,0,0,1.035-.828c.518-2.743,2.019-6.108,6.419-6.678a10.761,10.761,0,0,0,1.191.414,1.049,1.049,0,0,0,1.294-.725,1.049,1.049,0,0,0-.725-1.294,10.042,10.042,0,0,1-6.983-12.366,10.042,10.042,0,0,1,12.366-6.983,10.042,10.042,0,0,1,6.983,12.366,10.041,10.041,0,0,1-6.983,6.983,1.035,1.035,0,0,0-.732,1.268l.007.026a1,1,0,0,0,.984.776.385.385,0,0,0,.259-.052,8.029,8.029,0,0,0,1.191-.414c4.4.621,5.9,3.986,6.419,6.678a1.008,1.008,0,0,0,1.035.828h.207A1.056,1.056,0,0,0-468.589-322.337Z" transform="translate(494.387 352.05)" fill="#ffffff"/>
                                        <ellipse cx="1.449" cy="1.449" rx="1.449" ry="1.449" transform="translate(8.249 8.542)" fill="#ffffff"/>
                                        <path d="M-464.684-333.693a.621.621,0,0,0,.414-.1l2.588-1.087a1.036,1.036,0,0,0,.487-1.381,1.036,1.036,0,0,0-1.264-.535l-2.588,1.087a1.051,1.051,0,0,0-.569,1.346A1,1,0,0,0-464.684-333.693Z" transform="translate(480.543 344.72)" fill="#ffffff"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <p>Aide &amp; contact</p>
                    </a>
                </li>
                <li className="but_card">
                    <a href="/Carte_but">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="61.234" height="43.753"
                                 viewBox="0 0 61.234 43.753">
                                <g transform="translate(-899.868 -499.789)">
                                    <path
                                        d="M267.557,80.642a4.3,4.3,0,0,1-4.266-3.849l-3.149-29.949a4.306,4.306,0,0,1,3.826-4.729l49.481-5.2a4.311,4.311,0,0,1,4.729,3.832l3.149,29.949a4.3,4.3,0,0,1-3.826,4.729l-49.475,5.2A4.306,4.306,0,0,1,267.557,80.642ZM313.9,39.615h-.169l-49.475,5.237a1.574,1.574,0,0,0-1.383,1.693l3.143,29.949a1.58,1.58,0,0,0,1.738,1.405l49.475-5.2h0a1.569,1.569,0,0,0,1.405-1.738l-3.166-29.938a1.58,1.58,0,0,0-1.569-1.411Z"
                                        transform="translate(639.75 462.9)" fill="#fff"/>
                                    <rect width="7.308" height="7.308" rx="1.05"
                                          transform="translate(908.511 520.817) rotate(-6)" fill="#fff"/>
                                    <path
                                        d="M332.922,74.165l-.412.04.09.886.423-.045c.282,0,.384-.226.355-.5S333.261,74.125,332.922,74.165Z"
                                        transform="translate(608.211 446.663)" fill="#fff"/>
                                    <path
                                        d="M333.065,71.877c0-.231-.147-.339-.406-.31l-.429.045.085.767.435-.045C333.02,72.306,333.088,72.108,333.065,71.877Z"
                                        transform="translate(608.333 447.794)" fill="#fff"/>
                                    <path
                                        d="M339.5,59.87,326.05,61.286l1.416,13.454,13.448-1.416Zm-9.1,9.548-1.524.158-.372-3.567,1.665-.175c.564-.056,1,.113,1.078.773a.784.784,0,0,1-.474.847.846.846,0,0,1,.773.728C331.62,68.832,331.264,69.328,330.407,69.418Zm4.515-.847a2.15,2.15,0,0,1-2.477.26c-.158-.175-.214-.26-.26-.728l-.254-2.415.976-.1.254,2.393c.034.322.152.5.5.468s.423-.243.389-.564l-.254-2.393.982-.1.254,2.415c.056.468.023.559-.1.767Zm2.641.079-.988.1L336.287,66l-.886.09-.079-.745,2.754-.288.079.745-.88.1Z"
                                        transform="translate(611.026 452.888)" fill="#fff"/>
                                    <ellipse cx="3.6" cy="3.6" rx="3.6" ry="3.6" transform="translate(905.631 509.886)"
                                             fill="#fff"/>
                                    <ellipse cx="3.6" cy="3.6" rx="3.6" ry="3.6" transform="translate(909.547 509.474)"
                                             fill="#fff"/>
                                    <path
                                        d="M289.6,98.106a1.5,1.5,0,0,1,1.343-1.693,1.3,1.3,0,0,1,1.456.835l-.525.226a.768.768,0,0,0-.875-.508.993.993,0,0,0,.214,1.964.83.83,0,0,0,.767-.694l.564.107a1.343,1.343,0,0,1-1.258,1.129,1.484,1.484,0,0,1-1.687-1.366Z"
                                        transform="translate(626.912 436.977)" fill="#fff"/>
                                    <path
                                        d="M295.844,98.5l.491-.13a.136.136,0,0,0,.141-.164.343.343,0,0,0-.372-.231.367.367,0,0,0-.356.378l-.508-.056a.83.83,0,0,1,.813-.767c.649-.068.931.277.971.688l.107,1.01a2.037,2.037,0,0,0,.062.322l-.508.051a1.213,1.213,0,0,1-.051-.243.672.672,0,0,1-.564.367.655.655,0,0,1-.773-.564A.638.638,0,0,1,295.844,98.5Zm.694.277v-.09l-.457.118a.265.265,0,0,0-.226.288.243.243,0,0,0,.293.209.423.423,0,0,0,.389-.525Z"
                                        transform="translate(624.448 436.483)" fill="#fff"/>
                                    <path
                                        d="M300.56,97.7h-.169c-.282.028-.514.192-.468.632l.1.942-.564.056-.214-2.054.536-.056.034.3a.6.6,0,0,1,.564-.378h.13Z"
                                        transform="translate(622.706 436.646)" fill="#fff"/>
                                    <path
                                        d="M302.819,96.483l.412-.045.051.491-.412.045.09.858c0,.181.107.231.265.214a.684.684,0,0,0,.169-.04l.051.463a.965.965,0,0,1-.3.079.6.6,0,0,1-.722-.564l-.1-.954-.361.051-.051-.491h.1a.293.293,0,0,0,.282-.361l-.028-.288.5-.051Z"
                                        transform="translate(621.543 437.195)" fill="#fff"/>
                                    <path
                                        d="M307.011,97.9a.925.925,0,0,1-.863.745,1.044,1.044,0,0,1-1.157-.965,1.033,1.033,0,0,1,.9-1.185.959.959,0,0,1,1.129.954,1.371,1.371,0,0,1,0,.175l-1.467.152a.5.5,0,0,0,.564.412.451.451,0,0,0,.435-.361Zm-.564-.621a.418.418,0,0,0-.5-.356.451.451,0,0,0-.418.451Z"
                                        transform="translate(620.206 436.937)" fill="#fff"/>
                                    <path
                                        d="M313.009,96.727a1.8,1.8,0,0,0,.056.367l-.53.056a1.787,1.787,0,0,1-.045-.237.632.632,0,0,1-.564.35,1.084,1.084,0,0,1-.243-2.15.621.621,0,0,1,.6.192l-.124-1.168.564-.056Zm-1.05.034a.525.525,0,0,0,.446-.643.508.508,0,0,0-.564-.514.567.567,0,0,0,.119,1.129Z"
                                        transform="translate(617.678 437.984)" fill="#fff"/>
                                    <path
                                        d="M317.381,96.82a.925.925,0,0,1-.863.751,1.1,1.1,0,0,1-.277-2.173.959.959,0,0,1,1.129.954v.175l-1.467.152a.5.5,0,0,0,.564.412.451.451,0,0,0,.435-.361Zm-.564-.615a.412.412,0,0,0-.5-.356.446.446,0,0,0-.418.451Z"
                                        transform="translate(615.716 437.416)" fill="#fff"/>
                                    <path
                                        d="M321.837,95.908a.53.53,0,0,0,.564.525.468.468,0,0,0,.435-.4l.508.113a.959.959,0,0,1-.886.79,1.067,1.067,0,0,1-1.2-.976,1.05,1.05,0,0,1,.976-1.2.943.943,0,0,1,1.038.564l-.48.214a.451.451,0,0,0-.491-.3.53.53,0,0,0-.468.66Z"
                                        transform="translate(613.116 437.691)" fill="#fff"/>
                                    <path
                                        d="M326.751,94.939a.819.819,0,0,0-.169,0c-.282.028-.514.192-.468.632l.1.937-.564.062-.22-2.054.564-.056v.3a.626.626,0,0,1,.564-.384h.13Z"
                                        transform="translate(611.296 437.853)" fill="#fff"/>
                                    <path
                                        d="M330.274,94.8a.937.937,0,0,1-.869.751,1.05,1.05,0,0,1-1.174-.988,1.038,1.038,0,0,1,.9-1.185.959.959,0,0,1,1.129.954,1.418,1.418,0,0,1,0,.175l-1.467.152a.5.5,0,0,0,.564.412.446.446,0,0,0,.435-.361Zm-.564-.615a.418.418,0,0,0-.5-.356.446.446,0,0,0-.418.451Zm-.384-1.01-.451.045.316-.666.632-.062Z"
                                        transform="translate(610.081 438.676)" fill="#fff"/>
                                    <path
                                        d="M334.469,94.467a3.342,3.342,0,0,0,.056.372L334,94.9a2,2,0,0,1-.045-.237.666.666,0,0,1-.564.35,1.084,1.084,0,0,1-.243-2.15.621.621,0,0,1,.655.192l-.124-1.174.564-.056Zm-1.05.034a.564.564,0,1,0-.564-.53C332.889,94.343,333.126,94.534,333.419,94.5Z"
                                        transform="translate(608.329 438.968)" fill="#fff"/>
                                    <path
                                        d="M337,91.569a.35.35,0,0,1,.378.31.346.346,0,0,1-.689.073A.35.35,0,0,1,337,91.569Zm.045,3.1-.214-2.054.564-.056.2,2.077Z"
                                        transform="translate(606.392 439.078)" fill="#fff"/>
                                    <path
                                        d="M339.47,92.618l.412-.039.051.491-.412.045.09.858c0,.181.107.226.265.209a.728.728,0,0,0,.169-.034l.051.457a.8.8,0,0,1-.3.085.6.6,0,0,1-.722-.564l-.1-.954-.372.04-.051-.491h.1a.293.293,0,0,0,.282-.361l-.028-.288.5-.051Z"
                                        transform="translate(605.58 438.881)" fill="#fff"/>
                                    <path
                                        d="M275.194,58.378a3.076,3.076,0,0,1,.863-2.133,3.019,3.019,0,0,0-1.693-.525,3.07,3.07,0,1,0,2.2,5.2,3.064,3.064,0,0,1-1.371-2.545Z"
                                        transform="translate(634.884 454.696)" fill="#db101d"/>
                                    <path
                                        d="M281.947,55a3.064,3.064,0,0,0-2.207.937,3.07,3.07,0,0,1,.491,4.678,3.036,3.036,0,0,0,1.693.525,3.07,3.07,0,0,0,0-6.14Z"
                                        transform="translate(631.201 455.01)" fill="#f18618"/>
                                    <path d="M279.074,56.65a3.07,3.07,0,0,0,.491,4.678,3.07,3.07,0,0,0-.491-4.678Z"
                                          transform="translate(631.867 454.291)" fill="#e84d1b"/>
                                </g>
                            </svg>
                        </div>
                        <p>
                            La carte BUT
                        </p>
                    </a>
                </li>
                <li className="purchase_return">
                    <a href="/Services/Page/Retour-achats">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.879 47.178">
                                <g id="icon_retour_gratuit" transform="translate(-20.909 -16.802)">
                                    <path id="trace_133" data-name="trace 133"
                                          d="M382.148,597.619v8.489a.977.977,0,0,0,.569.932l8.489,4.245a1.1,1.1,0,0,0,.932,0h0l8.489-4.245a.977.977,0,0,0,.569-.932v-8.489a.978.978,0,0,0-.569-.932l-8.489-4.245a.99.99,0,0,0-.932,0l-8.541,4.3A.912.912,0,0,0,382.148,597.619Zm8.489,11.026-2.174-1.087V601.4l2.174,1.087Zm8.438-3.209-6.367,3.209v-6.16l6.367-3.209Zm-1.242-7.816-6.16,3.106-1.915-.932,6.16-3.106Zm-6.16-3.106,1.915.984-6.16,3.106-1.967-.984Zm-7.454,4.762,2.174,1.087v6.16l-2.174-1.087Z"
                                          transform="translate(-351.297 -561.487)" fill="#ffffff"/>
                                    <g className="anim">
                                        <path id="trace_134" data-name="trace 134"
                                              d="M377.078,605.515a1.026,1.026,0,1,0-1.5,1.4l4.348,4.711a17.333,17.333,0,0,1-5.435-32.612,1.042,1.042,0,1,0-.932-1.864,19.453,19.453,0,0,0,5.228,36.442l-4.866,2.536a.979.979,0,0,0-.465,1.3.911.911,0,0,0,.051.094,1.089,1.089,0,0,0,.932.569,1.5,1.5,0,0,0,.466-.1l7.972-4.141a.934.934,0,0,0,.518-.725,1.115,1.115,0,0,0-.259-.88Z"
                                              transform="translate(-342.034 -554.113)" fill="#ffffff"/>
                                        <path id="trace_135" data-name="trace 135"
                                              d="M418.987,588.784a19.492,19.492,0,0,0-15.84-19.1l4.866-2.536a1.031,1.031,0,1,0-.984-1.812l-7.972,4.141a.936.936,0,0,0-.518.725,1.114,1.114,0,0,0,.259.88l6.108,6.626a1.052,1.052,0,0,0,.776.311,1.233,1.233,0,0,0,.725-.259,1,1,0,0,0,.086-1.412c-.011-.013-.022-.025-.034-.037l-4.348-4.711a17.379,17.379,0,0,1,5.332,32.663,1.035,1.035,0,0,0-.472,1.386l.006.011a1.089,1.089,0,0,0,.932.569,1.5,1.5,0,0,0,.466-.1A19.479,19.479,0,0,0,418.987,588.784Z"
                                              transform="translate(-359.199 -548.408)" fill="#ffffff"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <p>
                            Retour Achats
                        </p>
                    </a>
                </li>
                <li>
                    Nous <br/>
                    sommes <br/>
                    là pour <br/>
                    vous !
                </li>
            </ul>
        </div>
    )
};
export default ServicesShops;