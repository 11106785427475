import ApiCaller from '../../../../services/ApiCaller/apiCallerDefault';
import {
    getMetaInfosValueByCode,
    pushContextUrl,
    pushState,
    prepareShopCircle, getFetchingProductsAvailabilitiesIsAllowed,
    getProductsAvailabilityApiParamsForProductList
} from "@/utils/Mobile/utils";
import ProductListAnimations, {
    refreshT2S,
    scrollToProductList
} from "@/animations/Default/productListPage"
import {
    APPLYSORT,
    HANDLENAVIGATION,
    CURRENTFILTERS_VALUES,
    RESETFILTERS,
    PRODUCTLIST_FETCHED,
    PRODUCTS_AVAILABILITY_DATA_FETCHED,
    SHOW_ATTACHMENT_BLOCK, CHECK_AVAILABILITY_FILTER_DISPLAY, HANDLEAPISTATUSPENDING, PRODUCTLIST_EMPTY_ERROR
} from '../actionsTypes'
import {
    getItemsFromNavigation,
    productListApplySort,
    fetchProductsListAvailabilityData
} from '../../../../services/productListServices'
import Router from "next/router";
import {
    DEFAULT_TEMPLATE_VERSION,
    FILTER_AVAILABILITY_SHOP_ID_LIST_CODE, PRODUCT_LIST_PAGE,
    SEARCH_PAGE, NOT_CALL_API_AND_NOT_FRONT_DISPLAY_MODE
} from "@/utils/constants";
import Utils from "@/utils/Default/Utils";

export function fetchItemsFromNavigation(categoryId, pager, currentIndex, currentSort, appliedFiltersValues, currentFiltersValue, keyWords) {
    let url = "V1/Catalog/Categories/c" + categoryId + "/ProductsList";
    let data = {};
    // Sort
    if (currentSort && currentSort.value != "" && currentSort.value != null) {
        data.SortedBy = JSON.stringify({"code": currentSort.value, "direction": "desc"});
    }

    // Filters
    if (appliedFiltersValues) {
        let tmp = [];
        Object.keys(appliedFiltersValues).map(function (filter, i) {
            if (appliedFiltersValues[filter] && appliedFiltersValues[filter].length > 0) {
                var info = {"code": filter, "values": appliedFiltersValues[filter]};
                tmp.push(info);
            }
        }, this);

        if (tmp.length > 0) {
            data.Filters = JSON.stringify(tmp);
        }
    }
    data.wordingVersion = DEFAULT_TEMPLATE_VERSION;
    // PageSize
    if (currentIndex && currentIndex <= pager.pageCount) {
        data.PageSize = currentIndex * pager.pageSize;
    }

    // keyords de la page recherche
    data.KeyWords = keyWords ? keyWords : null;
    data.ShopCircle = prepareShopCircle()
    return function (dispatch) {
        ApiCaller.get(url, {
            params: data
        })
            .then(response => {
                response.data.appliedFiltersValues = currentFiltersValue;
                response.data.currentSort = currentSort;
                response.data.pager.pageCount = pager.pageCount;
                response.data.pager.currentPageIndex = currentIndex ? currentIndex - 1 : 0;
                dispatch({
                    type: HANDLENAVIGATION,
                    payload: {
                        productsListData: response.data,
                        pager: response.data.pager,
                    }
                });
                let listItems = response.data.listItems
                let filters = response.data.filters.values
                if (listItems && listItems?.values?.length > 0 && getFetchingProductsAvailabilitiesIsAllowed(filters)) {
                    dispatch(fetchProductsAvailabilityData(listItems))
                } else {
                    dispatch({
                        type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                        payload: {
                            productsAvailabilityData: []
                        }
                    });
                }
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }

}

export const handleNavigation = (currentSort, appliedFiltersValues, context, pager, previousPager, currentPageIndex, listItems, merchListItems, listItemsCount, productsAvailabilityData, categoryId, categoryView = null) => {

    let data = {};
    let keyWords = null
    let url = "V1/Catalog/Categories/c" + categoryId + "/ProductsList" + (categoryView ? "?view=" + categoryView : '');
    if (window.location.pathname == "/Common/Search/SearchProductsList") {
        //Attention : ne pas utiliser URLSearchParams en descktop car ne fonctionne pas sur IE
        const urlSearchParams = new URLSearchParams(window.location.search);
        keyWords = urlSearchParams.has("KeyWords") ? urlSearchParams.get("KeyWords") : null
        url = "V1/SearchEngine/ProductsList?KeyWords=" + keyWords;
    }
    // Sort
    if (currentSort && currentSort[3] != "") {
        data.SortedBy = JSON.stringify({"code": currentSort[3], "direction": "desc"})
    }

    // Filters
    if (appliedFiltersValues) {
        let tmp = [];
        Object.keys(appliedFiltersValues).map((filter, i) => {
            if (appliedFiltersValues[filter] && appliedFiltersValues[filter].length > 0) {
                var info = {"code": filter, "values": appliedFiltersValues[filter]};
                tmp.push(info);
            }
        });

        if (tmp.length > 0) {
            data.Filters = JSON.stringify(tmp);
        }
    }
    data.wordingVersion = DEFAULT_TEMPLATE_VERSION;
    data.PageIndex = currentPageIndex;
    // keyords de la page recherche
    if (keyWords) {
        data.KeyWords = keyWords
        //todo here change with getState, eclater les state  et ne pas passer   data.Context = 'SSR'
        data.Context = 'SSR'
    }
    let shopCircleData = prepareShopCircle()
    if (shopCircleData && shopCircleData.shopCircle) {
        data.ShopCircle = shopCircleData;
    }
    return (dispatch, getState) => {
        productListApplySort(DEFAULT_TEMPLATE_VERSION, url, data)
            .then(response => {
                if (response.listItems) {
                    let filters = response.filters
                    let madIsActifInFiltredProductList = response.madIsActifInFiltredProductList
                    if (getFetchingProductsAvailabilitiesIsAllowed(filters.values, madIsActifInFiltredProductList)) {
                        dispatch(fetchProductsAvailabilityData(response.listItems, productsAvailabilityData,context))
                    } else {
                        dispatch({
                            type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                            payload: {
                                productsAvailabilityData: []
                            }
                        });
                    }
                    if (response.keyWords) {
                        response.productList.listItems.values = context === 'seeMore' ? listItems.values.concat(response.productList.listItems.values) : response.productList.listItems.values.concat(listItems.values);
                    } else {
                        response.listItems.values = context === 'seeMore' ? listItems.values.concat(response.listItems.values) : response.listItems.values.concat(listItems.values);
                    }
                    response.merchListItems = merchListItems;
                }
                if (response.productList && response.productList.listItems  && response.productList.listItems.values) {
                    let filters = response.productList.filters.values
                    let madIsActifInFiltredProductList = response.productList.madIsActifInFiltredProductList
                    if (getFetchingProductsAvailabilitiesIsAllowed(filters, madIsActifInFiltredProductList)) {
                        dispatch(fetchProductsAvailabilityData(response.productList.listItems, productsAvailabilityData, context))
                    } else {
                        dispatch({
                            type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                            payload: {
                                productsAvailabilityData: []
                            }
                        });
                    }
                    response.productList.listItems.values = context === 'seeMore' ?  listItems.values.concat(response.productList.listItems.values) : response.productList.listItems.values.concat(listItems.values);
                }
                pushState(data.SortedBy, data.Filters, data.PageIndex, data.KeyWords);
                let productsListData = response.keyWords ? response.productList : response;
                dispatch({
                    type: HANDLENAVIGATION,
                    payload: {
                        currentFiltersValue: appliedFiltersValues,
                        pager: context ==='seeMore' ?  response.keyWords ? response.productList.pager : response.pager : pager,
                        previousPager: context !=='seeMore' ? response.keyWords ? response.productList.pager : response.pager : previousPager,
                        productsListData: productsListData,
                        apiStatusPending: false,
                        isPaginationLoading: false,
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };

}


export const getCurrentFiltersValue = (appliedFiltersValues) => {
    const priceCode = "Prix de référence"
    return function (dispatch) {
        let currentFiltersValue = {};
        if (appliedFiltersValues) {
            appliedFiltersValues.map((filter) => {
                if(filter.filterCode == priceCode && !currentFiltersValue[filter.filterCode]) {
                    currentFiltersValue[filter.filterCode] = [];
                 } else if (!currentFiltersValue[filter.code] ) {
                    currentFiltersValue[filter.code] = [];
                }

                    if (filter.filterCode == priceCode) {
                        currentFiltersValue[filter.filterCode].push(filter.value);
                    } else {
                        currentFiltersValue[filter.code].push(filter.value);
                    }

                }
            );
        }
        dispatch({
            type: CURRENTFILTERS_VALUES,
            payload: {currentFiltersValue: currentFiltersValue}
        });
    }
}

export function fechFiltredProductListItems(keyWords, pager, currentSort, pageIndex, appliedFiltersValues,filterItem = null, categoryId, viewMode = null, availabilityFilterIsApplied = null, categoryView = null) {
    let isSearchPage = false;
    let url = "V1/Catalog/Categories/c" + categoryId + "/ProductsList";
    if(categoryView) {
        url += "?view=" + categoryView;
    }
    if (window.location.pathname == "/Common/Search/SearchProductsList") {
        url = "V1/SearchEngine/ProductsList?KeyWords=" + keyWords;
        isSearchPage = true;
    }
    if (viewMode) {
        url += "?View=" + viewMode;
    }
    let data = {};
    // Sort
    if (currentSort && currentSort[3] != "") {
        data.SortedBy = JSON.stringify({"code": currentSort[3], "direction": "desc"})
    }
    data.wordingVersion = DEFAULT_TEMPLATE_VERSION;
    // Filters
    if (appliedFiltersValues) {
        let tmp = [];
        Object.keys(appliedFiltersValues).map((filter) => {
                if (appliedFiltersValues[filter]) {
                    let info = {"code": filter, "values": appliedFiltersValues[filter]}
                    tmp.push(info);
                }
            }
        );
        if (tmp.length > 0) {
            data.Filters = JSON.stringify(tmp);
        }
    }
    // Filters
    if (keyWords) {
        data.keyWords = keyWords;
        //todo here change with getState, eclater les state  et ne pas passer   data.Context = 'SSR'
        data.Context = 'SSR'
    }
    let shopCircleData = prepareShopCircle()
    if (shopCircleData && shopCircleData.shopCircle) {
        data.ShopCircle = shopCircleData;
    }
    scrollToProductList('.dp__content')
    return function (dispatch) {

        ApiCaller.get(url, {
            params: data
        }).then(response => {
            if (isSearchPage) {
                pushState(data.SortedBy, data.Filters, data.PageIndex, data.keyWords);
            } else {
                let originContextUrl = Utils.getMetaInfosValueByCode("contextURL", response.data.metaInfos)
                if (data.PageIndex) {
                    let indexOfPageIndex = originContextUrl.indexOf("PageIndex=");
                    let contextURL = indexOfPageIndex > 0 ? originContextUrl.substr(0, indexOfPageIndex) + "PageIndex=" + data.PageIndex : originContextUrl;
                    pushContextUrl(contextURL);
                } else {
                    pushContextUrl(originContextUrl);
                }
            }
            if (isSearchPage && response.data.productList.listItems.values.length === 0) {
                dispatch({
                    type: PRODUCTLIST_EMPTY_ERROR
                });
                scrollToProductList('#conteneur_central')
            } else {
            let productListData = response.data.keyWords ? response.data.productList : response.data;
            let filters = response.data.keyWords ? response.data.productList?.filters?.values : response.data?.filters?.values
            // productListData.pager = pager;
            let currentFiltersValue = appliedFiltersValues
            let availabilityFilter = filters?.filter(filterItem => filterItem[1] === FILTER_AVAILABILITY_SHOP_ID_LIST_CODE)
            if (availabilityFilterIsApplied && availabilityFilter?.length > 0 && availabilityFilter[0][5]?.length > 0) {
                availabilityFilter[0][5].map(item => {
                    if (item[2] !== 'all') {
                        currentFiltersValue[item[2]] = [item[3]];
                    }
                })
                availabilityFilterIsApplied = null
                dispatch(fechFiltredProductListItems(keyWords, pager, currentSort, pageIndex, currentFiltersValue,null, categoryId, null, availabilityFilterIsApplied))
            } else {
                dispatch({
                    type: PRODUCTLIST_FETCHED,
                    payload: {
                        productsListData: productListData,
                        octipasPayload: response.data.octipasPayload,
                        trackingUrlFapi: response.data.trackingUrlFapi,
                        pager: response.data.keyWords ? response.data.productList.pager : response.data.pager,
                        keyWords: keyWords,
                        filters: response.data.keyWords ? response.data.productList.filters : response.data.filters,
                        itemsCount: response.data.keyWords ? response.data.productList.itemsCount : response.data.itemsCount,
                        appliedFiltersValues: response.data.keyWords ? response.data.productList.appliedFiltersValues : response.data.appliedFiltersValues
                    }
                });
                dispatch({
                    type: HANDLEAPISTATUSPENDING,
                    payload: {
                        apiStatusPending: false}
                })
                dispatch({
                    type: CHECK_AVAILABILITY_FILTER_DISPLAY,
                    payload: {
                        value: true
                    }
                });
                let listItems = response.data.keyWords ? response.data.productList.listItems : response.data.listItems
                let madIsActifInFiltredProductList = response.data.keyWords ? response.data.productList.madIsActifInFiltredProductList : response.data.madIsActifInFiltredProductList
                if (listItems && listItems.values && listItems.values.length > 0 && getFetchingProductsAvailabilitiesIsAllowed(filters, madIsActifInFiltredProductList)) {
                    dispatch(fetchProductsAvailabilityData(listItems))
                } else {
                    dispatch({
                        type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                        payload: {
                            productsAvailabilityData: []
                        }
                    });
                }
                let showT2S = currentFiltersValue ? (Object.keys(currentFiltersValue).length <= 1 ? true : false) : false;
                if (window.location.pathname !== "/Common/Search/SearchProductsList") {
                    let productListItemsT2S = response.data.keyWords ? null : response.data.listItems;
                    let filterItemTMP = {}
                    if (filterItem) {
                        filterItemTMP.filterCode = filterItem?.filterCode ?? filterItem[0];
                        filterItemTMP.isApplied = filterItem?.isApplied ?? filterItem[5] ?? false;
                    } else {
                        filterItemTMP = filterItem;
                    }
                    refreshT2S({currentSort, filterItemTMP}, productListItemsT2S, showT2S);
                }
            }
            }
        })
            .catch((error) => {
                //TODO SES thow exception
                if (error.response) {
                    if (error.response.status === 404 && error.response.data.code === 'Empty-ProductList-404') {
                        dispatch({
                            type: PRODUCTLIST_EMPTY_ERROR
                        });
                        scrollToProductList('#conteneur_central')
                    }
                }
                console.log(error);
            })
    }

}
export const fetchProductsListData = (deviceType, pageIndex, currentSort, appliedFiltersValues, keyWords, context) => async (dispatch) => {
    const productListData = await getItemsFromNavigation(deviceType, null, pageIndex, currentSort, appliedFiltersValues, null, null, keyWords, context)
    if (keyWords && productListData.status === 302) {
        Router.push(productListData.headers.navigateto);
    } else {
        return dispatch({
            type: PRODUCTLIST_FETCHED,
            payload: {
                productsListData: productListData.productList,
                navigationMetaInfosConfigs: productListData.navigationMetaInfosConfigs,
                octipasPayload: productListData.octipasPayload,
                trackingUrlFapi: productListData.trackingUrlFapi,
                keyWords: keyWords,
                pager: productListData.productList.pager,
                isFirstLoad: true,
                filters: productListData.productList.filters,
                itemsCount: productListData.productList.itemsCount,
                appliedFiltersValues: productListData.productList.appliedFiltersValues
            }
        });
    }

}

export function fetchProductsAvailabilityData(productListItems, productsAvailabilityData = [], context = '') {
    return function (dispatch) {
        if ([SEARCH_PAGE, PRODUCT_LIST_PAGE].includes(appData.context) && appData.availabilityDisplayMode === NOT_CALL_API_AND_NOT_FRONT_DISPLAY_MODE) {
            dispatch({
                type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                payload: {
                    productsAvailabilityData: []
                }
            });
        } else {
            fetchProductsListAvailabilityData(DEFAULT_TEMPLATE_VERSION, '/Catalog/Products/Availabilities?' + getProductsAvailabilityApiParamsForProductList(productListItems.values))
                .then(response => {
                    response.data = productsAvailabilityData.length > 0 ? context === 'seeMore' ? productsAvailabilityData.concat(response.data) : response.data.concat(productsAvailabilityData) : response.data;
                    dispatch({
                        type: PRODUCTS_AVAILABILITY_DATA_FETCHED,
                        payload: {
                            productsAvailabilityData: response.data
                        }
                    });
                })
                .catch((error) => {
                    //TODO SES thow exception
                    console.log(error);
                })
        }
    }
}
