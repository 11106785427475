import Cookies from "js-cookie";
import header from './header.js';
import Utils from "@/utils/Default/Utils"
export const headerPageInit = () => {
    header.init();
}
export const findShopFirstLandingAnimation = () => {
    // VERSION DECONNECTEE A L'OUVERTURE DE LA PAGE
    if (!Utils.getPrimaryShopId() && !Cookies.get('first-landing')) {
        $(".find-store").addClass('store-show');
        $('body').click(function (e) {
            if (!$(e.target).hasClass('.find-store')) {
                $('#main-header .board .find-store').css({'display': ''});
            }
        });
        setTimeout(function () {
            $(".find-store").removeClass('store-show')
        }, 5000);
        Cookies.set('first-landing', true);
    }
}

export const closeFancyBox = (element) => {
    if (getDisplayElement(element) === "block") {
        $.fancybox.close();
    }
}

export const getInputElementValue = (element) => {
    return $(element).val()
}

export const getDisplayElement = (element) => {
    return $(element).css('display');
}

export const setInputElementValue = (element, elementValue) => {
    $(element).val(elementValue)
}

export const showErrorGeo = (className = null) => {
    $(className ? '.' + className : '.errorGeo').css({display: "block"})
}
export const hideErrorGeo = () => {
    $('.errorGeo').hide()
    $('.errorGeo2').hide()
}

export const isDomTomZipCode = (zipCode) => {
    if ((/9[7-8][0-9]{3}/).test(zipCode)) {
        return true;
    }
    return false;
}

export const deleteLastSearchItem = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    $('#removeLastSearch' + index).parent().remove();

}

export const deleteAllLastSearchItems = (e) => {
    e.preventDefault();
    $('.lastLib').remove();
}

export const getOffsetXByMapElement = (element) => {
    let blockElement = $(element)
    return (blockElement.outerWidth() + blockElement.position().left) / 2
}

export const clearInfoWindow = (element) => {
    $(element).parent().parent().parent().parent().parent().parent().html('')
}
export const removeClassOwlLoaded = () => {
    $('#main-header > .main-header__infobar').removeClass('owl-loaded')
}
export const initOwlCarousel = () => {
    $('#main-header > .main-header__infobar').owlCarousel({
        items: 1,
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 8000,
        autoplayHoverPause: true,
        dots: false,
        smartSpeed: 600
    });
}
const initFlipDown = (id, endDate, color) => {
    // Set up FlipDown
    var flipdown = new FlipDown(endDate, id, {
        headings: ["Jours", "Heures", "Minutes", "Secondes"],
        theme: "light"
    }).start();
    // OWLCAROUSEL
    // Color of number
    $(".main-header__infobar #" + id + ".flipdown__theme-light .rotor, .main-header__infobar #" + id + ".flipdown__theme-light .rotor-top, .main-header__infobar #" + id + ".flipdown__theme-light .rotor-leaf-front, .main-header__infobar #" + id + ".flipdown__theme-light .rotor-bottom, .main-header__infobar #" + id + ".flipdown__theme-light .rotor-leaf-rear").css('color', color);
    /*Note Decade : impossible d'ajouter un style inline sur un pseudo el ::after donc ajout du style dans le head*/
    $('head').append('<style>.main-header__infobar #' + id + '.flipdown__theme-light .rotor::after{border-color:' + color + ';}</style>');
}

export const initBannersFlipDown = (bannersBlocList) => {
    bannersBlocList.map(item => {
        if (item.hasCountDown) {
            initFlipDown('infobar__flipdown_' + item.id, item.endDate, item.color)
        }
    })
}

export const triggerMenuClicEvents = (eventLabel) => {
    appData.actionTrigger.dispatch({
        trigger: "handleClickMenu",
        payload: {
            eventLabel: eventLabel
        }
    });
}

export const initShopCircleVersion = (shopCircleVersion) => {
    appData.shopCircleVersion = shopCircleVersion;
}

export const resizeHeaderInfoBarOwlCarousel = () => {
    if($('.main-header__infobar').data('owl.carousel')){
        $('.main-header__infobar').data('owl.carousel').onResize();
    }
}

export const showStoreAddClass = () => {
    $(".find-store").addClass("store-show");
}

export const showStoreRemoveClass = () => {
    $(".find-store").removeClass("store-show");
}
export const handleOpenStoreHover = () => {
    $(".open-store").hover(function () {
        $(this).removeClass('visible-store');
    });
}