import {FETCH_SHOP_DATA, SHOP_EVENT_LIST_DATA} from '../actionsTypes'
import {PRIMARY_SHOP_DATA_FETCHED} from "../../Mobile/actionsTypes";

const initialState = {
    shopId: null,
    shopName: null,
    shopUrl: null,
    shopUrlPicture: null,
    shopAndWharehouseData: null,
    openingWharehouseSchedule: null,
    openingShopSchedule: null,
    shopNextDayOpen: null,
    shopNextOpeningStartTime: null,
    shopNextDayOpenSchedule: null,
    shopWeekDays: null,
    shopAddress: null,
    shopCurrentDay:null,
    shopIsNowOpen:null,
    shopIsNowOpenAtNight:null,
    shopEventListData:null
};

const shopDatasReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SHOP_DATA:
            return {
                ...state,
                shopId: action.payload.shopId,
                shopName: action.payload.shopName,
                shopUrl: action.payload.shopUrl,
                shopUrlPicture: action.payload.shopUrlPicture,
                shopAndWharehouseData: action.payload.shopAndWharehouseData,
                openingWharehouseSchedule: action.payload.openingWharehouseSchedule,
                openingShopSchedule: action.payload.openingShopSchedule,
                shopNextDayOpen: action.payload.shopNextDayOpen,
                shopNextOpeningStartTime: action.payload.shopNextOpeningStartTime,
                shopNextDayOpenSchedule: action.payload.shopNextDayOpenSchedule,
                shopWeekDays: action.payload.shopWeekDays,
                shopAddress: {
                    streetAddress: action.payload.shopAddress.streetAddress,
                    zipCode: action.payload.shopAddress.zipCode,
                    city: action.payload.shopAddress.city,
                },
                shopCurrentDay:action.payload.shopCurrentDay,
                shopIsNowOpen:action.payload.shopIsNowOpen,
                shopIsNowOpenAtNight:action.payload.shopIsNowOpenAtNight,
            }
        case SHOP_EVENT_LIST_DATA:
            return {
                ...state,
                shopEventListData: action.payload.shopEventsData.eventList
            }
        default:
            return state;
    }
};

export default shopDatasReducer;

