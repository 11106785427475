import React from 'react';

const CartLink = ({items, totalQuantities}) => {
    return (
        <a className="gtm-header" data-gtm-libelle="panier" href="/Purchase/Cart/Summary" title="Mon panier"
           data-popin="cart" id="basket">Mon panier
            {(items && items.length != 0) ?
                <span className="items-number">{totalQuantities}</span> : null}</a>
    )
}
export default CartLink;