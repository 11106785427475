import React, {memo, useEffect} from 'react'
import * as googleMapAnimation from "@/animations/Mobile/googleMap";

const Map = memo((props) => {
    const {latitude, longitude, zoom, divId, style} = props
    useEffect(() => {
        let point = {
            latitude: latitude.replace(',', '.'),
            longitude: longitude.replace(',', '.')
        };
        initializeGoogleMap(point)
    })

    const initializeGoogleMap = (point) => {
        let mapProp = {
            center: new google.maps.LatLng(point.latitude, point.longitude),
            zoom: zoom ? zoom : 12,
            disableDefaultUI: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        let map = googleMapAnimation.showMap(divId, mapProp)
        appendPinsToMap(point, map);
    }

    const appendPinsToMap = (point, map) => {
        let bounds = new google.maps.LatLngBounds();
        let counter = 0;
        let markers = []

        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(point.latitude, point.longitude),
            counter: counter,
            map: map,
        });
        marker.setMap(map);

        let context = this
        google.maps.event.addListener(marker, 'click', function () {
            context.prepareRelayPointShipping(point[marker.counter])
            googleMapAnimation.showRelayPointMarkerInformation(point[counter])
        });
        markers.push(marker)
        bounds.extend(markers[counter].getPosition());
        counter += 1;

        map.setCenter(bounds.getCenter());
    }

    return <div id={divId} className="th"
                style={style ? style : {}}/>
})

export default Map;