import {getCookieValue} from "@/utils/Mobile/utils";

const OctipasMerch = {
    setPayload(payload) {
        if (getCookieValue('TC_PRIVACY') != null) {
            if (window.octipas) {
                if (octipas.tracking.payload && octipas.tracking.payload !== payload) {
                    octipas.tracking.payload = payload;
                }
            }
        }
    },
    createOrReplaceBasket(basket) {
        if (getCookieValue('TC_PRIVACY') != null) {
            if (window.octipas && window.octipas?.tracking && typeof window.octipas.tracking?.createOrReplaceBasket === 'function') {
                window.octipas.tracking.createOrReplaceBasket(basket, function callback(octipasResult) {
                    console.log('createOrReplaceBasket: octipasResult :', octipasResult)
                })
            }
        }
    }
}
export default OctipasMerch;