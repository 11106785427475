import React, {memo} from 'react'

const menuStoreLocator = memo((props) => {
    let iconClass = 'icon-lg icon-pin';
    let shopName = 'Magasin';
    if (props.shopId) {
        iconClass = 'icon-lg icon-pin icon-valid';
        if (props.shopName.length > 7) {
            if (props.shopName.indexOf('BUT') || props.shopName.indexOf('But')) {
                shopName = props.shopName.substring(4).substring(0, 9) + '...'
            } else {
                shopName = props.shopName.substring(0, 7) + '...'
            }
        } else {
            shopName = props.shopName
        }
    }

    return (
        <a href={props.shopId ? props.shopUrl : '/magasins/recherche-magasins'}
           data-gtm-libelle="magasins" className="nav__header__link gtm-header">
            <div className="nav__header__link__animation slideInUp">
                                <span className="nav__header__link__icone">
                    <i className="icon-3x icon-shop-v2"/>
                </span>
                <span>{shopName}</span>
            </div>
        </a>
    )
})

export default menuStoreLocator;



