import ApiCaller from "@/services/ApiCaller/apiCaller";
import {UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE} from "@/redux/reducers/Default/actionsTypes";
import {fetchShopData} from "@/redux/reducers/Mobile/header/headerActions";
import Cookies from "js-cookie";
import Utils from "@/utils/Default/Utils";
import {SCAN_ACTIVATION_ERROR_HANDLER} from "@/redux/reducers/responsive/actionsTypes";
import {SPEEDY_SCAN_CONTEXT} from "@/utils/constants";
import {deleteProductsFromCart} from "@/redux/reducers/responsive/cart/cartActions";
import {getUserIdFromCookie} from "@/utils/Mobile/utils";

export function fetchGeoloc(calculationMethod, calculationParams, callBackFunction = null, isIconOnly, isEligibleToScan, everyShop = 1, context = SPEEDY_SCAN_CONTEXT) {
    return function (dispatch) {
        if (isEligibleToScan) {
            callBackFunction && callBackFunction();
            return;
        }
        ApiCaller.defaults.headers['disableForcedReload'] = true;
        ApiCaller.put("/WebUsers/" + getUserIdFromCookie() + "/Shops", {
            CalculationMethod: calculationMethod,
            CalculationParams: calculationParams,
            EveryShop: everyShop,
            Context: context
        }).then(response => {
            localStorage.setItem('shopCircle', JSON.stringify(response.data));
            dispatch({
                type: UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE,
                payload: {value: false}
            })
            dispatch(fetchShopData(response.data.primaryShop.id))
            Cookies.set('availabilityShopCircle', JSON.stringify(Utils.getAvailabilityShopCircleData(response.data)), {
                path: "/",
                domain: appData.cookieDomain,
                expires: 365
            })
            if (response.data.primaryShop.id) {
                var reasonCode = response.data.primaryShop.eligibilityToScan.reasonCode;
                if (reasonCode === 'distanceTooFar') {
                    dispatch({
                        type: 'SCAN_ACTIVATION_ERROR_HANDLER',
                        payload: {
                            errors: {
                                isErrorOutOfShop: true,
                                isErrorGeolocHS: false,
                                isErrorCamRefused: false,
                                isErrorShopNotEligible: false,
                                isErrorGeolocRefused: false,
                            }
                        }
                    });
                    appData.actionTrigger.dispatch({
                        trigger: "eligibilityFailed",
                        payload: {
                            'errorType': 'Vous semblez être situé(e) en dehors du magasin'
                        }
                    });
                } else if (reasonCode === 'shopNotEligible') {
                    dispatch({
                        type: 'SCAN_ACTIVATION_ERROR_HANDLER',
                        payload: {
                            errors: {
                                isErrorShopNotEligible: true,
                                isErrorGeolocHS: false,
                                isErrorCamRefused: false,
                                isErrorOutOfShop: false,
                                isErrorGeolocRefused: false,
                            }
                        }
                    });
                    appData.actionTrigger.dispatch({
                        trigger: "eligibilityFailed",
                        payload: {
                            'errorType': 'Le magasin est non éligible'
                        }
                    });
                } else { //Succes
                    dispatch({
                        type: SCAN_ACTIVATION_ERROR_HANDLER, payload: {
                            errors: {
                                isErrorGeolocRefused: false,
                                isErrorGeolocHS: false,
                                isErrorCamRefused: false,
                                isErrorOutOfShop: false,
                                isErrorShopNotEligible: false
                            }
                        }
                    })
                    appData.actionTrigger.dispatch({
                        trigger: "eligibilitySuccess",
                    });

                    //écraser le panier persistant avec le panier achet rapide
                    if (!isIconOnly && !isEligibleToScan) {
                        dispatch(deleteProductsFromCart())
                    }
                    callBackFunction();
                }
            }
        })
            .catch((error) => {
                console.log(error)
            })
    }
}
