import {IDENTITY_FETCHED} from '../actionsTypes'


const initialState = {
    webUserId : null,
    navigationToken :null,
    userData : {},
    shopId : '',
    shopName : '',
    shopUrl : '',
    timeToDisconnect : 0,
    hasShop : false,
    shopData: null,
};

const identityReducer = (state = initialState, action) => {
    switch (action.type) {
        case IDENTITY_FETCHED :
            return {
                ...state,
                webUserId: action.payload.webUserId,
                navigationToken: action.payload.navigationToken,
                timeToDisconnect: action.payload.timeToDisconnect,
                userData : action.payload.userData,
                shopId : action.payload.shopId,
                shopName : action.payload.shopName,
                shopUrl : action.payload.shopUrl,
                hasShop : action.payload.hasShop
            }
        default:
            return state;
    }
};

export default identityReducer;
