/* ####################### Identity ########################### */
export const IDENTITY_FETCHED = 'IDENTITY_FETCHED';
/* ####################### Availbility ########################### */
export const UPDATE_ZIPCODE_KEYWORDS_IN_STATE = 'UPDATE_ZIPCODE_KEYWORDS_IN_STATE';
export const UPDATE_CITY_AND_ZIPCODE_IN_STATE = 'UPDATE_CITY_AND_ZIPCODE_IN_STATE';
export const UPDATE_GEO_ERROR_BLOC_STATUS = 'UPDATE_GEO_ERROR_BLOC_STATUS';
export const DELETE_CITYlIST_STATE = 'DELETE_CITYlIST_STATE';
export const CITY_SEARCHRESULTS_FETCHED = 'CITY_SEARCHRESULTS_FETCHED';
export const STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED = 'STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED';
export const AVAILABILITY_DATA_FETCHED = 'AVAILABILITY_DATA_FETCHED';
export const SHIPPING_AVAILABILITY_DATA_FETCHED = 'SHIPPING_AVAILABILITY_DATA_FETCHED';
/* ####################### ProductList ########################### */
export const PRODUCTLIST_FETCHED = 'PRODUCTLIST_FETCHED';
export const CURRENTFILTERSVALUE = 'CURRENTFILTERSVALUE';
export const RESETFILTERS = 'RESETFILTERS';
export const APPLYFILTERS = 'APPLYFILTERS';
export const APPLYFILTERSBLOCK = 'APPLYFILTERSBLOCK';
export const APPLYSORT = 'APPLYSORT';
export const HANDLENAVIGATION = 'HANDLENAVIGATION';
export const FETCHITEMFROMNAVIGATION = 'FETCHITEMFROMNAVIGATION';
export const PRODUCTS_AVAILABILITY_DATA_FETCHED = 'PRODUCTS_AVAILABILITY_DATA_FETCHED';
export const PRODUCTLISTDATA_FETCHED = 'PRODUCTLISTDATA_FETCHED';
export const SHOW_ATTACHMENT_BLOCK = 'SHOW_ATTACHMENT_BLOCK';
/* ####################### Menu ################################### */
export const MENUDATA_FETCHED = 'MENUDATA_FETCHED';
/* ####################### Header ################################# */
export const CARTDATA_FETCHED = 'CARTDATA_FETCHED';
export const USERDATA_FETCHED = 'USERDATA_FETCHED';
export const CART_DATA_UPDATE = 'CART_DATA_UPDATE';
export const CART_DATA_UPDATE_ERROR = 'CART_DATA_UPDATE_ERROR';
export const SHOP_DATA_FETCHED = 'SHOP_DATA_FETCHED';
export const CART_ITEMS_UPDATED = 'CART_ITEMS_UPDATED';
export const CART_ITEMS = 'CART_ITEMS';
export const PRIMARY_SHOP_DATA_FETCHED = 'PRIMARY_SHOP_DATA_FETCHED';
/* ####################### GEOLOCALISATION  ########################## */
export const GEOLOCATION_FETCHED = 'GEOLOCATION_FETCHED'
export const UPDATE_INSEE_CODE  = 'UPDATE_INSEE_CODE'
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE'
export const ZIPCODECITY_AUTOCOMPLETE = 'ZIPCODECITY_AUTOCOMPLETE'
export const UPDATE_ADDRESS_STATE = 'UPDATE_ADDRESS_STATE'
export const DESABLE_DQE_AUTOCOMPLETE = 'DESABLE_DQE_AUTOCOMPLETE'
export const ADDRESS_CHECKED = 'ADDRESS_CHECKED'
export const UPDATE_POPIN_DISPLAY = 'UPDATE_POPIN_DISPLAY'
export const UPDATE_ADDRESS_LATITUDE_LONGITUDE = 'UPDATE_ADDRESS_LATITUDE_LONGITUDE';
export const UPDATE_LATITUDE_LONGITUDE_IN_STATE = 'UPDATE_LATITUDE_LONGITUDE_IN_STATE';
export const UPDATE_LATITUDE_LONGITUDE_STATE = 'UPDATE_LATITUDE_LONGITUDE_STATE';
/* ####################### HeaderSearchBar  ########################## */
export const SEARCH_RESULTS_FETCHED = 'SEARCH_RESULTS_FETCHED';
export const TOP_SEARCHES_RESULTS_FETCHED = 'TOP_SEARCHES_RESULTS_FETCHED';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SEARCH_STARTED = 'SEARCH_STARTED';
export const CHANGE_KEYWORD = 'CHANGE_KEYWORD';
export const INCREMENT_REVIEWS_PAGE_SIZE = 'INCREMENT_REVIEWS_PAGE_SIZE';

/* ####################### SHOP ################################# */
export const DESTOCKED_PRODUCTS_INFOS_FETCHED = 'DESTOCKED_PRODUCTS_INFOS_FETCHED';
export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
export const GET_CARAC_PRODUCT = 'GET_CARAC_PRODUCT';
export const HANDLE_SHOP_OVERLAY = 'HANDLE_SHOP_OVERLAY';
export const SELECT_SHOP =  'SELECT_SHOP';
export const UPDATE_SHOW_MAP = 'UPDATE_SHOW_MAP';
export const SEARCH_SHOP_RESULT_FETCHED = 'SEARCH_SHOP_RESULT_FETCHED';
/* ####################### Tracking ########################### */
export const TAGGING_FETCHED = 'TAGGING_FETCHED';
/* ####################### ProductSheet ########################### */
export const OFFERS_DATA_FETCHED = 'OFFERS_DATA_FETCHED'
export const FILTER_CHANGED = 'FILTER_CHANGED'
export const CHANGE_DISPLAY_LISTING_FROM = 'CHANGE_DISPLAY_LISTING_FROM'
export const UPDATE_QUESTION_FORM_DATA = 'UPDATE_QUESTION_FORM_DATA'
export const UPDATE_SUBS_MENTIONS_FLAG = 'UPDATE_SUBS_MENTIONS_FLAG'
export const INIT_QUESTION_DATA = 'INIT_QUESTION_DATA'
export const QUESTIONS_DATA_SEND = 'QUESTIONS_DATA_SEND'
export const QUESTIONS_DATA_FETCHED = 'QUESTIONS_DATA_FETCHED'
export const REVIEWS_DATA_FETCHED = 'REVIEWS_DATA_FETCHED'
export const SUBSCRIBE_STOCK = 'SUBSCRIBE_STOCK'
export const HANDLE_EMAIL_CHANGE = 'HANDLE_EMAIL_CHANGE'
export const HANDLE_ASK_QUESTION_POPIN = 'HANDLE_ASK_QUESTION_POPIN'
export const HANDLE_STOCK_SUBSCRIPTION_POPIN = 'HANDLE_STOCK_SUBSCRIPTION_POPIN'
export const HANDLE_STOCK_SUBSCRIPTION_SUCCESS_POPIN = 'HANDLE_STOCK_SUBSCRIPTION_SUCCESS_POPIN'
export const UPDATE_SHOP_ID = 'UPDATE_SHOP_ID'
export const CART_GLD_UPDATED = 'CART_GLD_UPDATED'
export const BUNDLE_OFFERS_DATA_FETCH = 'BUNDLE_OFFERS_DATA_FETCH'
export const SET_SHOW_POPIN_PROMOTIONAL_OFFERS = 'SET_SHOW_POPIN_PROMOTIONAL_OFFERS'
export const UPDATE_LOAD_DQE = 'UPDATE_LOAD_DQE';
export const UPDATE_LOAD_DQE_LOCATE_ME = 'UPDATE_LOAD_DQE_LOCATE_ME';
export const UPDATE_LOAD_JQUERY_UI = 'UPDATE_LOAD_JQUERY_UI';
export const UPDATE_INPUT_SELECTED_IDS = 'UPDATE_INPUT_SELECTED_IDS';




/* ####################### CART ################################# */
export const SHIPPING_CART_ITEMS_UPDATED = 'SHIPPING_CART_ITEMS_UPDATED';
export const LOAD_CART_API_RESPONSE = 'LOAD_CART_API_RESPONSE';
export const UPDATE_CREATION_FORM_DATA = 'UPDATE_CREATION_FORM_DATA';
export const UPDATE_CHANGE_QUANTITY_STATE = 'UPDATE_CHANGE_QUANTITY_STATE';
/* ####################### SHIPPING ################################# */
export const UPDATE_BILLING_ADDRESS_STATE_ATTRIBUTE= 'UPDATE_BILLING_ADDRESS_STATE_ATTRIBUTE';
export const UPDATE_ADDRESS_CIVILITY= 'UPDATE_ADDRESS_CIVILITY';
export const UPDATE_ADDRESS_FIRSTNAME = 'UPDATE_ADDRESS_FIRSTNAME';
export const UPDATE_ADDRESS_LASTNAME = 'UPDATE_ADDRESS_LASTNAME';
export const UPDATE_ADDRESS_ZIPCODE = 'UPDATE_ADDRESS_ZIPCODE';
export const UPDATE_ADDRESS_CITY = 'UPDATE_ADDRESS_CITY';
export const UPDATE_ADDRESS_STREET_ADDRESS = 'UPDATE_ADDRESS_STREET_ADDRESS';
export const UPDATE_ADDRESS_STREET_ADDRESS_COMPLEMENT = 'UPDATE_ADDRESS_STREET_ADDRESS_COMPLEMENT';
export const UPDATE_ADDRESS_PHONE_NUMBER = 'UPDATE_ADDRESS_PHONE_NUMBER';
export const ERROR_CREATE_ORDER = 'ERROR_CREATE_ORDER';
export const ERROR_BILLING_ADDRESS = 'ERROR_BILLING_ADDRESS';
export const SET_ERROR_CART_MESSAGES = 'SET_ERROR_CART_MESSAGES';
export const DELETE_CITYLIST_STATE = 'DELETE_CITYLIST_STATE';
export const RESET_RELAY_POINT_ERROR_MESSAGE = 'RESET_RELAY_POINT_ERROR_MESSAGE';
export const PREPARE_RDV_CONTACTLESS_PICKUP_VISUAL_PRODUCTS = 'PREPARE_RDV_CONTACTLESS_PICKUP_VISUAL_PRODUCTS';
export const RESET_RElAY_POINT_ERROR_MESSAGE= 'RESET_RElAY_POINT_ERROR_MESSAGE';
export const INIT_CART_DATA_VIEW_SHIPPING= 'INIT_CART_DATA_VIEW_SHIPPING';
export const UPDATE_IN_STORE_ORDER_DETAILS = 'UPDATE_IN_STORE_ORDER_DETAILS';
export const DISCOUNT_CODE_APPLIED = 'DISCOUNT_CODE_APPLIED';
export const REMOVE_DISCOUNT_CODE = 'REMOVE_DISCOUNT_CODE';
export const CONDITION_OFFER_DETAILS = 'CONDITION_OFFER_DETAILS';