import React, {memo, useMemo} from 'react';
import Tracking from '../../Tracking/index'
import ServicesShops from "./ServiceShops";
import FooterMenu from "./FooterMenu";
import LastLignes from "./LastLignes";

const Footer = memo(({footerTrackingFlow, footerTrackingScopeData, children}) => {
    const memoizedFooter = useMemo(() =>
        <>
            <ServicesShops/>
            <FooterMenu/>
            <LastLignes/>
        </>, [])
    return (
        <>
            {memoizedFooter}
            {children}
            {footerTrackingFlow && <Tracking trackingData={footerTrackingFlow}/>}
            {footerTrackingScopeData && <Tracking trackingData={footerTrackingScopeData}/>}
        </>
    )
});
export default Footer;