import {
    FETCH_ORDER_LIST_DATA
} from '../actionsTypes'


const initialState = {
    items: null
};

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_LIST_DATA:
            return {
                ...state,
                items: action.payload.items,
            }
        default:
            return state;
    }
};

export default OrderReducer;

