import React from 'react';

export const FullPageLoader = () => (
    <div className="wrapper">
        <div className="navigationBloack aside">
            <div className="placeholder butCardPlaceholder">
                <div className="head block"/>
                <div className="body block"/>
            </div>
        </div>
        <div className="pageBody aside">
            <div className="placeholder orderPlaceholder">
                <div className="whiteBlock">
                    <div className="block1">
                        <div className="head">
                            <div>
                                <div className="line1 block"/>
                                <div className="line2 block"/>
                            </div>
                            <div className="line3 block"/>
                        </div>
                        <div className="body">
                            <div className="block"/>
                            <div className="block"/>
                            <div className="block"/>
                        </div>
                    </div>
                    <div className="block2">
                        <div>
                            <div className="line1 block"/>
                            <div className="line2 block"/>
                            <div className="line3 block"/>
                            <div className="line4 block"/>
                        </div>
                        <div className="square block"/>
                    </div>
                    <div className="block1">
                        <div className="head">
                            <div>
                                <div className="line1 block"/>
                                <div className="line2 block"/>
                            </div>
                            <div className="line3 block"/>
                        </div>
                        <div className="body">
                            <div className="block"/>
                            <div className="block"/>
                            <div className="block"/>
                        </div>
                    </div>
                    <div className="block2">
                        <div>
                            <div className="line1 block"/>
                            <div className="line2 block"/>
                            <div className="line3 block"/>
                            <div className="line4 block"/>
                        </div>
                        <div className="square block"/>
                    </div>
                </div>
            </div>
            <div className="placeholder orderPlaceholder">
                <div className="whiteBlock">
                    <div className="block1">
                        <div className="head">
                            <div>
                                <div className="line1 block"/>
                                <div className="line2 block"/>
                            </div>
                            <div className="line3 block"/>
                        </div>
                        <div className="body">
                            <div className="block"/>
                            <div className="block"/>
                            <div className="block"/>
                        </div>
                    </div>
                    <div className="block2">
                        <div>
                            <div className="line1 block"/>
                            <div className="line2 block"/>
                            <div className="line3 block"/>
                            <div className="line4 block"/>
                        </div>
                        <div className="square block"/>
                    </div>
                    <div className="block1">
                        <div className="head">
                            <div>
                                <div className="line1 block"/>
                                <div className="line2 block"/>
                            </div>
                            <div className="line3 block"/>
                        </div>
                        <div className="body">
                            <div className="block"/>
                            <div className="block"/>
                            <div className="block"/>
                        </div>
                    </div>
                    <div className="block2">
                        <div>
                            <div className="line1 block"/>
                            <div className="line2 block"/>
                            <div className="line3 block"/>
                            <div className="line4 block"/>
                        </div>
                        <div className="square block"/>
                    </div>
                </div>
                <div className="footer">
                    <div className="block"/>
                </div>
            </div>
            <div className="navigationBloack aside">
                <div className="placeholder butCardPlaceholder">
                    <div className="head block"/>
                    <div className="body block"/>
                </div>
            </div>
        </div>
    </div>
)
