/* ####################### Template Version Const ########################### */
export const MOBILE_TEMPLATE_VERSION = 'Mobile';
export const DEFAULT_TEMPLATE_VERSION = 'Default';
export const REGEX_IS_MOBILE_OR_TABLET = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i


/* ####################### PAGE LIST LABEL  ########################### */
export const HOME_PAGE = 'standard';
export const PRODUCT_LIST_PAGE = 'productsList';
export const PRODUCT_SHEET_PAGE = 'productSheet';
export const SEARCH_PAGE = 'searchPage';
export const CART_PAGE = 'cartPage';
export const CALL_API_AND_FRONT_DISPLAY_MODE = 'Call_API_And_Front_Display';
export const NOT_CALL_API_AND_NOT_FRONT_DISPLAY_MODE = 'Not_Call_API_And_Not_Front_Display';
export const NAVIGATION_PAGE = 'navigationPage';
export const REVIEWS_DISPLAY_THRESHOLD = 2;
export const DASHBOARD_PAGE = 'dashboard';
export const CATEGORIES_PAGE = 'categoriesPage';

/* ####################### PAGE CATEGORIES  ########################### */
export const FIRST_CATEGORY_LEVEL = 'niv1';
export const SECOND_CATEGORY_LEVEL = 'niv2';

/* ####################### PAGE FAST TRACK  ########################### */
export const IN_STORE_PAGES = 'inStore';
export const IN_STORE_PURCHASE_SHIPPING_PAGE = 'inStorePurchaseShipping';
export const IN_STORE_PURCHASE_CART_PAGE = 'inStorePurchaseCart';
export const IN_STORE_PURCHASE_BOOKING_PAGE = 'inStorePurchaseBooking';
export const IN_STORE_PURCHASE_CONFIRMATION_PAGE = 'inStorePurchaseConfirmation';
export const IN_STORE_PURCHASE_ACTIVATION_SCAN_PAGE = 'inStorePurchaseActivationScan';

export const ACTIVATE_PAGE_MVC_URL = 'Common/Common/getCommonRequiredData';

/* ####################### SHIPPING METHODS CONST ########################### */
export const SHIPPING_BY_TRANSPORTER = 'ShippingByTransporter';
export const RDV_CONTACTLESS_PICKUP = 'RdvContactlessPickup';

/* ####################### ERROR MESSAGE CONST ########################### */
export const CCV_ERROR_MESSAGE = 'Votre créneau de livraison n\'est plus disponible, veuillez en choisir un autre.';
export const TECHNICAL_ERROR_MESSAGE = 'Suite à une erreur technique, nous ne pouvons pas valider votre RDV avec notre transporteur. Ce dernier vous contactera afin de fixer votre RDV de livraison dès que votre commande sera expédiée.';


/* ####################### PAGE CUSTOMER AREA  ########################### */
export const CUSTOMER_AREA_PAGE = 'customerArea';
export const DEFAULT_VALUE_TO_CONVERT_MINUTES_TO_MILLISECONDS = 1000;
export const CUSTOMER_PAGE = 'CustomerPage';
export const CUSTOMER_ACCOUNT_ORDERS_PAGE = 'CUSTOMER_ACCOUNT_ORDERS_PAGE';
export const CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE = 'CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE';
export const CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE = 'CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE';
export const CUSTOMER_ACCOUNT_SOCIAL_MEDIA = 'CUSTOMER_ACCOUNT_SOCIAL_MEDIA';
export const CUSTOMER_ACCOUNT_PROFIL_PAGE = 'CUSTOMER_ACCOUNT_PROFIL_PAGE';
export const CUSTOMER_ACCOUNT_NEWSLETTER_PAGE = 'CUSTOMER_ACCOUNT_NEWSLETTER_PAGE';
export const CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE = 'CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE';
export const CUSTOMER_ACCOUNT_TRACKING_ACTION = 'Page d\'accueil';

/* ####################### PAGE PLANNER  ########################### */
export const PLANNER_PAGE = 'planner';
export const KITCHEN_PROJECTS = 'Mes projets cuisine 3D';
export const KITCHEN_PROJECTS_TRACKING_ACTION = '3D planner cuisine_Page mes projets';

/* ####################### PAGE MESSAGE  ########################### */
export const MAX_FILE_SIZE = 10485760;
export const FAILURE_CONTEXTS = ['sellerMessageFailed', 'sellerIncidentFailed', 'sellerMessageWithFileFailed', 'sellerIncidentWithFileFailed', 'invoiceRequestFailed', 'operatorMessageFailed'];
export const SELLER_INCIDENT_CONTEXT = 'sellerIncident';
export const SELLER_WITH_INCIDENT_CONTEXT = 'sellerWithIncident';
export const SELLER_INCIDENT_WITH_FILE_CONTEXT = 'sellerIncidentWithFile';
export const OPERATOR_MESSAGE_CONTEXT = 'operatorMessage';
export const SELLER_MESSAGE_WITH_FILE_CONTEXT = 'sellerMessageWithFile';
export const SELLER_MESSAGE_CONTEXT = 'sellerMessage';
export const INCIDENT_OPEN = 'INCIDENT_OPEN';
export const ORDER_LINE_STATE_LIST = ["REFUSED", "CLOSED", "CANCELED", "WAITING_ACCEPTANCE", "WAITING_DEBIT_PAYMENT"];
export const SEND_MESSAGE_CONTENT = "sendMessageContent";


/* ####################### not lazy loading product Image number   ########################### */
export const NOT_LAZY_MAX_IMAGE_NUMBER = 7
export const FILTER_AVAILABILITY_SHOP_ID_LIST_CODE = 'AvailabilityShopIdList'
/* ####################### renderMode PREFLTER LABEL    ########################### */
export const RENDER_MODE_PREFELTER = "IsPrefilter"
export const FILTER_PRICE_CODE = "Prix de référence"
/* ####################### max number  PREFLTER showed value    ########################### */
export const MAX_NUMBER_PREFELTER = 5

/* ####################### PAGINATION Const  ########################### */
export const SEE_MORE_VALUE = 'seeMore'
export const SEE_PREVIOUS_VALUE = 'seePrevious'

/* ####################### Regex    ########################### */
export const PHONE_REGEX = /^0[6-7][0-9]{8}$/
export const NAME_REGEX = /^[A-Za-zàâçéèêëîïôûùüÿñæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\d' .-]*$/i
export const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+\/=?^_'{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_'{|}~-]+)*|'(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
export const STREET_ADDRESS_COMPLEMENT_REGEX = /^[A-Za-zàâçéèêëîïôûùüÿñæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ\d,#'* .-]*$/i
export const CUSTOMER_ACCOUNT_MAX_NUMBER_ORDERS = 5
export const CUSTOMER_ACCOUNT_MAX_NUMBER_PROJECTS = 6

export const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
export const CUSTOMER_ACCOUNT_BACKEND_MAX_NUMBER_ORDERS_DASHBOARD_PAGE = 4
export const CUSTOMER_ACCOUNT_FRONTEND_MAX_NUMBER_ORDERS_DASHBOARD_PAGE = 3
export const ORDER_SHIPPED_LABEL = 'SHIPPED'
export const WORD_TO_REPLACE_IN_HOSTNAME = 'fasterize'


/* ####################### MESSAGE    ########################### */
export const REQUIRED_FIELD_MESSAGE = 'Champ obligatoire.'
export const REQUIRED_FIELD_MESSAGE_CIVILITY = 'Veuillez sélectionner une civilité.'
export const INVALIDE_FIELD_MESSAGE = 'Champ invalide.'
export const INVALIDE_FIELD_MESSAGE_COMP_ADR = 'Merci de ne pas renseigner de caractères spéciaux.'
export const MAX_LENGTH_FIELD_MESSAGE = (maxlength) => "Veuillez ne pas saisir plus de " + maxlength + " chiffres."
export const MIN_LENGTH_FIELD_MESSAGE = (minlength) => "Votre numéro doit être composé de " + minlength + " chiffres"
export const INVALIDE_PHONE_MESSAGE = 'Numéro de téléphone invalide.'
export const USED_PHONE_MESSAGE = 'Numéro de téléphone existant.'
export const INVALIDE_EMAIL_MESSAGE = 'Adresse e-mail invalide.'
export const USED_EMAIL_MESSAGE = 'Adresse e-mail existante'
export const UPDATE_KITCHEN_PROJECT_ERROR_MESSAGE = 'Une erreur technique est survenue, le projet n\'a pas pu être modifié.'
export const DELETE_KITCHEN_PROJECT_ERROR_MESSAGE = 'Une erreur technique est survenue, le projet n\'a pas pu être supprimé.'
export const LOAD_KITCHEN_PROJECT_ERROR_MESSAGE = 'Une erreur technique est survenue. Veuillez réessayer ultérieurement.'

/* ####################### Group User Type  ########################### */
export const GROUP_USER_WEB_BY_TOKEN = 'WebByToken';

/* ####################### Espace perso couleur des status commande ########################### */
export const DEFAULT_TRACKING_STATUS_COLOR = 'Blue';
export const SUCCESS_FINAL_TRACKING_STATUS_COLOR = 'Green';
export const FAILED_FINAL_TRACKING_STATUS_COLOR = 'Orange';
export const CANCELATION_STATUS_LABEL = 'Annulé';

/* ####################### RDV STATUS ########################### */
export const IN_PREPARATION_STATUS_ID = 20
export const LATE_STATUS_ID = 45

/* ####################### MarketPlace ########################### */
export const SOLD_BY_MKP = "MKP";
export const SOLD_BY_BUT = "BUT";
export const SOLD_BY_BUT_MKP = "BUT_MKP";


/* ####################### Product Sheet ########################### */
export const SEO_URL_PARAM_NAME = "SeoUrl";
export const MD_SLIDER_BREAKPOINT = 728;
export const L_SLIDER_BREAKPOINT = 999999;
export const VISUAL_MAX_IMAGES_TO_DISPLAY = 6;
export const SLIDER_MAX_IMAGES_TO_DISPLAY = 10;
export const MAX_IMAGES_BEFORE_LAZY_LOADING = 2;
export const POSTION_VIDEO_1 = 4;
export const POSTION_VIDEO_2 = 5;
export const FRONT_VUE_PRESET_REGEX = /_F\./;
export const AMBIANCE_VUE_PRESET_REGEX = /_AMB1\./;
export const FILE_FORMAT_REGEX = /[^.]+$/;
export const PHOTO_SIZE = /(p)-(xl|xxl|s)/;
export const BUNDLE_TYPE_PRODUCT = "bundle";
export const LAYER_TYPE = {
  WARRANTIES: 'warranties',
  BUNDLE: 'bundle',
  NONE: 'none',
  STOCK_LOCATOR: 'stockLocator',
  BASKET: 'basket',
  FEATURES: 'features',
  DOCUMENTATION: 'documentation',
  RATING: 'rating',
  QUESTION: 'question',
  OFFER_CHOICES: 'offerChoices',
  CREDIT: 'credit',
  ECO_CONCEPTION: 'ecoConception',
  DESCRIPTION: 'description',
  SELLER_SERVICE: 'sellerService',
  TAKE_BACK: 'takeBack',
  FIND_STORE: 'findStore',
  SHIPPING_INFO: 'shippingInfo',
  SHIPPING: 'shipping',
  ASK_QUESTION: 'askQuestion',
  OLD_PRODUCT: 'oldProduct',
  CHOICE_BOX: 'choiceBox',
  ATTACHEMENT: 'attachement'
};
export const UNAVAILABLE_PRODUCT_STATUS = "no";
export const AVAILABLE_PRODUCT_STATUS = "yes";
export const CAN_BE_AVAILABLE_STATUS  = "canBe";
export const PRODUCT_CURRENTLY_UNAVAILABLE  = "Produit indisponible pour le moment"
export const BREAKPOINT_DESKTOP = 768;
export const DEFAULT_QUANTITY = 1;
export const MAX_QUANTITY = 30;
export const MAX_RATING = 5;
export const BUTTON_CUSTOM_TYPE = {
  NONE: 'aucun',
  SOFA: 'canapé',
  BEDDING: 'literie',
  DRESSING: 'dressing',
};
export const ROOT_BUT_URL = 'https://www.but.fr';
export const INFORMATION_SHEET_LINK = "Fiche produit";
/* ####################### PAGE PRODUCTSHEET    ########################### */
export const SLIDER_BREAKPOINT = 768;
export const SHIPPING_INFO = "shippingInfo";
export const SHIPPING = "shipping";
export const PLACEHOLDER_TYPE = {
    GENERIC: 'generic',
    LOCATION: 'location',
    BASKET: 'basket',
    PICKUP: 'pickup',
    ICON: 'icon',
    SHOP: 'shop',
    AVAILABILITY: 'availability',
    SEARCH: 'search',
    BASKET_BUNDLE: 'basket-bundle',
  };
export const SHIPPING_BLOC_TYPE = {
  STOCK_LOCATOR: 'stockLocator',
  SHIPPING_LAYER: 'shippingLayer',
};
export const STOCK_LOCATOR_ERROR_TYPE = {
  NONE: "NONE",
  GEOLOC: "GEOLOC",
  ZIPCODE: "ZIPCODE",
  AVAILABILITY: "AVAILABILITY",
  SHOP_CIRCLE: "SHOP_CIRCLE",
};
export const SHIPPING_TYPE = {
  RELAY_POINT_METHODS: 'relayPointMethods',
  PICKUP_FROM_SHOP_METHODS: 'pickupFromShopMethods',
  SHIPPING_TO_CUSTOMER_METHODS: 'shippingToCustomerMethods',
  HIGHTLIGHT_SHIPPING_METHODS: 'hightlightShippingMethods',
};
/* ####################### SPEEDY SCAN #######################*/
export const NAVIGATION_META_INFO_SPEEDY_SCAN = "navigationMetaInfoSpeedyScan";
export const SPEEDY_SCAN_CONTEXT = "Scan";

/* ####################### VIEWS #######################*/
export const COMPACT_VIEW = "Compact";
export const SHIPPING_VIEW = "shipping";
export const CART_VIEW = "cart";

/* ####################### URL COMMON #######################*/
export const COMMON_API = "Common/RequiredData";
export const CUSTOMER_API = "Customers/CommunInfoData";

/* ####################### SOLD BY PRODUCT #######################*/
export const MKP = "MKP";
export const BUT = "BUT";
export const BUT_MKP = "BUT_MKP";
export const MAG_BUT = "MAG_BUT";

/* ####################### WARRANTY  #######################*/
export const TOTALY_REFUNDED = "100% REMBOURSEE"
