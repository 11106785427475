import Cookies from 'js-cookie'

export const initAppDataKeyValues = (navigationMetaInfosConfigs, octipasPayload, trackingUrlFapi, currentStep, olapicConfig = null, context = '', availabilityDisplayMode = '', isDQENormalisationActive = null) => {
    appData = appData || {}
    appData.navigationMetaInfosConfigs = navigationMetaInfosConfigs;
    appData.sparkowUserIdPatternName = 'cuserid';
    appData.currentStep = currentStep;
    appData.sparkowUserIdCookieName = 'CUserID';
    appData.trackingUrlFapi = trackingUrlFapi;
    appData.octipasCuserId = Cookies.get('CUserID');
    appData.octipasPayload = octipasPayload;
    appData.context = context;
    appData.availabilityDisplayMode = availabilityDisplayMode;
    appData.olapicConfig = olapicConfig;
    appData.isDQENormalisationActive = isDQENormalisationActive;
}
export const getCookieValue = (cookieName) => {
    return Cookies.get(cookieName)
}
export const setCookieValue = (cookieName, value) => {
    Cookies.set(cookieName, value)
}
export const dispatchEventSTPUSHUpdate = (tags) => {
     window.dispatchEvent(
         new CustomEvent('STPUSHUpdate', {
              detail: {
                   filters: {
                        tags: tags,
                        thematics: [],
                   },
              },
         }),
     )
}
