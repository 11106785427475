import ApiCaller from '@/services/ApiCaller/apiCallerDefault';
import Utils from "@/utils/Default/Utils"
import OctipasMerch from "@/utils/Default/OctipasMerch";
import {
    CARTDATA_FETCHED,
    MENUDATA_FETCHED,
    CART_ITEMS_UPDATED,
    UPDATE_SHOP,
    USER_IDENTITY_FETCHED,
    PRIMARY_SHOP_DATA_FETCHED,
    SHOP_EVENT_EXECEPTIONNAL_EVENT,
    SHOP_EVENT_LIST_DATA_FETCHED,
    UPDATE_SHOW_PRICE_WITHOUT_TAX
} from "@/redux/reducers/Default/actionsTypes"
import {DEFAULT_TEMPLATE_VERSION} from "@/utils/constants";
import Cookies from "js-cookie";

export const fetchCartData = () => {
    return function (dispatch) {
        let id = Utils.getUserIdFromCookie();
        ApiCaller.post('/WebUsers/' + id + '/Cart?View=compact', {context: ''})
            .then(response => {
                dispatch({
                    type: CARTDATA_FETCHED,
                    payload: {cart: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
export const synchronizeShopCircle = (shopCircleVersion) => {
    return new Promise((resolve, reject) => {
        localStorage.setItem('shopCircleVersion', shopCircleVersion)
        Utils.initializeLocalStorageShopCircle(shopCircleVersion);
        let data = {
            "shopCircle": Utils.isValidShopCircle() && !Cookies.get('shopCircleSource') ? localStorage.getItem('shopCircle') : ''
        }
        let id = Utils.getUserIdFromCookie();
        ApiCaller.put('/WebUsers/' + id + '/ShopCircle', data)
            .then(response => {
                if (response?.data?.shopCircle?.primaryShop) {
                    if (Cookies.get('shopCircleSource') && !Cookies.get('availabilityShopCircleCloudFrontHeader')) {
                        Cookies.set('shopCircleCloudFrontHeader', JSON.stringify(response.data.shopCircle))
                        Cookies.set('availabilityShopCircleCloudFrontHeader', JSON.stringify(Utils.getAvailabilityShopCircleData(response.data.shopCircle)))
                    }
                    localStorage.setItem('shopCircle', JSON.stringify(response.data.shopCircle))
                    Cookies.set('availabilityShopCircle', JSON.stringify(Utils.getAvailabilityShopCircleData(response.data.shopCircle)), {
                        path: "/",
                        domain: "",
                        expires: 365
                    })
                } else {
                    localStorage.removeItem('shopCircle')
                    localStorage.removeItem('availabilityShopCircle')
                }
                resolve(response);
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    })
}

export const fetchPrimaryShopData = (shopId) => {
    return function (dispatch) {
        let url = '/Shops/' + shopId + '?View=Light';
        ApiCaller.get(url)
            .then(response => {
                dispatch(fetchShopData(response.data));
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export const fetchShopData = (shopData) => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_SHOP,
            payload: {
                shop: shopData,
            }
        })
    }
}

export function deleteProduct(productId, trackingData) {
    return function (dispatch) {
        const data = {}
        data.action = "removeProducts"
        data.items = [{id: productId}];
        ApiCaller.put("/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=compact", data)
            .then(response => {
                OctipasMerch?.createOrReplaceBasket(Utils.getBasketData(response.data.items))
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cart: response.data}
                });
                appData.actionTrigger.dispatch({
                    trigger: "removeFromMiniCart",
                    payload: {
                        ...trackingData
                    }
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function getDefaultMenu(deviceType, userAgent) {
    let data = {wordingVersion: DEFAULT_TEMPLATE_VERSION};
    return function (dispatch) {
        ApiCaller.defaults.headers['User-Agent'] = userAgent;
        ApiCaller.get("V1/CMS/Menu", {params: data})
            .then(response => {
                dispatch({
                    type: MENUDATA_FETCHED,
                    payload: {menuItems: response.data.menuItems, isDefaultMenu: true}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}


export function getUserIdentity(isPrivateSale = false) {
    return function (dispatch) {
        ApiCaller.get("WebUsers/Identity")
            .then(response => {
                dispatch({
                    type: USER_IDENTITY_FETCHED,
                    payload: {userData: response.data}
                });
                if(isPrivateSale){
                    if(response?.data?.group !== 'WebCustomers') {
                        window.location.href = "/Customer/Account/Identification"
                    } else if(!response?.data?.eligibilityToPrivateSale){
                        window.location.href = "/Customer/Account/Dashboard/Id/"+response.data.userId
                    }
                }
            }).catch(error => {
            console.log(error)
        })

    }
}

export function getPrimaryShopData() {
    return function (dispatch) {
        ApiCaller.get("/Shops/" + Utils.getPrimaryShopId() + "?View=Full")
            .then(response => {
                dispatch({
                    type: PRIMARY_SHOP_DATA_FETCHED,
                    payload: {shopData: response.data}
                });
            }).catch(error => {
            console.log(error)
        })
    }
}

const prepareExceptionalShopEventMessage = (shopEvent) => {
    let exceptionnalEvent = shopEvent.filter(item => ['16', '6'].includes(item.typeId))
    if (exceptionnalEvent && exceptionnalEvent.length > 0) {
        let text = exceptionnalEvent[0].displayingInfos.items.filter(item => item.code == "textEventDate")
        let messageValue = text && text.length > 0 ? text[0].value.trim() : ''
        messageValue = messageValue.charAt(0).toLowerCase() + messageValue.slice(1);
        return exceptionnalEvent[0].label + ' ' + messageValue
    }
    return '';
}

export function getShopEventListData(shopId) {
    return function (dispatch) {
        ApiCaller.get("/ShopsEvents?ShopIdType=Web&ShopIds=" + shopId)
            .then(response => {
                dispatch({
                    type: SHOP_EVENT_LIST_DATA_FETCHED,
                    payload: {shopData: response.data}
                });
                let shopEvent = response.data?.items[0]?.eventList;
                if (shopEvent && shopEvent.length > 0) {
                    dispatch({
                        type: SHOP_EVENT_EXECEPTIONNAL_EVENT,
                        payload: {shopExpEventTxt: prepareExceptionalShopEventMessage(shopEvent)}
                    });
                }
            }).catch(error => {
            console.log(error)
        })
    }
}

export const updateShowPriceWithoutTax = (value) => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_SHOW_PRICE_WITHOUT_TAX,
            payload: {value: value}
        })
    }
}
