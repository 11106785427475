import $ from 'jquery'

const productListAnimation = {

    init() {
        let backToTop = $('#backToTop')
        /* BACKTOTOP */
        backToTop.on('click', function (e) {
            e.preventDefault()
            $('html,body').animate({
                scrollTop: 0
            }, 700)
        })
        $('#switch-list').on('change', function () {
            if ($(this).prop('checked')) {
                $('.little-euro-ht').removeClass('d-none')
            } else {
                $('.little-euro-ht').addClass('d-none')
            }
        })
        $(window).on('scroll', function (e) {
            e.preventDefault()
            if ($(window).scrollTop() < 50) {
                backToTop.addClass('fadeOut').removeClass('fadeIn').css('display', 'none')
            } else {
                backToTop.addClass('fadeIn').removeClass('fadeOut').css('display', 'block')
            }

        })

        $('.selectpicker').selectpicker({
            width: 'auto'
        })

        $('#filterCloseBtn').on('click', function (e) {
            e.preventDefault()
            $('body').removeClass('menu-open');
            $('.filter').fadeOut()
        })

        $('#filterBtn').on('click', function (e) {
            e.preventDefault()
            $('.filter').fadeIn()
        })

        $('#orderBtn').on('click', function (e) {
            e.preventDefault()
            $('#orderSelect').show().focus().click()
        })

        $('#lastProductCarousel').slick({
            arrows: false,
            adaptiveHeight: true,
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 2,
            slidesToScroll: 1
        })

        $('.collapse__row, .collapse__row--noborder').on('click', function (e) {
            e.preventDefault()
            $(this).find('input').prop('checked', !$(this).find('input').prop('checked'))
        })
        $('.item .category').on('click', function (event) {
            event.preventDefault();
            if ($(this).hasClass('collapsed')) {
                $(this).removeClass('collapsed')
                $(this).next().addClass('show')
                event.stopPropagation();
            } else {
                $(this).addClass('collapsed')
                $(this).next().removeClass('show')
                event.stopPropagation();
            }
        })
        $('.color__item').each(function () {
            if ($(this).find('input').prop('checked')) {
                $(this).addClass('color__item--active')
            }
        })
        $('.color__item').on('click', function (e) {
            e.preventDefault()
            $(this).find('input').prop('checked', !$(this).find('input').prop('checked'))
            $(this).toggleClass('color__item--active')
        })
        $('.avis__item').on('click', function (e) {
            e.preventDefault()
            $(this).find('input').prop('checked', !$(this).find('input').prop('checked'))
            $(this).toggleClass('avis__item--active')
        })

        $('#seemoreProductBtn').on('click', function () {
                $('#animsmall_container').parent().css('display', 'flex')
            }
        )
        $('.current-filter__item').on('click', function () {
            if ($(this).hasClass('blanc')) {
                $(this).removeClass('blanc')
            } else {
                $(this).addClass('blanc')
            }
        })

        $('.pannel-seller__collapse').on('click', function () {
            if ($(this).next().hasClass('show')) {
                $(this).next().removeClass('show')
                $(this).find(' .list__items__row__infos__collapse').addClass('collapsed')
            } else {
                $(this).next().addClass('show')
                $(this).find('.list__items__row__infos__collapse').removeClass('collapsed')
            }
        })
    },
    showFilterHandleClick() {
        $('#showFiltre').click(function () {
            if ($('#collapseSearch').hasClass('show')) {
                $('#showFiltre').text('Voir plus')
            } else {
                $('#showFiltre').text('Voir moins')
            }
        })
        $('#showMoreVille').on('click', function () {
            if ($('#collapseShowMoreVille').hasClass('show')) {
                $('#showMoreVille').text('Voir plus')
            } else {
                $('#showMoreVille').text('Voir moins')
            }
        })
    },
    closeFilterNavigation() {
        $('.filter').fadeOut(400, function () {
        })
    },
    showLoader() {
        $('.loader').css('display', 'flex');
    },

    hideLoader() {
        $('.loader').css('display', 'none');
    },
    hideSmallLoader() {
        $('.loader-small').css('display', 'none');
    },
    scrollToDiv(divId, context) {
        if(context && context === "search") {
            let self = this;
            $(window).on('load', function(){
                self.scrollToDivAnimation(divId)
            });
        } else {
            this.scrollToDivAnimation(divId)
        }
    },
    scrollToDivAnimation(divId) {
        var nav = $('#' + divId);
        if (nav.length > 0) {
            $('html, body').animate({
                scrollTop: nav.offset().top - 100
            }, 'slow');
        }
    },
    closeCollapses() {
        $(".filter__body > div:not(:first)").each(function (index) {
                let id = $(this).find('.collapse.show').attr('id');
                $(this).find('.category').removeClass('category').addClass('category collapsed')
                if (id) {
                    $(this).find('.collapse.show').removeClass('show')
                }
            }
        )
    },
    reinitLazyLoad() {
        if (appData.firstLoad) {
            $('.lazy').Lazy();
            appData.firstLoad = false;
        } else {
            let images = $('.lazy');
            [].forEach.call(images, function (image) {
                if ($(image).attr('data-src')) {
                    image.src = $(image).attr('data-src');
                    $(image).removeAttr('data-src');
                }
            });
        }

    },
    appendMerchActionHtml(id, data) {
        $('#' + id).append(data);
    },

    drainTarget2SellContent() {
        $('#PPNA1QL127P68J-1409-737').hide();
    },
    showTarget2SellContent() {
        $('#PPNA1QL127P68J-1409-737').show();
    },
    drainTarget2SellHeaderContent() {
        $('#PPNA1QL127P68J-1409-1466').hide();
    },
    showTarget2SellHeaderContent() {
        try {
            $('#PPNA1QL127P68J-1409-1466').show();
        } catch (e) {
            console.log('showTarget2SellContent error ', e)
        }
    },
    refreshT2S(params, productListIds, showT2S) {
        let isAppliedFilter = false;
        const {filterItemTMP: filterItem} = params
        if (params?.currentSort && params.currentSort[3] == '') {
            $('#PPNA1QL127P68J-1409-1466').empty();
            if (filterItem?.filterCode && filterItem.filterCode === 'LB_COLOR_COMPARIO') {
                isAppliedFilter = true;
            } else if (filterItem?.filterCode && filterItem.filterCode === 'D_VENDU_PAR') {
                isAppliedFilter = true;
            } else if (filterItem?.filterCode && filterItem.filterCode === 'Marque') {
                isAppliedFilter = true;
            } else if (filterItem?.filterCode && filterItem.filterCode === 'LB_STY') {
                isAppliedFilter = true;
            } else if (filterItem?.filterCode && filterItem.filterCode === 'removeAll') {
                isAppliedFilter = true;
            } else {
                if ((filterItem?.isApplied && showT2S) || (params.currentSort[3] == '' && showT2S)) {
                    isAppliedFilter = true;
                }
            }
        }
        isAppliedFilter && window._t2sblocs._ZoneDescenteProduitRefresh(productListIds);
    },
    hideParagraphLink() {
        $('.paragraphe__link').on('click', function () {
            $(this).hide()
        })
    },

    showProductHandleClick (){
        $('a.__col').click(function(event){
            event.preventDefault();
            var gtmProductString  = $(this).data('gtmProduct')
            if(event.target.className.indexOf('open-attachment-modal') < 0){
                let url = new URL(window.location.href);
                let query_string = url.search;
                let search_params = new URLSearchParams(query_string);
                if (gtmProductString) {
                    search_params.delete('selectedProductId');
                    search_params.append('selectedProductId', gtmProductString.product_id);
                }
                url.search = search_params.toString();
                const urlSearch = query_string.split('&')
                const querySearch = urlSearch[0]
                let newUrl = `${url.origin}${url.pathname}`;
                if (url.pathname == '/Common/Search/SearchProductsList') {
                    newUrl += `${querySearch}#${search_params.get('selectedProductId')}`;
                } else {
                    newUrl += `#${search_params.get('selectedProductId')}`;
                }
                let urlRedirectProduct = $(this).attr( "href" );
                history.pushState(null, null, newUrl);
                window.location.href = urlRedirectProduct
            }
        });
    },

    cancelFilterDefaultClick() {
        if (document.getElementById("filtersBlock")) {
            document.getElementById("filtersBlock").addEventListener("click", function (event) {
                event.preventDefault();
            });
        }
    },

    initScrollHeader() {
        const topBar = document.querySelector("#topBar");
        const filterBtns = document.querySelector("#filterBtns");
        const height = topBar.offsetHeight
        let tickingInitScrollHeader = false
        document.addEventListener('scroll', (e) => {
            let newPos = window.scrollY
            if (!tickingInitScrollHeader && filterBtns){
                window.requestAnimationFrame(function () {
                    if (newPos > height) {
                        filterBtns.classList.add('filter-btns--fixed')
                        filterBtns.style.top = 58 + 'px';
                    } else {
                        filterBtns.classList.remove('filter-btns--fixed')
                        filterBtns.style.top = 0;
                    }
                    tickingInitScrollHeader = false
                });
            }
        })
    }
};
export default productListAnimation;
