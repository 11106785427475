export const init = () => {

    $('.top-bar__store__close').on('click', function (e) {
        $('.section-store--overlay').removeClass('open')
    })
    $('#shop-page-content').on('click', "#btnCloseStoreFicheproduit", function (e) {
        e.preventDefault();
        $('.store-ficheproduit').removeClass('open');
        $('.store-ficheproduit__btn-btm').removeClass('open');
        $('body').removeClass('menu-open')
    })
    $('.shopOverlay').on('click', "#btnCloseStoreFicheproduit", function (e) {
        e.preventDefault();
        $('.shopOverlay .store-ficheproduit').removeClass('open');
        $('.shopOverlay .store-ficheproduit__btn-btm').removeClass('open');
        $('.section-store').addClass('section-store--overlay open');
        $('body').removeClass('menu-open')
    })
};

export const showDestockedProductOverlay = (destockedProductId) => {
    $('#shop-page-content').on('click', ".btn-store-ficheproduit" + destockedProductId, function (e) {
        e.preventDefault();
        $('.store-ficheproduit' + destockedProductId).addClass('open');
        $('.store-ficheproduit__btn-btm' + destockedProductId).addClass('open');
        $('body').addClass('menu-open');
    });
    $('.shopOverlay').on('click', ".btn-store-ficheproduit" + destockedProductId, function (e) {
        e.preventDefault();
        $('.shopOverlay .store-ficheproduit' + destockedProductId).addClass('open');
        $('.shopOverlay .store-ficheproduit__btn-btm' + destockedProductId).addClass('open');
        $('.section-store').removeClass('section-store--overlay open');
        $('body').addClass('menu-open')
    })
};
export const tagSeeStoreLocItineraire = () => {
    // appData.actionTrigger.dispatch({
    //     trigger: "ClickStoreLocItineraire"
    // })
}

export const tagSeeStoreLocWaze = () => {
    appData.actionTrigger.dispatch({
        trigger: "ClickStoreLocWaze"
    })
}

export const toggleMap = (showMap) => {
    if (showMap){
        $('#footer').show()
        $('#page').css('padding-top', '52px');
    } else {
        $('#footer').hide()
        $('#page').css('padding-top', '0px');
    }
}

export const displayLocateShopError = (display) => {
    if (display == "showError") {
        $('#page').hide();
        $('#footer, #header-content').hide()
        $('#page').css('padding-top', '0px');
        $('#storeError').show();
        $( ".modal-backdrop" ).remove();
        closeModal('fiche-geo-locateShop')
    } else if (display == "hideError") {
        $('#page').show();
        $('#footer, #header-content').show()
        $('#page').css('padding-top', '52px');
        $('#storeError').hide();
    } else if (display == 'hideErrorMap') {
        $('#page').show();
        $('#header-content').show()
        $('#storeError').hide();
    }
}

export const getHeightBlocMap = () => {
    return $(window).height()
}

export const closeModal = (id) => {
    $('#'+id).modal('hide');
}

export const  hideKeyboard = (id) => {
    $('#'+id).blur()
}

export const setInputElementValue = (element,elementValue) => {
    $(element).val(elementValue)
}

export const isBlocErrorVisible = () => {
    return $('#storeError').is(':visible')
}

export const setGeolocActivImg = () => {
    $('#your_locationImg').attr('src', '/Kawa/Sources/Mobilev2/images/icone-position-active.svg');
}

export const getShopDetailsHeight = () => {
    if(document.getElementById( 'shop_details' )){
        return $("#shop_details").height();
    }
    return 0;
}





