import Event from "./Event";


const EventList = (props) => {

    function renderEventListBloc(){
        let displayEventList = [];
        props.shopEventListData.map((childrenItem, i) => {
            displayEventList.push(
                <Event event={childrenItem}  shopAndWharehouseData={props.shopAndWharehouseData}/>
            );

        });
        return displayEventList;
    }

    return (
       renderEventListBloc()
    )

}
export default EventList;
