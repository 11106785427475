import React from "react";
const SearchBarInput = (props) => {
    let searchText = "Rechercher un produit, une marque...";
    if (props.context == "shopPage") {
        searchText = "Rechercher un produit, un magasin...";
    }
    const topBarNavClass = `top-bar__nav-btn ${props.isEligible ? 'p-10' : ''}`;
    return (
        <div className={topBarNavClass} data-gtm-libelle="recherche">
            <a href="#" className="btn btn-search" onClick={() => {
                props.setSearchTextField(true);
            }}>
                <span className="icon-loupe icon-md pr-2"/>
                <span id="fixedBtnSearch">{searchText}</span>
            </a>
        </div>
    )
}
export default SearchBarInput;