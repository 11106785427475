import React, {useEffect} from "react";
import {privateSalePopinAnimation} from "../../animations/responsive/customerAccount";

const PrivateSalePopin = ({privateSaleEligibilityHtmlContent}) => {
    useEffect(() => {
        privateSalePopinAnimation()
    })
    return (
        <>
            <style dangerouslySetInnerHTML={{
                __html: '#popBackground {\n' +
                    '    background-color: rgba(0,0,0,.6);\n' +
                    '    bottom: 0;\n' +
                    '    display: block;\n' +
                    '    height: 100vh;\n' +
                    '    left: 0;\n' +
                    '    position: fixed;\n' +
                    '    right: 0;\n' +
                    '    top: 0;\n' +
                    '    z-index: 10\n' +
                    '}\n' +
                    '\n' +
                    '#popContent {\n' +
                    '    background: url(/Sources/Mobilev2/images/visuel-desktop.jpg) no-repeat 0 0;\n' +
                    '    background-color: #fff;\n' +
                    '    box-shadow: 0 0 20px 7px rgba(0,0,0,.3);\n' +
                    '    box-sizing: border-box;\n' +
                    '    font-family: Lato,Arial,sans-serif;\n' +
                    '    height: 202px;\n' +
                    '    margin: auto;\n' +
                    '    padding: 20px;\n' +
                    '    position: relative;\n' +
                    '    top: 27vh;\n' +
                    '    width: 470px\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #close {\n' +
                    '    cursor: pointer;\n' +
                    '    position: absolute;\n' +
                    '    right: 0;\n' +
                    '    top: 0\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #close .croix {\n' +
                    '    content: "";\n' +
                    '    display: block;\n' +
                    '    background-image: none;\n' +
                    '    position: absolute;\n' +
                    '    right: 15px;\n' +
                    '    top: 9px;\n' +
                    '    width: 22px;\n' +
                    '    height: 22px;\n' +
                    '    border-radius: 50%;\n' +
                    '    color: #666;\n' +
                    '    z-index: 51000\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #close .croix:before {\n' +
                    '    -ms-transform: translate(-25%,-50%) rotate(-45deg);\n' +
                    '    transform: translate(-25%,-50%) rotate(-45deg)\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #close .croix:after,#popContent #close .croix:before {\n' +
                    '    content: "";\n' +
                    '    position: absolute;\n' +
                    '    left: 50%;\n' +
                    '    top: 50%;\n' +
                    '    width: 0;\n' +
                    '    height: 12px;\n' +
                    '    border-left: 2px solid;\n' +
                    '    z-index: 51000\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #close .croix:after {\n' +
                    '    -ms-transform: translate(-25%,-50%) rotate(45deg);\n' +
                    '    transform: translate(-25%,-50%) rotate(45deg)\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #close span {\n' +
                    '    display: none\n' +
                    '}\n' +
                    '\n' +
                    '#popContent .titre {\n' +
                    '    border-bottom: 1px solid #bfc0be;\n' +
                    '    color: #313131;\n' +
                    '    font-size: 18px;\n' +
                    '    font-weight: 700;\n' +
                    '    margin: 0;\n' +
                    '    padding-bottom: 5px;\n' +
                    '    text-align: left;\n' +
                    '    text-transform: uppercase\n' +
                    '}\n' +
                    '\n' +
                    '#popContent p {\n' +
                    '    color: #4d4d4d;\n' +
                    '    font-size: 14px;\n' +
                    '    font-weight: 400;\n' +
                    '    line-height: 14px;\n' +
                    '    margin-top: 20px;\n' +
                    '    text-align: left;\n' +
                    '    width: 270px\n' +
                    '}\n' +
                    '\n' +
                    '#popContent p .rouge {\n' +
                    '    color: #e2001a;\n' +
                    '    font-weight: 700\n' +
                    '}\n' +
                    '\n' +
                    '#popContent p .bold {\n' +
                    '    font-weight: 700\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #cta {\n' +
                    '    bottom: 20px;\n' +
                    '    display: block;\n' +
                    '    position: absolute;\n' +
                    '    width: 260px\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #cta a {\n' +
                    '    background-color: #e2001a;\n' +
                    '    box-sizing: border-box;\n' +
                    '    color: #fff;\n' +
                    '    display: block;\n' +
                    '    font-weight: 500;\n' +
                    '    font-size: 14px;\n' +
                    '    padding: 11px 12px;\n' +
                    '    position: relative;\n' +
                    '    text-align: center;\n' +
                    '    text-decoration: none;\n' +
                    '    text-transform: uppercase;\n' +
                    '    transition: all .3s ease-in-out;\n' +
                    '    width: 100%\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #cta a:hover {\n' +
                    '    background-color: #a20013\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #cta a:after {\n' +
                    '    content: "";\n' +
                    '    border-width: 2px 2px 0 0;\n' +
                    '    border-style: solid;\n' +
                    '    border-color: #fff #fff transparent transparent;\n' +
                    '    height: 8px;\n' +
                    '    position: absolute;\n' +
                    '    right: 20px;\n' +
                    '    top: 48%;\n' +
                    '    transform: rotate(45deg) translate(-50%);\n' +
                    '    -o-transform: rotate(45deg) translate(-50%);\n' +
                    '    -ms-transform: rotate(45deg) translate(-50%);\n' +
                    '    -moz-transform: rotate(45deg) translate(-50%);\n' +
                    '    -webkit-transform: rotate(45deg) translate(-50%);\n' +
                    '    -o-transition: all .3s ease-in-out;\n' +
                    '    -ms-transition: all .3s ease-in-out;\n' +
                    '    -moz-transition: all .3s ease-in-out;\n' +
                    '    -webkit-transition: all .3s ease-in-out;\n' +
                    '    width: 8px\n' +
                    '}\n' +
                    '\n' +
                    '#popContent #cta a:hover:after {\n' +
                    '    right: 10px\n' +
                    '}\n' +
                    '\n' +
                    '@media (max-width: 414px) {\n' +
                    '    #popContent .titre {\n' +
                    '        border-bottom:none;\n' +
                    '        color: #e2001a;\n' +
                    '        font-size: 16px;\n' +
                    '        font-weight: 600;\n' +
                    '        text-align: center\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent #close {\n' +
                    '        right: 0;\n' +
                    '        top: -49px\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent #close .croix {\n' +
                    '        color: #fff;\n' +
                    '        right: 7px;\n' +
                    '        top: 4px\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent #close .croix:after,#popContent #close .croix:before {\n' +
                    '        height: 25px;\n' +
                    '        border-left: 3px solid\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent #close span {\n' +
                    '        color: #fff;\n' +
                    '        display: block;\n' +
                    '        font-size: 10px;\n' +
                    '        font-weight: 400;\n' +
                    '        position: absolute;\n' +
                    '        right: 0;\n' +
                    '        text-transform: uppercase;\n' +
                    '        top: 28px\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent,#popContent #close span {\n' +
                    '        font-family: Titillium Web,Arial,sans-serif\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent {\n' +
                    '        background: url(/Sources/Mobilev2/images/visuel-mob.jpg) no-repeat 50% fixed;\n' +
                    '        box-shadow: none;\n' +
                    '        height: 260px;\n' +
                    '        width: 95%;\n' +
                    '        top: 32vh\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent p {\n' +
                    '        color: #000;\n' +
                    '        line-height: 20px;\n' +
                    '        width: 192px\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent #cta {\n' +
                    '        width: 90%\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent #cta a:hover {\n' +
                    '        background-color: #e2001a\n' +
                    '    }\n' +
                    '\n' +
                    '    #popContent #cta a:after {\n' +
                    '        display: none\n' +
                    '    }\n' +
                    '}\n' +
                    '\n' +
                    '@media (max-width: 375px) {\n' +
                    '    #popContent #cta {\n' +
                    '        width:88.8%\n' +
                    '    }\n' +
                    '}\n' +
                    '\n' +
                    '@media (max-width: 360px) {\n' +
                    '    #popContent #cta {\n' +
                    '        width:88.2%\n' +
                    '    }\n' +
                    '}\n' +
                    '\n' +
                    '@media (max-width: 320px) {\n' +
                    '    #popContent #cta {\n' +
                    '        width:87%\n' +
                    '    }\n' +
                    '}\n' +
                    '\n' +
                    '.vente_privee h4 {\n' +
                    '    color: #131b35;\n' +
                    '    font-size: 18px;\n' +
                    '    margin: 10px 0 0;\n' +
                    '    text-transform: uppercase\n' +
                    '}\n' +
                    '\n' +
                    '.vente_privee h4,.vente_privee p {\n' +
                    '    font-family: Lato,Arial,sans-serif;\n' +
                    '    font-weight: 700;\n' +
                    '    text-align: center\n' +
                    '}\n' +
                    '\n' +
                    '.vente_privee p {\n' +
                    '    color: #696969;\n' +
                    '    font-size: 14px;\n' +
                    '    margin: 0 0 10px\n' +
                    '}\n' +
                    '\n' +
                    '.vente_privee p>span {\n' +
                    '    display: block\n' +
                    '}\n'
            }}>

            </style>
            <div dangerouslySetInnerHTML={{__html: privateSaleEligibilityHtmlContent}}/>
        </>
    )

}
export default PrivateSalePopin;