import React from 'react';
import {arrowLeft} from './icons';
import PropTypes from "prop-types";
import Link from 'next/link'

export const NavigationBackLink = ({link, children}) => {
  return (
    <div className={`navigation`}>
        <Link href={link} legacyBehavior passHref={true}>
      <a className={"backLink mobile"}>
        {arrowLeft} <span>{children}</span>
      </a>
        </Link>
    </div>
  )
}

NavigationBackLink.propTypes = {
  link: PropTypes.string
}

NavigationBackLink.defaultProps = {
  link: '#'
}
