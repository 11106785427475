export const headerStoreLocator = (props) => {
    const iconClass = (props.hasShop || props.shopId) ? " valid" : "";
    const url = props.shopId ? "#" : "/magasins/recherche-magasins"
    const target = !props.shopId && "#localModal"
    if (!props.shopId) {
        return (
            <div className="col text-left">
                <a href={url} data-toggle="modal" data-target={target} data-btnSource="headerBtnSource"
                   className={`top-bar__nav__icone gtm-header store-btn${iconClass}`} data-gtm-libelle="magasins"
                    onClick={props.updatePopinDisplay.bind(this, 'hidePopin')}
                ><i className="icon-3x icon-shop-v2"/></a>
            </div>
        );
    } else {
        return (
            <div className="col text-left">
                <a href={url} className={`top-bar__nav__icone gtm-header store-btn${iconClass}`} data-gtm-libelle="magasins"
                    onClick={props.handleShopOverlay.bind(this, true)}
                ><i className="icon-3x icon-shop-v2"/></a>
            </div>
        );
    }

};

export default headerStoreLocator;