import React, {useState} from 'react';
import {informations, orders, shop, account, card, wink, message, kitchen} from './icons';
import {NavigationBackLink} from "./NavigationBackLink";
import Link from 'next/link'
import {
    CUSTOMER_ACCOUNT_ORDERS_PAGE,
    CUSTOMER_ACCOUNT_PROFIL_PAGE,
    DASHBOARD_PAGE,
    CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE,
    CUSTOMER_ACCOUNT_SOCIAL_MEDIA,
    CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE,
    GROUP_USER_WEB_BY_TOKEN,
    CUSTOMER_ACCOUNT_NEWSLETTER_PAGE,
    CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE
} from "@/utils/constants";
import {dispatchCustomerAccountMenuHandleClick} from "../../../../animations/responsive/customerAccount";
import Utils from "@/utils/Default/Utils";
import {dispatchEventSTPUSHUpdate} from "@/animations/Default/commonPage";


const menuList = (userId, currentStep, myShopUrl) => {
    return ([
        {
            url: '/Customer/Account/Dashboard/Id/' + userId,
            title: 'Mon compte',
            icon: 'account',
            active: currentStep === DASHBOARD_PAGE,
            autoLogin: false
        },
        {
            url: '/Customer/Account/Orders/Id/' + userId,
            title: 'Mes commandes',
            icon: 'orders',
            active: currentStep === CUSTOMER_ACCOUNT_ORDERS_PAGE || currentStep === CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE,
            autoLogin: false
        },
        {
            url: '/Customer/Account/Profile/Id/' + userId,
            title: 'Mes informations',
            icon: 'informations',
            active: currentStep === CUSTOMER_ACCOUNT_PROFIL_PAGE || currentStep === CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE || currentStep === CUSTOMER_ACCOUNT_SOCIAL_MEDIA,
            autoLogin: false
        },
        {
            url: '/Customer/Account/NewsLetter/Id/' + userId,
            title: 'Mes préférences de communication',
            icon: 'newsLetter',
            active: currentStep ===  CUSTOMER_ACCOUNT_NEWSLETTER_PAGE,
            autoLogin: false
        },
        {
            url: '/Customer/Account/KitchenProjects/Id/' + userId,
            title: 'Mes projets cuisine 3D',
            icon: 'kitchen',
            active: currentStep ===  CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE,
            autoLogin: false
        },
        {
            url: myShopUrl,
            title: 'Mon magasin',
            icon: 'shop',
            active: currentStep === 'shop',
            autoLogin: false
        },
        {
            url: '/Carte_but',
            title: 'La carte BUT',
            icon: 'card',
            active: currentStep === 'card',
            autoLogin: true
        },
        {
            url: '#',
            title: 'Aide & contact',
            icon: 'wink',
            active: currentStep === 'help',
            id: 'nouveau_espace_client_menu_smart_tribune',
            autoLogin: false
        },
    ])
};

export const Navigation = ({
                               userId,
                               currentSteps,
                               firstName,
                               level,
                               myShopUrl,
                               noBack,
                               groupUser,
                               resetWaitingMessage
                           }) => {
    const [open, setOpen] = useState(false);
    const iconList = {
        account: account,
        orders: orders,
        shop: shop,
        informations: informations,
        card: card,
        wink: wink,
        newsLetter: message,
        kitchen: kitchen,
    }
    const getLink = () => {
        if (level === 1) {
            return `/Customer/Account/Dashboard/Id/` + userId
        }
        if (currentSteps === CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE || currentSteps === CUSTOMER_ACCOUNT_SOCIAL_MEDIA) {
            return `/Customer/Account/profile/Id/` + userId
        }
        if (currentSteps === CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE) {
            return `/Customer/Account/Orders/Id/` + userId
        }
    }
    const handleClick = e => {
        e.preventDefault();
        console.log("DO SOMETHING");
    };
    const elements = !appData.isPlanner3dActive ? menuList(userId, currentSteps, myShopUrl)?.filter(e => e.title !== 'Mes projets cuisine 3D') : menuList(userId, currentSteps, myShopUrl);
    const activeElement = elements.find(e => e.active) || elements[0] || {};
    return (
        <div className={`navigation ${open ? 'open' : ''}`} id="navigation">
            <div className="navigationBackground">
                <div className="header">
                    <div className="desktop">Bienvenue <br/>{firstName}</div>
                    <button className={"navigationElement mobile"} onClick={() => setOpen(o => !o)}>
                        <span className="icon">{iconList[activeElement.icon]}</span>
                        <span className="text"
                              dangerouslySetInnerHTML={{__html: activeElement.htmlTitle || activeElement.title}}/>
                        <img className="arrow" src="/assets/images/responsive/icons/chevronVertical.svg"
                             alt="chevronVertical"/>
                    </button>
                </div>
                <div className="navigationBlock">
                    {elements.map && elements.map(e => {

                        return <Link href={groupUser == GROUP_USER_WEB_BY_TOKEN && !e.autoLogin ? "#" : e.url || '#'}
                                     legacyBehavior
                                     key={e.icon}><a
                            className={`navigationElement ${e.active ? 'active' : ''}`} key={e.icon} id={e.id || ''}
                            onClick={(event) => {
                                setOpen(o => !o);
                                if (e.title === "Mes préférences de communication") {
                                    resetWaitingMessage();
                                }
                                if(e.title === 'Aide & contact') {
                                    event.preventDefault();
                                    dispatchEventSTPUSHUpdate([])
                                }
                                groupUser == GROUP_USER_WEB_BY_TOKEN && !e.autoLogin ? Utils.redirectTo("/Customer/Account/Identification") : dispatchCustomerAccountMenuHandleClick(event, e.htmlTitle || e.title)
                            }}>
                            <span className="icon">{iconList[e.icon]}</span>
                            <span className="text" dangerouslySetInnerHTML={{__html: e.htmlTitle || e.title}}/>
                            <img className="arrow" src="/assets/images/responsive/icons/chevron.svg" alt="chevron"/>
                        </a></Link>
                    })}
                    <a className="logout" href={'/Customer/Account/Disconnect'}>
                        <img className="icon" src="/assets/images/responsive/icons/logout.svg" alt={"log-out"}/>
                        <span>Me déconnecter</span>
                    </a>
                </div>
            </div>
            {!!level && !noBack && (level === 1 || level === 2) && groupUser !== GROUP_USER_WEB_BY_TOKEN &&
                <NavigationBackLink
                    link={getLink()}>
                    {level === 1 ? 'Mon Compte' : 'Retour'}
                </NavigationBackLink>
            }
        </div>
    )
}


export default Navigation;
