import Scripts from "../../Default/Layout/Head/Scripts";
import HeadDetails from "../../Default/Layout/Head";
import React from 'react'
import Script from "next/script";
import {getBuildId} from "@/utils/communUtil";
import {DEFAULT_TEMPLATE_VERSION, MOBILE_TEMPLATE_VERSION} from "@/utils/constants";
import {getTitle} from "@/components/responsive/commun/SPAHead/PrefetchDataPages";


export const  CustomerHeadAndScripts = (props) => {
    return ( <>
        <HeadDetails title={getTitle(props.pathName)}>
            <link rel="stylesheet" href={"/assets/css/responsive/style.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/dashboard.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/order.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/newpassword.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/myorders.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/profil.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/socialmedia.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/newsletter.css?v="+ getBuildId()}/>
            <link rel="stylesheet" href={"/assets/css/responsive/myKitchenProjects.css?v=" + getBuildId()}/>
            {props.deviceType === DEFAULT_TEMPLATE_VERSION &&
                <link rel="stylesheet" href={"/assets/css/default/headerFooter.css?v="+ getBuildId()}/>}
            {props.deviceType === MOBILE_TEMPLATE_VERSION &&
                <link rel="stylesheet" href={"/assets/css/mobile/headerFooter.css?v="+ getBuildId()}/>}
        </HeadDetails>
        <Scripts>
            <Script type="text/javascript" src="https://unpkg.com/@reachfive/identity-core@1.18.0/umd/identity-core.min.js" strategy="beforeInteractive" />
            <Script src="/assets/js/imageMapResizer.min.js" strategy="beforeInteractive"/>
            <Script src="/assets/js/owl.carousel.min.js" strategy="beforeInteractive"/>
            <Script type="text/tc_privacy" data-category="3"
                    src="/assets/js/Default/octipasMerchLibraryConfig.js"/>

        </Scripts>
    </>)
}
export default CustomerHeadAndScripts
