import ApiCaller from './ApiCaller/apiCaller'
import { NAVIGATION_META_INFO_SPEEDY_SCAN, SPEEDY_SCAN_CONTEXT } from "@/utils/constants";
import Cookies from "js-cookie";

export const getPrimaryShopDataByShopId = (shopId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/ShopsEvents?ShopIdType=Web&ShopIds=" + shopId)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log("handleToken error-->", error)
                reject(error);
            });
    });
};

export const getShopDataByShopId = (shopId, isSliding = false) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Shops/" + shopId + "?View=Full" + '&IsSliding=' + isSliding)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.log("handleToken error-->", error)
                reject(error);
            });
    });
};

export const getOrderShopDataByShopId = (shopId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Shops/" + shopId + "?View=Full")
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.log("handleToken error-->", error)
                reject(error);
            });
    });
};

export const updatePrimaryShop = (userId, putParams, view = "") => {
    return new Promise((resolve, reject) => {
        ApiCaller.put("/WebUsers/" + userId + "/Shops" + (view && "?view=" + view), putParams)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                console.log("handleToken error-->", error)
                reject(error);
            });
    });
};

export const fetchShopCircle = (calculationParam, param) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Shops?CalculationMethod=' + calculationParam + '&CalculationParams=' + param)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', '/Shops?CalculationMethod=' + calculationParam + '&CalculationParams=' + param + ' Api responded failed : ' + error)
                reject(error)
            });
    });
};

export const updateShopCircle = (webUserId, data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put('/WebUsers/' + webUserId + '/ShopCircle', data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', '/WebUsers/' + webUserId + '/ShopCircle' + " Api responded failed : " + error)
                reject(error)
            });
    });
};
