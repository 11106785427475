import {CUSTOMER_ACCOUNT_TRACKING_ACTION, KITCHEN_PROJECTS, KITCHEN_PROJECTS_TRACKING_ACTION} from "@/utils/constants";

export const dispatchCustomerAccountMenuHandleClick = (e, label) => {
    let payload = {
        label: label, action: CUSTOMER_ACCOUNT_TRACKING_ACTION
    }
    if (label === KITCHEN_PROJECTS) {
        payload.action = KITCHEN_PROJECTS_TRACKING_ACTION
    }
    appData.actionTrigger.dispatch({
        trigger: "CustomerAccountMenuHandleClick", payload: payload

    });
}

export const dispatchCustomerAccountDashboardPageHandleClick = (e, label) => {
    appData.actionTrigger.dispatch({
        trigger: "CustomerAccountDashboardPageButtonOrLinkHandleClick",
        payload: {
            label: label
        }
    });
}

export const dispatchCustomerAccountMyOrdersPageHandleClick = (label) => {
    appData.actionTrigger.dispatch({
        trigger: "CustomerAccountMyOrdersPageButtonOrLinkHandleClick",
        payload: {
            label: label
        }
    });
}

export const dispatchCustomerAccountOrderDetailsPageHandleClick = (e, label, redirectionUrl, targetBlank = false) => {
    appData.actionTrigger.dispatch({
        trigger: "CustomerAccountOrderDetailsPageButtonOrLinkHandleClick",
        payload: {
            label: label
        },
        resolveCallback: function () {
            if (targetBlank) openLink(redirectionUrl, targetBlank)
        }
    });
}

const openLink = (redirectionUrl, targetBlank) => {
    if (targetBlank) {
        window.open(
            redirectionUrl,
            '_blank'
        );
    }
}

export const dispatchOpenMessageLayerView = (pagePath, soldBy, sellerName, sellerId) => {
    appData.actionTrigger.dispatch({
        trigger: "openMessageLayerView",
        payload: {
            pagePath: pagePath,
            soldBy: soldBy,
            sellerName: sellerName,
            sellerId: sellerId
        }
    });
}

export const dispatchCustomerAccountMyInformationsPageHandleClick = (e, label) => {

    appData.actionTrigger.dispatch({
        trigger: "CustomerAccountMyInformationsPageButtonOrLinkHandleClick",
        payload: {
            label: label
        }
    });
}

export const dispatchCustomerAccountOrderSupportHandleClick = (label) => {
    appData.actionTrigger.dispatch({
        trigger: "CustomerAccountOrderSupportButtonOrLinkHandleClick",
        payload: {
            label: label
        }
    });
}

export const dispatchCustomerAccountSubmitRequestInterventionHandleClick = (label) => {
    appData.actionTrigger.dispatch({
        trigger: "CustomerAccountSubmitRequestInterventionButtonHandleClick",
        payload: {
            label: label
        }
    });
}
export const dispatchCustomerAccountPlannerProjectHandleClick = (label) => {
    appData.actionTrigger.dispatch({
        trigger: "dispatchCustomerAccountPlannerProjectHandleClick",
        payload: {
            label: label
        }
    });
}
export const privateSalePopinAnimation = () => {
    $('#close').click(function () {
        $('#popBackground').remove();
    });
    $('#popBackground').click(function (e) {
        var container = $('#popContent');
        if (container.has(e.target).length === 0) {
            $('#popBackground').remove();
        }
    });
}
