import {
    AVAILABILITY_DATA_FETCHED_STOCKLOCATOR,
    CITY_SEARCHRESULTS_FETCHED,
    SELECTED_ZIPCODE_CITY,
    UPDATE_LOCATE_ME_SEARCH_PARAM_VALUE,
    UPDATE_SHOPCIRCLE,
    UPDATE_SHOW_LOADER_STATUS,
    UPDATE_GEO_ERROR_BLOC_STATUS,
    UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS,
    UPDATE_IS_CUSTOMER_LOCATION_CHANGED, UPDATE_GEOLOCATION_STATUS, UPDATE_ALERT_NO_STORE_STATUS
} from "../actionsTypes";

const initialState = {
    locationsData: {},
    selectedZipCity: null,
    keywords: '',
    productId: null,
    availabilityData: null,
    shopCircleData: null,
    showGeoErrorBloc: false,
    showStockLocGeoErrorBloc: false,
    isLocateMeSearch: false,
    isCustomerLocationChanged : false,
    showLoaderBloc: false,
    alertGeolocation : false,
    alertNoStore : false,

}

const StockLocatorReducer = (state = initialState, action) => {

    switch (action.type) {
        case CITY_SEARCHRESULTS_FETCHED:
            return {
                ...state,
                keywords: action.payload.keywords,
                locationsData: action.payload.locationsData,
                selectedZipCity: action.payload.locationsData[0]
            }
        case UPDATE_SHOPCIRCLE:
            return {
                ...state,
                shopCircleData: action.payload.shopCircleData,
                selectedZipCity: (action.payload.shopCircleData) && {
                    'zipCode': action.payload.shopCircleData.zipCode,
                    'cityName': action.payload.shopCircleData.city
                }
            }
        case AVAILABILITY_DATA_FETCHED_STOCKLOCATOR:
            return {
                ...state,
                locationsData: {},
                availabilityData: action.payload.availabilityData
            }
        case  SELECTED_ZIPCODE_CITY:
            return {
                ...state,
                locationsData: {},
            }
        case UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS:
            return {
                ...state,
                showStockLocGeoErrorBloc: action.element.value,
            }
        case UPDATE_LOCATE_ME_SEARCH_PARAM_VALUE:
            return {
                ...state,
                isLocateMeSearch: action.element.value,
                showStockLocGeoErrorBloc: action.element.showStockLocGeoErrorBloc,
                showGeoErrorBloc: false
            }
        case UPDATE_SHOW_LOADER_STATUS:
            return {
                ...state,
                showLoaderBloc: action.element.value
            }
        case UPDATE_GEO_ERROR_BLOC_STATUS:
            return {
                ...state,
                showGeoErrorBloc: action.element.status,
                isLocateMeSearch: false,
            }
        case UPDATE_IS_CUSTOMER_LOCATION_CHANGED:
            return {
                ...state,
                isCustomerLocationChanged: action.payload.value,
            }
        case UPDATE_ALERT_NO_STORE_STATUS:
            return {
                ...state,
                alertNoStore: action.payload.value,
            }
        case UPDATE_GEOLOCATION_STATUS:
            return {
                ...state,
                alertGeolocation: action.payload.value,
            }
        default:
            return state;
    }
}
export default StockLocatorReducer