import {
    FETCH_ORDER_MKP_TRACKING_INFOS,
    FETCH_ORDER_TRACKING_INFOS,
    FETCH_ONE_ORDER_INFOS_TRACKING,
    FETCH_ONE_ORDER_INFOS_TRACKING_MIXED_ORDER,
    CONFIRMATION_APPOINTMENTS, UPDATE_ERROR_ORDER_TRACKING_LIST, UPDATE_LOADER
} from '../actionsTypes'
import {formatDate} from "@/utils/responsive/utils";

const getAppointmentData = (orderItemsAppointment, orderInfos, mainRecipient, customerId) => {
    let appointments = [];
    for (let i = 0, l = orderItemsAppointment?.length; i < l; i++) {
        appointments.push({
            statusInfos: orderItemsAppointment[i].appointment && orderItemsAppointment[i].appointment.statusInfos,
            selectedDateId: 'date_prod_' + i,
            scheduleId: (orderItemsAppointment[i].appointment && (orderItemsAppointment[i].appointment?.status === 1)) ? orderItemsAppointment[i].appointment?.scheduleId : '',
            slotTimeStart: (orderItemsAppointment[i].appointment && (orderItemsAppointment[i].appointment?.status === 1)) ? orderItemsAppointment[i].appointment?.slotTimeStart : '',
            slotTimeEnd: (orderItemsAppointment[i].appointment && (orderItemsAppointment[i].appointment?.status === 1)) ? orderItemsAppointment[i].appointment?.slotTimeEnd : '',
            wpCustomerId: orderInfos.wpCustomerId,
            customerId: customerId,
            nosCustomerId: orderInfos.nosCustomerId,
            mail: mainRecipient.email,
            phone: mainRecipient.phone,
            firstName: mainRecipient.firstName,
            lastName: mainRecipient.lastName,
            date: (orderItemsAppointment[i].appointment && (orderItemsAppointment[i].appointment?.status === 1)) ? formatDate(orderItemsAppointment[i].appointment?.date) : '',
            civility: mainRecipient.civility,
            type: orderItemsAppointment[i].type,
            order: [
                {
                    documentNo: orderInfos.documentNumber,
                    orderId: orderInfos.orderId,
                    id: orderInfos.saleOrderId,
                    idCrypt: orderInfos.idCrypt ? orderInfos.idCrypt : '',
                    orderDate: orderInfos?.saleDate,
                    orderLines: [
                        {
                            id: orderItemsAppointment[i].id,
                            productId: orderItemsAppointment[i].productId,
                            ean13: orderItemsAppointment[i].ean13,
                            qtyAppointment: orderItemsAppointment[i].qtyAppointment,
                            package: orderItemsAppointment[i].package
                        }
                    ]
                }
            ]
        })
    }
    return appointments;
}
const initialState = {
    showLoaderList: [],
    orderButTracking: null,
    orderMKPTracking: null,
    trackingInfosByOrder: null,
    omniChannelOrder: {
        loader: false,
        storeInfos: '',
        pickupPoint: '',
        orderItems: [],
        alreadyCollectedItems: [],
        orderMessages: [],
        promessDates: [],
        mainRecipient: [],
        closestAppointment: null,
        orderItemsAppointment: [],
        orderItemSelected: {},
        errorProductIds: [],
        errorScheduleIds: [],
        isMultipleForm: false,
        errorMessage: '',
        appointmentData: {
            shopId: '',
            origine: 'Magasin',
            appointments: [
                {
                    scheduleId: '',
                    wpCustomerId: '',
                    customerId: '',
                    nosCustomerId: '',
                    mail: '',
                    phone: '',
                    firstName: '',
                    lastName: '',
                    civility: '',
                    date: '',
                    type: 'rdv_depot',
                    order: [
                        {
                            documentNo: '',
                            orderId: '',
                            id: '',
                            idCrypt: '',
                            orderLines: [],
                            orderDate: ''
                        }
                    ]
                }
            ]
        },
        appointmentsData: {
            shopId: '',
            origine: 'Magasin',
            appointments: []
        }
    },
    apiErrorOrderList:[]
}

const OrderTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_TRACKING_INFOS: {
            let orderTrackingData = {...state.orderButTracking};
            orderTrackingData[action.payload.orderTrackingData.orderInfos.orderId] = {
                "timeline": action.payload.orderTrackingData.orderItems[0].package.timeline,
                 "isCanceled": action.payload.orderTrackingData.orderItems[0].isCanceled
            }
            let showLoaderList = {...state.showLoaderList};
            showLoaderList[action.payload.orderTrackingData.orderInfos.orderId] = action.payload.showLoader;
            return {
                ...state,
                orderButTracking: orderTrackingData,
                showLoaderList: showLoaderList
            }
        }
        case FETCH_ORDER_MKP_TRACKING_INFOS: {
            let orderMKPTrackingData = {...state.orderMKPTracking}
            orderMKPTrackingData[action.payload.orderId] = {
                "timeline": action.payload.orderMKPTrackingData.trackingInfos.orderItems[0].package.timeline,
                "isCanceled": action.payload.orderMKPTrackingData.trackingInfos.orderItems[0].isCanceled,
                "shippingTrackingUrl": action.payload.orderMKPTrackingData.trackingInfos.orderItems[0].package.shipmentInfos ? action.payload.orderMKPTrackingData.trackingInfos.orderItems[0]?.package.shipmentInfos[0].trackingUrl : ""
            }
            let showLoaderList = {...state.showLoaderList};
            showLoaderList[action.payload.orderId] = action.payload.showLoader;
            return {
                ...state,
                orderMKPTracking: orderMKPTrackingData,
                showLoaderList: showLoaderList
            }
        }
        case FETCH_ONE_ORDER_INFOS_TRACKING:
            const trackingInfos = action.payload.orderTrackingData != null ? action.payload.orderTrackingData : (action.payload.orderMKPTrackingData != null ? action.payload.orderMKPTrackingData.trackingInfos : null)
            const omniChannelOrderData = action.payload.orderTrackingData
            const omniChannelOrder = (omniChannelOrderData !== null) ?
                {
                    storeInfos: omniChannelOrderData?.storeInfos,
                    orderItems: omniChannelOrderData?.orderItems,
                    alreadyCollectedItems: omniChannelOrderData?.alreadyCollectedItems,
                    orderMessages: omniChannelOrderData?.orderMessages,
                    promessDates: omniChannelOrderData?.promessDates,
                    mainRecipient: omniChannelOrderData?.mainRecipient,
                    closestAppointment: omniChannelOrderData?.closestAppointment,
                    orderItemsAppointment: omniChannelOrderData?.orderItems?.filter(orderItem => orderItem.possessionStatus === 1),
                    orderItemSelected: {},
                    errorProductIds: [],
                    errorScheduleIds: [],
                    isMultipleForm: false,
                    errorMessage: '',
                    appointmentData: {
                        shopId: omniChannelOrderData?.storeInfos?.nosicaId,
                        origine: omniChannelOrderData?.acquisitionSource ?? 'Magasin',
                        appointments: [
                            {
                                scheduleId: omniChannelOrderData?.closestAppointment?.scheduleId,
                                wpCustomerId: omniChannelOrderData?.orderInfos?.wpCustomerId,
                                customerId: action.payload.customerId,
                                nosCustomerId: omniChannelOrderData?.orderInfos?.nosCustomerId,
                                mail: omniChannelOrderData?.mainRecipient?.email,
                                phone: omniChannelOrderData?.mainRecipient?.phone,
                                firstName: omniChannelOrderData?.mainRecipient?.firstName,
                                lastName: omniChannelOrderData?.mainRecipient?.lastName,
                                civility: omniChannelOrderData?.mainRecipient?.civility,
                                date: (omniChannelOrderData?.closestAppointment) ? formatDate(omniChannelOrderData?.closestAppointment?.date) : '',
                                type: 'rdv_depot',
                                order: [
                                    {
                                        documentNo: omniChannelOrderData?.orderInfos?.documentNumber,
                                        orderId: omniChannelOrderData?.orderInfos?.orderId,
                                        id: omniChannelOrderData?.orderInfos?.saleOrderId,
                                        idCrypt: omniChannelOrderData?.orderInfos?.idCrypt ?? '',
                                        orderLines: [],
                                        orderDate: omniChannelOrderData?.orderInfos?.saleDate
                                    }
                                ]
                            }
                        ]
                    },
                    appointmentsData: {
                        shopId: omniChannelOrderData?.storeInfos?.nosicaId,
                        origine: omniChannelOrderData?.acquisitionSource ?? 'Magasin',
                        appointments: getAppointmentData(omniChannelOrderData?.orderItems?.filter(orderItem => orderItem?.possessionStatus === 1), omniChannelOrderData?.orderInfos, omniChannelOrderData?.mainRecipient, action.payload.customerId)

                    }
                } : state.omniChannelOrder
            let showLoaderList = {...state.showLoaderList};
            showLoaderList[action.payload.orderId] = action.payload.showLoader;
            return {
                ...state,
                trackingInfosByOrder: trackingInfos,
                omniChannelOrder: omniChannelOrder,
                showLoaderList: showLoaderList
            }
        case FETCH_ONE_ORDER_INFOS_TRACKING_MIXED_ORDER: {
            const omniChannelOrderDataMixedOrder = action.payload.orderTrackingData
            const omniChannelOrderMixedOrder = (omniChannelOrderDataMixedOrder !== null) ?
                {
                    storeInfos: omniChannelOrderDataMixedOrder?.storeInfos,
                    orderItems: omniChannelOrderDataMixedOrder?.orderItems,
                    alreadyCollectedItems: omniChannelOrderDataMixedOrder?.alreadyCollectedItems,
                    orderMessages: omniChannelOrderDataMixedOrder?.orderMessages,
                    promessDates: omniChannelOrderDataMixedOrder?.promessDates,
                    mainRecipient: omniChannelOrderDataMixedOrder?.mainRecipient,
                    closestAppointment: omniChannelOrderDataMixedOrder?.closestAppointment,
                    orderItemsAppointment: omniChannelOrderDataMixedOrder?.orderItems?.filter(orderItem => orderItem.possessionStatus === 1),
                    orderItemSelected: {},
                    errorProductIds: [],
                    errorScheduleIds: [],
                    isMultipleForm: false,
                    errorMessage: '',
                    appointmentData: {
                        shopId: omniChannelOrderDataMixedOrder?.storeInfos?.nosicaId,
                        origine: omniChannelOrderDataMixedOrder?.acquisitionSource ?? 'Magasin',
                        appointments: [
                            {
                                scheduleId: omniChannelOrderDataMixedOrder?.closestAppointment?.scheduleId,
                                wpCustomerId: omniChannelOrderDataMixedOrder?.orderInfos?.wpCustomerId,
                                customerId: action.payload.customerId,
                                nosCustomerId: omniChannelOrderDataMixedOrder?.orderInfos?.nosCustomerId,
                                mail: omniChannelOrderDataMixedOrder?.mainRecipient?.email,
                                phone: omniChannelOrderDataMixedOrder?.mainRecipient?.phone,
                                firstName: omniChannelOrderDataMixedOrder?.mainRecipient?.firstName,
                                lastName: omniChannelOrderDataMixedOrder?.mainRecipient?.lastName,
                                civility: omniChannelOrderDataMixedOrder?.mainRecipient?.civility,
                                date: (omniChannelOrderDataMixedOrder?.closestAppointment) ? formatDate(omniChannelOrderDataMixedOrder?.closestAppointment?.date) : '',
                                type: 'rdv_depot',
                                order: [
                                    {
                                        documentNo: omniChannelOrderDataMixedOrder?.orderInfos?.documentNumber,
                                        orderId: omniChannelOrderDataMixedOrder?.orderInfos?.orderId,
                                        id: omniChannelOrderDataMixedOrder?.orderInfos?.saleOrderId,
                                        idCrypt: omniChannelOrderDataMixedOrder?.orderInfos?.idCrypt ?? '',
                                        orderLines: [],
                                        orderDate: omniChannelOrderDataMixedOrder?.orderInfos?.saleDate
                                    }
                                ]
                            }
                        ]
                    },
                    appointmentsData: {
                        shopId: omniChannelOrderDataMixedOrder?.storeInfos?.nosicaId,
                        origine: omniChannelOrderDataMixedOrder?.acquisitionSource ?? 'Magasin',
                        appointments: getAppointmentData(omniChannelOrderDataMixedOrder?.orderItems?.filter(orderItem => orderItem.possessionStatus === 1), omniChannelOrderDataMixedOrder?.orderInfos, omniChannelOrderDataMixedOrder?.mainRecipient, action.payload.customerId)

                    }
                } : state.omniChannelOrder
            let showLoaderList = {...state.showLoaderList};
            showLoaderList[action.payload.orderId] = action.payload.showLoader;
            return {
                ...state,
                trackingInfosByOrder: action.payload.orderTrackingData,
                omniChannelOrder: omniChannelOrderMixedOrder,
                showLoaderList: showLoaderList
            }
        }
        case CONFIRMATION_APPOINTMENTS:
            return {
                ...state,
                omniChannelOrder: {
                    ...state.omniChannelOrder,
                    loader: action.payload.appointmentLoader
                }
            }
        case UPDATE_LOADER: {
            let showLoaderList = {...state.showLoaderList};
            showLoaderList[action.payload.orderId] = action.payload.showLoader
            return {
                ...state,
                showLoaderList: showLoaderList,
                trackingInfosByOrder: null
            }
        }
        case UPDATE_ERROR_ORDER_TRACKING_LIST: {
            let showLoaderList = {...state.showLoaderList};
            showLoaderList[action.payload.orderId] = action.payload.showLoader;
            return {
                ...state,
                apiErrorOrderList: [action.payload.orderId, ...state.apiErrorOrderList],
                showLoaderList: showLoaderList
            }
        }
        default:
            return state;
    }
};

export default OrderTrackingReducer;
