import $ from 'jquery'
import {isLightProductSheetPage} from "./productSheetPage"

const scrollNav = function ($) {
    // FONCTION DE FIXE AU SCROLL DU HEADER
    var heightHeader = $('#main-header').outerHeight(),
        lastScrollTop = 0,
        init = 0;

    // définit la hauteur du header
    /*$('#main-header').css({'height': heightHeader});*/

    // ajout d'une class speciale au clic sur la nav fiche produit si elle existe
    if ($('.scroll-details-nav').length > 0) {
        $('.scroll-details-nav ul > li a').on("click", function () {
            $('#main-header').addClass('special');
        });
    }

    // réinitialisation 0.2sec après fin de scroll
    var state;
    $(window).on("scroll", function () {
        if (state !== null) {
            clearTimeout(state);
        }
        state = setTimeout(function () {
            $('#main-header').removeClass('special');
            init = 0;
        }, 10);
    }, false);

    // Si on ne survole pas le sous menu
    if (!$('.sub-menu:hover').length > 0) {
        $(window).on('scroll', function () {
            $('#header-tools-content .tools > .search > .animateSearchBar').fadeOut();
            var st = $(this).scrollTop(),
                heightTools = $('#main-header.fixed .tools').outerHeight();
            // si on scroll vers le bas
            var heightInfoBar = $('#main-header .main-header__infobar').outerHeight()
            if (st > lastScrollTop) {
                init = 1;
                $('#main-header').addClass('invisible');
                $('#main-header .nav-main .overlayMenuAndSearch,.nav-main .niv1.opened,.product__burger-menu').removeClass('opened').addClass("closed");
                $('body').removeClass('fixedMenu fixedSearch');
                $('#autocompletetopSearch').fadeOut(200);
                $('#autocompleteresult').fadeOut(200);
                $("#header-tools-content input#autocomplete").blur().attr("placeholder", "Rechercher un produit, un magasin...");
            }
            // pour Bug IE
            else if (st == lastScrollTop) {
            }
            // si on scroll vers le haut
            else {
                init = -1;
            }

            // si on dépasse la hauteur du header
            if (st > (heightHeader + heightInfoBar)) {
                $('#main-header').addClass('fixed').find('.principalLogo').hide();
                $('#main-header').addClass('fixed').find('.scrollLogo').show();
                $(".dp__header__filter-bar").addClass('fixed');
                if (!$('#menu-burger').length > 0) {
                    /* $('#main-header .tools').prepend('<div id="menu-burger"><div class="burger"><i></i><i></i><i></i><i></i></div>MENU</div>');*/

                    var heightTools = $('#main-header.fixed .tools').outerHeight();

                    $('#menu-burger').on("click", function () {
                        if (!$(this).find($('.burger')).hasClass('cross')) {
                            $(this).find($('.burger')).addClass('cross');
                            $('#bloc_menu').animate({'top': heightTools}, 375);
                        } else {
                            $(this).find($('.burger')).removeClass('cross');
                            $('#bloc_menu').animate({'top': 0}, 425);
                        }
                    });
                    if (!$('#menu-burger').find($('.burger')).hasClass('cross')) {
                        $('#bloc_menu').css({'top': 0});
                    } else {
                        $('#bloc_menu').css({'top': heightTools});
                    }
                }
                // si on scroll vers le bas
                if (init > 0) {
                    if ((!$('.sub-menu:hover').length > 0) || ($('#main-header').hasClass('special'))) {
                        $('#main-header').addClass('invisible');
                        $(".dp__header__filter-bar").addClass('without-header');
                    }
                }
                // si on scroll vers le haut
                if (init < 0) {
                    if (!$('#main-header').hasClass('special')) {
                        $('#main-header').removeClass('invisible');
                        $(".dp__header__filter-bar").removeClass('without-header');
                    }
                }
            } else {
                if (!$('.sub-menu:hover').length > 0) {
                    if ($('#menu-burger').length > 0) {
                        $('#menu-burger').remove();
                        $('#bloc_menu').css({'top': 0});
                    }
                }
                $('#main-header').removeClass('fixed').removeClass('invisible').find('.scrollLogo').hide();
                $('#main-header').removeClass('fixed').removeClass('invisible').find('.principalLogo').show();
                $(".dp__header__filter-bar").removeClass('fixed');
            }
            lastScrollTop = st;
        });
    }
}.bind(this, (typeof jquery3 !== "undefined" ? jquery3 : $))
const header = {
    init: function ($) {
        // NEUTRALISE LES ANCRES VIERGES
        $('#main-header').find('a').click(function (e) {
            if ($(this).attr('href') == '#') {
                e.preventDefault();
            }
        });

        // ANIMATION DES POPINS
        $('.linked').click(function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            var $popin_link = $(this).data('popin'),
                $href_link = $(this).data('link');

            $('.popins-wrapper, .header-custom-popin').hide();

            if ($('.header-custom-popin.' + $popin_link).length > 0) {
                $('.popins-wrapper, .header-custom-popin.' + $popin_link).show();
            } else if ($href_link) {
                window.open($href_link, '_blank');
            }
        });
        $('.popins-wrapper, .close').click(function (e) {
            if ((e.target == this) && !$(e.target).is('.header-custom-popin')) {
                $('.popins-wrapper, .header-custom-popin').hide();
            }
        });

        // ANIMATION DU COEUR SUR LES PRODUITS DU PANIER
        $('#main-header .board .product .like').click(function (e) {
            e.preventDefault();
            if ($(this).hasClass('red')) {
                $(this).removeClass('red');
            } else {
                $(this).addClass('red');
            }
        });

        // SUPPRESSION DES PRODUITS DANS LE PANIER


        // ANIMATIONS POUR LES DEVICES TACTILES
        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            // AFFICHAGE DU MENU
            var sub_menu_is_open = false;
            $('#bloc_menu').find('.sub-menu').hide();
            $('#bloc_menu > ul > li').click(function (e) {
                e.preventDefault();
                sub_menu_is_open = true;
                $(this).find('.sub-menu').show();
            });

            $('.sub-menu').find('a').click(function (e) {
                var destination = $(this).attr('href');
                if (destination) {
                    document.location.href = destination;
                }

            });

            // AFFICHAGE DES POPINS
            $('.board > a').click(function () {
                var data = $(this).data('popin');
                $(this).parent().find('> div[class*=' + data + ']').toggle();
                $(this).parent().find('> div:not([class*=' + data + '])').hide();
            });

        }


        $('.account-auth').find('input').on('focus', function (e) {
            $('.account-auth').css({'display': 'block'});
        });
        $('.account-auth').find('input').on('focusout', function (e) {
            $('.account-auth').css({'display': ''});
        });

        if (appData.mvcUrl != 'Landing/Pages/ActualitiesMedias' && !isLightProductSheetPage(appData.productId)) {
            scrollNav();
        }

        let initClic = 0;



        //to keep header popin shown when select result from autocomplete list in stocklocater
        $("ul#ui-id-3")
            .mouseenter(function () {
                $(".find-store").addClass('store-show')
            })
            .mouseleave(function () {
                $(".find-store").removeClass('store-show')
            });

        // RECHERCHE MAGASIN
        // au moment de tapper sur les touches du clavier dans le champ de recherche
        $('.tools .field-search-popup-input').on('keyup', function () {
            var words = $(this).val();
            var el = $(this).attr('id');
            if (words.length > 1) {
                $('#autocompletetopSearch').fadeOut(200);
                $('#main-header #' + el + 'result').slideDown();
            } else if (words.length < 1) {
                $('#autocompleteresult').fadeOut(200);
                $('#main-header #' + el + 'topSearch').slideDown();
            }
            $('body').addClass('fixedSearch').removeClass('fixedMenu');
            $("#main-header .nav-main .overlayMenuAndSearch").addClass("opened").removeClass("closed");
            $("#main-header .nav-main .product__burger-menu,.nav-main .niv1").removeClass("opened").addClass("closed");

        });
        // au clique sur un lien on récupère le texte pour le mettre dans le champ de recherche
        $('.result ul .proposition a:not(#allMag a), .topSearch ul li a').on('click', function () {
            var elementsearch = $(this).text();
            $('.field-search-popup-input').val(elementsearch);
        });
        // fonction au focus sur le champ de recherche
        $('.field-search-popup-input').on('focus', function () {
            var words = $(this).val();
            var el = $(this).attr('id');
            if (words != '') {
                $('#main-header #' + el + 'result').slideDown();
            } else {
                $('#main-header #' + el + 'topSearch').slideDown();
            }
            $('body').addClass('fixedSearch').removeClass('fixedMenu');
            $("#main-header .nav-main .overlayMenuAndSearch").addClass("opened").removeClass("closed");
            $("#main-header .nav-main .product__burger-menu,.nav-main .niv1").removeClass("opened").addClass("closed");

            $('#fondResultSearch').fadeIn();
        });
        //au clique sur le champ de recherche le 'placeholder' se vide
        $('.search > input[name="KeyWords"]').click(function (e) {
            e.preventDefault();
            $(this).attr("placeholder", "");
        });
        //clique à l'extérieur du champ
        $(document.body).click(function (e) {
            if (!$(e.target).is($('.search > input[name="KeyWords"]')) && !$.contains($('.search > input[name="KeyWords"]')[0], e.target) && !$(e.target).is($('#closeAll, #propLast .lastLib .close'))) {
                $('.search > input[name="KeyWords"]').attr("placeholder", "Rechercher un produit, un magasin...");
                $('#autocompleteresult').slideUp(200);
                $('#autocompletetopSearch').slideUp(200);
                $('body').removeClass('fixedSearch');
            } else if ($(e.target).is($('.search > input[name="KeyWords"]'))) {
                if($('.main-header__infobar').data('owl.carousel')){
                    $('.main-header__infobar').data('owl.carousel').onResize();
                }
                $('#autocompletetopSearch').slideDown();
                $('body').addClass('fixedSearch').removeClass('fixedMenu');
                $("#main-header .nav-main .overlayMenuAndSearch").addClass("opened").removeClass("closed");
                $("#main-header .nav-main .product__burger-menu,.nav-main .niv1").removeClass("opened").addClass("closed");
            }
        });

        // Gestion de l'apparition de l'animation de la searchBar
        $('#header-tools-content .tools > .search > .animateSearchBar').fadeIn();
        setTimeout(function(){ $('#header-tools-content .tools > .search > .animateSearchBar').fadeOut(); }, 8000);
        $("#header-tools-content input#autocomplete,#header-tools-content .tools > .search > .animateSearchBar").mouseover(function() {
            $('#header-tools-content .tools > .search > .animateSearchBar').fadeOut();
        });

    }.bind(this, (typeof jquery3 !== "undefined" ? jquery3 : $))
}

export default header;
