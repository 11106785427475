import React, {useState, useRef, useCallback, useMemo, useEffect} from 'react';
import {connect} from 'react-redux';
import dynamic from 'next/dynamic';
import {CUSTOMER_PAGE, DEFAULT_TEMPLATE_VERSION, MOBILE_TEMPLATE_VERSION} from "@/utils/constants";
import  ShopLink from './ShopLink'
import  HelpLink from './HelpLink'
import  CartLink from './CartLink'
import  CustomerLink from './CustomerLink'
const MiniCartContainerWithNoSSR = dynamic(
    () => import('./MiniCartContainer'),
    {ssr: false}
)
const ShopDetailsWithNoSSR = dynamic(
    () => import('./ShopDetails'),
    {ssr: false}
)
const FindShopWithNoSSR = dynamic(
    () => import('./FindShop'),
    {ssr: false}
)
const CustomerDetailsWithNoSSR = dynamic(
    () => import('./CustomerDetails'),
    {ssr: false}
)
const TopAndLastSearchesResultWithNoSSR = dynamic(
    () => import('./TopAndLastSearchesResult'),
    {ssr: false}
)
const SearchResultWithNoSSR = dynamic(
    () => import('./SearchResult'),
    {ssr: false}
)
const ProductPriceWithNoSSR = dynamic(
    () => import('./ProductPrice'),
    {ssr: false}
)
import {
    getUserIdentity,
    getPrimaryShopData,
    synchronizeShopCircle,
    getShopEventListData
} from "@/redux/reducers/Default/header/headerActions";
import * as headerPageAnimation from "@/animations/Default/headerPage";
import * as searchBarActions from '@/redux/reducers/Default/header/SearchBarActions';
import Utils from "@/utils/Default/Utils";
import {
    UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE,
    CHANGE_KEYWORD,
    SEARCH_STARTED, HANDLEAPISTATUSPENDING
} from '@/redux/reducers/Default/actionsTypes'
import SearchBar from "./SearchBar";
import * as StockLocatorActions from "@/redux/reducers/Default/commun/StockLocatorActions";
import {handleOpenStoreHover} from "@/animations/Default/headerPage";
import Tracking from "../../Tracking";
import {useLifeCycleEffectWithDependencies} from "@/customHooks/hooks";
import {fetchRequiredCommonData} from "@/redux/reducers/responsive/common/CommonActions";
import {getCustomMenu} from "@/redux/reducers/responsive/common/CommonActions";

const HeaderContainer = ({
                             getUserIdentity, updateShopCircle, handleApiStatusPending,
                             getPrimaryShopData,
                             getShopEventListData,
                             updateShowRattachementBloc,
                             currentStep, productId,
                             productsListData, shopCircleVersion,
                             topSearchesResults, keywords, headerTrackingScopeData, headerTrackingFlow,
                             category,
                             currentSort, results,
                             currentFiltersValue,
                             pager,
                             keyWords,
                             circleShopList,
                             shopExpEventTxt,
                             showShopList,
                             showRattachementBloc,
                             shop, fetchKeywordsResults, handleChangeKeywords, searchStart,
                             cart,
                             customer, fetchTopSearchesResults,
                             logoVersion, searchStarted,
                             fetchRequiredCommon,
                             ABTastyCookieIsActive
                         }) => {
    const [searchBarClicked, setSearchBarClicked] = useState(false);
    const [showPopin, setShowPopin] = useState(false);
    const timerRef = useRef(0);
    useLifeCycleEffectWithDependencies(() => {
        Utils.handleLastSearchesExpiration();
        let currentSteps = ['productSheet', 'productsList', 'ProductsSearchList', 'customerPage']
        headerPageAnimation.initShopCircleVersion(shopCircleVersion)
        if (currentSteps.indexOf(currentStep) < 0 && shopCircleVersion != null) {
            synchronizeShopCircle(shopCircleVersion).then(() => {
                handleHeaderActions();
            })
        } else {
            handleHeaderActions();
        }
        if(Utils.getCookieValue('TC_PRIVACY') == null) {
            document.addEventListener("DOMNodeInserted", function (e) {
                if (e.target.id == "tc-privacy-wrapper" && typeof __tcfapi !== 'undefined') {
                    __tcfapi('addEventListener', 2, function (tcData, success) {
                        if (success && tcData.eventStatus === 'useractioncomplete') {
                            if(Utils.checkCookies(3) && (Utils.getCookieValue('passivegeoloc') == 'true' || !ABTastyCookieIsActive)) {
                                synchronizeShopCircle(shopCircleVersion).then(() =>  {
                                    getPrimaryShopData()
                                });
                            }
                        }
                    }.bind(this));
                }
            }.bind(this))
        }
    }, () => {
        if (currentStep === CUSTOMER_PAGE) {
            synchronizeShopCircle(shopCircleVersion).then(() => {
                updateShowRattachementBloc(false);
                if (Utils.getPrimaryShopId()) {
                    getPrimaryShopData();
                    getShopEventListData(Utils.getPrimaryShopId());
                }
            });
        }
        handleOpenStoreHover();
    }, [shopCircleVersion])

    useEffect(() => {
        handleOpenStoreHover();
    }, [shop?.shopInfos?.id]);
    const handleHeaderActions = () => {
        updateShowRattachementBloc(!Utils.getPrimaryShopId())
        headerPageAnimation.findShopFirstLandingAnimation()
        let isPrivateSale = false;
        if (currentStep === "productsList" && productsListData) {
            isPrivateSale = (Utils.getMetaInfosValueByCode('IsPrivate', productsListData?.category?.metaInfos)) === 'True' ? true : false
        }
        if(currentStep !== CUSTOMER_PAGE) {
            getUserIdentity(isPrivateSale)
        }
            if (Utils.getPrimaryShopId() && currentStep !== 'productsList' && currentStep !== 'ProductsSearchList' && currentStep !== CUSTOMER_PAGE) {
                getPrimaryShopData()
                getShopEventListData(Utils.getPrimaryShopId())
            }
             // PARCEQUE CUSTOMER_AREA_PAGE NOT SSR
        if (currentStep == CUSTOMER_PAGE) {
            fetchRequiredCommon(DEFAULT_TEMPLATE_VERSION, currentStep)
        }

    }

    const handleKeywordsChange = useCallback((event) => {
        clearTimeout(timerRef.current);
        const keywords = event.target.value;
        handleChangeKeywords(keywords);
        if (keywords.length >= 3) {
            timerRef.current = setTimeout((() => {
                fetchKeywordsResults(keywords);
                if (!searchStart) {
                    dataLayerSearchStartPush();
                }
            }), 300);
        }
    }, [searchStart])

    const handleKeyPress = useCallback ((e) => {
        if (keywords && keywords.length > 0) {
            e.preventDefault()
            let searchPageUrl = "/Common/Search/SearchProductsList?KeyWords=" + encodeURIComponent(keywords)
            window.location.href = searchPageUrl;
            updateLastSearchesStorage({
                label: keywords,
                url: searchPageUrl
            }, 'keyPress');
            dataLayerSearchTrackPush()
        }
    }, [keywords])

    const handleClick = useCallback((e) => {
        e.preventDefault()
        if (!searchBarClicked) {
            fetchTopSearchesResults()
            setSearchBarClicked(true)
        }
        dataLayerSearchFocusPush()
    },[searchBarClicked])

    const dataLayerSearchTrackPush = (e) => {
        if (keywords && keywords.replace(/\s/g, "").length > 0) {
            appData.actionTrigger.dispatch({
                trigger: "searchTrack"
            });
        }
    }

    const dataLayerSearchFocusPush = () => {
        appData.actionTrigger.dispatch({
            trigger: "searchFocus"
        });
    }

    const dataLayerSearchStartPush = () => {
        searchStarted()
        appData.actionTrigger.dispatch({
            trigger: "searchStart"
        });
    }

    const updateLastSearchesStorage = useCallback((lastSearchItem, context) => {
        Utils.updateLastSearchesStorage(lastSearchItem, context)
    }, [])

    const deleteLastSearchItem = useCallback((e, index, textItem) => {
        if (localStorage.getItem('lastSearches')) {
            let items = JSON.parse(localStorage.getItem('lastSearches'))
            items.splice(items.findIndex(element => element.text == textItem), 1);
            localStorage.setItem('lastSearches', JSON.stringify(items))
        }
        headerPageAnimation.deleteLastSearchItem(e, index)
    }, [])


    const renderCategories = useCallback(() => {
        let replacedKeywords = Utils.replaceRegExpInvalidCharacters(keywords);
        let pattern = new RegExp(Utils.replaceRegExpInvalidCharacters(keywords), 'ig');
        let formattedKeywords = replacedKeywords.charAt(0).toUpperCase() + replacedKeywords.slice(1);
        let replace = '<span class="rouge">' + formattedKeywords + '</span>';
        if (results && results.categories && results.categories.length > 0) {
            return (
                <div id="propCat">
                    <span className="titre">Cat&eacute;gories</span>
                    {results.categories.map((category, i) => {
                            return (
                                <a key={i} href={category.categoryUrl}
                                   dangerouslySetInnerHTML={{__html: category.label.replace(pattern, replace)}}
                                   onClick={() => updateLastSearchesStorage(category, 'category')}></a>
                            )
                        }
                    )}
                </div>
            );
        }
        return null;
    }, [keywords, results])

    const atLeastOneResult = useCallback(() => {
        return (results && keywords && keywords.length >= 2
            && ((results.shops && results.shops.length > 0)
                || (results.categories && results.categories.length > 0) ||
                (results.products && results.products.length > 0)))
    }, [results, keyWords]);

    const renderShops = useCallback(() => {
        let replacedKeywords = Utils.replaceRegExpInvalidCharacters(keywords);
        let pattern = new RegExp(Utils.replaceRegExpInvalidCharacters(keywords), 'ig');
        let formattedKeywords = replacedKeywords.charAt(0).toUpperCase() + replacedKeywords.slice(1);
        let replace = '<span class="rouge">' + formattedKeywords + '</span>';
        if (atLeastOneResult()) {
            return (
                <div id="propMag">
                    <span className="titre">Magasins</span>
                    {results.shops && results.shops.length > 0 &&
                        <>
                            {results.shops.map((shop, i) => {
                                    return (
                                        <a key={i} className="shop" href={shop.shopUrl}
                                           dangerouslySetInnerHTML={{__html: shop.label.replace(pattern, replace)}}
                                           onClick={() => updateLastSearchesStorage(shop, 'shop')}></a>
                                    )
                                }
                            )}
                        </>
                    }
                    <p id="allMag">›<a href="/magasins/recherche-magasins"
                                       alt="D&eacute;couvrir nos magasins">D&eacute;couvrir
                        nos
                        magasins</a></p>
                </div>
            );
        }
        return null;
    }, [results, keywords])

    const renderProducts = useCallback(() => {
        let replacedKeywords = Utils.replaceRegExpInvalidCharacters(keywords);
        let pattern = new RegExp(Utils.replaceRegExpInvalidCharacters(keywords), 'ig');
        let formattedKeywords = replacedKeywords.charAt(0).toUpperCase() + replacedKeywords.slice(1);
        let replace = '<span class="rouge">' + formattedKeywords + '</span>';
        if (results && results.products && results.products.length > 0) {
            return (
                <li className="produits">
                    <span className="titre">Produits</span>
                    {results.products.map((product, i) => {
                            return (
                                <a key={i} href={product.productSheetUrl} className="produit">
                                    <div className="image">
                                        <img src={product.image} alt={""}/>
                                    </div>
                                    <div className="texte">
                                        <p className="lib"
                                           dangerouslySetInnerHTML={{__html: (product.label) ? product.label.replace(pattern, replace) : ""}}></p>
                                        <ProductPriceWithNoSSR eligibilityToPrivateSale={customer.eligibilityToPrivateSale}
                                                               product={product}/>
                                    </div>
                                </a>
                            )
                        }
                    )}
                    <p id="seeAll">› <a onClick={handleKeyPress}
                                        alt="Voir toute la s&eacute;lection">Voir tout</a></p>
                    <div>
                        {results && results.bannerACP && results.bannerACP.length > 0 ? <div>
                            <div dangerouslySetInnerHTML={{__html: results.bannerACP[0]['html']}}></div>
                        </div> : null}
                    </div>
                </li>
            );
        }
        return null;
    }, [results, keywords])

    const renderResult = () => {
        if (atLeastOneResult()) {
            return (
                <div className="result" id="autocompleteresult"
                     style={{display: atLeastOneResult() ? "block" : "none"}}>
                    <ul>
                        <li className="proposition">
                            {renderCategories()}
                            {renderShops()}
                        </li>
                        {renderProducts()}
                    </ul>
                </div>
            );
        }
        return null
    }

    const displayLastSearches = useCallback(() => {
        let lastSearchesStorage = localStorage.getItem('lastSearches')
        let lastSearches = lastSearchesStorage ? JSON.parse(lastSearchesStorage) : null
        return (Utils.checkCookies(3) && lastSearches && lastSearches.length > 0)
    }, [])

    const customUpdateShopCircle = useCallback((calculationParams, productId, calculationMethod, context, productListParams, cartItems) => {
        handleApiStatusPending(true);
        let selectedZipCity = null
        let zipCode = headerPageAnimation.getInputElementValue("#zipCode");
        let city = headerPageAnimation.getInputElementValue("#city");
        if (zipCode) {
            selectedZipCity = {
                "zipCode": zipCode,
                "cityName": city
            }
        }
        updateShopCircle(calculationParams, productId, calculationMethod, context, selectedZipCity, productListParams, cartItems)
    }, [])

    const customUpdateShowRattachementBloc = useCallback((e, value) => {
        e.preventDefault();
        setShowPopin(true)
        appData.actionTrigger.dispatch({
            trigger: "handleHeaderShopCircleAction",
            payload: {
                action: 'Rattachement',
                label: 'Changer de magasin'
            }
        });
        updateShowRattachementBloc(value)
    }, [])
    const productListParams = useMemo(() => {
        return {
            currentSort: productsListData?.currentSort,
            categoryId: category?.id,
            currentFiltersValue: currentFiltersValue,
            pageIndex: productsListData?.pager ? productsListData.pager.currentPageIndex + 1 : null,
            availabilityFiltersCodes: Utils.prepareAllShopsProductAvailabilityFilterValue(productsListData?.filters?.values),
            pager: pager,
            keyWords: keyWords
        };
    }, [productsListData, currentFiltersValue, keyWords]);
    const renderToolsBlock = () => {
        let items = (cart && cart.items) ? cart.items.filter(item => item.quantity !== 0 ? item : null) : null
        let boardClassName = 'board-hover' + ((!showRattachementBloc && (shop?.shopInfos && shop.shopInfos.name)) ? " open-store " : " find-store " + (showShopList ? "step2" : "step1"));
        if (!showRattachementBloc && (shop?.shopInfos && shop.shopInfos.name) && showPopin) {
            boardClassName = 'board-hover open-store visible-store';
        }
        return (
            <div className="tools">
                <div className="logo">
                    <a href="/" title="BUT" className="logo gtm-header" data-gtm-libelle="home">
                        <img src={"https://media.but.fr/Sources/Default/Images/Header/logo-but.svg?v=" + logoVersion}
                             alt="BUT"
                             className="principalLogo"
                             width={125} height={60}
                        />
                        <img src={"https://media.but.fr/Sources/Default/Images/Header/logo-altV2.svg?v=" + logoVersion}
                             alt="BUT"
                             className="hiddenLogo scrollLogo"
                             width={45} height={44.4}
                        />
                    </a>
                </div>
                <SearchBar onSubmit={handleKeyPress} onClick={handleClick}
                           onChange={handleKeywordsChange}/>
                <div className="board connecte">
                    <HelpLink/>
                    <ShopLink shopInfos={shop?.shopInfos}/>
                    <div
                        className={boardClassName}>
                        {!showRattachementBloc && (shop?.shopInfos && shop.shopInfos.name) ?
                            <ShopDetailsWithNoSSR {...shop} shopExpEventTxt={shopExpEventTxt}
                                                  updateShowRattachementBloc={customUpdateShowRattachementBloc}/> :
                            <FindShopWithNoSSR productListParams={productListParams}
                                               updateShowRattachementBloc={customUpdateShowRattachementBloc}
                                               productId={productId}
                                               updateShopCircle={customUpdateShopCircle}
                                               circleShopList={circleShopList}
                                               cartItems={items}
                                               showShopList={showShopList}/>}
                    </div>
                    <CustomerLink {...customer}  />
                    {(customer?.showCustomerZone !== undefined && customer.showCustomerZone == 1) &&
                        <CustomerDetailsWithNoSSR {...customer} />}
                    <CartLink items={items} totalQuantities={cart.totalQuantities}/>
                    <MiniCartContainerWithNoSSR currentStep={currentStep}/>
                    <input type='hidden' id='zipCode'/>
                    <input type='hidden' id='inseeCode'/>
                    <input type='hidden' id='city'/>
                </div>
            </div>
        )
    }


    return (
        <div id="header-tools-content">
            {renderToolsBlock()}
            <TopAndLastSearchesResultWithNoSSR searchBarClicked={searchBarClicked}
                                               topSearchesResults={topSearchesResults}
                                               keywords={keywords}
                                               displayLastSearches={displayLastSearches}
                                               deleteLastSearchItem={deleteLastSearchItem}
                                               updateLastSearchesStorage={updateLastSearchesStorage}/>
            <SearchResultWithNoSSR atLeastOneResult={atLeastOneResult}
                                   renderCategories={renderCategories}
                                   renderProducts={renderProducts} renderShops={renderShops}/>
            {headerTrackingScopeData && <Tracking trackingData={headerTrackingScopeData}/>}
            {headerTrackingFlow && <Tracking trackingData={headerTrackingFlow}/>}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        shop: state.header.shop,
        showRattachementBloc: state.header.showRattachementBloc,
        shopExpEventTxt: state.header.shopExpEventTxt,
        showShopList: state.header.showShopList,
        circleShopList: state.header.circleShopList,
        customer: ownProps.currentStep == CUSTOMER_PAGE ? state.identity.userData :state.header.customer,
        cart: state.header.cart,
        keywords: state.searchBar.keywords,
        results: state.searchBar.results,
        searchStarted: state.searchBar.searchStarted,
        headerTrackingFlow: state.tracking.headerTrackingFlow,
        topSearchesResults: state.searchBar.topSearchesResults,
        headerTrackingScopeData: ownProps.headerTrackingScopeData?? state.requiredCommonData.headerTrackingScopeData,
        productsListData: state.productList && (state.productList.keyWords ? state.productList : state.productList.changeFromClient ? state.productList.productsListData : ownProps.productsListData),
        pager: state.productList ? (state.productList?.changeFromClient ? state.productList?.pager : ownProps.productsListData?.pager) : null,
        category: ownProps.productsListData?.category,
        currentStep: ownProps.currentStep,
        logoVersion: ownProps.logoVersion ?? state.requiredCommonData.logoVersion,
        shopCircleVersion: ownProps.shopCircleVersion ?? state.requiredCommonData.shopCircleVersion,
        currentFiltersValue: state.productList?.currentFiltersValue ? state.productList.currentFiltersValue : null,
        currentSort: state.productList && state.productList?.currentSort ? state.productList.currentSort : null,
        keyWords: state.productList && state.productList.keyWords,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserIdentity: (isPrivateSale) => dispatch(getUserIdentity(isPrivateSale)),
        getPrimaryShopData: () => dispatch(getPrimaryShopData()),
        handleApiStatusPending: (apiStatusPending) => dispatch({
            type: HANDLEAPISTATUSPENDING,
            payload: {apiStatusPending: apiStatusPending}
        }),
        handleChangeKeywords: (keywords) => dispatch({type: CHANGE_KEYWORD, keywords: keywords}),
        fetchKeywordsResults: (event) => dispatch(searchBarActions.fetchKeywordsResults(event)),
        fetchTopSearchesResults: () => dispatch(searchBarActions.fetchTopSearchesResults()),
        searchStarted: () => dispatch({type: SEARCH_STARTED}),
        getShopEventListData: (shopId) => dispatch(getShopEventListData(shopId)),
        updateShopCircle: (calculationParams, productId, calculationMethod, context, selectedZipCity, productListParams, cartItems) => dispatch(StockLocatorActions.updateShopCircle(calculationParams, productId, calculationMethod, context, null, selectedZipCity, productListParams, cartItems)),
        updateShowRattachementBloc: (value) => dispatch({
            type: UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE,
            payload: {value: value}
        }),
        fetchRequiredCommon: (templateVersion, context) => dispatch(fetchRequiredCommonData(templateVersion, context)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
