import React, { memo } from "react";
import Head from "next/head";
import MetaTags from "@/components/Mobile/Layout/Head/MetaTags";

const HeadDetails = memo((props) => {

    return (
        <>
            <Head>
                <title>{props.title}</title>
            </Head>
            <MetaTags title={props.title}
                description={props.description}
                keywords={props.keywords}
                canonical={props.canonical}
                context={props.context}
                nextURL={props.nextURL}
                previousURL={props.previousURL}
                images={props.images} />
            {props.context && props.context === 'productSheet' &&
                <link rel="stylesheet" href="/assets/css/mobile/magicZoom.min.css" />
            }
            {props.children}
        </>

    )
})
export default HeadDetails