import {Fragment, memo} from 'react'


const ShopContact = memo((props) => {
    return (
        <Fragment>
            <div className="store-fiche-content mb-0">
                <span className="store-fiche-content__title">Nous contacter</span>
                {props.contact.phone === '08.26.25.25.25' ?
                    <div className="contact-direct__content">
                        <div className="contact-direct__content__tel contact-flex">
                            <span className="contact-direct__label">CONTACT</span>
                            <div className="contact-direct__content__tel__content ">
                                <div className="contact-direct__content__tel__content__tel"><span
                                    className="tel">{ props.contact.phone }</span></div>
                                <div className="contact-direct__content__tel__content__txt">
                                    Services <span>0,18€ / min<br/>+ prix appel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="contact-direct__content">
                        <div className="contact-direct__content__tel contact-flex">
                            <span className="contact-direct__label">CONTACT</span>
                            <div className="contact-direct__content__tel__content contact-direct__content__tel__content--green">
                                <div className="contact-direct__content__tel__content__tel"><span
                                    className="tel">{ props.contact.phone }</span></div>
                                <div className="contact-direct__content__tel__content__txt">
                                    Service & appel<br/>gratuits
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {props.contact.afterSalesFurnitureServicePhone &&
                <div className="contact-direct__content">
                    <div className="contact-direct__content__tel contact-flex">
                        <span className="contact-direct__label">S.A.V. Mobilier</span>
                        <div className="contact-direct__content__tel__content contact-direct__content__tel__content--green">
                            <div className="contact-direct__content__tel__content__tel"><span
                                className="tel">{ props.contact.afterSalesFurnitureServicePhone }</span></div>
                            <div className="contact-direct__content__tel__content__txt">
                                Service & appel<br/>gratuits
                            </div>
                        </div>
                    </div>
                </div>}
                {props.contact.afterSalesElectroServicePhone &&
                <div className="contact-direct__content">
                    <div className="contact-direct__content__tel contact-flex">
                        <span className="contact-direct__label">S.A.V. Electroménager / TV-Son</span>
                        <div className="contact-direct__content__tel__content contact-direct__content__tel__content--green">
                            <div className="contact-direct__content__tel__content__tel"><span
                                className="tel">{ props.contact.afterSalesElectroServicePhone }</span></div>
                            <div className="contact-direct__content__tel__content__txt">
                                Service & appel<br/>gratuits
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </Fragment>
    )

})

export default ShopContact;