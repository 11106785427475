import {TAGGING_FETCHED} from '../actionsTypes'

const initialState = {
    headerTrackingFlow: '',
    footerTrackingFlow: '',
};
const trackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAGGING_FETCHED :
            return {
                ...state,
                headerTrackingFlow: action.payload.headerTrackingFlow,
                footerTrackingFlow: action.payload.footerTrackingFlow,
            }
        default:
            return state;
    }
};

export default trackingReducer;
