import Cookies from "js-cookie";
import {NAVIGATION_META_INFO_SPEEDY_SCAN} from "@/utils/constants";
export const removeAccents = (s) => {
    let ko = 'ÀÁÂÃÄÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÇçàáâãèéêëîïòôõöùúûüñ';
    let ok = 'AAAAAEEEEIIIIOOOOOUUUUCcaaaaeeeeiioooouuuun';
    let len = s.length;
    let p;
    let c;
    let result = "";
    for (let i = 0; i < len; i++) {
        c = s.charAt(i);
        p = ko.indexOf(c);
        result += p == -1 ? c : ok.charAt(p);
    }
    return result;
}
export const  getPrimaryShopId = () => {
    let primaryShopId = "";
    if (isValidShopCircle()) {
        var shopCircleData = JSON.parse(localStorage.getItem('shopCircle'));
        if (shopCircleData["primaryShop"]) {
            return shopCircleData["primaryShop"].id;
        }
    }
    return primaryShopId;
}
export const  getPrimaryShopDistance = () => {
    if (isValidShopCircle()) {
        let shopCircleData = JSON.parse(localStorage.getItem('shopCircle'));
        if (shopCircleData["primaryShop"]) {
            return shopCircleData["primaryShop"].distance;
        }
    }
    return '';
}
export const  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    if (name === "Filters") {
        return results[2].replace(/%22/g, "\"")
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const formatPrice = (price)=>  {
    return new Intl.NumberFormat('fr-FR').format(price)
}

export const getMetaInfosValueByCode = (code, metaInfosList) => {
    if (metaInfosList) {
        var key = metaInfosList.codes.indexOf(code);
        if (key != -1) {
            return metaInfosList.items[key].value;
        }
    }
    return '';
}

export const pushState = (currentSort, appliedFiltersValues, pageIndex, keyWords)=>  {
        var url = "";
        var separator = "?";

        // Keywords
        if (keyWords) {
            url += separator + "KeyWords=" + keyWords;
            separator = "&";
        }

        // Sort
        if (currentSort) {
            url += separator + "SortedBy=" + currentSort;
            separator = "&";
        }

        // Filters
        if (appliedFiltersValues) {
            url += separator + "Filters=" + appliedFiltersValues;
            separator = "&";
        }

        // PageIndex
        if (pageIndex) {
            url += separator + "PageIndex=" + pageIndex;
            separator = "&";
        }

        if (url) {
            history.pushState(null, null, url);
        } else {
            history.pushState(null, null, window.location.pathname);
        }
    }
export const pushContextUrl = (contextUrl) =>{
    if (contextUrl) {
        history.pushState(null, null, contextUrl);
    } else {
        history.pushState(null, null, window.location.pathname);
    }
}
export const resloveUrlParams = (urlparams) =>{
  let filters = urlparams.split('/')
    let urlData = [];
    let item = {}
    filters.forEach((urlParam,i) => {
      if(isSubStringExist(urlParam,'TW-')  || isSubStringExist(urlParam,'T-')){
        item = {}
        item.code = 'SortBy';
        item.value = urlParam;
        urlData.push(item);
      } else if(isSubStringExist(urlParam,'NW-')  || isSubStringExist(urlParam,'N-') ){
        item = {}
        item.type = 'Filters';
        item.code = 'NW-'+i
        item.values = [urlParam];
        urlData.push(item);
      }else if(isSubStringExist(urlParam,'PageIndex-') ){
        item = {}
        item.code = 'PageIndex';
        item.value = urlParam;
        urlData.push(item);
      }
    })
return urlData;
}

export const isSubStringExist = (myString, mySubString) => {
    if(myString.indexOf(mySubString) >= 0) {
        return true
    }
    return false;
}

export const updateLastSearchesStorage = (lastSearchItem, context) => {
    if(checkCookies(3)){
        let lastSearchesStorage = localStorage.getItem('lastSearches')
        let items = lastSearchesStorage ? JSON.parse(lastSearchesStorage) : []
        let url = ''
        switch(context) {
            case 'shop':
                url = lastSearchItem.shopUrl;
                break;
            case 'category':
                url = lastSearchItem.categoryUrl;
                break;
            case 'topSearch':
            case 'keyPress':
                url = lastSearchItem.url;
                break;
            default :
                url = '';
                break;
        }
        var today = new Date();
        var date = new Date();
        date.setDate(date.getDate() + 93);
        items.forEach((item, key) => {
            if(item.text === lastSearchItem.label){
                items.splice(key, 1);
            }
        })
        if(items.length >= 4){
            items.splice(4, 1);
        }
        items.unshift({
            text: lastSearchItem.label,
            url: url,
            searchDate: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
            expiry: date.getTime()
        })
        localStorage.setItem('lastSearches', JSON.stringify(items))
    }
}
export const checkCookies =  (category) => {
    let cookie = getCookieValue('TC_PRIVACY')
    let categoryCookie = encodeURI(cookie).split("@");
    return (categoryCookie && categoryCookie[2] && (categoryCookie[2].split(',').map(Number).includes(category)))
}
export const parseJson = (data) => {
    var response = null;
    try {
        response = JSON.parse(data);
    } catch (e) {
        response = data;
    }
    return response;
}
export const getFeaturesGroup = (code, data) =>{
    const codeIndex = data.codes.indexOf(code);
    if (codeIndex > -1) {
        return data.items[codeIndex];
    }
    return false
}
export const handleLastSearchesExpiration = () => {
    if(checkCookies(3)){
        let lastSearchesStorage = localStorage.getItem('lastSearches')
        if(lastSearchesStorage){
            let items = JSON.parse(lastSearchesStorage)
            var today = new Date();
            items.forEach((item, key) => {
                if (today.getTime() > item.expiry) {
                    items.splice(key, 1);
                }
            })
            localStorage.setItem('lastSearches', JSON.stringify(items))
        }
    }
}

export const replaceRegExpInvalidCharacters = (s) =>  {
    return s.replace(/[°"§%()\[\]{}=\\?´`'#<>|,;.:+_]+/g, "").replace(/-+/g, "-");
}

export const validateEmail =  (email) =>  {
    var regExp = /^(?:[a-z0-9!#$%&'*+\/=?^_'{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_'{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    return regExp.test(email);
}

export const getShopCirleDataFromLocalStorage = (context) => {
    let shopCircleByContext = getShopCircleFromSource(context);
    let shopCircle = []
    if (shopCircleByContext) {
        let shopCircleData = JSON.parse(shopCircleByContext);
        if (shopCircleData) {
            shopCircle = shopCircleData;
        }
    }
    return shopCircle;
}
export const getShopCirleFromLocalStorage = (context) => {
    let shopCircle = getShopCircleFromSource(context);
    let shopListData = []
    if (shopCircle) {
        let shopCircleData = JSON.parse(shopCircle);
        if (shopCircleData["circleShops"]) {
            shopListData = shopCircleData["circleShops"];
        }
    }
    return shopListData;
}
export const getShopCircleFromSource = (context) => {
    if (context == "stockLocator") {
        return Cookies.get("stockLocatorShopCircle")
    }
    return isValidShopCircle() ? localStorage.getItem('shopCircle') : null
}
export const getShopCircleFromCookie = (context) => {
    let cookieName = (context == "stockLocator") ? "stockLocatorShopCircle" : "shopCircle"
    let shopListData = []
    let shopCircleCookieValue = getCookieValue(cookieName)
    if (shopCircleCookieValue) {
        let shopCircleData = JSON.parse(shopCircleCookieValue);
        if (shopCircleData && shopCircleData["circleShops"]) {
            shopListData = shopCircleData["circleShops"];
        }
    }
    return shopListData;
}

export const convertDateStringToDate = (date) => {
    let arrayDate = date.split(/[\/ :]/);
    return arrayDate[2] + '-' + arrayDate[1] + '-' + arrayDate[0];
}

export const redirectTo = (url) => {
    window.location.href = url
}

export const isValidLatitudeOrLongitude = (element, indexOf) => {
    return element && element != '' && (element.indexOf(indexOf) > 0 || element.indexOf(indexOf) === -1);
}

export const getCookieValue = (cookieName) => {
    if(typeof Cookies.get(cookieName) !== 'undefined'){
        return Cookies.get(cookieName)
    }
    return null
}

export const setCookieValue = (cookieName, value) => {
    Cookies.set(cookieName, value);
}

export const getFetchingProductsAvailabilitiesIsAllowed = (filters, madIsActifInFiltredProductList) => {
    let allowFetchingProductsAvailabilities = true
    let availabilityFilter = filters.filter(item => item[1] == 'AvailabilityShopIdList')
    let availabilityFilterItem = availabilityFilter && availabilityFilter[0] && availabilityFilter[0][5] ? availabilityFilter[0][5].filter(item => item[0] == 'AvailabilityShopIdList' && item[5] == true) : null;
    let availabilityShopIdListFilterIsApplied = availabilityFilterItem?.length > 0;
    if(availabilityShopIdListFilterIsApplied) {
        allowFetchingProductsAvailabilities = madIsActifInFiltredProductList
    }
    return allowFetchingProductsAvailabilities;
}

export const getProductsAvailabilityApiParamsForProductList = (productListItems) => {
    let shopCircleData = getShopCirleDataFromLocalStorage()
    let productsIds = '';
    productListItems.map(item => {
        productsIds = productsIds + item[19] + ',1;'
    })
    if (shopCircleData && shopCircleData.primaryShop) {
        return "ProductIds=" + productsIds.slice(0, -1) + "&ShopIds=" + shopCircleData.primaryShop.id + "&View=ProductList" + "&ZipCode=" + shopCircleData.zipCode + "&City=" + shopCircleData.city;
    } else {
        return "ProductIds=" + productsIds.slice(0, -1) + "&View=ProductList";
    }
}

export const getProductAvailabilityApiParams = (allShops, productId, context = null) => {
    let shopCircle = getShopCirleFromLocalStorage(context);
    let shopCircleData = getShopCirleDataFromLocalStorage(context);
    let view = "ProductSheet"

    if (allShops) {
        view = "StockLocator"
    }
    if (shopCircle.length > 0) {
        let shopIdList = [];
        let counter = 1;
        shopIdList.push(shopCircle[0].id);
        const stockLocatorShopsCount = localStorage.getItem('stockLocatorShopsCount');
        while (shopCircle[counter] && shopIdList.length < parseInt(stockLocatorShopsCount)) {
            if (shopCircle[counter].isMerchant === true) {
                shopIdList.push(shopCircle[counter].id);
            }
            counter++;
        }

        let shopIdListParam = (shopIdList.join()) ? "&ShopIds=" + shopIdList.join() : "";
        return "ProductIds=" + productId + ",1" + shopIdListParam + "&View=" + view + "&ZipCode=" + shopCircleData.zipCode + "&City=" + shopCircleData.city;
    } else {
        return "ProductIds=" + productId + ",1" + "&View=" + view;
    }
}

export const getUserIdFromCookie = () => {
    let CookieAsArray = []
    let groupAndIdArray = []
    let formattedCookieArray = []
    formattedCookieArray['userId'] = 'dXNlcklkPS0xO2dyb3VwPXdlYnVzZXI='; /* token with no userId */
    if (Cookies.get('navigationToken')) {
        CookieAsArray = atob(Cookies.get('navigationToken')).split(':');
        groupAndIdArray = CookieAsArray[1].split(';')
        groupAndIdArray.forEach((element) => {
            formattedCookieArray[element.split("=")[0]] = element.split("=")[1]
        });
    }
    return formattedCookieArray['userId']
}

const getMaxDisplayedCommercialOperations = (commercialOperations) => {
    return getMetaInfosValueByCode("moblieMaxDisplayedStickersProductsheet", commercialOperations[0].metaInfos);
}

export const prepareCommercialOperationListByType = () => {
    const commercialOperations = appData.commercialOperations;
    if (commercialOperations && commercialOperations.length > 0) {
        let maxDisplayedPicto = getMaxDisplayedCommercialOperations(commercialOperations);
        let stickersNumbers = commercialOperations.length;
        let pictoFlashSaleList = commercialOperations.filter((item, index) => ((maxDisplayedPicto > index) && (index < stickersNumbers) && item.isFlash));
        let commercialOperationList = commercialOperations.filter((item, index) => ((maxDisplayedPicto > index) && (index < stickersNumbers) && !item.isFlash));
        return {"pictoFlashSaleList": pictoFlashSaleList, "commercialOperationList": commercialOperationList}
    }
}

export const prepareShopCircle = (shopCircleCookie = null) => {
    if(shopCircleCookie) {
        let shopCircleFromCookie = JSON.parse(shopCircleCookie);
        if (shopCircleFromCookie["circleShops"]) {
            let shopCircleQuery = {};
            shopCircleQuery.shopCircle = shopCircleFromCookie['circleShops'];
            shopCircleQuery.primaryShopId = shopCircleFromCookie['primaryShop']
            return shopCircleQuery;
        }
        return null ;
    } else if (Cookies.get('availabilityShopCircle')) {
        let shopCircleData = JSON.parse(Cookies.get('availabilityShopCircle'));
        if (shopCircleData["circleShops"]) {
            let shopCircleQuery = {};
            shopCircleQuery.shopCircle = shopCircleData['circleShops'];
            shopCircleQuery.primaryShopId = shopCircleData['primaryShop']
            return shopCircleQuery;
        }
        return null;
    }
    return null;
}

export const initializeLocalStorageShopCircle = (shopCircleVersion) => {
    if (getCookieValue('shopCircle') && JSON.parse(getCookieValue('shopCircle')).version == shopCircleVersion) {
        localStorage.setItem('shopCircle', getCookieValue('shopCircle'));
    }
    Cookies.remove('shopCircle');
}

export const isValidShopCircle = () => {
    if (localStorage.getItem('shopCircle') && Cookies.get('availabilityShopCircle') ) {
        let shopCircle = JSON.parse(localStorage.getItem('shopCircle'));
        let date = new Date(shopCircle.modificationDate);
        let dateYearLater = new Date(date.setMonth(date.getMonth() + 12))
        let now = new Date();
        let shopCircleVersion = localStorage.getItem('shopCircleVersion')
        if (shopCircle.version != shopCircleVersion || (dateYearLater <= now)) {
            localStorage.removeItem('shopCircle')
            return false;
        }
        return true;
    } else {
        localStorage.removeItem('shopCircle')
    }
    return false;
}

export const getAvailabilityShopCircleData = (shopCircleData) => {
    return {
        "modificationDate": shopCircleData.modificationDate,
        "primaryShop": shopCircleData.primaryShop.id,
        "version": shopCircleData.version,
        "circleShops": sortShopCircleByDistance(shopCircleData.circleShops, shopCircleData.primaryShop.id)
    }
}

export const sortShopCircleByDistance = (circleShops, primaryShop) => {
    let sortedShops = [];
    let shopCircleIds = [];
    circleShops.map(shop => {
        sortedShops.push([shop.id, shop.distance])
    })
    sortedShops.sort(function (shop1, shop2) {
        return shop1[1] - shop2[1];
    });
    shopCircleIds.push(primaryShop);
    for (let i = 0; i < 5; i++) {
        if (sortedShops[i][0] != primaryShop) {
            shopCircleIds.push(sortedShops[i][0])
        }
    }
    return shopCircleIds.slice(0, 4);
}

export const getDataFromShopCircle = (data) => {
    if (isValidShopCircle()) {
        let shopCircleData = JSON.parse(localStorage.getItem('shopCircle'));
            return shopCircleData[data] ? shopCircleData[data] : '';
        }
    return '';
}
 export  const isConsentment = () => {
     if (typeof  Cookies.get('TC_PRIVACY') !== 'undefined') {
         let cookie = Cookies.get('TC_PRIVACY')
         let categoryCookie = encodeURI(cookie).split("@");
         return  !!(categoryCookie && categoryCookie[2] && (categoryCookie[2].split(',').map(Number).includes(3)))
     }
     return false ;
 }
export const prepareMenuItemsByMenuIdNav = (menuIdNav, menuItems) => {
    let levels = [];
    if (menuIdNav) {
        let menuIdsNav = menuIdNav.split(";");
        menuIdsNav.map((menuId, index) => {
            let menuItem = menuItems.filter(item => item[6] == menuId);
            if (menuItem.length > 0 && (levels.filter(item => item[6] == menuId).length == 0)) {
                menuItem[0][7].map((childrenItem, i) => {
                    if (childrenItem[0].startsWith('Colonne')) {
                        childrenItem[7].map((childItem, j) => {
                            childItem[11] = false;
                            levels.push(childItem);
                        })
                    } else {
                        childrenItem[11] = false;
                        levels.push(childrenItem);
                    }
                })
            }
        })
    }
    return levels;
}
export const getBasketData = (items) => {
    let basketLinesItems = []
    let basketLinestotalAmount = 0;
    items.map(item => {
        basketLinesItems.push({

            "ProductId": item.productId,
            "Quantity": item.quantity,
            "Price": item.unitPrice * item.quantity
        })
        basketLinestotalAmount += parseFloat(item.unitPrice * item.quantity);
    })

    return {
        "BasketLines": basketLinesItems,
        "Field": "EAN_13",
        "TotalAmount": parseFloat(basketLinestotalAmount.toFixed(2))
    }
}
export const getFormatedDate = (date) => {
    let day = (date.getDate() >= 10) ? date.getDate() : "0" + date.getDate();
    let currentMonth = date.getMonth() + 1;
    let month = (currentMonth >= 10) ? currentMonth : "0" + currentMonth;
    let year = date.getFullYear();
    return day + "/" + month + "/" + year;
}
export const getPaginationNavigation = (totalPages, activePage) => {
    let links = [];
    if (totalPages > 3) {
        // this specifies the range of pages we want to show in the middle
        const min = Math.max(
            totalPages - activePage <= 3 ? totalPages - 4 : activePage - 2,
            2
        );
        const max = Math.min(
            activePage <= 4 ? 5 : activePage + 2,
            totalPages - 1
        );

        // we always show the first page
        links.push(1);

        // we're more than one space away from the beginning, so we need a separator
        if (min > 2) {
            links.push("...");
        }

        // generate the middle numbers
        for (let i = min; i < max + 1; ++i) {
            links.push(i);
        }

        // we're more than one space away from the end, so we need a separator
        if (max < totalPages - 1) {
            links.push("...");
        }

        // we always show the last page
        links.push(totalPages);
    } else {
        // we must special-case three or less, because the above logic won't work
        // generate the middle numbers
        for (let i = 1; i <= totalPages; ++i) {
            links.push(i);
        }
    }
    return links
}

export const getUpdatedUrl = (currentUrl, pageIndex, firstLoad, categoryView = null) => {
    let pageIndexValue = pageIndex.toString().replace('PageIndex=', '');
    const url = firstLoad ? currentUrl : window.location.href;
    let urlParams='';
    if (url.indexOf("PageIndex") >= 0) {
        urlParams = pageIndexValue > 1 ? url.replace(/PageIndex=[0-9]+/i, url.indexOf("?") >= 0 ? "PageIndex=" + pageIndexValue : "?PageIndex=" + pageIndexValue) : url.replace(/[?|&]PageIndex=[0-9]+/i, '')
        return urlParams.includes('view') ? urlParams : urlParams + (categoryView ? `&view=${categoryView}` : '');
    }
    urlParams = pageIndexValue > 1 ? url.indexOf("?") >= 0 ? url + '&PageIndex=' + pageIndexValue : url + '?PageIndex=' + pageIndexValue : url;
    return urlParams.includes('view') ? urlParams : urlParams + (categoryView ? `&view=${categoryView}` : '');
}

export function checkEligibilityFromLocalStorage() {
    var shopCircleData = localStorage.getItem('shopCircle');
    if (shopCircleData !== null) {
        var shopCircleObj = JSON.parse(shopCircleData);
        var isEligibleToScan = shopCircleObj.primaryShop.eligibilityToScan?.isEligibleToScan;
        var reasonCode = shopCircleObj.primaryShop.eligibilityToScan?.reasonCode;
        if (isEligibleToScan && reasonCode === null) {
            return true;
        } else if (isEligibleToScan === false && reasonCode === 'distanceTooFar') {
            return true;
        }
    }
    return false;
}

export const isEligibleToScan = () => {
    const cookieValue = Cookies.get(NAVIGATION_META_INFO_SPEEDY_SCAN);
    return cookieValue ? (() => {
        const decodedCookie = decodeURIComponent(cookieValue).match(/^\{.*\}/);
        return decodedCookie ? JSON.parse(decodedCookie[0]).value === true : false;
    })() : false;
}
