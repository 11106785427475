import $ from 'jquery'

const common = {
    init() {
        $('.paragraphe__link').on('click', function () {
            $(this).hide()
        })
        $('.top-bar__store__close').on('click', function (e) {
            e.preventDefault()
            $('.section-store--overlay').removeClass('open')
        })

        /* SWITCH PASSWORD EYE */
        $('.field__row__input__icon').on('click', function (e) {
            e.preventDefault()
            if ($(this).parent().find('input').attr('type') === 'text') {
                $(this).parent().find('input').attr('type', 'password')
                $(this).find('span').addClass('icon-oeil_ferme').removeClass('icon-oeil')
            } else {
                $(this).parent().find('input').attr('type', 'text')
                $(this).find('span').removeClass('icon-oeil_ferme').addClass('icon-oeil')
            }
        })
        let matInput = $('.mat-input')
        matInput.focus(function () {
            $(this).parents(".mat-field").addClass('is-active is-completed').removeClass('mat-field--valid').removeClass('mat-field--error')
        })
        matInput.focusout(function () {
            if ($(this).val() === '') {
                $(this).parents(".mat-field").removeClass('is-completed')
            }
            $(this).parents(".mat-field").removeClass('is-active')
            if(($(this).parents(".mat-field").siblings('#unique-email-error').length > 0) && ($(this).parents(".mat-field").siblings('#unique-email-error').text() !== "")) {
                $(this).parents(".mat-field").addClass('mat-field--error')
            }
        })
    },
    isSafariAndiOS() {
        let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        return isSafari && iOS;
    },
    deleteAllCookies(path = "/", domain = null) {
        let cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (domain) {
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=" + path + ";domain=" + domain;
            } else {
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=" + path;
            }

        }
    }

}

export default common
