import { Reducer } from "redux";
import { BUNDLE_OFFERS_DATA_FETCH, BUNDLE_OFFERS_PENDING_STATUS_FETCH } from "@/redux/reducers/responsive/actionsTypes";
import { PromotionalOffersState } from "@/models/appState/PromotionalOffersState";
import { PromotionalOffersActionTypes } from "@/redux/reducers/responsive/product/promotionalOffersType";

const initialState: PromotionalOffersState = {
    bundleOffers: null,
    isLoading: false,
};
const PromotionalOffersReducer: Reducer<PromotionalOffersState, PromotionalOffersActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case BUNDLE_OFFERS_DATA_FETCH:
            return {
                ...state,
                bundleOffers: action.payload.bundleOffers,
            };
        case BUNDLE_OFFERS_PENDING_STATUS_FETCH:
            return {
                ...state,
                isLoading: action.payload.isLoading
            }    
        default:
            return state;
    }

};

export default PromotionalOffersReducer;