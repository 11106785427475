import Cookies from 'js-cookie'
import {ReactChild, ReactFragment, ReactPortal} from "react";
//todo supprimer ce fichier et garder que /utils/utils.js
const Utils = {
    formatPrice(price) {
        return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price)
    },
    formatPriceWithoutTax(price) {
        return new Intl.NumberFormat("fr-FR", {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(price)
    },
    convertToRGB(colorCode) {
        let aRgbHex = colorCode.slice(1).match(/.{1,2}/g);
        return [
            parseInt(aRgbHex[0], 16),
            parseInt(aRgbHex[1], 16),
            parseInt(aRgbHex[2], 16)
        ];
    },
    removeAccents(s) {
        let ko = 'ÀÁÂÃÄÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÇçàáâãèéêëîïòôõöùúûüñ';
        let ok = 'AAAAAEEEEIIIIOOOOOUUUUCcaaaaeeeeiioooouuuun';
        let len = s.length;
        let p;
        let c;
        let result = "";
        for (let i = 0; i < len; i++) {
            c = s.charAt(i);
            p = ko.indexOf(c);
            result += p == -1 ? c : ok.charAt(p);
        }
        return result;
    },
    getUserIdFromCookie() {
        let CookieAsArray = []
        let groupAndIdArray = []
        let formattedCookieArray = []
        formattedCookieArray['userId'] = 'dXNlcklkPS0xO2dyb3VwPXdlYnVzZXI='; /* token with no userId */
        if (Cookies.get('navigationToken')) {
            CookieAsArray = atob(Cookies.get('navigationToken')).split(':');
            groupAndIdArray = CookieAsArray[1].split(';')
            groupAndIdArray.forEach((element) => {
                formattedCookieArray[element.split("=")[0]] = element.split("=")[1]
            });
        }
        return formattedCookieArray['userId']
    },
    getMetaInfosValueByCode(code, data) {
        if (data) {
            let codeIndex = data.codes.indexOf(code);
            if (codeIndex > -1) {
                return data.items[codeIndex].value;
            }
        }
        return "";
    },

    formatDate(date,validDate) {
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        return (regex.test(date)) ? date : new Date(validDate).toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    },

    getMaxDisplayedCommercialOperationsOfTypeCommercial(commercialOperations) {
        return Utils.getMetaInfosValueByCode("commercialMaxDisplayedStickersProductSheet", commercialOperations[0]?.metaInfos);
    },
    getMaxDisplayedCommercialOperationsOfTypeProduct(commercialOperations) {
        return Utils.getMetaInfosValueByCode("productMaxDisplayedStickersProductSheet", commercialOperations[0]?.metaInfos);
    },

    getMaxDisplayedCommercialOperationsPerProductSheet(commercialOperations) {
        return Utils.getMetaInfosValueByCode("maxDisplayedStickersProductSheet", commercialOperations[0]?.metaInfos);
    },

    hasCommercialOperationsWithMadeInFrance (commercialOperations) {
        return commercialOperations && commercialOperations.pictoMadeInFranceList && commercialOperations.pictoMadeInFranceList.length > 0
    },

    prepareCommercialOperationListByType(commercialOperations) {
        if (commercialOperations && commercialOperations.length > 0) {
            const maxDisplayedPicto = this.getMaxDisplayedCommercialOperationsPerProductSheet(commercialOperations);
            const stickersNumbers = commercialOperations.length;
            let commercialOperationsToDisplay = commercialOperations.filter(item => {
                return item.displayFP;
            });
            const pictoFlashSaleList = commercialOperationsToDisplay.filter((item, index) => ((maxDisplayedPicto > index) && (index < stickersNumbers) && item.isFlash && (Date.parse(item.endDate) - Date.parse(new Date())) > 0));
            const pictoMadeInFranceList = commercialOperationsToDisplay.filter((item, index) => ((maxDisplayedPicto > index) && (index < stickersNumbers) && item.isMadeInFrance ));
            const classicCommercialOperationList = commercialOperationsToDisplay.filter((item,index) => ((maxDisplayedPicto > index) && (index < stickersNumbers) && !item.isFlash && !item.isMadeInFrance));
            return {"pictoFlashSaleList": pictoFlashSaleList, "pictoMadeInFranceList": pictoMadeInFranceList, "classicCommercialOperationList": classicCommercialOperationList, "commercialOperationList": commercialOperationsToDisplay}
        }
    },

    renderCommercialOperationLink(commercialOperation) {
        let title = commercialOperation?.title
        title = title.replace('&euro;', '€');
        let commercialOperationLink = Utils.getMetaInfosValueByCode("pictoLinkUrlRedirection", commercialOperation?.metaInfos);
        return commercialOperationLink.length > 0 ? <a href={commercialOperationLink}
                                                       target="_blank">{title}</a> : title
    },

    hasTimerInPictoFalshSale(selector) {
        return $(selector).attr('data-time') ;
    },

    getShopCirleFromCookie: function (context) {
        let cookieName = (context == "stockLocator") ? "stockLocatorShopCircle" : "shopCircle"
        let shopListData = []
        if (Cookies.get(cookieName)) {
            let shopCircleData = JSON.parse(Cookies.get(cookieName));
            if (shopCircleData["circleShops"]) {
                shopListData = shopCircleData["circleShops"];
            }
        }
        return shopListData;
    },
    getShopCircleFromSource(context) {
        if (context == "stockLocator") {
            return Cookies.get("stockLocatorShopCircle")
        }
        return this.isValidShopCircle() ? localStorage.getItem('shopCircle') : null
    },
    isValidShopCircle() {
        if (localStorage.getItem('shopCircle') && Cookies.get('availabilityShopCircle')) {
            let shopCircle = JSON.parse(localStorage.getItem('shopCircle'));
            let date = new Date(shopCircle.modificationDate);
            let dateYearLater = new Date(date.setMonth(date.getMonth() + 12))
            let now = new Date();
            let shopCircleVersion = localStorage.getItem('shopCircleVersion')
            if (shopCircle.version != shopCircleVersion || (dateYearLater <= now)) {
                localStorage.removeItem('shopCircle')
                return false;
            }
            return true;
        } else {
            localStorage.removeItem('shopCircle')
        }
        return false;
    },
    getShopCirleFromLocalStorage: function (context) {
        let shopCircle = this.getShopCircleFromSource(context);
        let shopListData = []
        if (shopCircle) {
            let shopCircleData = JSON.parse(shopCircle);
            if (shopCircleData["circleShops"]) {
                shopListData = shopCircleData["circleShops"];
            }
        }
        return shopListData;
    },
    getShopCirleDataFromLocalStorage: function (context) {
        let shopCircleByContext = this.getShopCircleFromSource(context);
        let shopCircle = []
        if (shopCircleByContext) {
            let shopCircleData = JSON.parse(shopCircleByContext);
            if (shopCircleData) {
                shopCircle = shopCircleData;
            }
        }
        return shopCircle;
    },
    getShopCirleDataFromCookie: function (context) {
        let cookieName = (context == "stockLocator") ? "stockLocatorShopCircle" : "shopCircle"
        let shopCircle = []
        if (Cookies.get(cookieName)) {
            let shopCircleData = JSON.parse(Cookies.get(cookieName));
            if (shopCircleData) {
                shopCircle = shopCircleData;
            }
        }
        return shopCircle;
    },
    getProductAvailabilityApiParams: function (allShops, productId, context = null) {
        let shopCircle = this.getShopCirleFromLocalStorage(context);
        let shopCircleData = this.getShopCirleDataFromLocalStorage(context);
        let view = "ProductSheet"

        if (allShops) {
            view = "StockLocator"
        }
        if (shopCircle.length > 0) {
            let shopIdList = [];
            let counter = 1;
            shopIdList.push(shopCircle[0].id);
            if (allShops) {
                view = "StockLocator"
                const stockLocatorShopsCount = localStorage.getItem('stockLocatorShopsCount');
                while (shopCircle[counter] && shopIdList.length < parseInt(stockLocatorShopsCount)) {
                    if (shopCircle[counter].isMerchant === true) {
                        shopIdList.push(shopCircle[counter].id);
                    }
                    counter++;
                }
            } else {
                while (shopCircle[counter] && shopIdList.length < 4) {
                    if (shopCircle[counter].isMerchant === true) {
                        shopIdList.push(shopCircle[counter].id);
                    }
                    counter++;
                }
            }

            let shopIdListParam = (shopIdList.join()) ? "&ShopIds=" + shopIdList.join() : "";
            return "ProductIds=" + productId + ",1" + shopIdListParam + "&View=" + view + "&ZipCode=" + shopCircleData.zipCode + "&City=" + shopCircleData.city;
        } else {
            return "ProductIds=" + productId + ",1" + "&View=" + view;
        }

    },
    getApprovalDelayMessage (approvalDelay) {

        let approvalDelayMessage = ""
        let hours = approvalDelay / 3600
        let minutes = approvalDelay / 60

        if (approvalDelay <= 60) {
            approvalDelayMessage = "Immédiat"
        } else if (Math.round(minutes) >= 1 && Math.round(minutes) < 60) {
            approvalDelayMessage = Math.round(minutes) + " min"
        } else if (Math.round(hours) >= 1 && Math.round(hours) <= 24) {
            approvalDelayMessage = Math.round(hours) + " h"
        } else if (Math.round(hours) > 24 && Math.round(hours) <= 48) {
            approvalDelayMessage = "Entre 1 et 2 jours"
        } else {
            approvalDelayMessage = "Plus que 2 jours"
        }

        return approvalDelayMessage
    },
    getAvailabilityShopCircleData(shopCircleData) {
        return {
            "modificationDate": shopCircleData.modificationDate,
            "primaryShop": shopCircleData.primaryShop.id,
            "version": shopCircleData.version,
            "circleShops": this.sortShopCircleByDistance(shopCircleData.circleShops, shopCircleData.primaryShop.id)
        }
    },
    sortShopCircleByDistance(circleShops, primaryShop) {
        let sortedShops = [];
        let shopCircleIds = [];
        circleShops.map(shop => {
            sortedShops.push([shop.id, shop.distance])
        })
        sortedShops.sort(function (shop1, shop2) {
            return shop1[1] - shop2[1];
        });
        shopCircleIds.push(primaryShop);
        for (let i = 0; i < 5; i++) {
            if (sortedShops[i][0] != primaryShop) {
                shopCircleIds.push(sortedShops[i][0])
            }
        }
        return shopCircleIds.slice(0, 4);
    },
    getDecimalFormat(number) {
        const numberFlot = parseFloat(number)
        const roundedNumber = Math.round(numberFlot * 10) / 10;
        return roundedNumber % 1 === 0 ? roundedNumber : roundedNumber.toFixed(1).replace(".", ",");
    },
    getDistanceToShop: function (shopId) {
        let distanceToShop = '';
        let circleShops = [];
        let shopCircle = [];
        let stockLocatorShopCircle = []
        if (this.isValidShopCircle()) {
            shopCircle = jQuery.parseJSON(localStorage.getItem('shopCircle'));
            shopCircle = shopCircle["circleShops"]
        }
        if(Cookies.get('stockLocatorShopCircle')) {
            stockLocatorShopCircle = jQuery.parseJSON(Cookies.get('stockLocatorShopCircle'));
            stockLocatorShopCircle = stockLocatorShopCircle["circleShops"]
        }
        circleShops = shopCircle.concat(stockLocatorShopCircle);


        if (circleShops) {
            circleShops.forEach(function (shop) {
                if (shop.id == shopId.toString()) {
                    distanceToShop = shop.distance;
                }
            });
        }
        return distanceToShop;
    },
    recoT2S(pId, iId) {
        let t2sScript = document.createElement('script');
        t2sScript.type = 'text/javascript';
        t2sScript.defer = false;
        t2sScript.async = false;
        t2sScript.src = 'https://static.target2sell.com/t2s.min.js';
        t2sScript.onload = function () {
            let elements = document.querySelectorAll(
                "body div[id*='PPNA1QL127P68J']",
            );
            let length = elements.length;
            if (length > 0) {
                try {
                    window._t2sblocs = window._t2sblocs || {};
                    window._t2sparams = {}; // reset tout autre objet déjà créé éventuel
                    _t2sparams.cID = 'PPNA1QL127P68J'; // id de compte T2S
                    _t2sparams.eN = 'view';
                    _t2sparams.pID = pId;
                    _t2sparams.iID = iId;
                    T2S.reco();
                } catch (e) {
                    console.log("l'appel à T2S a échoué ", e);
                }
            }
        };
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(t2sScript, s);
    }

}

export default Utils;
