import ApiCaller from "@/services/ApiCaller/apiCallerDefault";
import OctipasMerch from "@/utils/Default/OctipasMerch"
import Utils from "@/utils/Default/Utils"
import * as cartPageAnimations from "@/animations/Default/cartPage"
import * as axios from "axios";
import {
    LOAD_CART_API_RESPONSE,
    CART_ITEMS_UPDATED,
    CART_GLD_UPDATED,
    UPDATE_CHANGE_QUANTITY_STATE,
    UPDATE_REDUCED_PRODUCT_SHEET
} from '../actionsTypes'

export const loadCartApiResponse = (view) => {
    return function (dispatch) {
        let id = appData.webUserId;
        ApiCaller.get('/WebUsers/' + id + '/Cart?View=' + view)
            .then(response => {
                dispatch({
                    type: LOAD_CART_API_RESPONSE,
                    payload: {cartObject: response.data}
                });
                $.fancybox.close();
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
export const fetchCartSummary = (context = null) => {
    return function (dispatch, getState) {
        let id = appData.webUserId;
        ApiCaller.post('/WebUsers/' + id + '/Cart?View=cart', {context: ''})
            .then(response => {
                let successMessages = (getState().cart.cartObject.messages && getState().cart.cartObject.messages.success) ? getState().cart.cartObject.messages.success : []
                let warningMessages = (getState().cart.cartObject.messages && getState().cart.cartObject.messages.warning) ? getState().cart.cartObject.messages.warning : []
                let errorMessages = (getState().cart.cartObject.messages && getState().cart.cartObject.messages.error) ? getState().cart.cartObject.messages.error : []
                let consolidationMessages = getState().cart.cartObject.consolidationMessages ? getState().cart.cartObject.consolidationMessages : []
                let cartObject = response.data
                if (response.data.messages && response.data.messages.success && response.data.messages.success.length > 0) {
                    response.data.messages.success.map((message) => {
                            if (successMessages.indexOf(message) < 0) {
                                successMessages.push(message)
                            }
                        }
                    )
                }
                if (response.data.messages && response.data.messages.warning && response.data.messages.warning.length > 0) {
                    response.data.messages.warning.map((message) => {
                            if (warningMessages.indexOf(message) < 0) {
                                warningMessages.push(message)
                            }
                        }
                    )
                }
                if (response.data.messages && response.data.messages.error && response.data.messages.error.length > 0) {
                    response.data.messages.error.map((message) => {
                            if (errorMessages.indexOf(message) < 0) {
                                errorMessages.push(message)
                            }
                        }
                    )
                }
                if (response.data.consolidationMessages && response.data.consolidationMessages.length > 0) {
                    response.data.consolidationMessages.map((message) => {
                            if (consolidationMessages.filter(msg => (msg.code == message.code && msg.type == message.type && msg.message == message.message)).length < 1) {
                                consolidationMessages.push(message)
                            }
                        }
                    )
                }
                cartObject.messages = {'success': successMessages, 'warning': warningMessages, 'error': errorMessages}
                cartObject.consolidationMessages = consolidationMessages
                dispatch({
                    type: LOAD_CART_API_RESPONSE,
                    payload: {cartObject: cartObject}
                });
                if (context == 'cart') {
                    OctipasMerch.createOrReplaceBasket(Utils.getBasketData(response.data.items))
                }
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
export const fetchReducedProductSheetContent = (productId, unitPrice, unitCrossedOutPrice) => {
    return function (dispatch) {
        let id = appData.webUserId;
        axios.get('/Catalog/Popin/Details/Id/' + productId)
            .then(response => {
                dispatch({
                    type: UPDATE_REDUCED_PRODUCT_SHEET,
                    payload: {reducedProductSheetContent: response.data}
                });
                cartPageAnimations.resizeFancyBoxPopinReducedProductSheet('popin-product', unitPrice, unitCrossedOutPrice);
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_REDUCED_PRODUCT_SHEET,
                    payload: {reducedProductSheetContent: "un erreur est servenu"}
                });
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function updateProductQuantityInCart(productId, quantity) {

    cartPageAnimations.showItemBlocLoader(productId);
    cartPageAnimations.showLoader(productId);
    return function (dispatch) {
        const data = {}
        data.action = "updateProductsQuantity"
        data.items = [{id: productId, qty: quantity}];
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=cart", data)
            .then(response => {
                // if (response.data.messages.error) {
                //     const updatedItem = response.data.items.filter(item => item.itemId === itemId)[0]
                //     const vendor = {name: updatedItem.sellerName, id: updatedItem.sellerId}
                //     appData.actionTrigger.dispatch({
                //         trigger: "LimitedQuantityMessageOnUpdatingQuantity",
                //         payload: {
                //             vendorName: vendor.name,
                //             vendorId: vendor.id
                //         }
                //     });
                //
                // }

                //cartPageAnimations.hideLoader(productId);
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cartObject: response.data, totalQuantity: response.data.totalQuantities}
                });
                dispatch({
                    type: UPDATE_CHANGE_QUANTITY_STATE,
                    payload: {itemId: productId, stateValue: 'success'}
                });
                cartPageAnimations.hideItemBlocLoader(productId);
                OctipasMerch.createOrReplaceBasket(Utils.getBasketData(response.data.items))
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export const updateProductsWarranty = (productId, code, gldAction) => {
    return (dispatch) => {
        const data = {}
        data.action = gldAction
        data.items = [{id: productId, service: {code: code}}];
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=cart", data)
            .then(response => {
                dispatch({
                    type: CART_GLD_UPDATED,
                    payload: {cartObject: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function deleteProductFromCart(itemId) {
    return function (dispatch) {
        const data = {}
        data.action = "removeProducts"
        data.items = [{id: itemId}];
        ApiCaller.put("/WebUsers/" + appData.webUserId + "/Cart?View=cart", data)
            .then(response => {
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cartObject: response.data, totalQuantity: response.data.totalQuantities}
                });
                OctipasMerch.createOrReplaceBasket(Utils.getBasketData(response.data.items))
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export const updateCustomerButCard = () => {
    return function (dispatch) {
        ApiCaller.patch("/Customers/" + appData.webUserId + "/Infos")
            .then(response => {
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
