
export const closeModal = (modalId) => {
    $('#' + modalId).modal('toggle')
}

export const openModal = (modalId) => {
    $('#' + modalId).modal()
}

export const checkWarrantyRadio = (productId) => {
    $("#r1-" + productId).prop('checked', true);
}
export const showLoader = (itemId) => {
    $('.loader' + itemId).removeAttr("style");
}
export const hideLoader = (itemId) => {
    $('.loader' + itemId).hide();
}

export const cartHideLoader = () => {
    $('.loader').css('display', 'none');
}
export const cartShowLoader = () => {
    $('.loader').css('display', 'flex');
}

export const checkOlapicBloc = () => {
    if ($('#olapic-item').length > 0) {
        return true
    }
    return false
};

export const showModal = (id) => {
    $('#' + id).modal();
}
export const showCartLoader = () => {
    $('.loader').css({'display': 'flex', 'z-index' : 2000});
}
export const hideCartLoader = () => {
    $('.loader').css('display', 'none');
}
