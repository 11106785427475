import {
    CUSTOMER_ACCOUNT_PROFIL_PAGE,
    DASHBOARD_PAGE,
    CUSTOMER_ACCOUNT_ORDERS_PAGE,
    CUSTOMER_ACCOUNT_SOCIAL_MEDIA,
    CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE,
    CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE,
    CUSTOMER_ACCOUNT_NEWSLETTER_PAGE,
    CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE
} from "@/utils/constants";

export const getTitle = (pathName) => {
    switch (pathName) {
        case '/customerAccount/orders':
            return 'Détails commande'
        case '/customerAccount/dashboard':
            return 'But - Espace perso'
        case '/customerAccount/profil':
            return 'Mes informations'
        case '/customerAccount/newsLetter':
            return 'Mes préférences de communication'
        case '/customerAccount/socialMedia':
            return 'Réseaux sociaux'
        case '/customerAccount/newPassword':
            return 'Nouveau mot de passe"'
        default:
            return 'But - Espace perso'
    }
}
export const getMvcUrl = (pathName) => {
    switch (pathName) {
        case '/customerAccount/orders':
            return 'Customer/Account/Orders'
        case '/customerAccount/dashboard':
            return 'Customer/Account/Dashboard'
        case '/customerAccount/profil':
            return 'Customer/Account/Profile'
        case '/customerAccount/newsLetter':
            return 'Customer/Account/Newsletters'
        case '/customerAccount/socialMedia':
            return 'Customer/Account/SocialMedia'
        case '/customerAccount/newPassword':
            return 'Customer/Account/NewPassword'
        case '/customerAccount/orderDetails':
            return 'Customer/Account/OrderDetails'
        default:
            return 'Customer/Account/CustomerAccountData'
    }
}
export const getContext = (pathName) => {
    switch (pathName) {
        case '/customerAccount/orders':
            return CUSTOMER_ACCOUNT_ORDERS_PAGE
        case '/customerAccount/dashboard':
            return DASHBOARD_PAGE
        case '/customerAccount/profil':
            return CUSTOMER_ACCOUNT_PROFIL_PAGE
        case '/customerAccount/newsLetter':
            return CUSTOMER_ACCOUNT_NEWSLETTER_PAGE
        case '/customerAccount/socialMedia':
            return CUSTOMER_ACCOUNT_SOCIAL_MEDIA
        case '/customerAccount/newPassword':
            return CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE
        case '/customerAccount/orderDetails':
            return CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE
        case '/customerAccount/kitchenProjects':
            return CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE
        default:
            return DASHBOARD_PAGE
    }
}

export const getNavigationLevel = (pathName) => {
    switch (pathName) {
        case '/customerAccount/dashboard':
            return 0
        case '/customerAccount/orderDetails':
            return 2
        case '/customerAccount/socialMedia':
            return 2
        case '/customerAccount/newPassword':
            return 2
        default:
            return 1
    }
}
export const getPageClassName = (pathName) => {
    if (!pathName) {
        return null
    }
    switch (pathName) {
        case '/customerAccount/orders':
            return 'page MyOrders'
        case '/customerAccount/dashboard':
            return 'page Dashboard'
        case '/customerAccount/profil':
            return 'page Profil'
        case '/customerAccount/newsLetter':
            return 'page newsletter'
        case '/customerAccount/socialMedia':
            return 'page SocialMedia'
        case '/customerAccount/newPassword':
            return "page NewPassword"
        case '/customerAccount/orderDetails':
            return "page OrderPage"
        case '/customerAccount/kitchenProjects':
            return "page KitchenProjectsPage"
        case 'categories':
            return "page category"
        default:
            return 'But - Espace perso'
    }
}
export const getNavigationData = (context, userId) => {
    switch (context) {
        case CUSTOMER_ACCOUNT_ORDERS_PAGE:
            return {
                parentsCategories: null,
                levelsNavigation: [
                    {
                        id: 2,
                        Label: "Mes commandes"
                    }
                ]
            };
        case CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE:
            return {
                parentsCategories: [
                    {
                        id: 1,
                        label: "Mes informations",
                        url: "/Customer/Account/Profile/Id/" + userId
                    }
                ],
                levelsNavigation: [
                    {
                        id: 1,
                        Label: "Mes informations"
                    },
                    {
                        id: 2,
                        Label: "Mon mot de passe"
                    }
                ]
            };
        case CUSTOMER_ACCOUNT_PROFIL_PAGE:
            return {
                parentsCategories: [
                    {
                        id: 1,
                        label: "Mes informations",
                        url: "/Customer/Account/Profile/Id/" + userId
                    }
                ],
                levelsNavigation: [
                    {
                        id: 1,
                        Label: "Mes informations"
                    }
                ]
            };
        case CUSTOMER_ACCOUNT_NEWSLETTER_PAGE:
            return {
                parentsCategories: [
                    {
                        id: 1,
                        label: "Mes informations",
                        url: "/Customer/Account/Profile/Id/" + userId
                    }
                ],
                levelsNavigation: [
                    {
                        id: 1,
                        Label: "Mes informations"
                    }
                ]
            };
        case CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE:
            return {
                parentsCategories: [
                    {
                        id: 1,
                        label: "Mes commandes",
                        url: "/Customer/Account/Orders/Id/" + userId
                    }
                ],
                levelsNavigation: [
                    {
                        id: 1,
                        Label: "Mes commandes"
                    }
                ]
            };
        case CUSTOMER_ACCOUNT_SOCIAL_MEDIA:
            return {
                parentsCategories: [
                    {
                        id: 1,
                        label: "Mes informations",
                        url: "/Customer/Account/Profile/Id/" + userId
                    }
                ],
                levelsNavigation: [
                    {
                        id: 1,
                        Label: "Mes informations"
                    },
                    {
                        id: 2,
                        Label: "Mes réseaux sociaux"
                    }
                ]
            };
        case CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE:
            return {
                parentsCategories: null,
                levelsNavigation: [
                    {
                        id: 1,
                        Label: "Mes projets cuisine 3D",
                    },
                ]
            };
        default:
            return null
    }
}