import $ from "jquery";

const customerOrderAnimation = {

    scrollToDivAnimation(divId) {
        let offset = $(divId).position().top;
        if (offset < jQuery(window).scrollTop()) {
            $('html, body').animate({
                scrollTop: offset
            }, 3000);
            return false;
        }
    }
}

export default customerOrderAnimation;