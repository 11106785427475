
const decounterPictoSettings = {
    getTimeRemaining(endtime,id) {
        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = (total <= 0) ? 0 : Math.floor((total / 1000) % 60);
        const minutes = (total <= 0) ? 0 : Math.floor((total / 1000 / 60) % 60);
        const hours = (total <= 0) ? 0 : Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = (total <= 0) ? 0 : Math.floor(total / (1000 * 60 * 60 * 24));
        if( total <= 0 ){
           $('#'+id).parent().parent().hide();
        }
        return {total,
            days,
            hours,
            minutes,
            seconds
        }
    },
    initializeClock(id, endtime) {
        const clock = document.getElementById(id);
        const daysSpan = clock.querySelector('.days');
        const hoursSpan = clock.querySelector('.hours');
        const minutesSpan = clock.querySelector('.minutes');
        const secondsSpan = clock.querySelector('.seconds');

        function updateClock() {
            const t = decounterPictoSettings.getTimeRemaining(endtime,id);

            daysSpan.innerHTML = t.days;
            hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
            minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
            secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

            if (t.total <= 0) {
                clearInterval(timeinterval);
            }
        }

        updateClock();
        const timeinterval = setInterval(updateClock, 1000);
    }

}
export default decounterPictoSettings ;