/* ####################### Identity ########################### */
export const IDENTITY_FETCHED = 'IDENTITY_FETCHED';
/* ####################### Availbility ########################### */
// export const UPDATE_ZIPCODE_KEYWORDS_IN_STATE = 'UPDATE_ZIPCODE_KEYWORDS_IN_STATE';
// export const UPDATE_CITY_AND_ZIPCODE_IN_STATE = 'UPDATE_CITY_AND_ZIPCODE_IN_STATE';
export const UPDATE_GEO_ERROR_BLOC_STATUS = 'UPDATE_GEO_ERROR_BLOC_STATUS';
export const MODIFY_QUANTITY = 'MODIFY_QUANTITY';
// export const DELETE_CITYlIST_STATE = 'DELETE_CITYlIST_STATE';
export const CITY_SEARCHRESULTS_FETCHED = 'CITY_SEARCHRESULTS_FETCHED';
export const AVAILABILITY_DATA_FETCHED_STOCKLOCATOR = 'AVAILABILITY_DATA_FETCHED_STOCKLOCATOR';
export const AVAILABILITYBUNDLEDATA_FETCHED = 'AVAILABILITYBUNDLEDATA_FETCHED';
export const AVAILABILITY_DATA_FETCHED = 'AVAILABILITY_DATA_FETCHED';
// export const SHIPPING_AVAILABILITY_DATA_FETCHED = 'SHIPPING_AVAILABILITY_DATA_FETCHED';
/* ####################### ProductList ########################### */
export const FETCH_PRODUCTS_AVAILABILITY_DATA = 'FETCH_PRODUCTS_AVAILABILITY_DATA';
export const PRODUCTLIST_CHANGED = 'PRODUCTLIST_CHANGED';
// export const APPLYFILTERS = 'APPLYFILTERS';
// export const APPLYFILTERSBLOCK = 'APPLYFILTERSBLOCK';
// export const APPLYSORT = 'APPLYSORT';
// export const HANDLENAVIGATION = 'HANDLENAVIGATION';
// export const FETCHITEMFROMNAVIGATION = 'FETCHITEMFROMNAVIGATION';
// export const PRODUCTS_AVAILABILITY_DATA_FETCHED = 'PRODUCTS_AVAILABILITY_DATA_FETCHED';
// export const PRODUCTLISTDATA_FETCHED = 'PRODUCTLISTDATA_FETCHED';
// export const SHOW_ATTACHMENT_BLOCK = 'SHOW_ATTACHMENT_BLOCK';
export const PRODUCTLIST_FETCHED = 'PRODUCTLIST_FETCHED';
export const CURRENTFILTERS_VALUES = 'CURRENTFILTERS_VALUES';
export const RESETFILTERS = 'RESETFILTERS';
export const APPLYFILTERS = 'APPLYFILTERS';
export const APPLYFILTERSBLOCK = 'APPLYFILTERSBLOCK';
export const APPLYSORT = 'APPLYSORT';
export const HANDLENAVIGATION = 'HANDLENAVIGATION';
export const CURRENTFILTERSVALUE = 'CURRENTFILTERSVALUE';
export const HANDLEAPISTATUSPENDING = 'HANDLEAPISTATUSPENDING';
export const HANDLE_IS_PAGINATION_LOADING = 'HANDLE_IS_PAGINATION_LOADING';
export const FETCHITEMFROMNAVIGATION = 'FETCHITEMFROMNAVIGATION';
export const PRODUCTS_AVAILABILITY_DATA_FETCHED = 'PRODUCTS_AVAILABILITY_DATA_FETCHED';
export const PRODUCTLIST_EMPTY_ERROR = 'PRODUCTLIST_EMPTY_ERROR';
export const CHECK_AVAILABILITY_FILTER_DISPLAY = 'CHECK_AVAILABILITY_FILTER_DISPLAY';
export const HANDLE_RATTACHEMENT_MODAL_DATA = 'HANDLE_RATTACHEMENT_MODAL_DATA';
export const PRODUCTLISTDATA_FETCHED = 'PRODUCTLISTDATA_FETCHED';
export const SHOW_ATTACHMENT_BLOCK = 'SHOW_ATTACHMENT_BLOCK';
/* ####################### Menu ################################### */
export const MENUDATA_FETCHED = 'MENUDATA_FETCHED';
/* ####################### Header ################################# */
export const CARTDATA_FETCHED = 'CARTDATA_FETCHED';
export const USER_IDENTITY_FETCHED = 'USER_IDENTITY_FETCHED';
export const CART_DATA_UPDATE = 'CART_DATA_UPDATE';
export const CART_DATA_UPDATE_ERROR = 'CART_DATA_UPDATE_ERROR';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const SHOP_EVENT_LIST_DATA_FETCHED = 'SHOP_EVENT_LIST_DATA_FETCHED';
export const UPDATE_SHOPCIRCLE_LIST = 'UPDATE_SHOPCIRCLE_LIST';
export const UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE = 'UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE';
export const UPDATE_SHOW_PRICE_WITHOUT_TAX = 'UPDATE_SHOW_PRICE_WITHOUT_TAX';
export const UPDATE_LOAD_DQE = 'UPDATE_LOAD_DQE';
export const UPDATE_LOAD_JQUERY_UI = 'UPDATE_LOAD_JQUERY_UI';
export const UPDATE_LOAD_DQE_LOCATE_ME = 'UPDATE_LOAD_DQE_LOCATE_ME';
export const UPDATE_LOAD_DQE_INPUT_KEY_UP = 'UPDATE_LOAD_DQE_INPUT_KEY_UP';
export const UPDATE_INPUT_SELECTED_IDS = 'UPDATE_INPUT_SELECTED_IDS';
export const SHOP_EVENT_EXECEPTIONNAL_EVENT = 'SHOP_EVENT_EXECEPTIONNAL_EVENT ';
export const SHOW_SHOP_LIST_BLOC = 'SHOW_SHOP_LIST_BLOC ';
export const CART_ITEMS_UPDATED = 'CART_ITEMS_UPDATED';
export const PRIMARY_SHOP_DATA_FETCHED = 'PRIMARY_SHOP_DATA_FETCHED';
/* ####################### GEOLOCALISATION  ########################## */
export const SELECTED_ZIPCODE_CITY = 'SELECTED_ZIPCODE_CITY'
export const UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS = 'UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS'
export const UPDATE_LOCATE_ME_SEARCH_PARAM_VALUE = 'UPDATE_LOCATE_ME_SEARCH_PARAM_VALUE'
export const UPDATE_SHOW_LOADER_STATUS = 'UPDATE_SHOW_LOADER_STATUS'
export const UPDATE_IS_CUSTOMER_LOCATION_CHANGED = 'UPDATE_IS_CUSTOMER_LOCATION_CHANGED'
export const UPDATE_ALERT_NO_STORE_STATUS = 'UPDATE_ALERT_NO_STORE_STATUS'
export const UPDATE_GEOLOCATION_STATUS = 'UPDATE_GEOLOCATION_STATUS'
export const ADDRESS_RNVP_CHECKED = 'ADDRESS_RNVP_CHECKED'
export const STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED = 'STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED'
export const DQE_LOCATION_FTECHED = 'DQE_LOCATION_FTECHED'
export const LOCATION_DATA_FETCHED = 'LOCATION_DATA_FETCHED'
// export const ZIPCODECITY_AUTOCOMPLETE = 'ZIPCODECITY_AUTOCOMPLETE'
// export const UPDATE_ADDRESS_STATE = 'UPDATE_ADDRESS_STATE'
export const DESABLE_DQE_AUTOCOMPLETE = 'DESABLE_DQE_AUTOCOMPLETE'
export const ADDRESS_CHECKED = 'ADDRESS_CHECKED'
// export const UPDATE_POPIN_DISPLAY = 'UPDATE_POPIN_DISPLAY'
export const UPDATE_ADDRESS_LATITUDE_LONGITUDE = 'UPDATE_ADDRESS_LATITUDE_LONGITUDE';
// export const UPDATE_LATITUDE_LONGITUDE_IN_STATE = 'UPDATE_LATITUDE_LONGITUDE_IN_STATE';
// export const UPDATE_LATITUDE_LONGITUDE_STATE = 'UPDATE_LATITUDE_LONGITUDE_STATE';
/* ####################### HeaderSearchBar  ########################## */
export const SEARCH_RESULTS_FETCHED = 'SEARCH_RESULTS_FETCHED';
export const TOP_SEARCHES_RESULTS_FETCHED = 'TOP_SEARCHES_RESULTS_FETCHED';
// export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SEARCH_STARTED = 'SEARCH_STARTED';
export const CHANGE_KEYWORD = 'CHANGE_KEYWORD';
// export const INCREMENT_REVIEWS_PAGE_SIZE = 'INCREMENT_REVIEWS_PAGE_SIZE';
//
// /* ####################### SHOP ################################# */
export const UPDATE_SHOPCIRCLE = 'UPDATE_SHOPCIRCLE';
// export const GET_PRODUCT_REVIEWS = 'GET_PRODUCT_REVIEWS';
// export const GET_CARAC_PRODUCT = 'GET_CARAC_PRODUCT';
// export const HANDLE_SHOP_OVERLAY = 'HANDLE_SHOP_OVERLAY';
// export const SELECT_SHOP =  'SELECT_SHOP';
// export const UPDATE_SHOW_MAP = 'UPDATE_SHOW_MAP';
// export const SEARCH_SHOP_RESULT_FETCHED = 'SEARCH_SHOP_RESULT_FETCHED';
// /* ####################### Tracking ########################### */
export const TAGGING_FETCHED = 'TAGGING_FETCHED';
// /* ####################### ProductSheet ########################### */
// export const OFFERS_DATA_FETCHED = 'OFFERS_DATA_FETCHED'
// export const FILTER_CHANGED = 'FILTER_CHANGED'
// export const CHANGE_DISPLAY_LISTING_FROM = 'CHANGE_DISPLAY_LISTING_FROM'
// export const UPDATE_QUESTION_FORM_DATA = 'UPDATE_QUESTION_FORM_DATA'
// export const UPDATE_SUBS_MENTIONS_FLAG = 'UPDATE_SUBS_MENTIONS_FLAG'
// export const INIT_QUESTION_DATA = 'INIT_QUESTION_DATA'
// export const QUESTIONS_DATA_SEND = 'QUESTIONS_DATA_SEND'
// export const QUESTIONS_DATA_FETCHED = 'QUESTIONS_DATA_FETCHED'
// export const REVIEWS_DATA_FETCHED = 'REVIEWS_DATA_FETCHED'
export const SUBSCRIBE_STOCK = 'SUBSCRIBE_STOCK'
export const CART_GLD_UPDATED = 'CART_GLD_UPDATED'
// export const HANDLE_EMAIL_CHANGE = 'HANDLE_EMAIL_CHANGE'
// export const HANDLE_ASK_QUESTION_POPIN = 'HANDLE_ASK_QUESTION_POPIN'
// export const HANDLE_STOCK_SUBSCRIPTION_POPIN = 'HANDLE_STOCK_SUBSCRIPTION_POPIN'
// export const HANDLE_STOCK_SUBSCRIPTION_SUCCESS_POPIN = 'HANDLE_STOCK_SUBSCRIPTION_SUCCESS_POPIN'
// export const UPDATE_SHOP_ID = 'UPDATE_SHOP_ID'
//

//
/* ####################### CART ################################# */
export const SHIPPING_CART_ITEMS_UPDATED = 'SHIPPING_CART_ITEMS_UPDATED';
export const LOAD_CART_API_RESPONSE = 'LOAD_CART_API_RESPONSE';
export const UPDATE_CREATION_FORM_DATA = 'UPDATE_CREATION_FORM_DATA';
export const UPDATE_CHANGE_QUANTITY_STATE = 'UPDATE_CHANGE_QUANTITY_STATE';
export const UPDATE_REDUCED_PRODUCT_SHEET = 'UPDATE_REDUCED_PRODUCT_SHEET';
export const DISCOUNT_CODE_APPLIED = 'DISCOUNT_CODE_APPLIED';
export const SET_ERROR_CART_MESSAGES = 'SET_ERROR_CART_MESSAGES';
/* ####################### SHIPPING ################################# */
export const PREPARE_CLICK_AND_COLLECT = 'PREPARE_CLICK_AND_COLLECT';
export const PROVIDER_TO_FETCH = 'PROVIDER_TO_FETCH';
export const RELAY_POINTS_FETCHED = 'RELAY_POINTS_FETCHED';
export const RELAY_POINT_SELECTED = 'RELAY_POINT_SELECTED';
export const RELAY_POINT_FETCHED = 'RELAY_POINT_FETCHED';
export const ERROR_CREATE_ORDER = 'ERROR_CREATE_ORDER';
export const REMOVE_DISCOUNT_CODE = 'REMOVE_DISCOUNT_CODE';
export const ERROR_SHIPPING_ADDRESS = 'ERROR_SHIPPING_ADDRESS';
export const ERROR_BILLING_ADDRESS = 'ERROR_BILLING_ADDRESS';
export const UPDATE_RELAY_POINT_DATA = 'UPDATE_RELAY_POINT_DATA';
export const SELECTED_DAY_DATE = 'SELECTED_DAY_DATE';
export const SELECTED_SCHEDULE_ID = 'SELECTED_SCHEDULE_ID';
export const SHIPPING_TRANSPORTERS_FETCHED = 'SHIPPING_TRANSPORTERS_FETCHED';

