import { CustomAction } from "@/models/common/core/CustomAction";
import { Reducer } from "redux";
import { ReviewsState } from "@/models/appState/ReviewsState";
import { ReviewsDataFetchedAction } from "@/models/scope/productSheet/reviews/ReviewsDataFetchedAction";
import { REVIEWS_DATA_FETCHED } from "@/redux/reducers/responsive/actionsTypes";

const initialState: ReviewsState = {
    productId: 0,
    pageIndex: 1,
    reviews: {
        reviewListStatistics: {
            countsByRate: {'0':'0'},
            maxRate: "0",
            positiveRecommandationTotal: 0,
            rateAverage: "0",
            recommandationsTotal: 0,
            total: "0"
        }, 
        items: []
    },
    changeFromClient: false
};

type ActionType = CustomAction<ReviewsDataFetchedAction>;

const reviewsReducer: Reducer<ReviewsState, ActionType> = (state = initialState, action) => {
    switch (action.type) {
        case REVIEWS_DATA_FETCHED:
            return {
                ...state,
                pageIndex: action.payload.pageIndex ,
                reviews: {
                    reviewListStatistics: action.payload.reviewListStatistics,
                    items: [...action.payload.productReviewsListItems, ...action.payload.items]
                },
                changeFromClient: action.payload.changeFromClient

            }
            
        default:
            return state;
    }
};

export default reviewsReducer;