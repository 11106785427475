import {Fragment, memo} from 'react'

const ShopOverlayHeader = (props) => {

    let urlFindShop = "/magasins/recherche-magasins";
    return (
        <Fragment>
            <div className="top-bar__store">
                <div className="top-bar__store__btn"></div>
                <div className="top-bar__store__title">
                    <span className="title">votre magasin</span>
                </div>
                <a href="#" className="top-bar__store__close" onClick={props.handleShopOverlay.bind(this,false)}>
                    <span className="icon-close_menu"></span>
                </a>
            </div>
            <div className="store-fiche">
                <div className="store-fiche__banner"
                     style={{background: 'url(' + props.shopAndWharehouseData.shopInfos.urlPicture + ') no-repeat 0 0'}}>
                    <span className="store-fiche__banner__title">{props.shopAndWharehouseData.shopInfos.name}</span>
                    <a href={urlFindShop} className="store-fiche__banner__link">Changer</a>
                </div>
            </div>
        </Fragment>
    )
}
export default ShopOverlayHeader;