import {Fragment} from 'react'

const Event = (props) => {


    function renderEvent() {
        let displayingInfos = props.event["displayingInfos"];
        let textEventDate = '';
        let textEventMobile = '';
        let receiptNumber = '';
        let receiptDate = '';
        let tplLinkMobileHP = '';
        let destinationLink = '';
        let eventSlogan = '';
        let startDateOfDisplay = '';
        let mobileText = props.event.displayText != "" ? props.event.displayText : (textEventMobile != "" ? textEventMobile : props.event['label']);;
        let code = displayingInfos.codes;
        let items = displayingInfos.items;

        code.map((childrenItem, i) => {
            if (childrenItem == 'textMobile') {
                textEventMobile = items[i].value;
            }
            if (childrenItem == 'tplLinkMobileHP') {
                tplLinkMobileHP = items[i].value;
            }
            if (childrenItem == 'urlDestinationPage') {
                destinationLink = items[i].value;
            }
            if (childrenItem == 'textEventDate') {
                textEventDate = items[i].value;
            }
            if (childrenItem == 'eventSlogan') {
                eventSlogan = items[i].value;
            }
            if (childrenItem == 'receiptNumber') {
                receiptNumber = items[i].value;
            }
            if (childrenItem == 'receiptDate') {
                receiptDate = items[i].value;
            }
            if (childrenItem == "startDateOfDisplay") {
                startDateOfDisplay = items[i].value;
            }
        });
        if (startDateOfDisplay <= getCurrentDate()) {
            return (
                <div className="store-fiche__event">
                    <span className="store-fiche__event__date" dangerouslySetInnerHTML={{__html: textEventDate}}/>
                    <div className="store-fiche__event__resume">
                        <div className="title"  dangerouslySetInnerHTML={{__html: mobileText}}></div>
                        {
                            (destinationLink.indexOf("magasins/" + props.shopAndWharehouseData.shopInfos.id) < 0) ? <a href={destinationLink} className="link">En savoir plus</a> : ''
                        }
                    </div>
                    {(eventSlogan) ? <span
                            className="store-fiche__event__info"> {eventSlogan}</span> :
                        (receiptNumber && receiptDate) ? <span
                            className="store-fiche__event__info"> Récépissé de déclaration n°V {receiptNumber} {receiptDate} </span> : null
                    }
                </div>
            );
        } else {
            return null
        }

    }
    function getCurrentDate() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        if(dd<10) {
            dd = '0'+dd
        }

        if(mm<10) {
            mm = '0'+mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        return (today);
    }
    return (
        <Fragment>{renderEvent()}</Fragment>
    )
}
export default Event;