import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {connect} from 'react-redux'
import TopBarNav from './TopBarNav'
import {Menu} from './Menu'
import {handleShopOverlay} from '@/redux/reducers/Mobile/shop/destockedProductListActions'
import {fetchCartData, fetchIdentity, fetchTopSearchesResults} from '@/redux/reducers/Mobile/header/headerActions'
import {UPDATE_POPIN_DISPLAY, MENUDATA_FETCHED} from '@/redux/reducers/Mobile/actionsTypes'
import Tracking from '../../Tracking'
import dynamic from 'next/dynamic'
import ShopPageOverlay from '../../ShopInfo/ShopPageOverlay'
import {CUSTOMER_AREA_PAGE, CUSTOMER_PAGE, MOBILE_TEMPLATE_VERSION} from "@/utils/constants";
import Utils from "@/utils/Default/Utils";
import {synchronizeShopCircle} from "@/redux/reducers/Default/header/headerActions";
import {fetchProductAvailabilities} from "@/redux/reducers/Mobile/product/productAvailabilityActions";
import {fetchRequiredCommonData} from "@/redux/reducers/responsive/common/CommonActions";

const DynamicComponentLocalisationModalWithNoSSR = dynamic(
    () => import('./LocalisationModal'),
    { ssr: false }
)

const DynamicComponentSearchBar = dynamic(() =>
    import('./SearchBar')
)

const Header = (props) => {

    const [openMenu, setOpenMenu] = useState(false)
    const [searchTextField, setSearchTextField] = useState(false)

    const {
        isPrivateSale, getCartData, fetchIdentity,
        context,
        shopId,
        shopCircleVersion,
        userData,
        cartProductsTotalQuantity,
        shopName,
        menuItems,
        menuItemsLevels,
        shopUrl,
        title,
        headerTrackingFlow,
        googleMapsKey,
        headerTrackingScopeData,
        handleShopOverlay,
        fetchTopSearchesResults,
        updatePopinDisplay,
        isDQENormalisationActive,
        currentPage,
        currentFiltersValue,
        categoryId,
        currentSort,
        productId,
        logoVersion,
        userConnected,
        fetchRequiredCommon,
        ABTastyCookieIsActive
    } = props
    const productListParams = useMemo(() => {
        return {
            currentFiltersValue: currentFiltersValue,
            currentSort: currentSort,
            categoryId: categoryId
        }
    }, [currentFiltersValue, currentSort, categoryId])
    useEffect(() => {
        if (context !== CUSTOMER_PAGE) {
            fetchIdentity(getCartData, isPrivateSale)
            if(Utils.getCookieValue('TC_PRIVACY') == null) {
                document.addEventListener("DOMNodeInserted", function (e) {
                    if (e.target.id == "tc-privacy-wrapper" && typeof __tcfapi !== 'undefined') {
                        __tcfapi('addEventListener', 2, function (tcData, success) {
                            if (success && tcData.eventStatus === 'useractioncomplete') {
                                if(Utils.checkCookies(3) && (Utils.getCookieValue('passivegeoloc') == 'true' || !ABTastyCookieIsActive)) {
                                    synchronizeShopCircle(shopCircleVersion).then(() =>  {
                                        fetchIdentity(getCartData, isPrivateSale)

                                    });
                                }
                            }
                        }.bind(this));
                    }
                }.bind(this))
            }
        }
        // PARCEQUE CUSTOMER_AREA_PAGE NOT SSR
        // TODO check the good device
        if (context == CUSTOMER_PAGE) {
            fetchRequiredCommon(MOBILE_TEMPLATE_VERSION, currentPage)
        }
    }, [])
    const handleOpenMenu = useCallback((open) => {
        setOpenMenu(open)
        if (typeof window === 'object') {
            let triggerAction = (open) ? "Open" : "Close"
            appData.actionTrigger.dispatch({
                trigger: "openCloseMenu",
                payload: {
                    action: triggerAction,
                }
            });
            //Required to prevent the page to scroll behind the menu
            open
                ? document.querySelector("body").classList.add("menu-open")
                : document.querySelector("body").classList.remove("menu-open")
        }
    }, [])

    let menuItemsLevelsData = typeof menuItemsLevels == 'undefined' ? [] : menuItemsLevels

    return (
        <>
            <div id="header-content">
                <TopBarNav title={title}
                           shopId={shopId}
                           userData={userData}
                           cartTotalQuantity={cartProductsTotalQuantity}
                           context={context}
                           logoVersion={logoVersion}
                           fetchTopSearchesResults={fetchTopSearchesResults}
                           handleShopOverlay={handleShopOverlay}
                           updatePopinDisplay={updatePopinDisplay}
                           setSearchTextField={setSearchTextField}
                           searchTextField={searchTextField}
                           setOpenMenu={handleOpenMenu}
                           userConnected={userConnected}
                />
                <Menu menuItems={menuItems} shopId={shopId} shopUrl={shopUrl} shopName={shopName}
                      userData={userData} cartTotalQuantity={cartProductsTotalQuantity}
                      openMenu={openMenu} setOpenMenu={handleOpenMenu} menuItemsLevel={menuItemsLevelsData}
                />
                { searchTextField &&
                    <DynamicComponentSearchBar searchTextField={searchTextField} setSearchTextField={setSearchTextField}/>
                }

                {context !== "storeLocator" && shopId &&
                    <div className="frz_nocache">
                        <ShopPageOverlay shopId={shopId}
                                         googleMapsKey={googleMapsKey}/>
                    </div>
                }
                {context !== CUSTOMER_AREA_PAGE &&
                    <DynamicComponentLocalisationModalWithNoSSR context={"headerLocationModal"}
                                                                productId={productId}
                                                                currentPage={currentPage}
                                                                isDQENormalisationActive={isDQENormalisationActive}
                                                                dataBtnSource={"sourceHeader"}
                                                                productListParams={productListParams}
                    />}
            </div>
            {headerTrackingScopeData && <Tracking trackingData={headerTrackingScopeData}/>}
            {headerTrackingScopeData && <Tracking trackingData={headerTrackingFlow}/>}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        cartProductsTotalQuantity: state.header.cartProductsTotalQuantity,
        shopId: state.header.shopId,
        userData: state.header?.userData,
        shopName: state.header.shopName,
        shopUrl: state.header.shopUrl,
        menuItems: ownProps.menuItems,
        logoVersion: ownProps.logoVersion,
        menuItemsLevels: ownProps.menuItemsLevels,
        googleMapsKey: ownProps.googleMapsKey ? ownProps.googleMapsKey : state.requiredCommonData.googleMapsKey,
        wordingVersion: ownProps.wordingVersion?? state.requiredCommonData.wordingVersion,
        headerTrackingFlow: state.tracking.headerTrackingFlow,
        context: ownProps.context ? ownProps.context : 'standard',
        categoryId: ownProps.categoryId ? ownProps.categoryId : null,
        currentPage: ownProps.currentPage ? ownProps.currentPage : '',
        productId: ownProps.productId ? ownProps.productId : null,
        headerTrackingScopeData: ownProps.headerTrackingScopeData?? state.requiredCommonData.headerTrackingScopeData,
        title: ownProps.title ? ownProps.title : null,
        userConnected: (state.header?.userData?.group === 'WebCustomers' || state.header?.userData?.group === 'WebCustomersViaCallCenter') ? true : false,
        currentSort:  state?.productList?.changeFromClient ? state.productList.productsListData.currentSort : ownProps.currentSort ? ownProps.currentSort : null ,
        currentFiltersValue: state?.blockFilter && state.blockFilter?.currentFiltersValue ? state.blockFilter.currentFiltersValue : null,
        isPrivateSale:(ownProps.isPrivateSale) ? ownProps.isPrivateSale : false,
        isDQENormalisationActive:ownProps.isDQENormalisationActive?? state.requiredCommonData.isDQENormalisationActive
    };
};
const mapDispatchToProps = dispatch => {
        return {
            handleShopOverlay: (state) => dispatch(handleShopOverlay(state)),
            getCartData: () => dispatch(fetchCartData()),
            fetchIdentity: (callback,isPrivateSale) => dispatch(fetchIdentity(callback,isPrivateSale)),
            fetchTopSearchesResults: () => dispatch(fetchTopSearchesResults()),
            fetchRequiredCommon: (templateVersion, context) => dispatch(fetchRequiredCommonData(templateVersion, context)),
            fetchMenuData: (menuItems) => dispatch({
                type: MENUDATA_FETCHED,
                payload: {
                    menuItems: menuItems,
                }
            }),
            updatePopinDisplay: (value) => dispatch({
                type: UPDATE_POPIN_DISPLAY,
                geoPopin: value
            }),
            getProductAvailabilities: (productId) => dispatch(fetchProductAvailabilities({
                didShopChanged: false,
                allShops: null,
                productId: productId,
                contexte: 'ProductAvailability'
            }))


        }
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
