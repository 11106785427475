import $ from 'jquery'
import decounterPictoSettings from "@/utils/Mobile/decounterPictoSettings"
import {isConsentment} from "@/utils/Mobile/utils"

export const closeFilterNavigation = () => {
    $('.filter').fadeOut(400, function () {
    })
}
export const scrollToDivAnimation = (divId) => {
    var nav = $('#' + divId);
    if (nav.length > 0) {
        $('html, body').animate({
            scrollTop: nav.offset().top - 100
        }, 'slow');
    }
}
export const hasTimerInPictoFalshSale = (selector) => {
    return $(selector).attr('data-time');
}
export const initTargetToSell = (currentPage, t2SellParams) => {
    appData = appData || {};
    appData.currentPage =  currentPage;
    appData.wordingVersion =  t2SellParams.wordingVersion;
    appData.userGroup =  t2SellParams.userGroup;
    appData.webUserId =  t2SellParams.webUserId;
    appData.consentmentValue =  isConsentment();
    if (currentPage === 'ProductsList') {
        appData.itemList =  t2SellParams.itemList;
        appData.productlistLevels =  t2SellParams.levels;
    } else if (currentPage === 'productSheet') {
        appData.productId =  t2SellParams.productId;
    }
}

export const setTimerForFlashSale = () => {
    $('[id^=clockdiv-]').each(function (i, obj) {
        let clockId = $(this).attr("id");
        if (hasTimerInPictoFalshSale("#" + clockId)) {
            let dateTimer = hasTimerInPictoFalshSale("#" + clockId)
            let dateTimeSplited = dateTimer.split(" ");
            let dateSplited = dateTimeSplited[0].split("-");
            let timeSplited = dateTimeSplited[1].split(":");
            const deadline = new Date(dateSplited[0], dateSplited[1] - 1, dateSplited[2], timeSplited[0], timeSplited[1], timeSplited[2]);// gestion du délais jours * heures * minutes * secondes
            decounterPictoSettings.initializeClock(clockId, deadline);
        }
    });
}

export const toggelZipCityShopListErrorStyle = (hide) => {
    if (hide) {
        $("#zipCityShopList").removeClass('error')
    } else {
        $("#zipCityShopList").addClass('error')
    }
}

export const triggerAvailabilityShopFilter = (eventName, filter_fields = '') => {
    appData.actionTrigger.dispatch({
        trigger: "AddAvailabilityShopIdListFilter",
        payload: {
            eventName: eventName,
            filter_fields: filter_fields
        }
    });
}
export const activeShowPreviousPage = (isActive) => {
    $('#seePreviousProductsBtn').css('pointer-events', isActive ? 'visible' : 'none');
}
export const triggerAppliedFilters = (filterFields) => {
    appData.actionTrigger.dispatch({
        trigger: "AppliedFilters",
        payload: {
            eventName: 'filter_add',
            filterFields: filterFields
        }
    });
}
export const triggerRemoveAppliedFilters = (filterFields) => {
    appData.actionTrigger.dispatch({
        trigger: "removeFilters",
        payload: {
            eventName: 'filter_delete',
            filterFields: filterFields
        }
    });
}
export const triggerRemoveAllAppliedFilters = () => {
    appData.actionTrigger.dispatch({
        trigger: "removeAllFilters",
        payload: {
            eventName: 'Tout effacer',
        }
    });
}