import {displayLocateShopError} from "./shopPage";

export const completeNormalizationStatusValue = (addressData) => {

    for (let addressInput in addressData) {
        if (addressData[addressInput].id) {

            $("#" + addressData[addressInput].id).on("autocompleteselect", function (event, ui) {
                if (addressInput == 'zipCode' || addressInput == 'city') {
                    $("#" + addressData['city'].id).attr('data-normalizationstatus', 1).parent('.row').removeClass('error').addClass('valid');
                    $("#" + addressData['zipCode'].id).attr('data-normalizationstatus', 1).parent('.row').removeClass('error').addClass('valid');
                }
                if (typeof addressData[addressInput].updateStateFunction === 'function') {
                    addressData[addressInput].updateStateFunction(ui.item.value);
                }
                event.target.dataset.normalizationstatus = 1;
                $("#" + addressData.type + "_normalisation").val(1);
                $("#" + addressData[addressInput].id).parent('.row').removeClass('error').addClass('valid');

            });
            $(window).on("custom-autocompleteselect" , function (e, data, ui) {
                if (addressInput === "street") {
                    if(data.target.id === "billingAddress") {
                        var truncatedAdress = ui.item.label.substr(0,30)
                        $("#billingAddress").data('originalStreetAddress',ui.item.label)
                    } else if(data.target.id === "shipping_address_street") {
                        $("#shipping_address_street").data('originalStreetAddress',ui.item.label)
                        var truncatedAdress = ui.item.label.substr(0,30);

                    }
                }
            })

        }
    }
}

export const isNormalizedBillingAddress = () => {

    let isNormalized = true;
    $.each($("#billing_address :input"), function () {
        if (["billingAddress_zipCode", "billingAddress_city", "billingCity", "billingZipCode", "billing_streetAddress", "billingStreetAddress"].indexOf($(this).attr('name')) != -1 && $(this).attr("data-normalizationStatus") == 0) {
            isNormalized = false;
            $(this).parent().parent().removeClass('field__row--valid').addClass('field__row--error');
        }
        if (isNormalized == false && ["billing_streetAddress", "billingStreetAddress"].indexOf($(this).attr('name')) != -1) {
            $(this).parent().parent().parent().find('.display-error').html('Nous n\'avons pas pu mettre aux normes postales votre adresse. Veuillez la vérifier ').show();
        }
    });
    return isNormalized;


}
export const isNormalizedShippingAddress = () => {

    let isNormalized = true;
    $.each($("#shipping_address :input"), function () {
        if (["shippingAddress_zipCode", "shippingZipCode", "shippingAddress_city", "shippingCity", "shipping_streetAddress", "shippingStreetAddress"].indexOf($(this).attr('name')) != -1 && $(this).attr("data-normalizationStatus") == 0) {
            isNormalized = false;
            $(this).parent().parent().removeClass('field__row--valid').addClass('field__row--error');
        }
        if (isNormalized == false && ["shipping_streetAddress", "shippingStreetAddress"].indexOf($(this).attr('name')) != -1) {
            $(this).parent().parent().parent().find('.display-error').html('Nous n\'avons pas pu mettre aux normes postales votre adresse. Veuillez la vérifier ').show();
        }
    });
    return isNormalized;

}
export const autoCompleteOff = (id) => {
    $("#" + id).attr("autoComplete", "new-" + id);
}
export const fillInChosenNormalizedAddress = (normAddress, addressType, addressNormalizationChoice, context) => {
    if (context === "updateAccount" || context === "shipping") {
        $("input[name*='" + addressType + "ZipCode']").val(normAddress.zip);
        $("input[name*='" + addressType + "City']").val(normAddress.city);
        $("input[name*='" + addressType + "StreetAddress']").val(normAddress.street);
        $("input[name*='" + addressType + "StreetAddressComplement']").val(normAddress.compl);
    } else {
        $("input[name*='" + addressType + "_zipCode']").val(normAddress.zip);
        $("input[name*='" + addressType + "_city']").val(normAddress.city);
        $("input[name*='" + addressType + "_streetAddress']").val(normAddress.street);
        $("#" + addressType + "Adress_streetAddressComplement").val(normAddress.compl);
    }
};
export const changeDataNormalizationstatus = (addressType, addressNormalizationChoice, context) => {
    if (addressType === 'billing') {
        $("#zipCode").attr('data-normalizationstatus', 1);
        $("#billingCity").attr('data-normalizationstatus', 1);
        if (context === "shipping")
            $("#billingAddress").attr('data-normalizationstatus', (addressNormalizationChoice === 'existingAddress') ? 2 : 1);
        else
            $("#billingAddress").attr('data-normalizationstatus', 1);
    } else {
        $("#shipping_address_zipcode").attr('data-normalizationstatus', 1);
        $("#shipping_address_city").attr('data-normalizationstatus', 1);
        if (context === "shipping")
            $("#shipping_address_street").attr('data-normalizationstatus', (addressNormalizationChoice === 'existingAddress') ? 2 : 1);
        else
            $("#shipping_address_street").attr('data-normalizationstatus', 1);
    }
}
export const getNormalizedStatus = (elementId) => {
    return $(elementId).attr('data-normalizationstatus');
}
export const setNormalizedStatus = (elementId, value) => {
    $(elementId).attr('data-normalizationstatus', value);
}
export const isNormalizedBillingZipCodeAndCity = () => {
    return $("#zipCode").attr("data-normalizationStatus") === '1' && $("#billingCity").attr("data-normalizationStatus") === '1'
}
export const isNormalizedShippingZipCodeAndCity = (billingAddresssIsShipping) => {
    return $("#shipping_address_zipcode").attr("data-normalizationStatus") === '1' && $("#shipping_address_city").attr("data-normalizationStatus") === '1' && billingAddresssIsShipping === 0
}
export const showLocalisationErrorMessage = (context) => {
        if (['locateShopResult', 'locateShop'].includes(context) || (['locateShopResult', 'locateShop'].includes(context) && isDomTomZipCode($('#zip').val()))) {
            displayLocateShopError('showError')
        } else if ($('#inseeCode').val() == "" && !context == 'stockLocator') {
            $('.display-error').show();
        }
}
export const hideLocalisationErrorMessage = () => {
    $('.display-error').hide();
}
export const setNormalizationStatus = (formId) => {
    $.each($("#" + formId + " :input"), function (i, field) {
            $(this).attr("data-normalizationStatus", 1)
        }
    );
}
export const removeZipCodeInuptValue = (id) => {
    $('#' + id).val('');
}
export const hideJqueryHelper = (ClassName) => {
    $("." + ClassName).remove();
}
export const truncateAddressInput = (inputId) => {
    var truncatedAddress = $("#" + inputId).val().substr(0,30);
    $("#" + inputId).val(truncatedAddress);
    $("#" + inputId).trigger("focusout");
}

export const hideErrorMessage = (id) => {
    $('.' + id).hide();
}
export const getElementValue = (element) => {
    return $("#" + element).val();
}

export const getInputElementValue = (element) => {
    return $(element).val()
}
export const resetInputElementValue = (element) => {
    $(element).bind('click', function(event, arg) {
        if (typeof arg === 'undefined') {
            $(element).val('');
        }
    });
}
export const setProviderLocationData = (adrLat, adrLong) => {
    $(adrLat).val($('#adrLat').val())
    $(adrLong).val($('#adrLong').val())
}
export const isDomTomZipCode = (zipCode) => {
    if ((/9[7-8][0-9]{3}/).test(zipCode)) {
        return true;
    }
    return false;
}

export const dispatchGtmAction = (label) => {
    let action = ''
    if (window.location.href.indexOf("recherche-magasins") > -1) {
        action = 'Store Locator_Home'
    } else if(window.location.href.indexOf("resultats-magasins") > 1) {
        action = 'Store Locator_Resultats'
    } else {
        action = 'Store Locator_RegionDepartement'
    }
    appData.actionTrigger.dispatch({
        trigger: "SearchDqeHandleClick",
        payload: {
            label : label,
            action : action
        }
    });
}

export const hideAutoCompleteBlock = (className) => {
    $(className).hide()
}

export const resetElementValue = (element) => {
    return $("#" + element).val('');
}

