import * as Sentry from '@sentry/nextjs';
export const  filterDuplicateErrors = (event) => {
    try {
        if (!event || typeof event !== 'object' || !event.event_id) {
            // Return null or handle the case when the event is missing or doesn't have event_id
            return null;
        }
        const hub = Sentry.getCurrentHub();
        const sentryEvents = (Sentry?.Integrations?.Dedupe !== null) ? Sentry.Integrations.Dedupe : null;
        // Check if the event has already been captured by Sentry
        if (sentryEvents !== null && hub && hub.getIntegration(sentryEvents) !== null) {
            const eventId = event.event_id;
            const previousEventId = (hub.getIntegration(sentryEvents)?._previousEvent?.event_id) ? hub.getIntegration(sentryEvents)._previousEvent.event_id : null;
            if (eventId === previousEventId) {
                // Return null to filter out duplicate events
                return null;
            }
        }
        // Return the event to capture it in Sentry
        return event;
    } catch (error) {
        // Handle the exception here or log it for debugging purposes
        console.error('An error occurred:', error);
        return null; // Return null or some other value to indicate failure, depending on your use case
    }

}
