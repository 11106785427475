export const initSlickCarousel = () => {
    $('#main-header__infobar').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        dots: false,
        infinite: true,
        speed: 600,
        pauseOnHover: true,
        prevArrow: null,
        nextArrow: null
    });
}