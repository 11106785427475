import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const clickRedirectionItemMenu = (event, link, redirectionUrl) => {
    event.preventDefault();
    appData.actionTrigger.dispatch({
        trigger: "clickMatchedLinkMenu",
        payload: {
            link: link.toLowerCase()
        },
        resolveCallback: function () {
            console.log('debug', redirectionUrl)
            window.location.href = redirectionUrl;
        }
    });
}
export const NextLevelMenuItems = (props) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(props.item[11]);
    }, [])

    const handleRemove = (e) => {
        e.preventDefault();
        setShow(false);
        setTimeout(props.removeLevel, 500)
        return false;
    }
    const addLevel = (event, child) => {
        event.preventDefault();
        props.addLevel(child, props.item[0] + '/' + child[0])
    }
    const renderChild = (child, j) => {
        return child[7].length ?
            (<li key={j}>
                    <a href={child[3]}
                       className="level__btn gtm-menu-rubrique"
                       onClick={(e) => addLevel(e, child)}
                       dangerouslySetInnerHTML={{__html: (child[1] !='' ? child[1] + '<span class="icon-chevron gtm-menu-rubrique"/>' : child[0] + '<span class="icon-chevron gtm-menu-rubrique"/>')}}/>
                </li>
            ) :
            (<li key={props.index}>
                <a
                href={child[3]}
                className="level__btn gtm-menu-rubrique"
                dangerouslySetInnerHTML={{__html: child[1] != '' ? child[1] : child[0]}}
                onClick={(event)=>{clickRedirectionItemMenu(event, props.menuHierarchy.join('/') + '/' + child[0], child[3])}}/>
            </li>)
    }

    return (
        <div className={`level ${show && 'level--open'}`} style={{zIndex: props.level}}>
            <a className="level__back"
               onClick={handleRemove}
               href={props.item[3]}
            ><span
                className="level__back__icone icon-back"/><span>{props.item[0]}</span></a>
            <div className="level__container">
                <ul>
                    {props.item[7].map((childrenItem, i) => {
                        if (childrenItem[0].startsWith('Colonne')) {
                            return childrenItem[7].map(renderChild)
                        } else {
                            return renderChild(childrenItem, i)
                        }
                    })}
                </ul>
            </div>
        </div>
    )
}

NextLevelMenuItems.propTypes = {
    menuHierarchy: PropTypes.array,
    item: PropTypes.object,
    key: PropTypes.number,
    level: PropTypes.number,
};

NextLevelMenuItems.defaultProps = {
    menuHierarchy: [],
    item: {},
    key: 0,
    level: 2,
};