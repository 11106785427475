import ApiCaller from './ApiCaller/apiCaller'
import ApiCallerSSR from './ApiCaller/apiCallerSSR'
import Utils from "@/utils/Default/Utils";
import {IDENTITY_FETCHED} from "@/redux/reducers/Mobile/actionsTypes";
export const handleToken = () => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("WebUsers/Identity")
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', "WebUsers/Identity Api responded failed: " + error)
                reject(error)
            });
    });
};
export const dispatchIdentityData = (response) => {
    return (dispatch) => {
        if (response) {
            dispatch({
                type: IDENTITY_FETCHED,
                payload: {
                    webUserId: response.userId,
                    navigationToken: response.token,
                    timeToDisconnect: response.timeToDisconnect,
                    userData: response,
                    hasShop: response.hasShop,
                    shopId: (response.hasShop) ? response.primaryShopId : '',
                    shopName: (response.hasShop) ? response.primaryShopName : '',
                    shopUrl: (response.hasShop) ? response.primaryShopUrl : '',
                }
            });
        }
    };
};
export const fetchIdentity = (navigationToken, navigationTokenExTime) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("WebUsers/Identity")
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', "WebUsers/Identity  Api responded failed : " + error)
                reject(error)
            });
    });
}
export const getCustomerInfos = (userId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Customers/" + userId + "/FullData")
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    Utils.redirectTo("/Customer/Account/Identification")
                }
                console.error('ERROR', "/Api/Rest/Customers/{id}/Profile Api responded failed : " + error)
                reject(error)
            });
    });
};
export const getCustomerAddressInfo = (userId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Customers/" + userId + "/Addresses")
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    Utils.redirectTo("/Customer/Account/Identification")
                }
                console.error('ERROR', "/Api/Rest/Customers/{id}/Profile Api responded failed : " + error)
                reject(error)
            });
    });
};
export const getNewslettersOption = (userId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Customers/" + userId + "/NewslettersOptin")
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    Utils.redirectTo("/Customer/Account/Identification")
                }
                console.error('ERROR', "/Api/Rest/Customers/{id}/NewslettersOptin Api responded failed : " + error)
                reject(error)
            });
    });
};
export const updateCustomerNewslettersOption = (userId, data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put("/Customers/" + userId + "/NewslettersOptin", data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    Utils.redirectTo("/Customer/Account/Identification")
                }
                console.error('ERROR', "/Api/Rest/Customers/{id}/NewslettersOptin Api responded failed : " + error)
                reject(error)
            });
    });
};


export const isEmailExist=(email)=> {
    return new Promise((resolve, reject) => {
        ApiCaller.post("Customers/Email", {'email': email})
            .then((response) => {
                resolve(response);})
            .catch((error) => {
                reject(error)
            });
    });
};
export const updateCustomersInfos =  (userId, data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put("/Customers/" + userId + "/Infos", data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
                console.error('ERROR', "/Api/Rest/Customers/{id}/Info Api responded failed : " + error)
            });
    })
};
export const updateCustomerIdentifier = (userId, data, hint) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put("/Customers/" + userId + "/Identifier", {'identifier': data, 'hint': hint})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    })
};
export const sendChangePasswordRequest = (userId, password) => {
    const  url = '/Customers/'+ userId + '/Password'
    const data = {password: password}
    return new Promise((resolve, reject) => {
        ApiCaller.put(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error)
            });
    });
};