import {APPLYFILTERSBLOCK,APPLYFILTERS,APPLYSORT,RESETFILTERS,HANDLENAVIGATION,CURRENTFILTERSVALUE,FETCHITEMFROMNAVIGATION,SHOW_ATTACHMENT_BLOCK} from '../actionsTypes'

const initialState = {
    currentFiltersValue : {},
    showGeoErrorBloc: false,
    showAttachmentBlock: false,
    availabilityShopIdListFilter : 'AvailabilityShopIdList'
};


const blockFilterReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case CURRENTFILTERSVALUE:
            return {
                ...state,
                currentFiltersValue : action.payload.currentFiltersValue
            }
        case APPLYFILTERS:
            return {
                ...state,
                productsListData : action.payload.productsListData,
                filters : action.payload.filters,
                itemsCount : action.payload.itemsCount,
                appliedFiltersValues : action.payload.appliedFiltersValues,
                currentFiltersValue : action.payload.currentFiltersValue
            }
        case APPLYFILTERSBLOCK:
            return {
                ...state,
                productsListData : action.payload.productsListData,
                filters : action.payload.filters,
                itemsCount : action.payload.itemsCount,
                appliedFiltersValues : action.payload.appliedFiltersValues,
                currentFiltersValue : action.payload.currentFiltersValue
            }
        case APPLYSORT:
            return {
                ...state,
                filters : action.payload.filters,
                itemsCount : action.payload.itemsCount,
                currentFiltersValue : action.payload.currentFiltersValue,
                productsListData : action.payload.productsListData,

            }
        case RESETFILTERS:
            return {
                ...state,
                filters : action.payload.filters,
                productsListData : action.payload.productsListData,
                currentFiltersValue : action.payload.currentFiltersValue,
                appliedFiltersValues : action.payload.currentFiltersValue,
                itemsCount : action.payload.itemsCount,
            }
        case HANDLENAVIGATION:
            return {
                ...state,
                productsListData : action.payload.productsListData,
            }
        case FETCHITEMFROMNAVIGATION:
            return {
                ...state,
                productsListData : action.payload.productsListData,
            }
        case SHOW_ATTACHMENT_BLOCK :
            return {
                ...state,
                showAttachmentBlock: action.showAttachmentBlock
            }
        default:
            return state;
    }

};
export default blockFilterReducer;