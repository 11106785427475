import {CustomAction} from "@/models/common/core/CustomAction";
import {QuestionsDataFetchedAction} from "@/models/scope/productSheet/questions/QuestionsDataFetchedAction";
import {Reducer} from "redux";
import {QuestionsState} from "@/models/appState/QuestionsState";
import {
    HANDLE_ASK_QUESTION_LOADER,
    QUESTIONS_DATA_FETCHED,
    QUESTIONS_DATA_SEND
} from "@/redux/reducers/responsive/actionsTypes";

const initialState: QuestionsState = {
    content: '',
    pseudo: '',
    email: '',
    subsMentions: 0,
    successSend: false,
    message: '',
    pageIndex: 2,
    items: [],
    changeQuestionsFromClient: false,
    summary: '',
    isPending:false

};
type ActionType = CustomAction<QuestionsDataFetchedAction>;
const questionsReducer: Reducer<QuestionsState, ActionType> = (state = initialState, action) => {
    switch (action.type) {
        case QUESTIONS_DATA_FETCHED:
            return {
                ...state,
                pageIndex: state.pageIndex + 1,
                summary: action.payload.summary,
                items: [...action.payload.reviewQuestionsItems, ...action.payload.items],
                changeQuestionsFromClient: action.payload.changeQuestionsFromClient
            }
        case QUESTIONS_DATA_SEND:
            return {
                ...state,
                successSend: action.payload.success,
                message: action.payload.message,
                isPending: false
            }
        case HANDLE_ASK_QUESTION_LOADER:
            return {
                ...state,
                isPending: true
            }
        default:
            return state;
    }
};
export default questionsReducer;