import React from 'react';

export const LastLignes = () => {

    return (
        <div id="legals_infos">
            <ul>
                <li>
                    <a href="https://www.fevad.com/structure-et-organisation/" target="_blank"
                       title="Charte qualité fevad">
                        <img src="https://media.but.fr/animations/images/footer/footer-but-fevad.png"
                             alt="Charte qualité fevad" width={35} height={34}/>
                        <p>
                            But adhère au système de médiation du e-commerce.
                        </p>
                    </a>
                </li>
                <li>
                    <p>
                        Tous droits réservés
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="37.736" height="37.745" viewBox="0 0 37.736 37.745">
                        <title>BUT</title>
                        <rect width="37.736" height="37.745" transform="translate(0 0)" fill="#d9000d"/>
                        <g transform="translate(5.449 13.827)">
                            <path
                                d="M884.285,498.458h-4.274V488.449h4.665c1.524,0,2.763.616,2.763,2.455a2.157,2.157,0,0,1-1.557,2.213,2.366,2.366,0,0,1,1.935,2.255c0,1.822-1.135,3.086-3.533,3.086m-1.567-8.3v2.161h1.232c.745,0,1-.538,1-1.179s-.311-.982-1.039-.982Zm1.156,4.11h-1.156v2.484h1.191c.789,0,1.12-.52,1.12-1.295,0-.631-.195-1.189-1.156-1.189"
                                transform="translate(-880.011 -488.449)" fill="#fff"/>
                            <path d="M952.322,491.089h-2.474v7.718h-2.768v-7.718H944.6V489h7.718Z"
                                  transform="translate(-925.491 -488.835)" fill="#fff"/>
                            <path
                                d="M917.656,488.826v6.708c0,.9-.276,1.447-1.24,1.447s-1.244-.543-1.244-1.447v-6.708h-2.749V495.6c0,1.314.122,1.555.518,2.091.643.805,1.79,1.11,3.475,1.11s2.829-.3,3.47-1.11c.4-.536.519-.777.519-2.091v-6.778Z"
                                transform="translate(-902.832 -488.714)" fill="#fff"/>
                        </g>
                    </svg>
                </li>
            </ul>
        </div>
    )
};
export default LastLignes;