import {SEARCH_STARTED,SEARCH_RESULTS_FETCHED,CLEAR_SEARCH_RESULTS,CHANGE_KEYWORD,TOP_SEARCHES_RESULTS_FETCHED} from '../actionsTypes'

const initialState = {
    keywords : "",
    results : {},
    keywordChanged : false,
    searchStart: false,
    topSearchesResults : null
};


const searchBarReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case  SEARCH_STARTED:
            return {
                ...state,
                searchStart : true
            }

        case  SEARCH_RESULTS_FETCHED:
            return {
                ...state,
                keywords : action.payload.keywords,
                results : action.payload.searchResults
            }
        case TOP_SEARCHES_RESULTS_FETCHED:
            return {
                ...state,
                topSearchesResults : action.payload.topSearchesResults,
            }

        case  CLEAR_SEARCH_RESULTS:
            return initialState

        case  CHANGE_KEYWORD:
            return {
                ...state,
                keywords : action.keywords,
                keywordChanged : true,
            }
    }
    return state;
};

export default searchBarReducer;