import React, {Fragment, memo, useEffect, useState} from 'react';
import HeaderCart from './HeaderCart';
import HeaderStoreLocator from './HeaderStoreLocator';
import ClientAccount from "./ClientAccount";
import ScannerButton from "@/components/responsive/Scanner/ScannerButton";
import {useSelector} from "react-redux";
import {checkEligibilityFromLocalStorage} from "@/utils/Mobile/utils";
import SearchBarInput from "./SearchBarInput";

const TopBarNavContent = (props) => {
    return (
        <div className="top-bar__nav" id="topBarNav">
            <div className="top-bar__nav__content">
                <a href="#" onClick={() => props.setOpenMenu(true)} className="gtm-header top-bar__nav__left open-nav-btn">
                    <span className="icon-burger" /><span>Menu</span></a>
                {props.context === "storeLocatorShop" ?
                    <div className="top-bar__nav__middle w-100">
                        <div className="row align-items-center">
                            <div className="col text-center">
                                <span className="title">Trouver mon magasin <span className="red">BUT</span></span>
                            </div>
                        </div>
                    </div> :
                    <div className="top-bar__nav__middle">
                        <div className="col text-center">
                            {props.title ? <span className="title">{props.title}</span> :
                                <a className="gtm-header top-bar__nav__logo" href="/" width="7.2rem" height="3rem"
                                   data-gtm-libelle="home">
                                    <img alt="BUT" className="gtm-header" data-gtm-libelle="home" data-name="Fond 1"
                                         src={"https://media.but.fr/Sources/MobileV2/Images/Header/logo-but.svg?v=" + props.logoVersion}/>
                                </a>}
                        </div>
                    </div>}
                <div className="top-bar__nav__right" data-gtm-libelle="panier">
                    {renderTopBar(props)}
                </div>
            </div>
        </div>
    )
};
const renderTopBar = (props) => {
    return (<Fragment>
        <div className="row align-items-center">
            <HeaderStoreLocator shopId={props.shopId}
                                handleShopOverlay={props.handleShopOverlay}
                                updatePopinDisplay={props.updatePopinDisplay}
                                hasShop={props.hasShop}
            />
            <ClientAccount userConnected={props.userConnected} userId={props?.userData?.userId}/>
            <HeaderCart totalQuantity={props.cartTotalQuantity}/>
        </div>
    </Fragment>)
}

const SearchBar = memo((props) => {
    const [isEligible, setIsEligible] = useState(false)
    const shopId = useSelector(store => store.header?.shopId)
    useEffect(() => {
        setIsEligible(checkEligibilityFromLocalStorage())
    }, [shopId])
    return (
        <Fragment>
            <a href="#" data-gtm-libelle="ouvrir"
               onClick={() => props.setOpenMenu(true)}
               className={`top-bar__nav-btn__left open-nav-btn gtm-menu ${isEligible ? 'withScanButton' : ''}`}>
               <span className="icon-burger"/><span>Menu</span></a>
            <SearchBarInput context={props.context} setSearchTextField = {props.setSearchTextField} isEligible={isEligible} />
            {isEligible && <ScannerButton isIconOnly={true}/>}
            <div className="top-bar__nav-btn__right text-right" data-gtm-libelle="panier">
                <HeaderCart totalQuantity={props.cartTotalQuantity}/>
            </div>
        </Fragment>
    )
});


export const TopBarNav = memo(({context, userData, cartTotalQuantity, shopId, title, handleShopOverlay, updatePopinDisplay,
                              fetchTopSearchesResults,setSearchTextField,searchTextField,setOpenMenu , logoVersion, hasShop, userConnected}) => {
    //todo hasShop in props  a voir pkoi
    const [collapse, setCollapse] = useState(0);
    useEffect(() => {
        const topBarNav = document.querySelector("#topBarNav");
        const topBar = document.querySelector("#topBar");
        const filterBtns = document.querySelector("#filterBtns");
        const height = topBar.offsetHeight
        let tickingInitScrollHeader = false
        document.addEventListener('scroll', (e) => {
            let newPos = window.scrollY
            if (!tickingInitScrollHeader && filterBtns) {
                window.requestAnimationFrame(function () {
                    if (newPos > height) {
                        filterBtns.classList.add('filter-btns--fixed')
                        filterBtns.style.top = 58 + 'px';
                        topBar.style.transform = 'translate3d(0,-60px, 0)'
                    } else {
                        filterBtns.classList.remove('filter-btns--fixed')
                        filterBtns.style.top = 0;
                        topBar.style.transform = 'translate3d(0,0px, 0)'
                    }
                    tickingInitScrollHeader = false
                });
            }
            tickingInitScrollHeader = true
            const s = window.scrollY > topBar.offsetHeight
                ? (topBarNav.offsetHeight)
                : 0;

            setCollapse(s);
        })
    }, [])

    const className = collapse
        ? 'top-bar top-bar--sm gtm-header-float'
        : 'top-bar gtm-header-fixe';
    return (
        <div className={className} id="topBar" style={{
            transform: `translate3d(0px, -${collapse}px, 0px)`,
        }}>
            <TopBarNavContent title={title} shopId={shopId} userData={userData}
                              cartTotalQuantity={cartTotalQuantity} context={context}
                              handleShopOverlay={handleShopOverlay}
                              updatePopinDisplay={updatePopinDisplay}
                              setSearchTextField={setSearchTextField}
                              setOpenMenu={setOpenMenu}
                              logoVersion={logoVersion}
                              hasShop={hasShop}
                              userConnected={userConnected}
            />
            <div className="top-bar__nav-sm">
                {context !== "storeLocatorShop" ?
                    <SearchBar
                        context={context}
                        cartTotalQuantity={cartTotalQuantity}
                        fetchTopSearchesResults={fetchTopSearchesResults}
                        setSearchTextField={setSearchTextField}
                        setOpenMenu={setOpenMenu}
                    /> : null}
            </div>
        </div>
    )
});

export default TopBarNav;
