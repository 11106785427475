import ApiCaller from '../../../../services/ApiCaller/apiCallerDefault';
import Cookies from 'js-cookie'
import {fetchProductAvailabilities} from "../productSheet/ProductAvailabilityActions"
import {loadCartApiResponse} from "../cart/CartActions"
import {fechFiltredProductListItems} from "../productList/productListActions"
import {
    displayDomElementBySelector,
    closeProductListGeoLocPopin,
    setLoacteMeAvailabilityBlocDisplayValue
} from "@/animations/Default/productListPage"
import {fetchPrimaryShopData, getShopEventListData, getPrimaryShopData} from "../header/headerActions"
import {
    hideLoaderAvailability,
    showLoaderAvailability,
    closeFancyBoxPopin
} from "@/animations/Default/productSheetPage"
import Utils from "../../.././../utils/Default/Utils";
import * as headerAnimations from "@/animations/Default/headerPage";
import {getInputElementValue, hideAutoCompleteBlock, showErrorGeo, hideJqueryHelper} from "@/animations/Default/shopPage";

import {
    CITY_SEARCHRESULTS_FETCHED,
    UPDATE_SHOPCIRCLE,
    AVAILABILITY_DATA_FETCHED_STOCKLOCATOR,
    UPDATE_GEO_ERROR_BLOC_STATUS,
    UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS,
    UPDATE_SHOW_LOADER_STATUS,
    SHOW_SHOP_LIST_BLOC,
    UPDATE_SHOPCIRCLE_LIST,
    UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE,
    HANDLE_RATTACHEMENT_MODAL_DATA,
    UPDATE_IS_CUSTOMER_LOCATION_CHANGED,
    UPDATE_ALERT_NO_STORE_STATUS, HANDLEAPISTATUSPENDING
} from '../actionsTypes'
import {initializeSelectedShippingMethod} from "@/redux/reducers/Default/shipping/ShippingActions";

export const searchLocationByCityNameOrZipCode = fragment => {
    return function (dispatch) {
        let url = ''
        isNaN(fragment) ? url = "/Localizations/InseeCodes?City=" + fragment : url = "/Localizations/InseeCodes?ZipeCode=" + fragment
        ApiCaller.get(url).then(
            response => {
                let results = response.data.locationInfosList.map((location) => {
                    return {
                        zipCode: location.cp,
                        cityName: location.lib,
                        insee: location.insee
                    }
                })
                dispatch({
                    type: CITY_SEARCHRESULTS_FETCHED,
                    payload: {locationsData: results, keywords: fragment}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error)
            })

    }

}

const canUpdateShopCircleInCookies = (context) => {
    return (!Utils.isValidShopCircle() && context !== 'ProductSheet' && context !== 'CartPage' && context !== 'ShippingPage')
}

const getStockLocatorAvailability = (shopCircleData, productIdFromCart, context) => {
    let circleShops = [];
    if (shopCircleData["circleShops"]) {
        circleShops = shopCircleData["circleShops"];
        if (canUpdateShopCircleInCookies(context)) {
            localStorage.setItem('shopCircle', JSON.stringify(shopCircleData));
            Cookies('availabilityShopCircle', JSON.stringify(Utils.getAvailabilityShopCircleData(shopCircleData)), {
                path: "/",
                domain: appData.cookieDomain,
                expires: 365,
                secure: true, sameSite: "None"
            });
        }
    }
    let params = Utils.getProductAvailabilityParams(circleShops, productIdFromCart, shopCircleData.zipCode, shopCircleData.city);
    return function (dispatch) {
        let url = '/Catalog/Products/Availabilities.json?' + params
        ApiCaller.get(url).then(
            response => {
                hideLoaderAvailability()
                dispatch(updateShowLoaderBlocStatus(false))
                let availabilityData = null

                if (response.data[0] && response.data[0].detailedAvailablity) {
                    availabilityData = response.data[0]
                } else if (response.data[0] && response.data[0].availabilityByShops && response.data[0].availabilityByShops[0]) {
                    availabilityData = response.data[0]
                    dispatch(updateStockLocatorErrorBlocStatus(false))
                }
                if (response.data[0] && !response.data[0].availabilityByShops) {
                    dispatch(updateStockLocatorErrorBlocStatus(true))
                }
                //productSheetAnimations.hideLoaderAvailability()
                dispatch({
                    type: AVAILABILITY_DATA_FETCHED_STOCKLOCATOR,
                    payload: {
                        availabilityData: availabilityData,
                    }
                })
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error)
            })


    }
}
const prepareHeaderShopList = (shopList) => {
    if (shopList && shopList.length > 0) {
        let storedShopList = shopList.sort(function (a, b) {
            a.distance - b.distance;
        });
        return storedShopList.slice(0, 3);
    }
    return []
}

export const getShopCircle = (param, productIdFromCart, calculationParam = 'ByInsee', context, formInputId = null, productListParams = null) => {
    showLoaderAvailability()
    return function (dispatch) {
        let url = '/Shops?CalculationMethod=' + calculationParam + '&CalculationParams=' + param
        if (context === "LocateMeHeader") {
            url += "&View=Full";
        }
        ApiCaller.get(url).then(
            response => {
                if (context !== "LocateMeHeader") {
                    dispatch(getStockLocatorAvailability(response.data, productIdFromCart, context))
                    dispatch({type: UPDATE_SHOPCIRCLE, payload: {shopCircleData: response.data}})
                }
                if (context === "LocateMeHeader") {
                    if (response?.data?.circleShops.length > 0) {
                        dispatch({
                            type: UPDATE_SHOPCIRCLE_LIST,
                            payload: {
                                circleShopList: prepareHeaderShopList(response.data?.circleShops)
                            }
                        })
                        dispatch({
                            type: SHOW_SHOP_LIST_BLOC,
                            payload: {
                                value: true
                            }
                        })
                    } else {
                        headerAnimations.showErrorGeo()
                    }
                }
                formInputId && headerAnimations.setInputElementValue('#' + formInputId, response.data.zipCode + ' ' + response.data.city)
            })
            .catch((error) => {
                if (context === 'ProductSheet' || context === 'CartPage' || context === 'ShippingPage') {
                    dispatch(updateShowLoaderBlocStatus(false))
                    hideLoaderAvailability()
                    if (error.response.status == '400') {
                        dispatch(updateStockLocatorErrorBlocStatus(true))
                        dispatch({
                            type: AVAILABILITY_DATA_FETCHED_STOCKLOCATOR,
                            payload: {
                                availabilityData: null,
                            }
                        })
                    } else {
                        dispatch(updateStockLocatorErrorBlocStatus(false))
                    }
                } else if (context === "LocateMeHeader") {
                    headerAnimations.showErrorGeo()
                }
                console.log(error)
            })
    }
}
const changeStoreTracking = (chosenShop) => {

    appData.actionTrigger.dispatch({
        trigger: "ChangeStoreUsingStockLocator",
        payload: {
            shopName: chosenShop
        }
    });
}

export const updateGeoErrorBlocStatus = () => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_GEO_ERROR_BLOC_STATUS,
            element: {status: true}
        });
    }
}
export const updateStockLocatorErrorBlocStatus = (value) => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS,
            element: {value: value}
        });
    }
}

export const updateShowLoaderBlocStatus = (value) => {
    return function (dispatch) {
        dispatch({
            type: UPDATE_SHOW_LOADER_STATUS,
            element: {value: value}
        })
    }
}

export const callApiUpdateShopCircle = (dispatch, calculationParams, productId, calculationMethod, context, productListParams = null, formInputId = null, btnSource = null, selectedZipCity = null, cartItems) => {
    const allShops = (context !== 'ProductSheet' && context !== "LocateMeHeader")
    const url = "/WebUsers/" + Utils.getUserIdFromCookie() + "/Shops"
    setLoacteMeAvailabilityBlocDisplayValue('block')
    if (selectedZipCity !== null && Utils.isValidShopCircle()) {
        let shopCircle = JSON.parse(localStorage.getItem('shopCircle'));
        shopCircle.zipCode = selectedZipCity.zipCode;
        shopCircle.city = selectedZipCity.cityName;
        shopCircle.modificationDate = Utils.getFormatedCurrentDateTime();
        localStorage.setItem('shopCircle', JSON.stringify(shopCircle));
        Cookies('availabilityShopCircle', JSON.stringify(Utils.getAvailabilityShopCircleData(shopCircle)), {
            path: "/",
            domain: appData.cookieDomain,
            expires: 365,
            secure: true, sameSite: "None"
        });
    }
    let putParams = {
        CalculationParams: calculationParams,
        CalculationMethod: calculationMethod
    }
    if (context === 'LocateShopResults') {
        putParams.View = 'Heavy'
        putParams.EveryShop = '1'
    }
    ApiCaller.put(url, putParams).then(response => {
        if (context !== "LocateShopResults" && context !== "LocateShop") {
            appData.actionTrigger.dispatch({
                trigger: "userAttachedToShop",
            });
        }
        if (context === 'ProductSheet') {
            closeFancyBoxPopin()
        }
        if (context === 'ProductListPopin') {
            dispatch({
                type: HANDLE_RATTACHEMENT_MODAL_DATA, payload: {
                    rattachementModal: {
                        modalShow: false,
                        modalTitle: '',
                    }
                }
            })
        }
        if (context === 'ProductListPopin' || context === 'ProductListAvailabilityFilterBloc' ||  context === 'ProductListAvailabilityWithDelayFilterBloc') {
            dispatch({
                type: HANDLEAPISTATUSPENDING,
                payload: {
                    apiStatusPending: true
                }
            })
        }
        if (!(context === "LocateMeHeader" && appData.mvcUrl == 'Shop/Shop/ResultShops')) {
            dispatch({
                type: UPDATE_SHOPCIRCLE,
                payload: {shopCircleData: response.data}
            })
        }
        let shopCircle = response.data;
        if (selectedZipCity !== null) {
            shopCircle.zipCode = selectedZipCity.zipCode;
            shopCircle.city = selectedZipCity.cityName;
        }
        localStorage.setItem('shopCircle', JSON.stringify(shopCircle))
        Cookies.set('availabilityShopCircle', JSON.stringify(Utils.getAvailabilityShopCircleData(shopCircle)), {
            path: "/",
            domain: appData.cookieDomain,
            expires: 365,
            secure: true, sameSite: "None"
        })
        dispatch({
            type: UPDATE_IS_CUSTOMER_LOCATION_CHANGED,
            payload: {value: true}
        })
        if ((appData.currentStep !== 'productSheet') && (context === "LocateMeHeader" || context === 'ProductList' || context === 'ProductListAvailabilityFilterBloc' ||  context === 'ProductListAvailabilityWithDelayFilterBloc' || context === 'ProductListPopin')) {
            hideJqueryHelper("ui-helper-hidden-accessible")
            dispatch(getPrimaryShopData())
            dispatch(getShopEventListData(Utils.getPrimaryShopId()))
            dispatch(initializeSelectedShippingMethod(cartItems))
        }
        if (context === "LocateMeHeader") {
            appData.actionTrigger.dispatch({
                trigger: "handleHeaderShopCircleAction",
                payload: {
                    action: 'Utilisateur_rattaché',
                    label: 'header'
                }
            });
        }
        if (context === 'ProductSheet' || (context === "LocateMeHeader" && (appData.currentStep == 'productSheet'))) {
            dispatch(
                fetchProductAvailabilities({
                    didShopChanged: true,
                    allShops: allShops,
                    productId: productId,
                    context: "stockLocator",
                    shopWasUpdated: true
                })
            )
            dispatch({
                type: UPDATE_SHOW_RATTACHEMENT_BLOC_VALUE,
                payload: {value: false}
            })
            dispatch(getShopEventListData(Utils.getPrimaryShopId()))
            dispatch(initializeSelectedShippingMethod(cartItems))
        } else if (context === 'CartPage' || context === 'ShippingPage') {
            let view = (context === 'CartPage') ? 'cart' : 'shipping'
            dispatch(loadCartApiResponse(view))
        } else if (context === 'LocateShopResults') { /*page resultats de recherche magasins*/
            let url = '/magasins/resultats-magasins?zip_code=' + shopCircle.zipCode + '&city=' + shopCircle.city
            if (btnSource === "ShopGeolocBtnSource") { /*if click "voir les mag autour de moi" */
                formInputId && headerAnimations.setInputElementValue('#' + formInputId, shopCircle.zipCode + ' ' + shopCircle.city)
            }
            dispatch(fetchPrimaryShopData(response.data.primaryShop.id))
        } else if (context === 'LocateShop') { /*page recherche magasins*/
            let url = '/magasins/resultats-magasins?zip_code=' + shopCircle.zipCode + '&city=' + shopCircle.city
            if (btnSource === "ShopGeolocBtnSource") { /*if click "voir les mag autour de moi" */
                url = "/magasins/resultats-magasins?latitude=" + getInputElementValue('#adrLat') + "&longitude=" + getInputElementValue('#adrLong');
            } else { /*if click input dqe */
                if (shopCircle.insee) {
                    url += '&insee=' + shopCircle.insee
                }
            }
            window.location.href = url
        } else if ((context === 'ProductList') || (context === 'ProductListAvailabilityFilterBloc')  || (context === 'ProductListAvailabilityWithDelayFilterBloc')|| (context === 'ProductListPopin') || (context === "LocateMeHeader" && (appData.currentStep == 'ProductsList' || appData.currentStep == 'ProductsSearchList'))) {
            if ((context === 'ProductList') || (context === 'ProductListAvailabilityFilterBloc') || (context === 'ProductListAvailabilityWithDelayFilterBloc') || (context === 'ProductListPopin')) {
                appData.actionTrigger.dispatch({
                    trigger: "updateShopCircleSuccess",
                    payload: {
                        label: context === 'ProductListAvailabilityFilterBloc' || context === 'ProductListAvailabilityWithDelayFilterBloc'  || context === 'ProductList' ? 'filtre_géolocalisation' : 'filtre_géolocalisastion_pop_in'
                    }
                });
            }
            let availabilityFilterIsApplied = getAvailabilityFilterIsApplied(productListParams)  || ( context === 'ProductListAvailabilityWithDelayFilterBloc' || btnSource == 'ProductListAvailabilityWithDelayFilter' )
            if (productListParams.availabilityFiltersCodes) {
                let currentFiltersValue = {...productListParams.currentFiltersValue}
                productListParams.availabilityFiltersCodes.map(item => {
                    delete currentFiltersValue[item]
                })
                productListParams.currentFiltersValue = currentFiltersValue;
            }
            let currentSort = productListParams.currentSort ? [null, productListParams.currentSort.label, null, productListParams.currentSort.value] : null
            dispatch(fechFiltredProductListItems(productListParams.keyWords, productListParams.pager, currentSort, productListParams.pageIndex, productListParams.currentFiltersValue,null, productListParams.categoryId, null, availabilityFilterIsApplied))
        }
    })
        .catch((error) => {
            if ((context === 'ProductSheet' || context === 'CartPage') && error.response.status == 404) {
                dispatch(updateGeoErrorBlocStatus())
            }
            if ((context === 'LocateMeHeader') && error.response.status == 404) {
                headerAnimations.showErrorGeo()
            }
            if (((context === 'LocateShop') || (context === 'LocateShopResults')) && (error.response.status == 404 || error.response.status == 400)) {
                showErrorGeo()
                hideAutoCompleteBlock()
            }
            if (btnSource ==  'ProductListAvailabilityFilter' || btnSource == 'ProductListAvailabilityWithDelayFilter' || context === 'ProductListAvailabilityFilterBloc' ||  context === 'ProductListAvailabilityWithDelayFilterBloc') {
                dispatch({
                    type: UPDATE_ALERT_NO_STORE_STATUS,
                    payload: {value: true}
                })
            }
            dispatch({
                type: UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS,
                element: {value: true}
            })
            console.log(error)
        })
}
const getAvailabilityFilterIsApplied = (productListParams) => {
    let exist = false
    if (productListParams.currentFiltersValue) {
        productListParams.availabilityFiltersCodes.map(item => {
            if (productListParams.currentFiltersValue[item] && !exist) {
                exist = true;
            }
        })
    }
    return exist
}
export const updateShopCircle = (calculationParams, productId, calculationMethod, context, shopData = null, selectedZipCity = null, productListParams = null, cartItems) => {
    if (shopData) {
        changeStoreTracking(shopData.shortName)
    }
    return function (dispatch) {
        callApiUpdateShopCircle(dispatch, calculationParams, productId, calculationMethod, context, productListParams, null, null, selectedZipCity, cartItems)
    }

}
