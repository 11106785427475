import ApiCaller from './ApiCaller/apiCaller'
import {getUserIdFromCookie} from "@/utils/Mobile/utils";

export const getCartData = (webUserId, view) => {
    return new Promise((resolve, reject) => {
           ApiCaller.get('/WebUsers/' + webUserId + '/Cart?View=' + view)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', '/WebUsers/' + webUserId + '/Cart?View=Compact Api responded failed : ' + error)
                reject(error)
            });
    });
  };

export const updateShopInCartViewShipping = (webUserId,data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put("/WebUsers/" + webUserId + "/Cart?View=shipping", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', "/WebUsers/" + webUserId + "/Cart?View=shipping  Api responded failed : " + error)
                reject(error)
            });
    });
};

export const addToCart = (url, data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', url + " Api responded failed : " + error)
                reject(error)
            });
    });
};

export const subscribeToStock = (url, data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.post(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', url + " Api responded failed : " + error)
                reject(error)
            });
    });
};

export const updateProductsWarranties = (url,data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.put(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', url + " Api responded failed : " + error)
                reject(error)
            });
    });
};