import {
    CUSTOMER_ACCOUNT_PROFIL_PAGE,
    DASHBOARD_PAGE,
    HOME_PAGE,
    MOBILE_TEMPLATE_VERSION,
    PRODUCT_LIST_PAGE,
    PRODUCT_SHEET_PAGE,
    SEARCH_PAGE,
    CUSTOMER_ACCOUNT_ORDERS_PAGE,
    CUSTOMER_ACCOUNT_SOCIAL_MEDIA,
    CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE,
    CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE,
    CUSTOMER_ACCOUNT_NEWSLETTER_PAGE,
    CATEGORIES_PAGE,
    CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE,
    CART_PAGE,
    IN_STORE_PURCHASE_ACTIVATION_SCAN_PAGE,
    IN_STORE_PURCHASE_SHIPPING_PAGE, IN_STORE_PURCHASE_CART_PAGE, IN_STORE_PURCHASE_CONFIRMATION_PAGE
} from "@/utils/constants";
import defaultHeaderReducer from "@/redux/reducers/Default/header/headerReducer";
import mobileHeaderReducer from "@/redux/reducers/Mobile/header/headerReducer";
import defaultMenuReducer from "@/redux/reducers/Default/header/MenuReducer";
import mobileMenuReducer from "@/redux/reducers/Mobile/header/menuReducer";
import mobileSearchBarReducer from "@/redux/reducers/Mobile/header/searchBarReducer";
import defaultSearchBarReducer from "@/redux/reducers/Default/header/SearchBarReducer";
import StockLocatorReducer from "@/redux/reducers/Default/commun/StockLocatorReducer";
import defaultTrackingReducer from "@/redux/reducers/Default/tracking/trackingReducer";
import mobileTrackingReducer from "@/redux/reducers/Mobile/tracking/trackingReducer";
import defaultProductListReducer from "@/redux/reducers/Default/productList/productListReducer"
import mobileProductListReducer from "@/redux/reducers/Mobile/productList/productListReducer"
import blockFilterReducer from "@/redux/reducers/Default/productList/blockFilterReducer";
import identityReducer from "@/redux/reducers/Mobile/commun/identityReducer";
import shopReducer from "@/redux/reducers/Mobile/shop/shopReducer";
import geolocationReducer from "@/redux/reducers/Mobile/commun/geolocationReducer";
import mobileProductAvailabilityReducer from "@/redux/reducers/Mobile/product/productAvailabilityReducer";
import marketplaceReducer from "@/redux/reducers/responsive/product/marketplaceReducer";
import questionsReducer from "@/redux/reducers/responsive/product/questionReducer";
import reviewsReducer from "@/redux/reducers/responsive/product/reviewsReducer";
import promotionalOffersReducer from "@/redux/reducers/responsive/product/promotionalOffersReducer";
import customerReducer from "@/redux/reducers/responsive/customer/CustomerReducer";
import shopDatasReducer from "@/redux/reducers/responsive/shop/shopReducer";
import orderReducer from "@/redux/reducers/responsive/order/orderReducer";
import orderTrackingReducer from "@/redux/reducers/responsive/orderTracking/orderTrackingReducer";
import socialMediaReducer from "@/redux/reducers/responsive/socialMedia/socialMediaReducer";
import orderDetailsReducer from "@/redux/reducers/responsive/order/orderDetailsReducer";
import commonReducer from "@/redux/reducers/responsive/common/CommonReducer";
import productAvailabilityReducer from "@/redux/reducers/responsive/product/productAvailabilityReducer";
import plannerReducer from "@/redux/reducers/responsive/planner/plannerReducer";
import layoutCartReducer from "@/redux/reducers/responsive/cart/LayoutCartReducer";
import scanReducer from "./Mobile/scan/scanReducer";
import shippingReducer from "@/redux/reducers/Mobile/shipping/shippingReducer";
import inStoreOrderReducer from "@/redux/reducers/Mobile/inStoreOrder/inStoreOrderReducer";

export const defaultReducers = {
    header: defaultHeaderReducer,
    menu: defaultMenuReducer,
    searchBar: defaultSearchBarReducer,
    stockLocator: StockLocatorReducer,
    tracking: defaultTrackingReducer,
    customer: customerReducer,
    identity: identityReducer,
    requiredCommonData: commonReducer
}

export const defaultProductListReducers = {
    productList: defaultProductListReducer,
    blockFilter: blockFilterReducer
}

export const mobileReducers = {
    menu: mobileMenuReducer,
    header: mobileHeaderReducer,
    searchBar: mobileSearchBarReducer,
    identity: identityReducer,
    tracking: mobileTrackingReducer,
    shop: shopReducer,
    geolocation: geolocationReducer,
    customer: customerReducer,
    requiredCommonData: commonReducer
}

export const mobileProductListReducers = {
    productList: mobileProductListReducer,
    blockFilter: blockFilterReducer,
    availability: mobileProductAvailabilityReducer,
}

export const productSheetReducers = {
    marketplace: marketplaceReducer,
    questions: questionsReducer,
    reviews: reviewsReducer,
    availability: productAvailabilityReducer,
    lastAddedElement: layoutCartReducer,
    promotionalOffers: promotionalOffersReducer,
}
export const defaultDashboardReducers = {
    shopData: shopDatasReducer,
    orders: orderReducer,
    orderTracking: orderTrackingReducer,
    requiredCommonData: commonReducer,
    shop: shopReducer,
    header: defaultHeaderReducer,
    identity: identityReducer
}
export const mobileDashboardReducers = {
    shopData: shopDatasReducer,
    orders: orderReducer,
    orderTracking: orderTrackingReducer,
    requiredCommonData: commonReducer,
    shop: shopReducer,
    header: mobileHeaderReducer,
    identity: identityReducer
}
export const mobileProfileReducers = {
    customer: customerReducer,
    header: mobileHeaderReducer,
    requiredCommonData: commonReducer,
    identity: identityReducer
}
export const defaultProfileReducers = {
    customer: customerReducer,
    header: defaultHeaderReducer,
    requiredCommonData: commonReducer,
    identity: identityReducer
}
export const customerAccountOrderReducer = {
    orders: orderReducer,
    customer: customerReducer,
    orderTracking: orderTrackingReducer,
    orderDetails: orderDetailsReducer,
    requiredCommonData: commonReducer,
    identity: identityReducer
}
export const socialMediaReducers = {
    socialMedia: socialMediaReducer,
    requiredCommonData: commonReducer,
    identity: identityReducer
}
export const newsLetterReducers = {
    customer: customerReducer,
    requiredCommonData: commonReducer,
    identity: identityReducer
}
export const plannerReducers = {
    planner: plannerReducer
}
export const cartReducers = {
    cart: layoutCartReducer,
    requiredCommonData: commonReducer,
}
export const shippingReducers = {
    shipping : shippingReducer,
    geolocation: geolocationReducer,
    requiredCommonData: commonReducer
}

export const orderConfirmationReducers = {
    order : inStoreOrderReducer,
    requiredCommonData: commonReducer
}
export const scanReducers = {
    scan : scanReducer,
    requiredCommonData: commonReducer
}
export const getReducerByContext = (templateVersion, context) => {
    switch (context) {
        case HOME_PAGE:
            return templateVersion === MOBILE_TEMPLATE_VERSION ? mobileReducers : defaultReducers;
        case PRODUCT_LIST_PAGE:
        case SEARCH_PAGE:
            return templateVersion === MOBILE_TEMPLATE_VERSION ? mobileProductListReducers : defaultProductListReducers;
        case PRODUCT_SHEET_PAGE:
            return templateVersion === MOBILE_TEMPLATE_VERSION ? { ...mobileReducers, ...productSheetReducers }
                : { ...defaultReducers, ...productSheetReducers };
        case CUSTOMER_ACCOUNT_PROFIL_PAGE:
            return templateVersion === MOBILE_TEMPLATE_VERSION ? mobileProfileReducers : defaultProfileReducers;
        case DASHBOARD_PAGE:
            return templateVersion === MOBILE_TEMPLATE_VERSION ? mobileDashboardReducers : defaultDashboardReducers;
        case CUSTOMER_ACCOUNT_ORDERS_PAGE:
            return customerAccountOrderReducer;
        case CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE:
            return customerAccountOrderReducer;
        case CUSTOMER_ACCOUNT_SOCIAL_MEDIA:
            return socialMediaReducers;
        case CUSTOMER_ACCOUNT_CHANGE_PASSWORD_PAGE:
            return customerAccountOrderReducer;
        case CUSTOMER_ACCOUNT_NEWSLETTER_PAGE:
            return newsLetterReducers;
        case CUSTOMER_ACCOUNT_KITCHEN_PROJECTS_PAGE :
            return plannerReducers;
        case CATEGORIES_PAGE :
            return templateVersion === MOBILE_TEMPLATE_VERSION ? mobileReducers : defaultReducers;
        case IN_STORE_PURCHASE_CART_PAGE :
            return cartReducers;
        case IN_STORE_PURCHASE_SHIPPING_PAGE :
            return shippingReducers;
        case IN_STORE_PURCHASE_CONFIRMATION_PAGE :
            return orderConfirmationReducers;
        case IN_STORE_PURCHASE_ACTIVATION_SCAN_PAGE :
            return scanReducers;
        default:
            return templateVersion === MOBILE_TEMPLATE_VERSION ? mobileReducers : defaultReducers;
    }
}
