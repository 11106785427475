import MobileApiCaller from './ApiCaller/apiCaller'
import DefaultApiCaller from './ApiCaller/apiCallerDefault'
import ApiCallerSSR from './ApiCaller/apiCallerSSR'
import {prepareShopCircle} from "@/utils/Mobile/utils";
import {DEFAULT_TEMPLATE_VERSION, MOBILE_TEMPLATE_VERSION, PRODUCT_LIST_PAGE} from "@/utils/constants";

// ajouter les vrai config
export const getItemsFromNavigation = (deviceType, categoryID, currentIndex, currentSort, appliedFiltersValues, currentFiltersValue, query, keyword = null, shopCircleCookie = null, requestUrl = null, context = 'SSR', userAgent = null, cUserID = null, navigationToken = null, currentPage = '', categoryView = null) => {
    //traitement ici pour les parametres
    let data = {};
    const httpRedirectCodes = [301, 302, 307, 404];
    // Sort
    if (currentSort && currentSort.value != "" && currentSort.value != null) {
        data.SortedBy = JSON.stringify({"code": currentSort.value, "direction": "desc"});
    }
    // Filters
    if (appliedFiltersValues) {
        data.Filters = appliedFiltersValues;
    }
    // PageSize
    /*   if (currentIndex && currentIndex <= appData.productsListObject.pager.pageCount) {
           data.PageSize = currentIndex * appData.productsListObject.pager.pageSize;
       }*/

    if (currentIndex) {
        data.PageIndex = currentIndex
    }

    // keyords de la page recherche
    if (keyword) {
        data.KeyWords = keyword;
    } else {
        data.keyWords = null;
    }

    data.wordingVersion = deviceType && deviceType.toLowerCase() === MOBILE_TEMPLATE_VERSION.toLowerCase() ? MOBILE_TEMPLATE_VERSION : DEFAULT_TEMPLATE_VERSION;
    data.ShopCircle = keyword ? prepareShopCircle() : (shopCircleCookie ? prepareShopCircle(shopCircleCookie) : null)

    data.Context = context

    if (!keyword || currentPage == PRODUCT_LIST_PAGE) {
        data.RequestUrl = requestUrl
    }

    let ApiCaller = deviceType === MOBILE_TEMPLATE_VERSION ? MobileApiCaller : DefaultApiCaller;
    return new Promise((resolve, reject) => {
        if (keyword && currentPage != PRODUCT_LIST_PAGE) {
            const userAgent = window.navigator.userAgent;
            ApiCaller.defaults.headers['User-Agent'] = userAgent;
            console.log('INFO', "Calling V1/SearchEngine/ProductsList Api with this data :", JSON.stringify(data))
            ApiCaller.get("V1/SearchEngine/ProductsList", {
                params: data
            })
                .then((response) => {
                    (currentIndex) ? response.data.productList.pager.currentPageIndex = currentIndex - 1 : response.data.productList.pager.currentPageIndex = 0;
                    resolve(response.data);
                })
                .catch((error) => {
                    if (keyword && error.response.status === 302) {
                        resolve(error.response);
                    } else {
                        console.error('ERROR', "V1/SearchEngine/ProductsList Api responded failed : " + error)
                        reject(error);
                    }

                });
        } else {
            if (cUserID !== null) {
                ApiCallerSSR.defaults.headers['CUserID'] = cUserID;
            }
            if (navigationToken !== null) {
                ApiCallerSSR.defaults.headers['navigation-token'] = navigationToken;
            }
            let categoryTypeDesente = '';
            if (categoryView !== null) {
                categoryTypeDesente = "?view=" + categoryView;
            }
            ApiCallerSSR.defaults.headers['User-Agent'] = userAgent;
            console.log('INFO', "Calling V1/Catalog/Categories/c" + categoryID + "/ProductsList" + categoryTypeDesente + " Api with this data :", JSON.stringify(data))
            ApiCallerSSR.get("V1/Catalog/Categories/c" + categoryID + (context == 'SSR' ? "/SSRProductsList" + categoryTypeDesente : "/ProductsList" + categoryTypeDesente), {
                params: data
            })
                .then((response) => {
                    console.log('INFO', "V1/Catalog/Categories/c" + categoryID + "/ProductsList" + categoryTypeDesente + " Api responded with success")
                    if (response.status === 200 && response.data) {
                        (currentIndex) ? response.data.pager.currentPageIndex = currentIndex - 1 : response.data.pager.currentPageIndex = 0;
                    }
                    resolve(response);
                })
                .catch((error) => {
                    if (httpRedirectCodes.includes(error.response.status)) {
                        resolve(error.response);
                    } else {
                        console.error('ERROR', "V1/Catalog/Categories/c" + categoryID + "/ProductsList" + categoryTypeDesente + " Api responded failed : " + error)
                    }
                reject(error)
                });
        }

    });
}

export const productListApplySort = (deviceType, url, data) => {
    let ApiCaller = deviceType === MOBILE_TEMPLATE_VERSION ? MobileApiCaller : DefaultApiCaller;
    return new Promise((resolve, reject) => {
        ApiCaller.get(url, {
            params: data
        }).then((response) => {
            resolve(response.data);
        })
            .catch((error) => {
                console.error('ERROR', url + ' Api responded failed : ' + error)
                reject(error)
            });
    });
};

export const fetchProductsListAvailabilityData = (deviceType, url) => {
    let ApiCaller = deviceType === MOBILE_TEMPLATE_VERSION ? MobileApiCaller : DefaultApiCaller;
    ApiCaller.defaults.headers['referer'] = 'A6423ZsbYqADzhQN4KhaU'
    return new Promise((resolve, reject) => {
        ApiCaller.get(url, {})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', url + ' Api responded failed : ' + error)
                reject(error)
            });
    });
};
