import React, {useCallback, useEffect, useState} from 'react';
import AboveMenuBlock from "./AboveMenuBlock";
import BurgerBlock from './BurgerBlock'
import Utils from "@/libs/Utils";
import { refreshCarouselBySelector } from "@/animations/Default/commonPage";
import { triggerMenuClicEvents, resizeHeaderInfoBarOwlCarousel } from "@/animations/Default/headerPage";

export const MenuBlock = ({menuItems, aboveMenuItems, currentPage,context}) => {

    const [megaMenu, setMegaMenu] = useState("closed");
    const [firstLoaded, setFirstLoaded] = useState(true);
    useEffect(() => {
        setFirstLoaded(false)
    }, [])

    const openMegaMenu = useCallback((BurgerClicked) => {
        if (megaMenu === "closed") {
            triggerMenuClicEvents(BurgerClicked ? 'Ouverture burger menu' : 'Produits');
            document.querySelector("body").classList.remove("fixedSearch");
            document.querySelector("body").classList.add("fixedMenu");
            setMegaMenu("opened");
            resizeHeaderInfoBarOwlCarousel()
        } else {
            triggerMenuClicEvents(BurgerClicked ? 'Fermeture burger menu' : 'Produits');
            document.querySelector("body").classList.remove("fixedMenu");
            document.querySelector("body").classList.remove("fixedSearch");
            setMegaMenu("closed");
            refreshCarouselBySelector('.owl-carousel');
        }
    },[megaMenu])

    const closeMegaMenu = (event) => {
        document.querySelector("body").classList.remove("fixedMenu");
        document.querySelector("body").classList.remove("fixedSearch");
        document.querySelector(".overlayMenuAndSearch").classList.remove("opened");

        let elementAutocompletetopSearch = document.getElementById("autocompletetopSearch");
        let elementAutocompleteresult = document.getElementById("autocompleteresult");
        if(elementAutocompletetopSearch){
            elementAutocompletetopSearch.style.display = 'none';
        }
        if(elementAutocompleteresult){
            elementAutocompleteresult.style.display = 'none';
        }

        setMegaMenu("closed");
        $('#autocompletetopSearch').slideUp(200);
        $('#autocompleteresult').slideUp(200);
    }

    const overNiv2 = (event) => {
        // Reset categories
        let getAllLevelsVisibles = Array.from(event.target.parentNode.parentNode.querySelectorAll('li[id*="level-"]'));
        let getAllTabVisibles = Array.from(event.target.parentNode.parentNode.querySelectorAll('div[id*="tab-"]'));
        getAllLevelsVisibles.forEach(node => {
            node.classList.remove('active');
        });
        getAllTabVisibles.forEach(node => {
            node.classList.remove('active');
        });
        // Get category id and add class active
        let getIdCategory = event.target.id.slice(6);
        document.getElementById("level-" + getIdCategory).classList.add("active");
        document.getElementById("tab-" + getIdCategory).classList.add("active");
    }

    const getTitleTag = (menuItem) => {

        if (firstLoaded && context != 'productsList') {
            return <a
                href={menuItem[3]}
                className="gtm-header a-title-menu gtm-header-redirection"
                dangerouslySetInnerHTML={{ __html: (menuItem[1] != '') ? menuItem[1] : menuItem[0] }}
                onClick={() => clicItemMenu(Utils.removeAccents(menuItem[0]).toLowerCase(), menuItem[3], menuItem[5])}></a>
        } else if (menuItem[7].length > 0) {

            return <span className="gtm-header"
                dangerouslySetInnerHTML={{ __html: (menuItem[1] != '') ? menuItem[1] : menuItem[0] }}>
            </span>
        } else {
            return <a
                href={menuItem[3]}
                className="gtm-header a-title-menu gtm-header-redirection"
                dangerouslySetInnerHTML={{ __html: (menuItem[1] != '') ? menuItem[1] : menuItem[0] }}
                onClick={(event) => {
                    event.preventDefault();
                    clicItemMenu(Utils.removeAccents(menuItem[0]).toLowerCase(), menuItem[3], menuItem[5])
                }}
            >
            </a>
        }

    }

    const getSubMenuBloc = (menuItem) => {
        if (menuItem[7] && menuItem[7].length > 0) {
            return (<div className='niv1__dropdown'>
                <div>
                    <ul>
                        {getTabSubMenuBloc(menuItem[7], menuItem[0])}
                    </ul>
                    {getTabContentBloc(menuItem[7], menuItem[0])}
                </div>
            </div>)
        }
    }

    const clicItemMenu = useCallback((eventLabel, redirectionUrl, targetBlank) => {
        appData.actionTrigger.dispatch({
            trigger: "handleClickMenu",
            payload: {
                eventLabel: eventLabel
            },
            resolveCallback: function () {
                openLink(redirectionUrl, targetBlank)
            }
        });
    }, [])

    const openLink = (redirectionUrl, targetBlank) => {
        if (targetBlank === 'True') {
            window.open(
                redirectionUrl,
                '_blank'
            );
        } else {
            window.location.href = redirectionUrl;
        }
    }
    const getRelatedContentBloc = (children) => {
        return (
            <React.Fragment>
                <div className="encarts" dangerouslySetInnerHTML={{ __html: children[9] }}></div>
                {children[10] && <div className="bottom"
                    dangerouslySetInnerHTML={{ __html: children[10] }}></div>}

            </React.Fragment>
        )
    }


    const getTabSubMenuBloc = (children, parentTitle) => {
        {
            return (children.map((child, index) => {
                if (!firstLoaded) {
                    return (
                        <li key={index} className={index === 0 ? "active" : ""}
                            id={"level-" + child[6]}
                            onMouseEnter={overNiv2.bind(this)}
                            onClick={(event) => {
                                event.preventDefault();
                                triggerMenuClicEvents(parentTitle + '/' + child[0]);
                                clicItemMenu(parentTitle + "/" + child[0], child[3], child[5])
                            }}
                        >{child[0]}</li>)
                } else {
                    return (
                        <a key={index} className={index === 0 ? "active" : ""}
                            href={child[3]}
                        >{child[0]}</a>
                    )
                }

            }))
        }
    }

    const getTabContentBloc = (children, parentTitle) => {
        {
            return (children.map((child, index) => {
                return (
                    <div key={index} id={"tab-" + child[6]} className={index === 0 ? "active colonnes" : "colonnes"}>
                        {getTabContentColonnesBloc(child, child[6], index, parentTitle)}
                    </div>
                )
            }))
        }

    }


    const getTabContentColonnesBloc = (children, categoryId, index, parentTitle) => {

        return (<>
            {(children[7].map((child, i) => {
                let parentsTitle = parentTitle + "/" + children[0]
                return getColmunContent(child, i, parentsTitle)

            }))}
            {prepareMissingColumn(children[7])}
            {getRelatedContentBloc(children)}
        </>)

    }

    const prepareMissingColumn = (menuItem) => {
        let missingColumns = [];
        if (menuItem && menuItem.length < 4) {
            for (let i = menuItem.length; i < 4; i++) {
                missingColumns.push(<div className="colonne"></div>)
            }
        }
        return missingColumns;
    }

    const getColmunContent = (menuItem, column, parentsTitle) => {
        return (<div className="colonne">
            {menuItem[7].map(function (category) {
                return getColmunItems(category, parentsTitle);
            })}
        </div>)
    }

    const getColmunItems = (category, parentsTitle) => {
        return category[7].length > 0 ? (<ul>
            {!category[3] ?
                <li dangerouslySetInnerHTML={{ __html: (category[1]) ? category[1] : category[0] }} /> :
                <li>
                    <a className="gtm-menu-item"
                        href={firstLoaded ? category[3] : '#'}
                        dangerouslySetInnerHTML={{ __html: (category[1] != '') ? category[1] : category[0] }}
                        onClick={(event) => {
                            event.preventDefault();
                            clicItemMenu(parentsTitle + "/" + category[0], category[3], category[5])
                        }}></a>
                </li>
            }
            {category[7].map((childrenItem, index) => {
                return ((childrenItem[4]) ? <li key={index} dangerouslySetInnerHTML={{ __html: childrenItem[4] }}></li> :
                    !childrenItem[3] ?
                        <li key={index} dangerouslySetInnerHTML={{ __html: (childrenItem[1]) ? childrenItem[1] : childrenItem[0] }} /> :
                        <li key={index}
                            className="gtm-menu-item"
                            dangerouslySetInnerHTML={{ __html: (childrenItem[1]) ? childrenItem[1] : childrenItem[0] }}
                            href={firstLoaded ? childrenItem[3] : '#'}
                            onClick={(event) => {
                                event.preventDefault();
                                clicItemMenu((category[0] === 'Concevoir sa cuisine' || category[0] === 'Nos conseils déco') ? parentsTitle + "/" + category[0] : parentsTitle + "/" + category[0] + "/" + childrenItem[0], childrenItem[3], childrenItem[5])
                            }}
                        />)
            })}
        </ul>) : (<ul>
            {!category[3] ?
                <li dangerouslySetInnerHTML={{ __html: (category[1] != '') ? category[1] : category[0] }} /> :
                <li>
                    <a className="gtm-menu-item"
                        dangerouslySetInnerHTML={{ __html: (category[1] != '') ? category[1] : category[0] }}
                        href={firstLoaded ? category[3] : '#'}
                        onClick={(event) => {
                            event.preventDefault();
                            clicItemMenu(parentsTitle + "/" + category[0], category[3], category[5])
                        }}></a>
                </li>
            }

        </ul>)
    }

    let navMainClass = currentPage == "homePage" ? "niv0 noborder" : "niv0"
    return (
        <>
            <div className='burgerScroll'>
                <BurgerBlock burgerClass={"product__burger-menu " + megaMenu}
                    openMegaMenu={openMegaMenu} />
            </div>
            <div className='containerMenu'>
                <ul className={navMainClass}>
                    <li className="product">
                        <BurgerBlock burgerClass={"product__burger-menu " + megaMenu}
                            openMegaMenu={openMegaMenu} />
                        <div className="product__name" aria-label="Menu principal" role="button"
                            aria-controls="navigation" onClick={() => openMegaMenu(false)}>Produits
                        </div>

                        <div className={'niv1 ' + megaMenu}>
                            {firstLoaded && context != 'productsList' ?
                                <ul>
                                    {menuItems?.map((menuItem, index) => {
                                        return (<li key={index} >
                                            {getTitleTag(menuItem)}
                                        </li>)
                                    })}
                                </ul> :
                                <ul>
                                    {menuItems?.map((menuItem, index) => {
                                        return (<li key={index} >
                                            {getTitleTag(menuItem)}
                                            {getSubMenuBloc(menuItem)}
                                        </li>)
                                    })}
                                </ul>
                            }
                        </div>
                    </li>
                    {aboveMenuItems &&
                        <AboveMenuBlock aboveMenuItems={aboveMenuItems} clicAboveItemMenu={clicItemMenu} />}
                </ul>
            </div>
            <div className={'overlayMenuAndSearch ' + megaMenu}
                onClick={closeMegaMenu}></div>
        </>
    )
};
export default MenuBlock;