import DestockedProduct from "./DestockedProduct";
import DestockedProductOverlay from "./DestockedProductOverlay";

const DestockedProductList = (props) => {

    function renderProductListBloc() {
        let destockedProductData = props.destockedProductsData;
        let displayProductList = [];
        if (destockedProductData) {
            for (let i = 0; i < destockedProductData.length; i++) {
                let product = destockedProductData[i];
                if (product != undefined && product != "null") {
                    displayProductList.push(
                        <DestockedProduct product={product}
                                          shopAndWharehouseData={props.shopAndWharehouseData}
                                          productReviewData={props.productReviewData}
                                          getProductReviews={(id,pageSize) => props.getProductReviews(id,props.totalReviewsPageSize)}
                                          caracProductData={props.caracProductData}
                                          getCaracProduct={(id) => props.getCaracProduct(id)}
                                          productshowedType={props.productshowedType}
                                          totalReviewsPageSize={props.totalReviewsPageSize}/>
                    );
                }
            }
        }
        return displayProductList;
    }
    function renderProductListOverlayBloc() {
        let destockedProductData = props.destockedProductsData;
        let displayProductListOverlay = [];
        if (destockedProductData) {
            for (let i = 0; i <= destockedProductData.length; i++) {
                let product = destockedProductData[i];
                if (product != undefined && product != "null") {
                    displayProductListOverlay.push(
                        <DestockedProductOverlay product={product}
                                                 shopAndWharehouseData={props.shopAndWharehouseData}
                                                 destockedProductReviews={props.destockedProductReviews}
                                                 productReviewData={props.productReviewData}
                                                 getProductReviews={(id,pageSize) => props.getProductReviews(id,props.totalReviewsPageSize)}
                                                 caracProductData={props.caracProductData}
                                                 incrementTotalReviewsPageSize={() => props.incrementTotalReviewsPageSize()}
                                                 productshowedType = {props.productshowedType}
                                                 totalReviewsPageSize={props.totalReviewsPageSize}/>
                    );
                }
            }
        }
        return displayProductListOverlay;
    }

    return (
        <div className="store-fiche-carousel">
            <span className="store-fiche-carousel__title bg_gray"> Produits déstockés dans ce magasin</span>
            <div className="fiche-bundle">
                <div className="fiche-bundle-product__table">
                    {renderProductListBloc()}
                </div>
            </div>
            {renderProductListOverlayBloc()}
        </div>
    )

}
export default DestockedProductList;
