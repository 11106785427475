import React from "react";

const ScanIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_862_929)">
            <path d="M5.635 5.8999H4.97658C4.6562 5.8999 4.39648 6.15962 4.39648 6.48V15.5197C4.39648 15.8401 4.6562 16.0998 4.97658 16.0998H5.635C5.95538 16.0998 6.21509 15.8401 6.21509 15.5197V6.48C6.21509 6.15962 5.95538 5.8999 5.635 5.8999Z" fill="white" />
            <path d="M8.4807 5.8999H7.82229C7.50191 5.8999 7.24219 6.15962 7.24219 6.48V15.5197C7.24219 15.8401 7.50191 16.0998 7.82229 16.0998H8.4807C8.80108 16.0998 9.0608 15.8401 9.0608 15.5197V6.48C9.0608 6.15962 8.80108 5.8999 8.4807 5.8999Z" fill="white" />
            <path d="M11.3284 5.8999H10.6699C10.3496 5.8999 10.0898 6.15962 10.0898 6.48V15.5197C10.0898 15.8401 10.3496 16.0998 10.6699 16.0998H11.3284C11.6487 16.0998 11.9085 15.8401 11.9085 15.5197V6.48C11.9085 6.15962 11.6487 5.8999 11.3284 5.8999Z" fill="white" />
            <path d="M14.176 5.8999H13.5176C13.1972 5.8999 12.9375 6.15962 12.9375 6.48V15.5197C12.9375 15.8401 13.1972 16.0998 13.5176 16.0998H14.176C14.4964 16.0998 14.7561 15.8401 14.7561 15.5197V6.48C14.7561 6.15962 14.4964 5.8999 14.176 5.8999Z" fill="white" />
            <path d="M17.0237 5.8999H16.3653C16.0449 5.8999 15.7852 6.15962 15.7852 6.48V15.5197C15.7852 15.8401 16.0449 16.0998 16.3653 16.0998H17.0237C17.344 16.0998 17.6038 15.8401 17.6038 15.5197V6.48C17.6038 6.15962 17.344 5.8999 17.0237 5.8999Z" fill="white" />
            <path d="M5.81753 1.37179e-09H0.994046C0.863506 -6.85577e-06 0.734235 0.0256938 0.613632 0.0756481C0.493022 0.125602 0.383438 0.198823 0.291127 0.291134C0.198823 0.383445 0.125602 0.493029 0.0756481 0.613639C0.0256938 0.734249 -6.85577e-06 0.86352 1.37179e-09 0.994066V5.81726C1.37179e-09 6.08085 0.104729 6.33374 0.291148 6.52011C0.477573 6.70656 0.730409 6.81127 0.994046 6.81127C1.25769 6.81127 1.51053 6.70656 1.69694 6.52011C1.88337 6.33374 1.9881 6.08085 1.9881 5.81726V1.98813H5.81753C6.08112 1.98813 6.33401 1.8834 6.52039 1.69698C6.70683 1.51055 6.81154 1.25771 6.81154 0.994066C6.81154 0.730423 6.70683 0.477579 6.52039 0.291154C6.33401 0.104729 6.08112 1.37179e-09 5.81753 1.37179e-09Z" fill="white" />
            <path d="M22 0.994066C22 0.86352 21.9743 0.734249 21.9244 0.613639C21.8744 0.493029 21.8012 0.383445 21.7089 0.291134C21.6166 0.198823 21.507 0.125602 21.3864 0.0756481C21.2658 0.0256938 21.1365 -6.85577e-06 21.0059 1.37179e-09H16.1825C16.0519 1.37179e-09 15.9227 0.0257142 15.8021 0.0756685C15.6815 0.125623 15.5719 0.19885 15.4796 0.291154C15.3873 0.383465 15.3141 0.493049 15.2641 0.613653C15.2142 0.734256 15.1885 0.86352 15.1885 0.994066C15.1885 1.12461 15.2142 1.25387 15.2641 1.37448C15.3141 1.49508 15.3873 1.60467 15.4796 1.69698C15.5719 1.78928 15.6815 1.86251 15.8021 1.91246C15.9227 1.96242 16.0519 1.98813 16.1825 1.98813H20.0119V5.81726C20.0119 6.08085 20.1166 6.33374 20.3031 6.52011C20.4895 6.70656 20.7424 6.81127 21.0059 6.81127C21.2696 6.81127 21.5224 6.70656 21.7089 6.52011C21.8953 6.33374 22 6.08085 22 5.81726V0.994066Z" fill="white" />
            <path d="M1.37179e-09 21.0062C-6.85577e-06 21.1367 0.0256938 21.266 0.0756481 21.3866C0.125602 21.5072 0.198823 21.6168 0.291127 21.7091C0.383438 21.8014 0.493022 21.8746 0.613632 21.9246C0.734235 21.9745 0.863506 22.0002 0.994046 22.0002H5.81753C6.08112 22.0002 6.33401 21.8955 6.52039 21.7091C6.70683 21.5226 6.81154 21.2698 6.81154 21.0062C6.81154 20.7426 6.70683 20.4897 6.52039 20.3033C6.33401 20.1168 6.08112 20.0121 5.81753 20.0121H1.9881V16.183C1.9881 15.9194 1.88337 15.6665 1.69694 15.4801C1.51053 15.2937 1.25769 15.189 0.994046 15.189C0.730409 15.189 0.477573 15.2937 0.291148 15.4801C0.104729 15.6665 1.37179e-09 15.9194 1.37179e-09 16.183V21.0062Z" fill="white" />
            <path d="M22 16.183C22 15.9194 21.8953 15.6665 21.7089 15.4801C21.5224 15.2937 21.2696 15.189 21.0059 15.189C20.7424 15.189 20.4895 15.2937 20.3031 15.4801C20.1166 15.6665 20.0119 15.9194 20.0119 16.183V20.0121H16.1825C15.9188 20.0121 15.666 20.1168 15.4796 20.3033C15.2932 20.4897 15.1885 20.7426 15.1885 21.0062C15.1885 21.2698 15.2932 21.5226 15.4796 21.7091C15.666 21.8955 15.9188 22.0002 16.1825 22.0002H21.0059C21.1365 22.0002 21.2658 21.9745 21.3864 21.9246C21.507 21.8746 21.6166 21.8014 21.7089 21.7091C21.8012 21.6168 21.8744 21.5072 21.9244 21.3866C21.9743 21.266 22 21.1367 22 21.0062V16.183Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_862_929">
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default ScanIcon;
