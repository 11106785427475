import React,{memo} from 'react';


export const BurgerBlock = memo(({burgerClass,openMegaMenu}) => {

    return (
        <>
        <div className={burgerClass} onClick={() => openMegaMenu(true)} aria-label="Menu principal" role="button"
             aria-controls="navigation">
            <div></div>
            <div></div>
            <div></div>
        </div>
        </>
    )
});
export default BurgerBlock;