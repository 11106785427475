import {Fragment} from 'react'

const StoreSchedule = (props) => {

    function renderStoreScheduleBloc() {
        let openingSchedule = props.openingSchedule;
        let weekDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ];
        let displaySchedule = [];
        let date = new Date();
        let currentDay = weekDays[date.getDay()];
        if (openingSchedule) {
            openingSchedule.map((childrenItem, i) => {
                let dayName = (date.getDay() + i) % 7;
                let className = (childrenItem.isOpen == false) ? 'red' : (weekDays[dayName] === currentDay ? 'green' : '');
                displaySchedule.push(
                        <div className="store-fiche-tab-content__collapse__row" key={i}>
                            <span className={className}> {weekDays[dayName]}</span>
                            {(childrenItem.isOpen == false) ? <span className={className}> fermé</span> :
                                <span className={className}>
                                   {childrenItem.daySchedule.morning.startTime ? childrenItem.daySchedule.morning.startTime : null}
                                    {(childrenItem.daySchedule.morning.endTime && childrenItem.daySchedule.afterNoon.startTime) ? ' - ' + childrenItem.daySchedule.morning.endTime + ' | ' + childrenItem.daySchedule.afterNoon.startTime : null}
                                    {(childrenItem.daySchedule.morning.endTime && !childrenItem.daySchedule.afterNoon.startTime) ? ' - ' + childrenItem.daySchedule.morning.endTime : null}
                                    {(!childrenItem.daySchedule.morning.startTime && !childrenItem.daySchedule.morning.endTime) ? childrenItem.daySchedule.afterNoon.startTime : null}
                                    {childrenItem.daySchedule.afterNoon.endTime ? ' - ' + childrenItem.daySchedule.afterNoon.endTime : null}
                                    {childrenItem.isOpenAtNight == true ?
                                        childrenItem.daySchedule.morning.endTime || childrenItem.daySchedule.afterNoon.endTime ? ' | ' + childrenItem.daySchedule.night.startTime + ' - ' + childrenItem.daySchedule.night.endTime :
                                            childrenItem.daySchedule.night.startTime + ' - ' + childrenItem.daySchedule.night.endTime
                                        : null}

                                </span>
                            }
                        </div>
                );

            });
        }
        return displaySchedule;
    }

    return (
        <div className="store-fiche-tab-content__collapse">
            {renderStoreScheduleBloc()}
        </div>
    )
}

export default StoreSchedule;