import {Reducer} from "redux";
import {LastAddedElementState} from "@/models/scope/cart/LastAddedElementState";
import {LayoutCartProduct} from "@/models/scope/cart/LayoutCartProduct";
import {CART_GLD_UPDATED, CART_ITEMS_UPDATED, UPDATE_CHANGE_QUANTITY_STATE} from "@/redux/reducers/Mobile/actionsTypes";

const initialState: LayoutCartProduct = {
    cartObject: [],
    cartItemQuantityUpdateStatus: {itemId:'', status: ''},
    productId: "",
    denomination: "",
    imageUrl: "",
    soldBy: ""

};
const layoutCartReducer: Reducer<LastAddedElementState> = (state = initialState, action) => {
    switch (action.type) {
        case 'LAST_ADDED_ITEM':
            const { productId, denomination, imageUrl, soldBy } = action.payload;
            return {
                ...state,
                productId,
                denomination,
                imageUrl,
                soldBy,
            };
        case CART_ITEMS_UPDATED:
            return {
                ...state,
                cartObject : action.payload.cartObject
            }
        case UPDATE_CHANGE_QUANTITY_STATE:
            return {
                ...state,
                cartItemQuantityUpdateStatus: {...{itemId:action.payload.itemId, status:action.payload.stateValue}}
            }
        case CART_GLD_UPDATED:
            return {
                ...state,
                cartObject : action.payload.cartObject
            }
        default:
            return state;
    }

};

export default layoutCartReducer;
