import React, {memo, useMemo} from 'react';
import MenuStoreLocator from "./MenuStoreLocator";
import {MenuItems} from "./MenuItems";
import MenuContact from "./MenuContact";
import dynamic from 'next/dynamic'
import {dispatchEventSTPUSHUpdate} from "@/animations/Mobile/commonPage";
const MenuAccount= dynamic(() =>
        import('./MenuAccount'),
    { ssr: false }
)
export const Menu = memo((props) => {
    const memorizedMenuItems = useMemo(() =>
        <>
            <ul>
                <li className="level__title">Là pour vous</li>
                <li>
                    <a href="#" id="Menu_Lien_Aide_et_Contact_smart_tribune" className="level__btn gtm-menu-rubrique"
                       onClick={(event) => {
                           event.preventDefault();
                           dispatchEventSTPUSHUpdate(['classic-5560']);
                       }}
                    >
                        <div className="level__btn__left">
                            <div className="level__btn__left__icon">
                                <img src="/assets/images/Mobile/svg/aide.svg" alt=""/>
                            </div>
                            <span>Aide & contact</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a className="gtm-menu-item level__btn"
                       href="/Services">
                        <div className="level__btn__left">
                            <div className="level__btn__left__icon">
                                <span className="icon-i icon-2x"/>
                            </div>
                            <span>Nos services</span>
                        </div>
                    </a>
                </li>
            </ul>
            <ul>
                <li className="level__title">Les autres sites</li>
                <li className="level__custom--padding">
                    <div className="row pb-3 ">
                        <div className="col">
                            <a className="gtm-menu-item col"
                               href="http://www.but-cuisines.fr" target="_blank">
                                <span className="level__custom-btn">but-cuisines.fr</span>
                            </a>
                        </div>
                        <div className="col">
                            <a className="gtm-menu-item col"
                               href="https://blog.but.fr" target="_blank">
                                <span className="level__custom-btn">Le blog BUT</span>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </>, [])
    return (<nav id="nav" className={props.openMenu && 'menu-open'}>
            <div className="nav__header" id="navHeader">
                <a href="#" onClick={() => props.setOpenMenu(false)} data-gtm-libelle="fermer" className="nav__header__link close-nav-btn gtm-menu">
                    <span className="nav__header__link__icone"><i className="icon-2x icon-close_menu"/></span>
                    <span>Fermer</span>
                </a>
                <a href="/" className="nav__header__link">
                    <div className="nav__header__link__animation">
                        <span className="nav__header__link__icone"><img src="/assets/images/Mobile/svg/home-icon.svg" alt="icon acceuil"/></span>
                        <span>Accueil</span>
                    </div>
                </a>
                { (props.userData) &&
                    <MenuAccount
                        userGroup={props.userData.group}
                        userName={props.userData.firstName}
                        lastName={props.userData.lastName}
                        userId={props.userData.userId}
                    />
                }
                <MenuStoreLocator
                    shopId={props.shopId}
                    shopName={props.shopName}
                    shopUrl={props.shopUrl}
                />
                <MenuContact/>
            </div>
            <div className="nav__body" id="navBody">
                <div className="content">
                    <div className="level level--open">
                        <div className="level__container">

                            {props.menuItems &&
                                <MenuItems menuItems={props.menuItems} menuItemsLevels={props.menuItemsLevel}/>
                            }
                            {memorizedMenuItems}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )});