
import {
    CITY_SEARCHRESULTS_FETCHED,
    DELETE_CITYlIST_STATE, ERROR_CREATE_ORDER, INIT_CART_DATA_VIEW_SHIPPING,
    LOAD_CART_API_RESPONSE,
    SHIPPING_CART_ITEMS_UPDATED,
    UPDATE_ADDRESS_CITY,
    UPDATE_ADDRESS_CIVILITY,
    UPDATE_ADDRESS_FIRSTNAME,
    UPDATE_ADDRESS_LASTNAME,
    UPDATE_ADDRESS_LATITUDE_LONGITUDE,
    UPDATE_ADDRESS_PHONE_NUMBER,
    UPDATE_ADDRESS_STREET_ADDRESS,
    UPDATE_ADDRESS_STREET_ADDRESS_COMPLEMENT,
    UPDATE_ADDRESS_ZIPCODE,
    UPDATE_BILLING_ADDRESS_STATE_ATTRIBUTE, UPDATE_CREATION_FORM_DATA,
    UPDATE_ZIPCODE_KEYWORDS_IN_STATE, ERROR_BILLING_ADDRESS, UPDATE_CITY_AND_ZIPCODE_IN_STATE,
    CONDITION_OFFER_DETAILS, REMOVE_DISCOUNT_CODE, DISCOUNT_CODE_APPLIED
} from "@/redux/reducers/Mobile/actionsTypes";
import {ADDRESS_CHECKED} from "@/redux/reducers/responsive/actionsTypes";



const initialState = {
    cartObject: {},
    delayChangedMessage:'',
    paymentIsPendingMessage: '',
    billingAddress: {},
    billingAddressId: "",
    // isDQENormalisationActive: appData !== undefined ? appData.isDQENormalisationActive : true,
    relayPointErrorMessages:{},
    errorCartMessage: [],
    cityList: {},
    zipCodekeywords: '',
    citykeywords: '',
    latitude: '',
    longitude: '',
    inseeCode: '',
    errorsBillingAddress : {},
    rdvContactLessPickupVisualProducts: [],
    isDiscountCodeApplied: '',
    discountAmount: '',
    consolidationMessagesWithoutDelayChanged: '',
    conditionsOffer: 'Une erreur technique est survenue. Veuillez nous excuser pour la gêne occasionnée'

};


const ShippingReducer = (state = initialState, action) => {
    switch (action.type) {

        case  UPDATE_CREATION_FORM_DATA :
            let newShippingPopinState = {...state};
            newShippingPopinState[action.element.name] = action.element.value;
            return {
                ...state,
                ...newShippingPopinState,
            }

        case SHIPPING_CART_ITEMS_UPDATED:
            return {
                ...state,
                cartObject: action.payload.cartObject,
            }
        case ERROR_CREATE_ORDER:
            return {
                ...state,
                errorCreateOrder: action.payload.errorCreateOrder
            }


        case UPDATE_BILLING_ADDRESS_STATE_ATTRIBUTE:
            let newState = {
                ...state,
            }
            if (action.event.target.type == 'radio') {
                newState[event.target.name] = event.target.id
            } else {
                newState[event.target.name] = event.target.value
            }
            return newState

        case UPDATE_ADDRESS_CIVILITY : {
            let billingAdress = state.billingAddress
            billingAdress.civility = action.value
            return {
                ...state,
                billingAddress: billingAdress
            };

        }

        case UPDATE_ADDRESS_FIRSTNAME : {
            let billingAddress = state.billingAddress
            billingAddress.firstname = action.value
            return {
                ...state,
                billingAddress: billingAddress
            };
        }
        case UPDATE_ADDRESS_LASTNAME : {
            let billingAddress = state.billingAddress
            billingAddress.lastname = action.value
            return {
                ...state,
                billingAddress: billingAddress
            };
        }

        case UPDATE_ADDRESS_ZIPCODE : {
            let billingAddress = state.billingAddress
            billingAddress.zipCode = action.value
            return {
                ...state,
                billingAddress: billingAddress
            };
        }
        case UPDATE_ADDRESS_LATITUDE_LONGITUDE : {
            return {
                ...state,
                latitude: action.latitude,
                longitude: action.longitude,
                inseeCode: action.inseeCode,
            }
        }
        case UPDATE_ADDRESS_CITY : {
            let billingAddress = state.billingAddress
            billingAddress.city = action.value
            return {
                ...state,
                billingAddress: billingAddress
            };
        }

        case UPDATE_ADDRESS_STREET_ADDRESS : {
            let billingAddress = state.billingAddress
            billingAddress.streetAddress = action.value
            return {
                ...state,
                billingAddress: billingAddress
            };
        }

        case UPDATE_ADDRESS_STREET_ADDRESS_COMPLEMENT : {
            let billingAddress = state.billingAddress
            billingAddress.streetAddressComplement = action.value
            return {
                ...state,
                billingAddress: billingAddress
            };
        }

        case UPDATE_ADDRESS_PHONE_NUMBER : {
            if (action.addressType === "billing") {
                return {
                    ...state,
                    billingPhone: action.value
                };
            } else if (action.addressType === "shipping") {
                return {
                    ...state,
                    shippingPhone: action.value
                };
            }
        }
        case UPDATE_ZIPCODE_KEYWORDS_IN_STATE :
            if (action.context === 'stockLocator' || action.context === 'relayPointsModal') {
                return {
                    ...state,
                    stockLocatorZipCodekeywords: action.value,
                }
            } else if (action.addressType === "billing") {
                if (action.input == 'zipCode') {
                    return {
                        ...state,
                        zipCodekeywords: action.value,
                        billingZipCode: action.value,
                        shippingZipCodekeywords: '',
                        citykeywords: '',
                        shippingCitykeywords: '',
                        errorsBillingAddress: {}

                    }
                } else {
                    return {
                        ...state,
                        citykeywords: action.value,
                        billingCity: action.value,
                        zipCodekeywords: '',
                        shippingCitykeywords: '',
                        shippingZipCodekeywords: '',
                    }
                }
            } else {
                if (action.input == 'zipCode') {
                    return {
                        ...state,
                        shippingZipCodekeywords: action.value,
                        shippingZipCode: action.value,
                        zipCodekeywords: '',
                        citykeywords: '',
                        shippingCitykeywords: '',
                        errorsShippingAddress: {}

                    }
                } else {
                    return {
                        ...state,
                        shippingCitykeywords: action.value,
                        shippingCity: action.value,
                        citykeywords: '',
                        shippingZipCodekeywords: '',
                        zipCodekeywords: '',

                    }
                }
            }
        case CITY_SEARCHRESULTS_FETCHED :
            return {
                ...state,
                cityList: action.payload.locationsData,
            }
        case DELETE_CITYlIST_STATE :
            return {
                ...state,
                cityList: {},
            }
        case UPDATE_CITY_AND_ZIPCODE_IN_STATE :

            if (action.context === 'relayPointsModal') {
                return {
                    ...state,
                    relayPointCity: action.city,
                    relayPointZipCode: action.zipCode,
                    stockLocatorZipCodekeywords: action.zipCode,
                    cityList: {}
                }
            } else if (action.addressType === "billing") {
                return {
                    ...state,
                    billingCity: action.city,
                    billingZipCode: action.zipCode,
                    zipCodekeywords: action.zipCode,
                    citykeywords: action.city,
                    cityList: {}
                }
            } else {
                return {
                    ...state,
                    shippingCity: action.city,
                    shippingZipCode: action.zipCode,
                    shippingZipCodekeywords: action.zipCode,
                    shippingCitykeywords: action.city,
                    cityList: {}
                }
            }
        case LOAD_CART_API_RESPONSE :
            return {
                ...state,
                cartObject: action.payload.cartObject,
                consolidationMessagesWithoutDelayChanged: action.payload.pickUpShippingUpdatedMessage

            }
        case INIT_CART_DATA_VIEW_SHIPPING:
            return {
                ...state,
                cartObject: action.payload.cartObject,
                billingAddressId: action.payload.cartObject.billingAddressId,
                billingAddress: action.payload.cartObject.billingAddress,
                delayChangedMessage: (action.payload.cartObject?.consolidationMessages && action.payload.cartObject?.consolidationMessages.length > 0 && action.payload.cartObject?.consolidationMessages[0].code === "delay_changed") ?  action.payload.cartObject?.consolidationMessages[0].message : '',
                paymentIsPendingMessage :(action.payload.cartObject?.consolidationMessages && action.payload.cartObject?.consolidationMessages.length > 0 && action.payload.cartObject?.consolidationMessages[0].code === "payment_is_pending") ?  action.payload.cartObject?.consolidationMessages[0] : ''
            }
        case ERROR_BILLING_ADDRESS:
            return {
                ...state,
                errorsBillingAddress: action.payload.errorsBillingAddress
            }
        case ADDRESS_CHECKED:
            let newStateObject = {
                ...state,
                showRNVPBlock: action.payload.showRNVPBlock,
                validateFormAndSubmitFunction: action.payload.validateFormAndSubmitFunction,
                redirectToIfRNVPNotValidate: (action.payload.redirectToIfRNVPNotValidate) ?
                    action.payload.redirectToIfRNVPNotValidate : null
            }
            if (action.payload.type) {
                newStateObject.type = action.payload.type
            }
            newStateObject.rndvBillingAddressData = action.payload.billingAddressData ? action.payload.billingAddressData : []
            return newStateObject
        case CONDITION_OFFER_DETAILS: {
            return {
                ...state,
                conditionsOffer: action.payload.conditionsOffer
            }
        }
        case DISCOUNT_CODE_APPLIED: {
            let isDiscountCodeApplied = action.payload.isDiscountCodeApplied === 'discountcode_applied';
            let discountAmount = isDiscountCodeApplied ? action.payload.discountAmount : '';
            return {
                ...state,
                cartObject: action.payload.cartObject,
                isDiscountCodeApplied: isDiscountCodeApplied,
                discountAmount: discountAmount
            }
        }
        case REMOVE_DISCOUNT_CODE: {
            return {
                ...state,
                discountCode: '',
                isDiscountCodeApplied: '',
                discountAmount: '',
                cartObject: action.payload.cartObject,
                consolidationMessagesWithoutDelayChanged: action.payload.consolidationMessagesWithoutDelayChanged
            }
        }
        default:
            return state
    }

};

export default ShippingReducer;
