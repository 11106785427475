import Cookies from 'js-cookie'
import Utils from "@/utils/Default/Utils"

export const initAppDataKeyValues = (navigationMetaInfosConfigs, octipasPayload, trackingUrlFapi, currentStep, mvcUrl, isDQENormalisationActive, shopCircleVersion, logoVersion, olapicConfig = null, context = '', availabilityDisplayMode = '', isWebloyaltyActive = false, deviceType = 'Default', onlyInitAppDataVar = false, isPlanner3dActive = false) => {
    appData = appData || {}
    if (!onlyInitAppDataVar) {
        appData.navigationMetaInfosConfigs = navigationMetaInfosConfigs;
        appData.sparkowUserIdPatternName = 'cuserid';
        appData.currentStep = currentStep;
        appData.isDQENormalisationActive = isDQENormalisationActive;
        appData.webUserId = Utils.getUserIdFromCookie();
        appData.shopCircleVersion = shopCircleVersion;
        appData.mvcUrl = mvcUrl;
        appData.sparkowUserIdCookieName = 'CUserID';
        appData.trackingUrlFapi = trackingUrlFapi;
        appData.octipasCuserId = Cookies.get('CUserID');
        appData.octipasPayload = octipasPayload;
        appData.logoVersion = logoVersion;
        appData.isWebloyaltyActive = isWebloyaltyActive;
        appData.deviceType = deviceType;
        appData.context = context;
        appData.availabilityDisplayMode = availabilityDisplayMode;
        appData.olapicConfig = olapicConfig;
        appData.isPlanner3dActive = isPlanner3dActive;
    }
}

export const getElementValueBySelector = (selector) => {
    return $(selector).val();
}
export const setElementValueBySelector = (selector, value) => {
    return $(selector).val(value);
}
export const resetElementValueBySelector = (selector) => {
    $(selector).bind('click', function (event, arg) {
        if (typeof arg === 'undefined') {
            $(selector).val('');
        }
    });
}

export const refreshCarouselBySelector = (selector) => {
    $(selector).trigger('refresh.owl.carousel');
}
export const getCookieValue = (cookieName) => {
    return Cookies.get(cookieName)
}
export const setCookieValue = (cookieName, value) => {
    Cookies.set(cookieName, value)
}
export const dispatchEventSTPUSHUpdate = (tags) => {
    window.dispatchEvent(
        new CustomEvent('STPUSHUpdate', {
            detail: {
                filters: {
                    tags: tags,
                    thematics: [],
                },
            },
        }),
    )
}