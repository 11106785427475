import {
    FETCH_ORDER_DATA,
    FETCH_ORDER_LIST_DATA,
    MESSAGES_HISTORY,
    CLOSE_INCIDENT_REASONS_LISTED,
    REASONS_LISTED,
    INVOICE_REQUEST_SENT,
    MESSAGE_SENT_WITH_FILE,
    MESSAGE_SENT,
    MESSAGE_FAILED,
    FETCH_ORDER_SHOP_DATA,
    MESSAGE_SENT_INCIDENT,
    SHOW_LOADER,
    OPERATOR_MESSAGE,
    CONFIRM_RECEPTION_ORDER,
    UPDATE_BREADCRUMB_DATA,
    INCIDENT_CLOSED,
    UPDATE_CURRENT_PAGE_DATA,
    RESET_ORDER_DETAILS
} from '../actionsTypes'
import {
    confirmReceptionMarketPlaceOrder,
    getMessages,
    getOrderDetails,
    getOrdersListByUserId,
    getReasons,
    uploadFilesToMiraklAPi,
    createIncident,
    sendMessageContent,
    sendRequest,
    sendMessageWithFile,
    closeIncidentTopic
} from '../../../../services/orderServices'
import Utils from "@/utils/Default/Utils";
import customerOrderAnimation from "@/animations/responsive/customerOrder";
import {getOrderShopDataByShopId} from "@/services/shopServices";
import {
    SELLER_INCIDENT_CONTEXT,
    SELLER_WITH_INCIDENT_CONTEXT,
    OPERATOR_MESSAGE_CONTEXT,
    SELLER_MESSAGE_WITH_FILE_CONTEXT,
    SELLER_MESSAGE_CONTEXT,
    SELLER_INCIDENT_WITH_FILE_CONTEXT
} from "@/utils/constants";

export const fetchOrdersListData = (userId, offset, size, previousOrders = null, context = null) => {
    return (dispatch) => {
        getOrdersListByUserId(userId, offset, size)
            .then(response => {
                let items = response.orders
                if (response.orders && previousOrders?.length > 0 && context === "seeMore") {
                    items = previousOrders.concat(response.orders)
                }
                dispatch({
                    type: FETCH_ORDER_LIST_DATA,
                    payload: {items: items}
                });
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    Utils.redirectTo("/Customer/Account/Identification")
                }
                console.error('ERROR', "Api/Rest/Customers/{id}/Orders Api responded failed : " + error)
            })
    }
}

export const getOrderShopData = (shopId) => {
    return (dispatch) => {
        getOrderShopDataByShopId(shopId)
            .then(response => {
                dispatch({
                    type: FETCH_ORDER_SHOP_DATA,
                    payload: {shopData: response.data}
                });
            })
            .catch((error) => {
                console.log(error);
            })
    };
}

export const fetchOrderData = (userId, orderId) => {
    return (dispatch) => {
        getOrderDetails(userId, orderId)
            .then(response => {
                dispatch({
                    type: FETCH_ORDER_DATA,
                    payload: {order: response}
                });
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    Utils.redirectTo("/Customer/Account/Identification")
                }
                console.error('ERROR', "Api/Rest/Customers/{Id}/Orders/{OId}/Items Api responded failed : " + error)
            })
    }
}

export const confirmReceptionOrder = (childOrderId) => {
    return function (dispatch) {
        confirmReceptionMarketPlaceOrder(childOrderId)
            .then(response => {
                dispatch({
                    type: CONFIRM_RECEPTION_ORDER,
                });
            })
            .catch((error) => {
                console.error('ERROR', "Marketplace/LogisticOrders/{childOrderId} Api responded failed : " + error)
            })
    }
}


export const getMessagesList = (customerId, logisticOrderId) => {
    return (dispatch, getState) => {
        getMessages(customerId, logisticOrderId)
            .then(response => {
                let messagesList = getState().orderDetails?.messages;
                let newTab = [];
                const key = Object.keys(response?.messages)[0];
                const isKeyExist = Object.keys(messagesList).some(e => e === key);
                const values = Object.values(response?.messages)[0];
                if (!isKeyExist) {
                    messagesList[key] = values
                } else {
                    for (let i = 0; i < values.length; i++) {
                        let isExist = messagesList[key].some(value => value.commercialId === values[i].commercialId)
                        if (!isExist) {
                            newTab = [...newTab, values[i]]
                        }
                    }
                    let newMessagesList = [...newTab, ...messagesList[key]];
                    messagesList[key] = newMessagesList.sort((a, b) => {
                        return Utils.getTime(b.createdDate) - Utils.getTime(a.createdDate)
                    })
                }
                dispatch({
                    type: MESSAGES_HISTORY,
                    payload: {
                        messages: messagesList
                    }
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const getReasonsList = (item, reasonType, childOrder, orderId, orderTime, orderDate) => {
    return (dispatch) => {
        getReasons(reasonType)
            .then(response => {
                if (reasonType === 'INCIDENT_CLOSE') {
                    dispatch({
                        type: CLOSE_INCIDENT_REASONS_LISTED,
                        payload: {
                            closeIncidentReasons: response?.reasons,
                            reasonType: reasonType,
                            selectCloseIncidentFirstClick: true
                        }
                    });
                } else {
                    dispatch({
                        type: REASONS_LISTED,
                        payload: {
                            reasonsList: response?.reasons,
                            selectedChildOrder: childOrder,
                            selectedOrderId: orderId,
                            selectedItem: item,
                            selectedOrderTime: orderTime,
                            selectedOrderDate: orderDate,
                            reasonType: reasonType
                        }
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const sendMessage = (logisticOrderId, messageData, context, selectItemInfos, sellerName, orderInfos = null) => {
    const messageRequestSentContext = (!selectItemInfos?.canOpenIncident && (messageData.context && messageData.context !== OPERATOR_MESSAGE_CONTEXT && messageData.context !== SELLER_MESSAGE_WITH_FILE_CONTEXT)) ? SELLER_INCIDENT_CONTEXT : context;
    return (dispatch) => {
        if (context === OPERATOR_MESSAGE_CONTEXT) {
            dispatch({
                type: SHOW_LOADER,
                payload: {
                    showLoader: true
                }
            })
        }
        sendMessageContent(logisticOrderId, messageData, context, selectItemInfos)
            .then(() => {
                dispatch(
                    handleSendMessageSuccessfulResponse(messageData.customerId, context, logisticOrderId, selectItemInfos?.canOpenIncident, messageRequestSentContext, sellerName, orderInfos)
                )
            })
            .catch((error) => {
                console.log("error", error)
                dispatch(handleFailedResponse(logisticOrderId, context, null));
            })
    }
}

export const handleSendMessageSuccessfulResponse = (customerId, context, logisticOrderId, canOpenIncident, messageRequestSentContext, sellerName, orderInfos) => {
    return (dispatch) => {
        if (context === "invoiceRequest") {
            dispatch({
                type: INVOICE_REQUEST_SENT,
                payload: {
                    messageRequestSentContext: messageRequestSentContext
                }
            })
        } else {
            if (context === SELLER_MESSAGE_WITH_FILE_CONTEXT) {
                dispatch({
                    type: MESSAGE_SENT_WITH_FILE
                })
            }
            if (context != SELLER_INCIDENT_CONTEXT && context != SELLER_WITH_INCIDENT_CONTEXT && context != OPERATOR_MESSAGE_CONTEXT) {
                dispatch({
                    type: MESSAGE_SENT,
                    payload: {
                        messageRequestSentContext: messageRequestSentContext,
                        messageContent: "",
                        selectedReasonCode: "Choix",
                        reasonSelectedLabel: "",
                        reasonSelectedType: "",
                        orderItemDetailsDisplay: false
                    }
                });
            } else {
                dispatch({
                    type: MESSAGE_SENT_INCIDENT,
                    payload: {
                        messageRequestSentContext: messageRequestSentContext,
                        messageContent: "",
                        orderItemDetailsDisplay: false
                    }
                });
            }
            dispatch(
                getMessagesList(customerId, logisticOrderId)
            )
            if (context === 'operatorMessage') {
                dispatch({
                    type: OPERATOR_MESSAGE,
                    payload: {
                        showLitige: true,
                        canRequestInterventionBut: false,
                        orderInfos: orderInfos
                    }
                })
                dispatch({
                    type: SHOW_LOADER,
                    payload: {
                        showLoader: false,
                    }
                });
            }
            if (context === SELLER_MESSAGE_WITH_FILE_CONTEXT || context === SELLER_MESSAGE_CONTEXT || context === SELLER_INCIDENT_CONTEXT || context === SELLER_WITH_INCIDENT_CONTEXT) {
                customerOrderAnimation.scrollToDivAnimation($('.MessagePage')[0]);
            }
        }
    }
}

export const openIncident = (orderId, messageDataForIncident, logisticOrderId, orderLineId, ean13, messageData, uploadFilesToMiraklUrl) => {
    return (dispatch) => {
        uploadFilesToMiraklAPi(uploadFilesToMiraklUrl, JSON.stringify({
            orderId: orderId,
            requestData: messageDataForIncident
        }), {
            overrideHeaders: true,
            withoutDefaultContentType: true,
            rowData: true,
            headers: {'Content-Type': 'application/json'}
        }).then(() => {
            createIncident('/Marketplace/LogisticOrders/' + logisticOrderId + '/Items/' + orderLineId + '/IncidentOpened?ean_13=' + ean13 + '&order_id=' + orderId, messageData)
                .catch((error) => {
                    console.error(error);
                })
        }).catch((error) => {
            console.error(error);
        })
    }
}

export const sendRequestToMirakl = (customerId, selectedFiles, orderId, requestData, context, canOpenIncident, sellerName, uploadFilesToMiraklUrl) => {
    return new Promise((resolve, reject) => {
            sendRequest(uploadFilesToMiraklUrl, JSON.stringify({
                orderId: orderId,
                requestData: requestData
            }), {
                overrideHeaders: true,
                withoutDefaultContentType: true,
                rowData: true,
                headers: {'Content-Type': 'application/json'}
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error)
            })
            if (context === SELLER_MESSAGE_WITH_FILE_CONTEXT || context === SELLER_MESSAGE_CONTEXT || context === SELLER_INCIDENT_CONTEXT || context === SELLER_INCIDENT_WITH_FILE_CONTEXT) {
                customerOrderAnimation.scrollToDivAnimation($('.MessagePage')[0]);
            }
        }
    )
}

export const handleOpenIncidentWithoutFile = (customerId, selectedFiles, logisticOrderId, requestData, context, sellerName, uploadFilesToMiraklUrl, messageDataForIncident, selectItemInfos) => {
    return (dispatch) => {
        sendRequestToMirakl(customerId, selectedFiles, logisticOrderId, requestData, context, false, sellerName, uploadFilesToMiraklUrl)
            .then(response => {
                if (response?.status === 201) {
                    if (context == 'sellerMessageWithFile') {
                        dispatch(
                            handleSendMessageSuccessfulResponse(customerId, context, logisticOrderId, selectItemInfos.canOpenIncident, 'sellerMessageWithFile', sellerName)
                        );
                    } else {
                        dispatch(
                            handleOpenIncidentSuccessfulResponse(context, logisticOrderId, customerId, sellerName)
                        )
                    }
                } else {
                    throw "Une erreur est survenue.";
                }
            })
            .then(() => {
                if (selectItemInfos.canOpenIncident) {
                    dispatch(openIncident(selectItemInfos.orderId, messageDataForIncident, logisticOrderId, selectItemInfos.orderLineId, selectItemInfos.ean13, requestData, uploadFilesToMiraklUrl))
                }
            })
            .catch((error) => {
                    console.error(error);
                    dispatch(
                        handleFailedResponse(logisticOrderId, context, error)
                    );
                }
            )
    }
}

export const handleOpenIncidentWithFile = (customerId, files, logisticOrderId, requestData, context, sellerName, messageDataForIncident, messageData, selectItemInfos, miraklApiParameters) => {
    return (dispatch) => {
        dispatch(sendMessageWithFile(customerId, files, logisticOrderId, requestData, context, false, sellerName, miraklApiParameters.uploadFilesToMiraklUrl, miraklApiParameters.awsS3Url))
            .then(() => {
                if (selectItemInfos.canOpenIncident) {
                    dispatch(openIncident(selectItemInfos.orderId, messageDataForIncident, logisticOrderId, selectItemInfos.orderLineId, selectItemInfos.ean13, messageData, miraklApiParameters.uploadFilesToMiraklUrl))
                }
            })
            .catch((error) => {
                    console.error(error);
                    dispatch(
                        handleFailedResponse(logisticOrderId, context, error)
                    );
                }
            )
    }
}

export const handleFailedResponse = (logisticOrderId, context, error) => {
    return (dispatch) => {
        let isValidSizeMsg = (error?.response?.data?.code === 417);
        let errorMessage = '';
        if (error?.response?.status && error?.response?.status == 400 && error?.response?.data?.code === 418) {
            if (error?.response?.data?.message && (error?.response?.data?.message.indexOf('file type is not supported') != -1 || error?.response?.data?.message.indexOf('unsupported format') != -1)) {
                errorMessage = wordingManager.__('ATTACHMENT_INVALID_MEDIA_TYPE')
            }
        }
        dispatch({
            type: MESSAGE_FAILED,
            messageRequestSentContext: context + 'Failed',
            messageServerErrorResponse: (error?.response?.status && error?.response?.status === 400) ? (error?.response?.data?.message ? (error?.response?.data?.code == 418) ? errorMessage : error?.response?.data?.message : '') : '',
            validSizeServer: !isValidSizeMsg
        });
        if (context === 'operatorMessage') {
            dispatch({
                type: SHOW_LOADER,
                payload: {
                    showLoader: false
                }
            });
        }
        customerOrderAnimation.scrollToDivAnimation($('.MessagePage')[0]);
    }
}

export const handleOpenIncidentSuccessfulResponse = (context, logisticOrderId, customerId) => {
    return function (dispatch) {
        dispatch({
            type: MESSAGE_SENT_INCIDENT,
            payload: {
                messageRequestSentContext: context,
                messageContent: "",
                orderItemDetailsDisplay: false
            }
        });
        if (context === 'sellerIncidentWithFile') {
            dispatch({
                type: MESSAGE_SENT_WITH_FILE
            });
        }
        dispatch(
            getMessagesList(customerId, logisticOrderId)
        )
    }
}

export const openIncidentWithoutFile = (customerId, logisticOrderId, requestData, context, marketplaceIncidentData, sellerName, selectItemInfos, uploadFilesToMiraklUrl) => {
    let sendRequest = true;
    for (const item of requestData?.body) {
        if (item.required && item.value.length === 0) {
            sendRequest = false;
            break
        }
    }
    if (sendRequest) {
        return (dispatch) => {
            dispatch(handleOpenIncidentWithoutFile(customerId, [], logisticOrderId, requestData, context, sellerName, uploadFilesToMiraklUrl, prepareBodyDataForIncident(context, marketplaceIncidentData, logisticOrderId, selectItemInfos.orderLineId), selectItemInfos)
            )
        }
    } else {
        return (dispatch) => {
            dispatch(handleFailedResponse(logisticOrderId, context, null));
        }
    }
}

export const openIncidentWithFile = (customerId, files, logisticOrderId, requestData, messageData, context, marketplaceIncidentData, sellerName, selectItemInfos, miraklApiParameters) => {
    let sendRequest = true;
    for (const item of requestData?.body) {
        if (item.required && item.value.length === 0) {
            sendRequest = false;
            break;
        }
    }
    if (sendRequest) {
        return (dispatch) => {
            dispatch(handleOpenIncidentWithFile(customerId, files, logisticOrderId, requestData, context, sellerName, prepareBodyDataForIncident(context, marketplaceIncidentData, logisticOrderId, selectItemInfos.orderLineId), messageData, selectItemInfos, miraklApiParameters))
        }
    } else {
        return function (dispatch) {
            dispatch(handleFailedResponse(logisticOrderId, context, null));
        }
    }
};

export const prepareBodyDataForIncident = (context, marketplaceIncidentData, orderId, miraklId) => {
    let preparedIncidentData = [];
    if (marketplaceIncidentData?.reasonCode) {
        preparedIncidentData = [
            {
                name: "reason_code",
                value: marketplaceIncidentData.reasonCode,
                type: "string"
            }
        ];
    }
    return {
        url: '/api/orders/:logisticOrderId/lines/:orderItemId/open_incident',
        queryParams: [
            {
                code: "logisticOrderId",
                value: orderId
            },
            {
                code: "orderItemId",
                value: miraklId
            }
        ],
        method: "POST",
        body: preparedIncidentData,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
}
export const updateBreadCrumb = (levelsNavigationLocal) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_BREADCRUMB_DATA,
            payload: {levelData: levelsNavigationLocal}
        });

    };
}
export const updateCurrentPage = (currentPage) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_CURRENT_PAGE_DATA,
            payload: {currentPage: currentPage}
        });

    };
}

export const resetOrderDetailsData = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_ORDER_DETAILS
        });
    };
}

export const closeIncident = (logisticOrderId, incidentCloseData, childOrderIndex, index, commandLineId) => {
    return function (dispatch) {
        dispatch({
            type: SHOW_LOADER,
            payload: {
                showLoader: true
            }
        })
        closeIncidentTopic(logisticOrderId, commandLineId, incidentCloseData)
            .then(() => {
                dispatch({
                    type: INCIDENT_CLOSED,
                    payload: {
                        closeIncidentSuccess: true,
                        childOrderIndex: childOrderIndex,
                        index: index
                    }
                });
                dispatch({
                    type: SHOW_LOADER,
                    payload: {
                        showLoader: false
                    }
                })
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: INCIDENT_CLOSED,
                    payload: {
                        closeIncidentSuccess: false
                    }
                });
                dispatch({
                    type: SHOW_LOADER,
                    payload: {
                        showLoader: false
                    }
                })
            })
    }
}
