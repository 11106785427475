import {APPLYFILTERSBLOCK,APPLYFILTERS,APPLYSORT,RESETFILTERS,HANDLENAVIGATION,FETCHITEMFROMNAVIGATION,PRODUCTLIST_FETCHED,PRODUCTS_AVAILABILITY_DATA_FETCHED} from '../actionsTypes'


const initialState = {
    productsListData : [],
    filters : [],
    changeFromClient : false ,
    itemsCount : 0,
    appliedFiltersValues : [],
    productsAvailabilityData: [],
    previousPager: null,
    pager: null,
    isFirstLoad: true,
    octipasPayload : ''
};

const productListReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case APPLYFILTERS:
            return {
                ...state,
                productsListData : action.payload.productsListData,
                filters : action.payload.filters,
                itemsCount : action.payload.itemsCount,
                changeFromClient: true,
                appliedFiltersValues : action.payload.appliedFiltersValues,
                pager: action.payload.pager,
                previousPager: action.payload.pager,
                octipasPayload :action.payload.octipasPayload
            }
        case APPLYFILTERSBLOCK:
            return {
                ...state,
                filters : action.payload.filters,
                productsListData : action.payload.productsListData ? action.payload.productsListData : state.productsListData,
                itemsCount : action.payload.itemsCount,
                changeFromClient: true,
                appliedFiltersValues : action.payload.appliedFiltersValues,
                octipasPayload : action.payload.octipasPayload
            }
        case APPLYSORT:
            return {
                ...state,
                changeFromClient: true,
                productsListData : action.payload.productsListData,
                filters : action.payload.filters,
                itemsCount : action.payload.itemsCount,
                pager: action.payload.pager,
                previousPager: action.payload.pager,
                octipasPayload : action.payload.octipasPayload
            }
        case RESETFILTERS:
            return {
                ...state,
                filters : action.payload.filters,
                changeFromClient: true,
                productsListData : action.payload.productsListData,
                currentFiltersValue : action.payload.currentFiltersValue,
                appliedFiltersValues : action.payload.currentFiltersValue,
                itemsCount : action.payload.itemsCount,
                pager: action.payload.pager,
                previousPager: action.payload.pager,
                octipasPayload : action.payload.octipasPayload
            }
        case HANDLENAVIGATION:
            return {
                ...state,
                changeFromClient: true,
                productsListData : action.payload.productsListData,
                pager: action.payload.pager,
                previousPager: action.payload.previousPager,
                filters: action.payload.filters,
                itemsCount : action.payload.itemsCount,
                octipasPayload : action.payload.octipasPayload
            }
        case FETCHITEMFROMNAVIGATION:
            return {
                ...state,
                productsListData : action.payload.productsListData,
                appliedFiltersValues: action.payload.appliedFiltersValues,
                filters: action.payload.filters,
                changeFromClient: true,
                itemsCount: action.payload.itemsCount
            }
        case PRODUCTLIST_FETCHED:
            return {
                ...state,
                productsListData : action.payload.productsListData,
                appliedFiltersValues: action.payload.appliedFiltersValues,
                filters: action.payload.filters,
                changeFromClient: true,
                itemsCount: action.payload.itemsCount,
                navigationMetaInfosConfigs: action.payload?.navigationMetaInfosConfigs,
                octipasPayload: action.payload?.octipasPayload,
                trackingUrlFapi: action.payload?.trackingUrlFapi
            }
        case PRODUCTS_AVAILABILITY_DATA_FETCHED:
            return {
                ...state,
                productsAvailabilityData : action.payload.productsAvailabilityData,
                isFirstLoad : false
            }
        default:
            return state;
    }
    }


export default productListReducer;
