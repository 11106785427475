import {SCAN_ACTIVATION_ERROR_HANDLER} from "../../responsive/actionsTypes";


const initialState = {
    errors : {
        isErrorGeolocHS : false,
        isErrorCamRefused : false,
        isErrorOutOfShop : false,
        isErrorShopNotEligible : false,
        isErrorGeolocRefused : false,
    }
};

const scanReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCAN_ACTIVATION_ERROR_HANDLER :
            let errorsPreviousValue = state.errors
            return {
                ...state,
                errors: {...errorsPreviousValue , ...action.payload.errors},

            }
        default:
            return state;
    }
};

export default scanReducer;
