import ApiCaller from '../../../../services/ApiCaller/apiCallerDefault'
import * as productSheetAnimations from "@/animations/Default/productSheetPage"
import * as headerActions from "../header/headerActions";
import OctipasMerch from "@/utils/Default/OctipasMerch";
import Utils from "@/utils/Default/Utils";
import {
    AVAILABILITY_DATA_FETCHED,
    AVAILABILITY_DATA_FETCHED_STOCKLOCATOR,
    AVAILABILITYBUNDLEDATA_FETCHED,
    CART_DATA_UPDATE,
    UPDATE_SHOPCIRCLE,
    UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS,
    UPDATE_SHOW_LOADER_STATUS
} from '../actionsTypes'

export const fetchProductAvailabilities = params => {
    return function (dispatch) {
        if (params.allShops === true) {
            productSheetAnimations.showLoaderAvailability()
        }
        if (Utils.isValidShopCircle() && jQuery.parseJSON(localStorage.getItem('shopCircle'))["primaryShop"] && !params.hasOwnProperty('shopWasUpdated')) {
            let ShopId = jQuery.parseJSON(localStorage.getItem('shopCircle'))["primaryShop"].id
            const url = "/WebUsers/" + Utils.getUserIdFromCookie() + "/Shops"
            ApiCaller.put(url, {
                CalculationParams: ShopId,
                CalculationMethod: "AroundShop"
            }).then(response => {
                dispatch({
                    type: UPDATE_SHOPCIRCLE,
                    payload: { shopCircleData: response.data }
                })
            }).catch((error) => {
                //TODO SES thow exception
                console.log(error)
            })

        } else {
            dispatch({
                type: UPDATE_SHOPCIRCLE,
                payload: { shopCircleData: Utils.isValidShopCircle() ? jQuery.parseJSON(localStorage.getItem('shopCircle')) : null }
            })
        }

        ApiCaller.get('/Catalog/Products/Availabilities?' + Utils.getProductAvailabilityApiParams(params.allShops, params.productId))
            .then(response => {
                let availabilityData = null
                if (params && params.hasOwnProperty('context') && params.context === 'productSheet') {
                    productSheetAnimations.applyScrollNav()
                }

                //cas rattaché ou non rattaché
                if (response.data[0] && response.data[0].detailedAvailablity) {
                    availabilityData = response.data[0]
                } else if (response.data[0] && response.data[0].availabilityByShops && response.data[0].availabilityByShops[0]) {
                    availabilityData = response.data[0]
                    let shop = response.data[0].availabilityByShops[0].shop
                    dispatch(
                        headerActions.fetchShopData(shop)
                    )
                    dispatch({
                        type: UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS,
                        element: {
                            value: false
                        }
                    })
                }
                if (response.data[0] && !response.data[0].availabilityByShops) {
                    dispatch({
                        type: UPDATE_STOCK_LOC_GEO_ERROR_BLOC_STATUS,
                        element: {
                            value: true
                        }
                    })
                }
                if (params.allShops === true) {
                    dispatch({
                        type: UPDATE_SHOW_LOADER_STATUS,
                        element: { value: false }
                    })
                    productSheetAnimations.hideLoaderAvailability()
                    dispatch({
                        type: AVAILABILITY_DATA_FETCHED_STOCKLOCATOR,
                        payload: {
                            availabilityData: availabilityData,
                        }
                    })
                } else if (params.isBundle && params.isBundle == "isBundle") {
                    dispatch({
                        type: AVAILABILITYBUNDLEDATA_FETCHED,
                        payload: {
                            availabilityBundleData: availabilityData
                        }
                    })
                } else {
                    if (params.didShopChanged && appData.linkedPacks && appData.linkedPacks.length > 0) {
                        let bundlesIds = '';
                        appData.linkedPacks.forEach(linkedPack => {
                            bundlesIds += linkedPack.id + ',1;'
                        }
                        )
                        ApiCaller.get('/Catalog/Products/Availabilities?' + Utils.getProductAvailabilityApiParams(params.allShops, bundlesIds.substring(0, bundlesIds.length - 3)))
                            .then(response => {

                                availabilityData = response.data
                                dispatch({
                                    type: AVAILABILITYBUNDLEDATA_FETCHED,
                                    payload: {
                                        availabilityBundleData: availabilityData,
                                        allBundles: true
                                    }
                                })
                            })
                    }
                    dispatch({
                        type: AVAILABILITY_DATA_FETCHED,
                        payload: {
                            availabilityData: availabilityData,
                            availabilityByShops: availabilityData?.availabilityByShops,
                            detailedAvailablity: availabilityData?.detailedAvailablity,
                            genericAvailability: availabilityData?.genericAvailability,
                        }
                    })
                }
            })
            .catch((error) => {
                console.log('error')
                //TODO SES thow exception
                console.log(error)
            })
    }
}

export function addCart(productId, quantity, trackingData = null, pricesToDisplay = null) {
    let url = "/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=compact"
    let addedFrom = 'nav'
    let hasOlapicContent = productSheetAnimations.checkOlapicBloc();
    if (appData.comingFrom == 't2s') {
        addedFrom = 't2s'
    }
    let data = {
        action: "addProducts",
        items: [{ id: productId, qty: quantity, addedFrom: addedFrom, hasOlapicContent: hasOlapicContent }]
    }
    return function (dispatch) {
        ApiCaller.put(url, data)
            .then(response => {
                if (response.data.consolidationMessages[0] && (response.data.consolidationMessages[0]['code'] === 'prodcut_added_to_cart' || response.data.consolidationMessages[0]['code'] === 'cart_stock_reservation' || response.data.consolidationMessages[0]['code'] === 'prodcut_added_from_t2s_to_cart')) {
                    if (pricesToDisplay) {
                        productSheetAnimations.addToCartPopInAnimations(quantity, pricesToDisplay.price, pricesToDisplay.crossedOutPrice, null, productId)
                    } else {
                        productSheetAnimations.addToCartPopInAnimations(quantity, null, null, null, productId);
                    }
                    productSheetAnimations.sendCartItemsToT2s(response.data.items, productId);
                    if (trackingData !== null) {
                        appData.actionTrigger.dispatch({
                            trigger: "addToCartProductSheet",
                            payload: {
                                eventLabel: trackingData.eventLabel,
                                price: trackingData.price,
                                productVendorType: trackingData.productVendorType,
                                productVendorName: trackingData.productVendorName,
                                productVendorId: trackingData.productVendorId,
                                productOffersNb: trackingData.productOfferNb,
                                quantity: quantity
                            }
                        });
                    }
                    OctipasMerch.createOrReplaceBasket(Utils.getBasketData(response.data.items))
                } else {
                    appData.actionTrigger.dispatch({
                        trigger: "addToCartFailed"
                    });
                    productSheetAnimations.showPopInError()
                }
                dispatch({
                    type: CART_DATA_UPDATE,
                    payload: {
                        cart: response.data,
                    }
                });
            })
            .catch((error) => {
                appData.actionTrigger.dispatch({
                    trigger: "addToCartFailed"
                });
                productSheetAnimations.showPopInError()
                //TODO SES thow exception
                console.log(error)
            })
    }
}

export function handleEmailChange(email) {
    return function (dispatch) {
        dispatch({
            type: HANDLE_EMAIL_CHANGE,
            payload: { email: email }
        })


    }
}

