import React, { MouseEventHandler } from "react";

interface Props {
    type?: "primary" | "secondary" | "tertiary" | 'adaptive' | 'black',
    size?: "large" | "medium" | "small" | "xsmall",
    callBack?: MouseEventHandler,
    onClick?: MouseEventHandler
    onFocus?: () => void;
    enabled?: boolean,
    children?: JSX.Element[] | JSX.Element,
    href?: string,
    isLink?: boolean,
    className?: string;
}

export const Button = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<Props>>(({
    onClick,
    onFocus,
    type = "primary",
    size = "large",
    callBack,
    enabled = true,
    children,
    href = "#",
    isLink = false,
    className = "",
},
    ref) => {
    return (
        isLink ? <a className={`button ${type} ${size}`} href={href} onClick={onClick} ref={ref}> {children} </a>
            : <button
                disabled={!enabled}
                className={`${className} button ${type} ${size}`}
                onFocus={onFocus}
                onClick={(e) => enabled && callBack ? callBack(e) : undefined}
            >
                {children}
            </button>
    )
});