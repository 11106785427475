import ApiCaller from './ApiCaller/apiCaller'
import {createCustomerMetaInfos} from "@/utils/responsive/utils";

export const fetchSocialMediaDataRequest = (userId) => {
    const url = '/Customers/' + userId + '/SocialMedia'
    return new Promise((resolve, reject) => {
        ApiCaller.get(url)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error)
            });
    });
};

export const getUserInfoProviders = (reachFiveApiDomain, reachFiveId, token) => {
    return new Promise((resolve, reject) => {
        const url = reachFiveApiDomain + '/api/v2/users/' + reachFiveId + '?fields=id,email,gender,given_name,name,family_name,birthdate,providers,identities,emails'
        ApiCaller.get(url, {
            methode: 'getUserInfoProviders',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            overrideHeaders: true
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{reachFiveId} Api reachFive responded failed : " + error)
                reject(error)
            });
    });
};


export const providerDissociatedHandler = (reachFiveApiDomain, userInfo, token) => {

    return new Promise((resolve, reject) => {
        const url = reachFiveApiDomain + '/api/v2/users/' + userInfo.id
        ApiCaller.delete(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            overrideHeaders: true
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{userInfo} Api reachFive responded failed : " + error)
                reject(error)
            });
    });

}

export const removeProvider = (reachFiveApiDomain, provider, reachFiveId, token) => {

    return new Promise((resolve, reject) => {
        const url = reachFiveApiDomain + '/api/v2/users/' + reachFiveId + '/providers/' + provider
        ApiCaller.delete(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            overrideHeaders: true
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{reachFiveId}/providers/{provider} Api reachFive responded failed : " + error)
                reject(error)
            });
    });
}

export const mergeUserReachFiveIds = (reachFiveApiDomain, reachFiveId1, reachFiveId2, token) => {
    return new Promise((resolve, reject) => {
        const url = reachFiveApiDomain + '/api/v2/users/' + reachFiveId1 + '/merge/' + reachFiveId2 + '?fields=id,email,gender,given_name,name,family_name,birthdate,providers,identities'
        ApiCaller.post(url, {}, {
            methode: 'mergeUserReachFiveIds', overrideHeaders: true,
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/api/v2/users/{reachFiveId1}/merge/{reachFiveId2} Api reachFive responded failed : " + error)
                reject(error)
            });
    })
}

export const updateReachFiveId = (reachFiveId, userId) => {
    return new Promise((resolve, reject) => {
        const data = createCustomerMetaInfos({'socialLoginId': reachFiveId})
        const url = '/Customers/' + userId + '/Infos '
        ApiCaller.put(url, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/Customers/{userId}/Infos Api reachFive responded failed : " + error)
                reject(error)
            });
    })
}
