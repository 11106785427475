import {Fragment} from 'react'
import * as  shopPageAnimation from "@/animations/Mobile/shopPage";
import Utils from '@/libs/Utils';

const DestockedProduct = (props) => {
    const {ean13, totalReviewsPageSize, percentage, brand, title, productPrice, destockedProductPrice, comment} = props.product

    function renderImageProduct() {
        return (
            <span className="fiche-bundle-product__table__sitem__img">
                    <img src={props.product.productPictures['smallDestocked']} className="th" alt/>
            </span>
        );
    }

    function onViewButtonClick() {
        props.getProductReviews(ean13, totalReviewsPageSize);
        props.getCaracProduct(ean13);
        shopPageAnimation.showDestockedProductOverlay(ean13);
    }

    return (
        <Fragment>
            <a className={"fiche-bundle-prodcut__table__item" + " " + "btn-store-ficheproduit" + ean13}
               onClick={onViewButtonClick}>
                {percentage > 0 && <div className="fiche-bundle-prodcut__table__item__reduction">
                    <span>-{percentage}</span>
                    <span className="pourcentage">%</span>
                </div>}
                {renderImageProduct()}
                <span className="title"> {(brand && brand !== '-') && brand} {title}</span>
                {comment && <span className="text">{'"' + comment + '"'}</span>}
                {(productPrice > 0 && destockedProductPrice > 0) &&
                <span className="price-barre">{Utils.formatPrice(productPrice)} </span>}
                {destockedProductPrice > 0 ?
                    <span className="price">{Utils.formatPrice(destockedProductPrice)}</span> :
                    (productPrice > 0 && <span className="price">{Utils.formatPrice(productPrice)}</span>)}
            </a>
        </Fragment>
    )

}
export default DestockedProduct;
