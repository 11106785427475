import React, {memo, useEffect, useState} from "react";
import PropTypes from "prop-types";

import {FirstLevelMenuItem} from "./FirstLevelMenuItem";
import {NextLevelMenuItems} from "./NextLevelMenuItems";


const clickMatchedLinkMenu = (linkLibelle) => {
    appData.actionTrigger.dispatch({
        trigger: "clickMatchedLinkMenu",
        payload: {
            link: linkLibelle.toLowerCase()
        }
    });
}

export const MenuItems = memo(({menuItems, menuItemsLevels}) => {
    const [levels, setLevels] = useState(menuItemsLevels)
    const [menuHierarchy, setMenuHierarchy] = useState([])
    useEffect(() => {
        const temp = levels.filter(item => item[11] !== false);
        if (temp[temp.length - 1])
            setMenuHierarchy(temp[temp.length - 1][0])
        setLevels(temp)
    }, [])
    const addLevel = (content, url) => {
        if (url !== undefined) {
            clickMatchedLinkMenu(url)
        }
        const temp = [...levels];
        const menuHierarchyTemp = [...menuHierarchy];
        content[11] = true;
        temp.push(content);
        menuHierarchyTemp.push(content[0]);
        setMenuHierarchy(menuHierarchyTemp)
        setLevels(temp)
    }

    const removeLevel = () => {
        const temp = [...levels];
        const menuHierarchyTemp = [...menuHierarchy];
        temp.splice(temp.length - 1, 1);
        menuHierarchyTemp.splice(menuHierarchyTemp.length - 1, 1);
        setMenuHierarchy(menuHierarchyTemp)
        setLevels(temp)
    }

    return (
        <ul>
            <li className="level__title">Tous nos produits</li>
            {menuItems.map((menuItem, i) => {
                return (
                    <FirstLevelMenuItem item={menuItem} key={i} addLevel={addLevel}/>
                );
            })}
            {levels.map((l, i) => <NextLevelMenuItems
                key={`n${i}`}
                level={i + 2}
                item={l}
                menuHierarchy={menuHierarchy}
                addLevel={addLevel}
                removeLevel={removeLevel}
            />)}
        </ul>
    )
})

MenuItems.propTypes = {
    menuItems: PropTypes.array,
};

MenuItems.defaultProps = {
    menuItems: []
};