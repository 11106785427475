import React, {memo, useEffect} from 'react';

export const Tracking = memo (({trackingData}) => {
    useEffect(() => {
        appData.actionTrigger.dispatch({
            trigger: "JsDeferToJavaScript"
        });
    }, [trackingData])
    return (<div
        dangerouslySetInnerHTML={{__html: trackingData}}/>)
});

export default Tracking;
