import Utils from "@/utils/Default/Utils"
import questions from './productSheetQuestions'
import decounterPictoSettings from "@/utils/Default/decounterPictoSettings";

export const productSheetQuestionsPageInit = () => {
    questions.init();
}

export const openReview = () => {
    $('a.read-end-avis').prev('.end-avis').addClass('active').prev('.point-disappear').hide()
    $('a.read-end-avis').parents('.avis-unit').find('a.close-avis-unit').css('display', 'block')
    $('a.read-end-avis').hide()
}

export const hasTimerInPictoFalshSale = (selector) => {
    return $(selector).attr('data-time') ;
}

export const setTimerForFlashSale = () => {
    $('[id^=clockdiv-]').each(function(i, obj) {
        let clockId = $(this).attr("id");
        if(hasTimerInPictoFalshSale("#"+clockId)){
            let dateTimer = hasTimerInPictoFalshSale("#"+clockId)
            let dateTimeSplited = dateTimer.split(" ");
            let dateSplited = dateTimeSplited[0].split("-");
            let timeSplited = dateTimeSplited[1].split(":");
            const deadline = new Date(dateSplited[0],dateSplited[1]-1,dateSplited[2],timeSplited[0],timeSplited[1],timeSplited[2]); // gestion du délais jours * heures * minutes * secondes
            decounterPictoSettings.initializeClock(clockId, deadline);
        }
    });
}

export const closeReview = () => {
    $('a.close-avis-unit').parent().find('.end-avis').removeClass('active').prev('.point-disappear').show()
    $('a.close-avis-unit').parent().find('a.read-end-avis').show()
    $('a.close-avis-unit').hide()
}

export const openFancyBoxPopin = () => {
    if(isLightProductSheetPage(appData.id)){
        $.fancybox.open([{
            href: '#popin-stock-locator',
            afterClose: openFancyBoxProduct,
        }], {padding: 0})
    }else{
        $.fancybox.open([{
            href: '#popin-stock-locator',
        }], {padding: 0})
    }
}

export const openFancyBoxProduct = () => {
    $.fancybox({
        'width': '80%',
        'height': '80%',
        'autoScale': true,
        'transitionIn': 'fade',
        'transitionOut': 'fade',
        'position': 'fixed',
        'type': 'ajax',
        'href': window.location.origin + '/Catalog/Product/LightDetails/Id/' + appData.id,
        "afterClose": function(){
            $('iframe').parents('div').show()
        }
    })
}

export const openFancyBoxPopinWarrantyAdvantages = () => {
    $.fancybox.open([{
        href: '#garantie_popin'
    }], {padding: 0})
}
export const openFancyBoxPopinWarrantyConditions = () => {
    $.fancybox.open([{
        href: '#popin-garantie-legale'
    }], {padding: 0})
}

export const openFancyBoxPopinCredit = () => {
    $.fancybox.open([{
        href: '#credit_popin'
    }], {padding: 0})
}

export const openFancyBoxPopinProduct = (productId) => {
    if(productId){
        $.fancybox([
            {scrolling: 'none',
                href: '/Catalog/Popin/Details/Id/' + productId,
                type: 'ajax',
                padding: 0
            }
        ]);
    }
}

export const sendCartItemsToT2s = (cartItems, productId) => {
    if (typeof(_t2sparams) != 'undefined' && typeof(T2S) != 'undefined' ) {
        let listProductsId = [];
        _t2sparams.pID = '4000';
        _t2sparams.iID = productId ? productId : appData.id;
        _t2sparams.bP = '';

        if (cartItems.length > 1){
            listProductsId = cartItems.map( obj => {
                return obj.productId;
            });
            listProductsId = listProductsId.join('|');
        }

        _t2sparams.bP = listProductsId;
        T2S._sendTracking();
        if (window.t2sConsiderUserConsent !== 'undefined') {
            T2S.reco();
        }
    }
}

export const addToCartPopInAnimations = (quantity, offerPrice, offerCrossedOutPrice,winningOffer, productId=null) => {
    let offerId= winningOffer?.offerId ? winningOffer.offerId : "";
    let popin = productId ? '#popin-article-ajout-panier-' + productId + offerId : '#popin-article-ajout-panier';
    if(isLightProductSheetPage(productId)){
        $('iframe').parents('div').show()
    }
    $.fancybox.open([{href: popin}], {padding: 0})
    if (winningOffer && winningOffer.seller && winningOffer.seller.hasOwnProperty('shopName')) {
        $('#vendor').html('Vendu et expédié par <a id="linkSeller" href="#"><span class="blue bold" id="vendorName">'+winningOffer.seller.shopName+'</span></a>');
        $("#linkSeller").click(function() {window.open(winningOffer.seller.sheetUrl, '_blank');})
        $('#vendor').show();
    } else {
        $('#vendor').hide();
    }
    $('#offerPrice').remove()
    if (winningOffer && offerPrice && offerCrossedOutPrice && offerCrossedOutPrice > offerPrice) {
        $('#garanties_but').hide();
        $('#garanties_mkp').show();
        if(winningOffer && (winningOffer.warranty || (winningOffer.offerWarranty && winningOffer.offerWarrantyUnit))){
            let warranty = (winningOffer.offerWarranty && winningOffer.offerWarrantyUnit) ? winningOffer.offerWarranty : (winningOffer.warranty > 1) ? winningOffer.warranty : (winningOffer.warranty) * 12
            let warrantyUnit = (winningOffer.offerWarranty && winningOffer.offerWarrantyUnit) ?  winningOffer.offerWarrantyUnit : (winningOffer.warranty > 1) ? "ans" : "mois"
            $('#bloc1-ajout-panier').html('<div class="garantie-noir">' + warranty + '<span>'+ warrantyUnit + '</span></div> <p><a href="#popin-garantie-legale" class="type-popins">Garantie</a></p>')
        }else{
            $('#bloc1-ajout-panier').hide();
        }
        $('#extended_warranties_warding').hide();
        $('#productPrice').hide();
        $('#productPrice').before('<div id="offerPrice"><p class="old-price">' + offerCrossedOutPrice + '</p> <p class="current-price">' + offerPrice + '</p></div>');
    } else if (winningOffer && offerPrice) {
        $('#garanties_but').hide();
        $('#garanties_mkp').show();
        if(winningOffer && (winningOffer.warranty || (winningOffer.offerWarranty && winningOffer.offerWarrantyUnit))){
            let warranty = (winningOffer.offerWarranty && winningOffer.offerWarrantyUnit) ? winningOffer.offerWarranty : (winningOffer.warranty > 1) ? winningOffer.warranty : (winningOffer.warranty) * 12
            let warrantyUnit = (winningOffer.offerWarranty && winningOffer.offerWarrantyUnit) ?  winningOffer.offerWarrantyUnit : (winningOffer.warranty > 1) ? "ans" : "mois"
            $('#bloc1-ajout-panier').html('<div class="garantie-noir">' + warranty + '<span>'+ warrantyUnit + '</span></div> <p><a href="#popin-garantie-legale" class="type-popins">Garantie</a></p>')
        }else{
            $('#bloc1-ajout-panier').hide();
        }
        $('#extended_warranties_warding').hide();
        $('#productPrice').hide();
        $('#productPrice').before('<div id="offerPrice"><p class="current-price">' + offerPrice + '</p></div>');
    } else {
        $('#garanties_but').show();
        $('#garanties_mkp').hide();
        $('#productPrice').show();
    }
    if(!isLightProductSheetPage(productId)){
        $('.finish-to-shop:not(a),.back-to-shop:not(a)').click(function (e) {
            e.preventDefault();
            // on supprime les liens dynamiques présents dans le document
            $(document).find('.temp-link-page').remove();
            // on recréer ce lien a l'endroit ou on a cliqué
            var link_page_stock = $(this).data('href');
            $(this).after('<a href="' + link_page_stock + '" class="temp-link-page"></a>');
            $('.temp-link-page').get(0).click();
        });
    }
}

export const changeAsideProductPrice = (oldPrice, price) => {
    $('.aside-product').show();
    if (oldPrice && price && (parseFloat(oldPrice) > parseFloat(price))) {
        $('#productCtaPrice').hide();
        $('#offerCtaPrice').html('<span class="old-price big">' + Utils.formatPrice(oldPrice) + '</span> <span class="current-price small">' + Utils.formatPrice(price) + '</span>');
        $('#offerCtaPrice').show();
    } else if (price) {
        $('#productCtaPrice').hide();
        $('#offerCtaPrice').html('<span class="current-price small">' + Utils.formatPrice(parseFloat(price)) + '</span>');
        $('#offerCtaPrice').show();
    } else {
        $('#offerCtaPrice').hide();
        $('#productCtaPrice').show();
    }
}

export const showPopInError = () => {
    $.fancybox.open([{href: '#popin-erreur-03', title: '1st title'}], {padding: 0});
}

export const tooltip = () => {
    // ouverture des infobulles
    $(document).find('.type-infobulle').click(function (e) {
        e.preventDefault();
        var infobulle = $(this).parent().find('.infobulle');
        if ((($(e.target).is('.close-infobulle')) && (infobulle.hasClass('active')))) {
            infobulle.removeClass('active');
            $('.bulle-overlay').hide().css('z-index', '');
        } else {
            infobulle.addClass('active');
            if (infobulle.parents('#popin-feature-bundle-product,#popin-stock-locator').hasClass('popin')) {
                $('.bulle-overlay').css('z-index', '51001');
            }
            $('.bulle-overlay').show();
        }
    })
    // fermeture des infobulles
    $('.bulle-overlay').click(function (e) {
        $(this).parents('body').find('.infobulle.active').removeClass('active');
        $(this).parents('body').find('.infobulle .active').removeClass('active');
        $('.bulle-overlay').hide().css('z-index', '');
    });
    $('#feature-product .close-infobulle').click(function (e) {
        $(this).parents('#feature-product').find('.infobulle.active').removeClass('active');
        $('.bulle-overlay').hide().css('z-index', '');
    });
    $('#main-product-sheet .close-infobulle').click(function (e) {
        $(this).parents('#main-product-sheet').find('.infobulle .active').removeClass('active').hide();
        $('.bulle-overlay').hide().css('z-index', '');
    });

    $('.infobulle').find('a').click(function (e) {
        e.preventDefault();
        var infobulle = $(this).parents('.infobulle'),
            id = $(this).attr('href'),
            offset = $(id).offset().top - 65;
        if (infobulle.hasClass('active')) {
            infobulle.removeClass('active');
            $('.bulle-overlay').hide().css('z-index', '');
        }
        $('html, body').animate({
            scrollTop: offset
        }, 'slow');
        return false;
    });
}

export const showSearchedLocationList = () => {

    $( "#availability_search_shop_form #searchKey" ).keyup(function() {
        $( this ).siblings( ".result" ).css( "display", "block" );
    });

}
export const toggleDeliveryMethod = () => {
    $(".delivery-method").click(function (e) {
        var codeDelivery = $(this).data('codeDelivery')
        var classNameSvg = '.svg-'+codeDelivery
        var position = Math.round($(classNameSvg).offset().top)-100
        $(classNameSvg).click()
        $('html, body').animate({
            scrollTop: position+'px'
        }, 100);
    });
}

export const bindContextualAvailabilityArrow = () => {
    var togglebar = $('#main-delivery-product').find('.link_toggle'),
        togglecontent = $('#main-delivery-product').find('.content_toggle');


    togglebar.parent().removeClass('open');
    togglebar.unbind("click");
    togglebar.click(function (e) {
        e.preventDefault();

        if (!$(this).parent().hasClass('open')) {
            $(this).parent().addClass('open');
            $(this).parent().find('.content_toggle').slideDown('400');
        } else {
            $(this).parent().removeClass('open');
            $(this).parent().find('.content_toggle').slideUp('400');
        }
    });

}

export const hideConrextualAvailabilityLink = () => {
    $("#contextual-avalability-link").hide();
}

export const showConrextualAvailabilityLink = () => {
    $("#contextual-avalability-link").show();
}

export const hideConrextualAvailability = () => {
    $("#main-delivery-product").hide();
    $("#contextual-avalability-link").hide();
}

export const showConrextualAvailability = () => {
    $("#main-delivery-product").show();
    $("#contextual-avalability-link").show();
}

export const hideFundingProduct = () => {
    $("#funding-product-link").hide();
    $("#funding-product").hide();
}

export const showFundingProduct = () => {
    $("#funding-product-link").show();
    $("#funding-product").show();
}

export const hideReturnProduct = () => {
    $("#return-product-link").hide();
    $("#return-product").hide();
}
export const hideODRProductMixte = () => {
    $("#block-odr-fpmixte").hide();

}
export const showODRProductMixte = () => {
    $("#block-odr-fpmixte").show();
}

export const showReturnProduct = () => {
    $("#return-product-link").show();
    $("#return-product").show();
}

export const hideAsideAvailability = () => {
    $('.aside-product').hide();
}
export const hideGeoErrorBloc = () => {
    $('.champ_errorGeo').hide();
}

export const closeFancyBoxPopin = (e) => {
    if(e){
        e.preventDefault();
    }
    $.fancybox.close();
}

export const showLoaderAvailability = () => {
    $('.list-stock').prepend('<div class="ajax-loader-container"><div class="ajax-loader"></div></div>');
    $('.list-stock').css({'overflow-y': 'hidden'});
};

export const checkOlapicBloc = () => {
    if ($('#olapic-item').length > 0) {
        return true
    }
    return false
};

export const applyScrollNav = () => {
    scrollEvents.forEach(function(item){
        $(window).unbind('scroll',item);
    })
    scrollNav();
};

export const removeFocusLink = () => {
    $('#funding-product-link').removeClass('focus');
    $('#return-product-link').removeClass('focus');
    $('#contextual-avalability-link').removeClass('focus');
    $('#carasDetailedNavigationBar').addClass('focus');
};


export const hideLoaderAvailability = () => {
    $('.ajax-loader-container').remove();
    if ($('.list-stock').html() != "") {
        $('.list-stock').css({'overflow-y': 'scroll'});
    } else {
        $('.list-stock').css({'overflow-y': 'hidden'});
    }
};


export const subscribeToStockFancyBox = () => {
    $("a#fancyBoxLinkSubscribeToStock").fancybox({
        'href': '#popin-prodNoDisp'
    });
};

export const toggelContextualShippingContent = (item, target) => {
    $('html, body').animate({scrollTop: $('#' + target).offset().top - 50}, 1000);
    if (!$('#' + item).hasClass('open')) {
        $('#' + target).find('.toggle_bar').removeClass('open');
        $('#' + target).find('.content_toggle').css('display', 'none');
        $('#' + item).addClass('open');
        $('#' + item + '> .content_toggle').css('display', 'block');
    }
}

export const openSeller = (evt, sellerName, soldBy) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(sellerName).style.display = "block";
    if (sellerName == 'OngletMkp') {
        $("#btnmkp").addClass("active");
        hideReturnProduct();
        hideFundingProduct();
        hideConrextualAvailability();
        hideODRProductMixte();
    } else if (sellerName == "OngletBut") {
        $("#defaultOpen").addClass("active");
        Utils.browserHistory.pushContextUrl();
        if (soldBy == "MKP") {
            hideReturnProduct();
            hideFundingProduct();
            hideConrextualAvailability();
            hideODRProductMixte()
        } else {
            removeFocusLink();
            showReturnProduct();
            showFundingProduct();
            showConrextualAvailability();
            changeAsideProductPrice();
            showODRProductMixte();
        }

    }
    //evt.target.className += " active";

};
export const FreeShipingCommercialStickersRendred = () => {
    return $('.picto-payment bg-green').length == 0
}

export const renderNumberOfSteakers = (maxDisplayedPicto) => {
    return $('.picto-payment').length == maxDisplayedPicto
}
export const removeCommertialSteaker = () => {
    $("#picto-payment-1").remove();
}

export const openFancyBox = (id) => {
        $.fancybox.open([{
            href: "#"+id
        }], {padding: 0})
}

export const isValidForm = (id) => {
    return $('#' + id).valid();
}
export const resetValidateForm = (id) => {
    $('#'+id+' .row').removeClass('error').removeClass('valid');
    $('textarea#qr-msg').removeClass('error').removeClass('valid');
    $('input#qr_pseudo').removeClass('error').removeClass('valid');
    $('input#qr_email').removeClass('error').removeClass('valid');
    $('input#qr-mentions').removeClass('error').removeClass('valid');
}

export const getInputElementValue = (element) => {
    return $(element).val()
}

export const isLightProductSheetPage = (productId) => {
    return appData.mvcUrl === 'Catalog/Product/LightDetails'
}

export const reinitLazyLoad = () => {
    if (appData.firstLoad) {
        $('.lazy').Lazy();
        appData.firstLoad = false;
    } else {
        let images = $('.lazy');
        [].forEach.call(images, function (image) {
            if ($(image).attr('data-src')) {
                image.src = $(image).attr('data-src');
                $(image).removeAttr('data-src');
            }
        });
    }
}

export const showProductPartBlocs = () => {
    $(".product-part").each(function () {
        if ($.trim($(this).html()) !== "" && !$(this).hasClass('target-to-sell') && ($(this)[0].id !== 'eco-pack-product-hide') && ($(this)[0].id !== 'main-product-sheet')) {
            $(this).css({'display': 'block'});
        }
    });
}