export const hideLoader = () => {
    $('.loader').css('display', 'none');
}

export const checkOlapicBloc = () => {
    if ($('#olapic-item').length > 0) {
        return true
    }
    return false
};

export const removeLastCommertialSteaker = () => {
    $(".product-tag").last.remove();
}

export const maxPictoNumberIsDisplayed = (commercialOperations) => {
    return $('.product-tag').length == commercialOperations
}