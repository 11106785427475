import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {connect} from "react-redux";
import {DEFAULT_VALUE_TO_CONVERT_MINUTES_TO_MILLISECONDS} from "@/utils/constants";

const MonitorInactivity = ({timeout, redirectTo}) => {
    timeout = timeout * DEFAULT_VALUE_TO_CONVERT_MINUTES_TO_MILLISECONDS * 60;
    const router = useRouter();
    const [hasRedirected, setHasRedirected] = useState(false);
    const workerScript = `
            let timer;
            self.onmessage = function (e) {
                const { action, timeout } = e.data;
                switch (action) {
                    case 'start':
                        resetTimer(timeout);
                        break;
                    case 'reset':
                        resetTimer(timeout);
                        break;
                    case 'clear':
                        clearTimeout(timer);
                        break;
                }
            };
            function resetTimer(timeout) {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    postMessage('timeout');
                }, timeout);
            }
        `;


    useEffect(() => {
        if (hasRedirected) return;
        const blob = new Blob([workerScript], {type: 'application/javascript'});
        const worker = new Worker(URL.createObjectURL(blob));
        worker.onmessage = function (e) {
            if (e.data === 'timeout' && !hasRedirected) {
                router.push(redirectTo);
                localStorage.setItem('cart', "");
                setHasRedirected(true);
            }
        };
        const handleEvent = () => {
            worker.postMessage({action: 'reset', timeout});
        };

        worker.postMessage({action: 'start', timeout});
        window.addEventListener('click', handleEvent);
        window.addEventListener('scroll', handleEvent);
        return () => {
            worker.postMessage({action: 'clear'});
            worker.terminate();
            window.removeEventListener('click', handleEvent);
            window.removeEventListener('scroll', handleEvent);
        };
    }, [timeout, redirectTo, router, hasRedirected]);
    return null;
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(null, mapDispatchToProps)(MonitorInactivity);