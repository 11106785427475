import ApiCallerSSR from './ApiCaller/apiCallerSSR'
import ApiCaller from './ApiCaller/apiCaller'
import {DEFAULT_TEMPLATE_VERSION, MOBILE_TEMPLATE_VERSION} from "@/utils/constants";

export const getProductDetails = (ean13, view, scriptUrl= null, userAgent, cUserID, deviceType = DEFAULT_TEMPLATE_VERSION, requestUrl = "") => {
    const wordingVersion = deviceType && deviceType.toLowerCase() === MOBILE_TEMPLATE_VERSION.toLowerCase() ? MOBILE_TEMPLATE_VERSION : DEFAULT_TEMPLATE_VERSION;
    ApiCallerSSR.defaults.headers['User-Agent'] = userAgent;
    ApiCallerSSR.defaults.headers['CUserID'] = cUserID;
    const httpRedirectCodes = [301, 302, 307,404];
    return new Promise((resolve, reject) => {
        let url = 'V1/Catalog/SSRProducts/' + ean13 + '?View=' + view + '&Context=SSR'
        if (scriptUrl) {
            url += '&ScriptUrl=' + scriptUrl
        }
        console.log('INFO', url)
        ApiCallerSSR.get(url, {params: {RequestUrl: requestUrl, wordingVersion: wordingVersion}})
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if(httpRedirectCodes.includes(error.response.status)){
                    resolve(error.response);
                } else {
                    console.error('ERROR', url + " Api responded failed : " + error)
                }

                reject(error)
            });
    });
};

export const getProductLightData = (ean) => {
    const httpRedirectCodes = [301, 302, 307,404];
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Catalog/Products/' + ean + '?View=Light')
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', '/Catalog/Products/' + ean + " Api responded failed : " + error)
                reject(error);
            });
    });
};

export const getProductAvailabilities = (params) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Catalog/Products/Availabilities?' + params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR','/Catalog/Products/Availabilities?' + params + ' Api responded failed : ' + error)
                reject(error)
            });
    });
};

export const getQuestions = (url) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', url + " Api responded failed : " + error)
                reject(error)
            });
    });
};

export const fetchReviewsData = (data) => {
    return new Promise((resolve, reject) => {
        let queryString= '';
        if(!!data.sortedBy) {
            queryString = "&SortedBy=" + data.sortedBy;
        }
        ApiCaller.get('/Catalog/Products/' + data.productId + '/Reviews.json?PageIndex=' + data.pageIndex + queryString)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', '/Catalog/Products/' + data.productId + '/Reviews.json?PageIndex=' + data.pageIndex + queryString + " Api responded failed : " + error)
                reject(error);
            });
    });
};

export const askQuestionService = (url, data) => {
    return new Promise((resolve, reject) => {
        ApiCaller.post(url, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', url + " Api responded failed : " + error)
                reject(error)
            });
    });
};

export const getBundleDetails = (bundleId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Catalog/PromotionalCampaignOffers/' + bundleId + '/Bundle')
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', '/Catalog/PromotionalCampaignOffers/' + bundleId + '/Bundle' + " Api responded failed : " + error)
                reject(error);
            });
    });
};
