export const showErrorGeo = () => {
    $('#page_storeLoc .map #blocInfo #start_infoBloc').addClass('no-before')
    $('#start_infoBloc').addClass('has-error')
    $('#errorBloc').css({display:"block"})
}
export const hideErrorGeo = () => {
    $('#page_storeLoc .map #blocInfo #start_infoBloc').removeClass('no-before')
    $('#start_infoBloc').removeClass('has-error')
    $('#errorBloc').hide()
}

export const hideAutoCompleteBlock = () => {
    $('.ui-menu.ui-widget.ui-widget-content.ui-autocomplete.ui-front').hide()
}
export const hideJqueryHelper = (ClassName) => {
    $("." + ClassName).remove();
}
export const getInputElementValue = (element) => {
    return $(element).val()
}
export const dispatchGtmAction = (label) => {
    let action = ''
    if (window.location.href.indexOf("recherche-magasins") > -1) {
        action = 'Store Locator_Home'
    } else if(window.location.href.indexOf("resultats-magasins") > 1) {
        action = 'Store Locator_Resultats'
    } else {
        action = 'Store Locator_RegionDepartement'
    }
    appData.actionTrigger.dispatch({
        trigger: "SearchDqeHandleClick",
        payload: {
            label : label,
            action : action
        }
    });
}

export const bindShopClick = (element) => {
    // OUVERTURE BLOC MAGASIN
    $('#page_storeLoc .contentMag .blocMag').click(function(e) {
        e.preventDefault();
        // Ouverture/fermeture des blocs
        if($(this).parent().hasClass('active')) {
            $('#page_storeLoc .contentMag').removeClass('active');
            $('#page_storeLoc .contentMag .blocToggle').slideUp(300);
            $('#page_storeLoc .contentMag .blocMag .plusToggle').html('<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><defs><style>.cls-11{fill:#768d9f;fill-rule:evenodd;}</style></defs><path id="Rectangle_4_copie" data-name="Rectangle 4 copie" class="cls-11" d="M11.086,22.178A11.092,11.092,0,1,1,22.178,11.086,11.092,11.092,0,0,1,11.086,22.178Zm0-20.005A8.913,8.913,0,1,0,20,11.086,8.913,8.913,0,0,0,11.086,2.173Zm1.783,14.855H9.3V12.869H5.144V9.3H9.3V5.144h3.565V9.3h4.16v3.565h-4.16v4.159Z"/></svg>');
            $('#listMag').animate({scrollTop: $(this).offset().top - $('#listMag').offset().top + $('#listMag').scrollTop() - 60}, 700);
        } else {
            $('#page_storeLoc .contentMag').removeClass('active');
            $(this).parent().addClass('active');
            $('#page_storeLoc .contentMag .blocToggle:not(#page_storeLoc .contentMag.active .blocToggle)').slideUp(300);
            $('#page_storeLoc .contentMag .blocMag .plusToggle:not(#page_storeLoc .contentMag.active .blocMag .plusToggle)').html('<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><defs><style>.cls-11{fill:#768d9f;fill-rule:evenodd;}</style></defs><path id="Rectangle_4_copie" data-name="Rectangle 4 copie" class="cls-11" d="M11.086,22.178A11.092,11.092,0,1,1,22.178,11.086,11.092,11.092,0,0,1,11.086,22.178Zm0-20.005A8.913,8.913,0,1,0,20,11.086,8.913,8.913,0,0,0,11.086,2.173Zm1.783,14.855H9.3V12.869H5.144V9.3H9.3V5.144h3.565V9.3h4.16v3.565h-4.16v4.159Z"/></svg>');
            $(this).next('#page_storeLoc .contentMag.active .blocToggle').slideDown(300);
            $(this).children('#page_storeLoc .contentMag.active .blocMag .plusToggle').html('<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><defs><style>.cls-11{fill:#768d9f;fill-rule:evenodd;}</style></defs><path id="Rectangle_4_copie" data-name="Rectangle 4 copie" class="cls-11" d="M11.086,22.178A11.092,11.092,0,1,1,22.178,11.086,11.092,11.092,0,0,1,11.086,22.178Zm0-20.005A8.913,8.913,0,1,0,20,11.086,8.913,8.913,0,0,0,11.086,2.173Zm5.942,10.7V9.3H5.144v3.565H17.028Z"/></svg>');
            // animation scroll adresses
            $('#listMag').animate({scrollTop: $(this).offset().top - $('#listMag').offset().top + $('#listMag').scrollTop() - 60}, 700);
        }
    });
}
