import {Fragment, memo} from 'react'

const ShopPresentation = memo((props) => {
    return (
        <Fragment>
            <div className="store-fiche__content">
                {
                    (props.shopAndWharehouseData.shopInfos.message) ?
                        <Fragment>
                            <span className="store-fiche__content__title">Présentation du magasin</span>
                            <p>{props.shopAndWharehouseData.shopInfos.message}</p>
                        </Fragment>
                        : null
                }
                {
                    (props.shopAndWharehouseData.shopInfos.additionnalInformation) ?
                        <a href="#" className="store-fiche__content__link">{props.shopAndWharehouseData.shopInfos.additionnalInformation}</a>
                        : null
                }
            </div>
        </Fragment>
    )
})
export default ShopPresentation;