import React from "react";
import {connect} from "react-redux";
import {
    CUSTOMER_AREA_PAGE,
    DEFAULT_TEMPLATE_VERSION,
    MOBILE_TEMPLATE_VERSION,
    CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE,
    GROUP_USER_WEB_BY_TOKEN, CUSTOMER_PAGE
} from "@/utils/constants";
import {Breadcrumb} from "../../Default/Common/Breadcrumb";
import {Navigation} from "./navigation";
import {getNavigationData, getNavigationLevel} from "../commun/SPAHead/PrefetchDataPages"
import ResponsiveLayout from "../ResponsiveLayout";
import {RESET_CUSTOMER_NEWSLETTERS_MESSAGE} from "@/redux/reducers/responsive/actionsTypes";
import DefaulPrivateSalePopin from "../../Default/PrivateSalePopin";
import MobilePrivateSalePopin from "../../Mobile/PrivateSalePopin";

const CustomerAccountWrapperContainer = (props) => {

    const {
        templateVersion,
        context,
        children,
        shopUrl,
        userId,
        firstName,
        pathName,
        levelData,
        groupUser,
        resetWaitingMessage,
        privateSaleEligibilityHtmlContent
    } = props;
    const level = getNavigationLevel(pathName)
    const levelListData  = context == CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE ? levelData : getNavigationData(context, userId)
    return (

        <ResponsiveLayout templateVersion={templateVersion} level={level} pathName={pathName} currentStep={CUSTOMER_PAGE} ssr={false} context={context}>
            {templateVersion === MOBILE_TEMPLATE_VERSION ?
                <>
                    <div className="wrapper">
                        <Navigation userId={userId} firstName={firstName} myShopUrl={shopUrl}
                                    currentSteps={context}
                                    level={level}
                                    noBack={false}
                                    resetWaitingMessage={resetWaitingMessage}
                                    groupUser={groupUser}
                        />
                        {children}
                    </div>
                    <div id="webLoyaltyMobile"/>
                    {level === 0 &&
                        <MobilePrivateSalePopin privateSaleEligibilityHtmlContent={privateSaleEligibilityHtmlContent}/>}
                </> :
                <div className="wrapper">
                    {levelListData && groupUser !== GROUP_USER_WEB_BY_TOKEN && <Breadcrumb
                        userId={userId}
                        context={CUSTOMER_AREA_PAGE}
                        levelsNavigation={levelListData.levelsNavigation}
                        parentsCategories={levelListData.parentsCategories}
                    />}
                    <div className="navigationBlock aside">
                        <Navigation userId={userId} firstName={firstName} myShopUrl={shopUrl}
                                    currentSteps={context}
                                    level={level}
                                    noBack={false}
                                    resetWaitingMessage={resetWaitingMessage}
                                    groupUser={groupUser}/>
                        <div id="webLoyaltyDesktop" />
                        {level === 0 &&
                            <DefaulPrivateSalePopin privateSaleEligibilityHtmlContent={privateSaleEligibilityHtmlContent}/>}
                    </div>
                    {children}
                </div>
            }
        </ResponsiveLayout>
    );

}

const mapStateToProps = (state, ownProps) => {
    return {
        customerData: state.customer.customerObject,
        firstName: state.header?.customer?.firstName ? state.header.customer.firstName : state.identity?.userData?.firstName ? state.identity.userData.firstName : null,
        shopUrl: state.header?.shop?.shopInfos?.url ? state.header.shop.shopInfos.url : state.header?.shopUrl ? state.header.shopUrl : null,
        userId: state?.identity?.webUserId,
        levelData : state?.orderDetails?.levelData,
        templateVersion: ownProps.templateVersion,
        groupUser : ownProps.templateVersion === DEFAULT_TEMPLATE_VERSION ? state.header.customer.group : state?.identity?.userData?.group,
        privateSaleEligibilityHtmlContent: state.requiredCommonData?.privateSaleEligibilityHtmlContent,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        resetWaitingMessage : () => dispatch({type: RESET_CUSTOMER_NEWSLETTERS_MESSAGE, payload: {message: null, options: {}}})

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAccountWrapperContainer);
