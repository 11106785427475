import ApiCaller from './ApiCaller/apiCaller'
import {fetchTopSearchesResults} from "../redux/reducers/Mobile/header/headerActions";
export const getKeywordsResults = (keywordValue) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/SearchEngine/Suggestions?KeyWords=' + keywordValue)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', "/SearchEngine/Suggestions?KeyWords=" + keywordValue + " Api responded failed : " + error)
                reject(error)
            });
    });
  };
export const getTopSearchesResults = () => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/SearchEngine/Suggestions')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', "/SearchEngine/Suggestions Api responded failed : " + error)
                reject(error)
            });
    });
};