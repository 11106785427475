import { Reducer } from "redux";
import { OFFERS_DATA_FETCHED } from "@/redux/reducers/responsive/actionsTypes";
import { MarketplaceState } from "@/models/appState/MarketplaceState";
import { CustomAction } from "@/models/common/core/CustomAction";
import { OFFERS_DATA_FETCHED_TYPE } from "@/redux/reducers/responsive/product/marketplaceTypes";

const initialState: MarketplaceState = {
    offersData: null,
    newOffers: [],
    reconditionnedOffers: [],
    selectedFilter: 'all',
    selectedOffers: [],
    view: '',
    selectedOfferId: '',
    displayOfferListFrom: '',
    winningOffer: null,
};

type ActionType = CustomAction<OFFERS_DATA_FETCHED_TYPE>;

const marketplaceReducer: Reducer<MarketplaceState, ActionType> = (state = initialState, action) => {
    switch (action.type) {
        case OFFERS_DATA_FETCHED:
            return {
                ...state,
                offersData: action.payload.offersData,
                selectedOffers: action.payload.offersData.items,
                newOffers: action.payload.newOffers,
                reconditionnedOffers: action.payload.reconditionnedOffers,
                winningOffer: action.payload.winningOffer,
            }
        default:
            return state;
    }
};

export default marketplaceReducer;