import ApiCaller from './ApiCaller/apiCaller'
import ApiCallerSSR from "./ApiCaller/apiCallerSSR";

export const getRequiredCommonData = (url, requestUrl = '') => {
    return new Promise((resolve, reject) => {
        const params = { params: { RequestUrl: requestUrl } } ;
        ApiCaller.get(url, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });
    });

};
export const getCommonRequiredDataSSR = (requestUrl = '') => {
    return new Promise((resolve, reject) => {
        const params = { params: { RequestUrl: requestUrl } } ;
        ApiCallerSSR.get('Common/RequiredData', params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error)
            });
    });
}
