import Axios from 'axios'
import Cookies from 'js-cookie'
import getConfig from 'next/config'
import { handleToken } from "../userServices";
import { parseJson } from "@/utils/Mobile/utils"
import * as Sentry from '@sentry/nextjs';

const { publicRuntimeConfig } = getConfig();
const ApiCaller = Axios.create({
    baseURL: publicRuntimeConfig.frontBaseUrl,
});
const isTokenExpired = () => {
    let navigationTokenExpiration = Cookies.get('navigationTokenExTime');
    let currentDateTime = Math.round(+new Date() / 1000);
    return (!navigationTokenExpiration || currentDateTime >= navigationTokenExpiration);
}
const changeWebUserIdUrl = (currentUrl, oldWebUserId, newWebUserId) => {
    return currentUrl.replace(oldWebUserId, newWebUserId);
}

ApiCaller.interceptors.request.use(async (config) => {
    if (isTokenExpired() && config.method === 'put' && config.url.indexOf("Cart?View=compact") != -1) {
        const result = await handleToken();
        const oldWebUserId = config.url.match(/WebUsers\/(.*?)\/Cart/)[1];
        config.url = config.url = changeWebUserIdUrl(config.url, oldWebUserId, result.userId)
        Cookies.set('navigationToken', result.token, {
            secure: true,
            sameSite: "None"
        })
    }
    if ((config.method === 'put' || config.method === 'post') && config.url.includes('/Cart') && !config.rowData) {
        if (typeof Cookies.get('cart') !== 'undefined') {
            localStorage.setItem('cart', Cookies.get('cart'));
            Cookies.remove('cart');
        }
        config.data.cart = localStorage.getItem('cart');
    }
    if (config.method === 'post' && !config.rowData) {
        let data = new URLSearchParams();
        Object.keys(config.data).forEach(function (key) {
            data.append(key, config.data[key]);
        });
        config.data = data;
    }
    if (config.overrideHeaders === true) {
        if (!config.withoutDefaultContentType) {
            config.headers.common['content-type'] = 'application/json'
        }
        return config
    } else {
        config.headers.common['WORDING-VERSION'] = 'Mobile'
        config.headers.common['navigation-token'] = Cookies.get('navigationToken')
        config.headers.common['sellerInfos'] = Cookies.get('sellerInfos')
        config.headers.post['content-type'] = 'application/x-www-form-urlencoded';
        if (typeof appData !== undefined && !!appData?.navigationMetaInfosConfigs) {
            var navigationMetaInfosConfigs = appData?.navigationMetaInfosConfigs;
            Object.keys(navigationMetaInfosConfigs).forEach(function (key) {
                var navigationMetaInfoName = navigationMetaInfosConfigs[key].pattern;
                let navigationMetaInfoValue = Cookies.get(navigationMetaInfoName == appData.sparkowUserIdPatternName ? appData.sparkowUserIdCookieName : navigationMetaInfoName);
                if (typeof navigationMetaInfoValue != "undefined" && navigationMetaInfoValue != undefined) {
                    config.headers.common[navigationMetaInfoName] = navigationMetaInfoValue
                }
            }, config);
        } else {
            config.headers.common["cuserid"] = Cookies.get('CUserID');
            config.headers.common["compariouserid"] = Cookies.get('compariouserid');
        }
        return config
    }
});
ApiCaller.interceptors.response.use((config) => {
    if (config.headers.hasOwnProperty('navigation-token')) { //TODO To check why it necessery
        Cookies.set('navigationToken', config.headers['navigation-token'], { secure: true, sameSite: "None" });
        Cookies.set('navigationTokenExTime', config.headers['navigation-token-ex-time'], {
            secure: true,
            sameSite: "None"
        })
    }
    if (typeof appData !== undefined && !!appData?.navigationMetaInfosConfigs) {
        var navigationMetaInfosConfigs = appData?.navigationMetaInfosConfigs;
        Object.keys(navigationMetaInfosConfigs).forEach(function (key) {
                var navigationMetaInfoName = navigationMetaInfosConfigs[key].pattern;
                var navigationMetaInfoValue = '';
                if (config.headers.hasOwnProperty(navigationMetaInfoName.toLowerCase())) {
                    var navigationTokenMetaInfoHeaderValue = config.headers[navigationMetaInfoName.toLowerCase()];
                    if (typeof navigationTokenMetaInfoHeaderValue != 'undefined' && navigationTokenMetaInfoHeaderValue != 'undefined'
                        && navigationTokenMetaInfoHeaderValue != null) {
                        navigationMetaInfoValue = navigationTokenMetaInfoHeaderValue;
                    }
                }
                var navigationMetaInfoConfigs = navigationMetaInfosConfigs[key];
                var lifeTime = parseFloat(navigationMetaInfoConfigs.duration) / (60 * 60 * 24);
            if (navigationMetaInfoConfigs.pattern !== appData?.sparkowUserIdPatternName && typeof navigationTokenMetaInfoHeaderValue != 'undefined' && navigationTokenMetaInfoHeaderValue != 'undefined' && navigationMetaInfosConfigs[key].httpOnly == 'false') {
                Cookies.set(navigationMetaInfoConfigs.pattern, navigationMetaInfoValue, {
                    path: navigationMetaInfoConfigs.path,
                    domain: navigationMetaInfoConfigs.domain,
                    expires: lifeTime
                });
            }
            if (config.headers['force-refresh'] !== undefined) {
                if (config.headers['force-refresh'] == '*' && !config?.config?.headers['disableForcedReload']) {
                    location.reload();
                }
            }

        }, config);
    }
    let body = config.data;

    try {
        if (body !== null) {
            if (typeof body.debugData !== 'undefined') {
                let debugBarData = parseJson(body.debugData);
                delete body.debugData;
                let id = debugBarData.__meta.id;
                if (typeof phpdebugbar !== 'undefined')
                    phpdebugbar.addDataSet(debugBarData, id);
            } else if (Array.isArray(body) && typeof body['debugData'] !== 'undefined') {
                let debugBarData = body.pop();
                debugBarData = parseJson(debugBarData);
                let id = debugBarData.__meta.id;
                if (typeof phpdebugbar !== 'undefined')
                    phpdebugbar.addDataSet(debugBarData, id);
            } else if (typeof body['cartStorage'] !== 'undefined') {
                localStorage.setItem('cart', body['cartStorage']);
            }
        }
    } catch (err) {
        console.log(err);
    }
    return config
}, function (error) {

    const httpCodesNotCapture = [301, 302, 307];
    if (!httpCodesNotCapture.includes(error.response.status)) {
        Sentry.captureException(error);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default ApiCaller



