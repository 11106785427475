import {
    CUSTOMER_PAGE,
    IN_STORE_PAGES,
} from "@/utils/constants";
import CustomerHeadAndScripts from "@/components/responsive/customerAccount/CustomerHeadAndScripts";
import InStoreHeadAndScripts from "@/components/responsive/purchaseChannel/InStoreHeadAndScripts";


export const  HeadAndScripts = (props) => {
    if (props.context === CUSTOMER_PAGE) {
        return <CustomerHeadAndScripts pathName={props.pathName} deviceType={props.deviceType}/>
    }
    if (props.context === IN_STORE_PAGES) {
        return <InStoreHeadAndScripts pathName={props.pathName}/>
    }

}
export default HeadAndScripts
