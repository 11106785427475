import {
    COMMON_INIT_DATA

} from "../actionsTypes"

const initialState = {
    isDQENormalisationActive: false,
    googleMapsKey: '',
    shopCircleVersion: null,
    logoVersion: null,
    footerTrackingScopeData: null,
    headerTrackingScopeData: null,
    isWebloyaltyActive: false,
    privateSaleEligibilityHtmlContent : '',
    deviceType: 'Default',
    countriesList: null,
    isInitialized: false,
    trackingUrlFapi: null
}

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case  COMMON_INIT_DATA :
            return {
                ...state,
                isDQENormalisationActive: action.payload.data.isDQENormalisationActive,
                googleMapsKey: action.payload.data.googleMapsKey,
                shopCircleVersion: action.payload.data.shopCircleVersion,
                headerTrackingScopeData: action.payload.data.headerTrackingScopeData,
                footerTrackingScopeData: action.payload.data.footerTrackingScopeData,
                logoVersion: action.payload.data.logoVersion,
                isWebloyaltyActive: action.payload.data.isWebloyaltyActive,
                privateSaleEligibilityHtmlContent: action.payload.data.privateSaleEligibilityHtmlContent,
                deviceType: action.payload.data.deviceType,
                countriesList: action.payload.data.countriesList,
                trackingUrlFapi: action.payload.data.trackingUrlFapi,
                isInitialized: true,
                showBanners: action.payload.data.showBanners,
                bannersBlocList: action.payload.data.bannersBlocList,
                addressMaxLength: action.payload.data.addressMaxLength,
                hasCountDown: action.payload.data.hasCountDown
            }
        default:
            return state;
    }


};

export default CommonReducer
