import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {headerPageInit} from "@/animations/Default/headerPage";
import MenuBlock from "./MenuBlock";


const MenuContainer = ({menuItems, aboveMenuItems, context, currentPage}) => {

    useEffect(() => {
        headerPageInit();

    })

    return (
        <header className="main-header">
            <nav id="bloc_menu" className="nav-main">
                <MenuBlock
                    menuItems={menuItems}
                    aboveMenuItems={aboveMenuItems}
                    context={context}
                    currentPage={currentPage}
                />
            </nav>
        </header>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        menuItems: ownProps.menuItems,
        aboveMenuItems: ownProps.aboveMenuItems,
        currentPage: ownProps.currentPage ? ownProps.currentPage : "",
        context: ownProps.context ? ownProps.context : ""
    };
};


export default connect(mapStateToProps, null)(MenuContainer);
