import React, {memo, useMemo, useState} from 'react';
import {Collapse} from "react-bootstrap";
import Tracking from "../../Tracking";
import ShopServices from "../../Common/ShopServices";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {dispatchEventSTPUSHUpdate} from "@/animations/Mobile/commonPage";

const Footer = memo(({context, footerTrackingFlow, footerTrackingScopeData}) => {
    const [collapseTopSearch, setCollapseTopSearch] = useState(false);
    const [collapseCompany, setCollapseCompany] = useState(false);
    const [collapseMkp, setCollapseMkp] = useState(false);
    const [collapseAbout, setCollapseAbout] = useState(false);
    const memoizedFooterContent = useMemo(()=>
        <>
            <div className="footer-social">
                <a href="https://www.instagram.com/BUT/" target="_blank"
                   className="footer-social__link gtm-footer-link"><span className="icon-insta icon-2x"/></a>
                <a href="https://www.tiktok.com/@magasinsbut" target="_blank"
                   className="footer-social__link gtm-footer-link">
                    <img width={22.374} height={24} alt="Tik-Tok" src="https://media.but.fr/Sources/MobileV2/Images/Footer/logo-tiktok.svg" /></a>
                <a href="https://www.facebook.com/but" target="_blank"
                   className="footer-social__link gtm-footer-link"><span
                    className="icon-facebook icon-2x"/></a>
                <a href="https://www.pinterest.fr/magasinsbut/" target="_blank"
                   className="footer-social__link gtm-footer-link"><span
                    className="icon-pinterest icon-2x"/></a>
                <a href="https://twitter.com/But" target="_blank"
                   className="footer-social__link gtm-footer-link"><span
                    className="icon-twitter icon-md"/></a>
            </div>
            <div className="footer-horizontal-links">
                <a href="#" className="gtm-footer-link" id="Footer_HorsTunnel_Lien_Aide_et_Contact_smart_tribune"
                   onClick={(event) => {
                       event.preventDefault();
                       dispatchEventSTPUSHUpdate(['classic-5560']);
                   }}
                >Aide &amp;<br/>contact</a>
                <a href="https://www.but.fr/aide" className="gtm-footer-link">Questions<br/>fréquentes</a>
                <a href="/Common/Services/LegalMentions" className="gtm-footer-link">
                    Mentions<br/>légales
                </a>
            </div>
            <div className="footer-vertical-links">
                <a href="/Carte_but" className="gtm-footer-link">Découvrez la Carte BUT</a>
                <a href="/actualites/les-catalogues-but" className="gtm-footer-link">Dépliants et catalogue</a>
                <a href="https://media.but.fr/PDF/CGV-BUT-Magasins-Decembre2019.pdf" target="_blank"
                   className="gtm-footer-link">CGV Magasins</a>
                <a href="/Common/Services/GeneralCondition" className="gtm-footer-link">CGV Internet</a>
                <a href="/Common/Services/Cookies" className="gtm-footer-link">Protection des données
                    personnelles</a>
                <a href="https://media.but.fr/PDF/Rappel_produit_en_cours.pdf" target="_blank"
                   className="gtm-footer-link">Rappel produits</a>
            </div>
            <div className="footer-collapse">
                <a onClick={() => setCollapseMkp(!collapseMkp)} aria-controls="collapseMkp"
                   aria-expanded={collapseMkp}
                   className={!collapseMkp ? "footer-collapse__btn collapsed" : "footer-collapse__btn"}>
                    <span>Marketplace</span><span className="icon-expand"/>
                </a>
                <Collapse in={collapseMkp}>
                    <div id="collapseMkp" className="footer-collapse__content">
                        <ul>
                            <li><a href="/marketplace-but" className="gtm-footer-link">Qu'est ce que la
                                marketplace</a>
                            </li>
                            <li><a href="https://media.but.fr/PDF/BUT_CGV_Marketplace.pdf" target="_blank"
                                   className="gtm-footer-link">CGV marketplace</a></li>
                            <li><a href="https://media.but.fr/PDF/BUT_CGU_Marketplace.pdf" target="_blank"
                                   className="gtm-footer-link">CGU marketplace</a></li>
                            <li><a href="https://media.but.fr/PDF/BUT_CGU_prestataire_paiement.pdf"
                                   target="_blank"
                                   className="gtm-footer-link">Prestataire de paiement</a></li>
                        </ul>
                    </div>
                </Collapse>
            </div>
            <div className="footer-collapse">
                <a onClick={() => setCollapseCompany(!collapseCompany)} aria-controls="collapseCompany"
                   aria-expanded={collapseCompany}
                   className={!collapseCompany ? "footer-collapse__btn collapsed" : "footer-collapse__btn"}>
                    <span>L'entreprise</span><span className="icon-expand"/>
                </a>
                <Collapse in={collapseCompany}>
                    <div id="collapseCompany" className="footer-collapse__content">
                        <ul>
                            <li><a href="http://www.planetebut.fr/nos-offres-emplois.html" target="_blank"
                                   className="gtm-footer-link">BUT recrute</a></li>
                            <li><a href="http://www.but-cuisine.fr/" target="_blank"
                                   className="gtm-footer-link">BUT
                                Cuisines</a></li>
                            <li><a href="https://blog.but.fr/" target="_blank" className="gtm-footer-link">Le
                                Blog
                                BUT</a>
                            </li>
                            <li><a href="https://www.lineanatura.fr/" target="_blank"
                                   className="gtm-footer-link">Linea
                                Natura</a></li>
                            <li><a href="https://www.modern-living.fr/" target="_blank"
                                   className="gtm-footer-link">Modern
                                Living</a></li>
                            <li><a href="https://www.time-collection.fr/" target="_blank"
                                   className="gtm-footer-link">Time</a></li>
                            <li><a href="https://www.zandiara.fr/" target="_blank"
                                   className="gtm-footer-link">Zandiara</a>
                            </li>
                            <li><a href="https://www.bxgaming.fr/" target="_blank" className="gtm-footer-link">BX
                                Gaming</a>
                            </li>
                            <li><a href="https://retailium-media.com/" target="_blank" className="gtm-footer-link">
                                Retailium Média</a>
                            </li>
                            <li><a href="/geev-dons-d-objets-entre-particuliers" target="_blank" className="gtm-footer-link">
                                Partenariat Geev x BUT</a>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </div>
            <div className="footer-collapse">
                <a onClick={() => setCollapseAbout(!collapseAbout)} aria-controls="collapseAbout"
                   aria-expanded={collapseAbout}
                   className={!collapseAbout ? "footer-collapse__btn collapsed" : "footer-collapse__btn"}>
                    <span>À propos de BUT</span><span className="icon-expand" />
                </a>
                <Collapse in={collapseAbout}>
                    <div className="footer-collapse__content" id="collapseAbout">
                        <h1>
                            Achat Meubles, Electroménager, TV Son et High Tech, Matelas et sommiers, Cuisine équipée
                            et décoration
                        </h1>
                        <p className="pt-3">
                            Commandez vos meubles, votre cuisine, votre matelas ou votre canapé pas cher sur But.fr
                            votre magasin spécialiste de l'équipement et de la décoration de maison et appartement.
                            Le site des magasins de meuble et électroménager But propose des canapés, lits pas cher,
                            meubles de salon et bureau, meubles de cuisine, de chambre à coucher, de salle de bain
                            et autres idées d'objets de déco aux meilleurs prix tout au long de l'année durant les
                            soldes ou encore à Noël. Vous retrouverez également un large choix de canapés,
                            clic-clac, banquettes, canapé lit, chaises, tables et matelas. Vous trouverez chez But
                            tous les styles de déco pour vos meubles : du meuble design et moderne au traditionnel
                            pour revoir l'aménagement de vos pièces ou préparer un emménagement. Une petite
                            hésitation sur l'achat de matériel électroménager, hi-fi ou TV ? Référez-vous aux avis
                            des clients But, aux vidéos de présentation des produits, à la comparaison de produits
                            pour trouver le produit le moins cher ou le plus adapté à vos besoins ou à nos guides
                            d'aide au choix.
                            Profitez également de tous nos services pour acheter en toute sérénité une cuisine
                            équipée pas cher, du mobilier déco ou du matériel TV et son : les packs simplicité (pour
                            étendre vos garanties en cas de panne), les options de livraison (retrait dans en
                            magasin, livraison colis, prêt de camion, transporteur...) et les modes de paiement
                            sécurisés (carte de crédit, cartes de crédit VISA AURORE BUT et BUT AURORE). Enfin, pour
                            poser toutes vos questions (garantie, SAV, mode d'emploi, etc.) et partager votre avis,
                            rendez-vous sur votre espace client ou contactez notre service consommateur.
                            Découvrez notre espace bons plans et réduction pour plus de petits prix discount et
                            profiter de depromo exceptionnelles durant les soldes ou les ventes privées. Pour tous
                            vos cadeaux de Noël et vos achats durant les soldes, rendez-vous sur But.fr pour
                            retrouver toutes nos ventes flash, produits exclusifs de dernière minute, destockage et
                            autres bonnes affaires au meilleur prix. Abonnez-vous à notre newsletter pour être
                            informé de nos promotions toute l'année et à Noël pour trouver de bonnes idées cadeaux.
                        </p>
                    </div>
                </Collapse>
            </div>
            <div className="footer-paragraphe">
                <p>Commandez vos meubles, cuisine, matelas ou canapé pas cher sur But.fr, votre magasin
                    spécialiste
                    de
                    l’équipement et de la décoration d’intérieur.</p>
            </div>
            <div className="footer-paragraphe footer-paragraphe--flex">
                <span><img className="lazy" src={"https://media.but.fr/animations/images/footer/footer-but-fevad.png"}
                           alt=""/>
                </span>
                <p>But adhère au code déontologique de la Fevad et au système de médiation du e-commerce.</p>
            </div>
            <div>
                <a onClick={() => setCollapseTopSearch(!collapseTopSearch)} aria-controls="collapseTopSearch"
                   className={!collapseTopSearch ? "footer-collapse__btn collapsed" : "footer-collapse__btn"}
                   aria-expanded={collapseTopSearch}>
                    <span>Top recherches</span><span className="icon-expand"/>
                </a>
                <Collapse in={collapseTopSearch}>
                    <div id="collapseTopSearch" className="footer-collapse__content">
                        <ul>
                            <li><a href="/themes/soldes-toute-la-selection/index-c10523.html"
                                   className="gtm-footer-link">Soldes</a>
                            </li>
                            <li><a href="/themes/noel-toute-la-selection/index-c10515.html" className="gtm-footer-link">Revons
                                Noel</a>
                            </li>
                            <li><a href="/themes/destockage-toute-la-selection/index-c10459.html"
                                   className="gtm-footer-link">Destockage</a>
                            </li>
                            <li><a href="/themes/solution-gain-de-place/index-c10527.html"
                                   className="gtm-footer-link">Solution
                                gain de place</a></li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </>
    ,[collapseTopSearch, collapseAbout, collapseMkp, collapseCompany])
    return (
        <>
            <ShopServices inTunnel={false}/>
            {context !== 'Identification' && <section className="section-gray" id="newsletterSubscribe">
                <p className="newsletter-subtitle">Recevez la newsletter, 10€ offerts sur votre prochaine commande</p>
                <input type="text" placeholder="Email" className="newsletter-input"/>
                <a href="/InscriptionNewsletterBUT"
                   className="btn btn-default btn-red btn-lowercase py-3">Inscrivez-vous</a>
            </section>}
            {context !== 'Identification' && <footer>
                {memoizedFooterContent}
                <Tracking trackingData={footerTrackingFlow}/>
            </footer>}
            <Tracking trackingData={footerTrackingScopeData}/>
        </>
    )
});


Footer.propTypes = {
    context: PropTypes.string,
    footerTrackingFlow: PropTypes.string,
    footerTrackingScopeData: PropTypes.string,
};

Footer.defaultProps = {
    context: '',
    footerTrackingFlow: '',
    footerTrackingScopeData: ''
};

const mapStateToProps = (state, ownProps) => {
    return {
        context: ownProps.context,
        footerTrackingFlow: state.tracking.footerTrackingFlow,
        footerTrackingScopeData: ownProps.footerTrackingScopeData,
    };
};
export default connect(mapStateToProps, null)(Footer);