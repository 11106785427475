const cart = {
    init() {
        var topBar = $('#topBar')
        var paddingSupl = 0
        if (document.getElementById('afterwork')) {
            var afterworkHeight = $('#afterwork').outerHeight()
            paddingSupl = afterworkHeight
        }
        // APPARITION MESSAGE D'ERREUR
        $('.mess_error').slideDown();

        // fermer le bloc avec la croix
        $('.closeError').click(function (e) {
            e.preventDefault();
            $(this).parent('.mess_error').slideUp();
        });
        // APPARITION MESSAGE DE DISPONIBILITE
        $('#mess_dispo').slideDown();

        // fermer le bloc avec la croix
        $('#closeMess').click(function (e) {
            e.preventDefault();
            $('#mess_dispo').slideUp();
        });
    }
}

export const openFancyBoxPopin = (productId) => {
    $('.popin-stock-locator-' + productId).fancybox()
}

export default cart;