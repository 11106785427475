import React from 'react';
import {dispatchEventSTPUSHUpdate} from "@/animations/Default/commonPage";

export const FooterMenu = () => {
    return (
        <div id="menu_footer">
            <div className="wrap bloc_SEI">
                <div className="menu">
                    <p className="titre_liste">
                        Les services But
                    </p>
                    <ul>
                        <li>
                            <p className="sous_titre_liste">
                                Mes solutions de paiement
                            </p>
                            <ul>
                                <li><a href="/Carte_but" className="a_brouille c_brouille">Réglez avec la Carte BUT CPAY
                                    MASTERCARD</a></li>
                                <li><a href="/aide?_eptssref=/fr-fr/50/paiement-et-financement/" className="a_brouille c_brouille">Cartes
                                    cadeaux BUT et chèques partenaires</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="sous_titre_liste">
                                Mes modes de livraison/retrait
                            </p>
                            <ul>
                                <li><a href="/Services/Page/Retrait-2h" className="a_brouille c_brouille">Retrait
                                    gratuit</a></li>
                                <li><a href="/Services/Page/Livraison-domicile" className="a_brouille c_brouille">Livraison
                                    et montage</a></li>
                                <li><a href="/Services/Page/Camionnette" className="a_brouille c_brouille">Location de
                                    camionnette</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="sous_titre_liste">
                                Mes garanties
                            </p>
                            <ul>
                                <li><a href="/Services/Page/Sav" className="a_brouille c_brouille">SAV</a></li>
                                <li><a href="/a/1-Eco-Mobilier" className="a_brouille c_brouille">Recyclage et reprise
                                    Mobilier</a></li>
                                <li><a href="/a/41-Eco-Electromenager" className="a_brouille c_brouille">Recyclage et
                                    reprise Electroménager</a></li>
                                <li><a href="https://media.but.fr/PDF/N_SYDEREP_Magasins_BUT.pdf" target="_blank" className="a_brouille c_brouille">N°&nbsp;Registre de suivi Ademe/Ecomobilier</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="menu">
                    <p className="titre_liste">
                        Les + But
                    </p>
                    <ul>
                        <li>
                            <p className="sous_titre_liste">
                                Mon inspiration
                            </p>
                            <ul>
                                <li><a href="https://blog.but.fr/" target="_blank" className="a_brouille c_brouille">Blog
                                    BUT</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="sous_titre_liste">
                                Mes bons plans
                            </p>
                            <ul>
                                <li><a href="/Carte_but" className="a_brouille c_brouille">Tous les avantages de la
                                    Carte BUT</a></li>
                                <li><a href="/actualites/les-catalogues-but" className="a_brouille c_brouille">Dépliants
                                    et catalogue</a></li>
                                <li><a href="/newsletter/programme_fidelite/inscription"
                                       className="a_brouille c_brouille">Programme de fidélité</a></li>
                                <li><a href="https://monavantage.but.fr/" target="_blank"
                                       className="a_brouille c_brouille">Offres Mes avantages BUT</a></li>
                                <li><a href="https://monavantage.but.fr/" target="_blank"
                                       className="a_brouille c_brouille">Réclamation Offre de remboursement</a></li>
                                <li><a href="http://lesrendezvous.but.fr/" target="_blank"
                                       className="a_brouille c_brouille">Les rendez-vous BUT</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="sous_titre_liste">
                                Mes projets
                            </p>
                            <ul>
                                <li><a href="http://www.but-cuisines.fr/" target="_blank"
                                       className="a_brouille c_brouille">Projet Cuisine</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="sous_titre_liste commandables">
                                <a href="/themes/commandables-express/index-c488517.html"
                                   className="a_brouille c_brouille">Commandables Express</a>
                            </p>
                        </li>
                        <li>
                            <p className="sous_titre_liste commandables">
                                <a href="/geev-dons-d-objets-entre-particuliers" target="_blank" className="a_brouille c_brouille">Partenariat
                                    Geev x BUT</a>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="menu">
                    <p className="titre_liste">
                    But &amp; moi
                    </p>
                    <ul>
                        <li>
                            <p className="sous_titre_liste">
                                Besoin d’aide ?
                            </p>
                            <ul>
                                <li><a href="#" id="Footer_HorsTunnel_Lien_Aide_et_Contact_smart_tribune"
                                       onClick={(event) => {
                                           event.preventDefault();
                                           dispatchEventSTPUSHUpdate(['classic-5560']);
                                       }} className="a_brouille c_brouille">Aide &amp; Contact</a></li>
                                <li><a href="/aide" className="a_brouille c_brouille">Questions fréquentes</a></li>
                                <li><a href="/Informations/Conditions-generales" className="a_brouille c_brouille">CGV
                                    Internet</a></li>
                                <li><a href="https://media.but.fr/PDF/CGV-BUT-Magasins-Decembre2019.pdf" target="_blank"
                                       className="a_brouille c_brouille">CGV magasin</a></li>
                                <li><a href="/Common/Services/Accessibility" target="_blank"
                                       id="access" className="a_brouille c_brouille">Accessibilité</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="sous_titre_liste">
                                Informations
                            </p>
                            <ul>
                                <li><a href="https://media.but.fr/PDF/Rappel_produit_en_cours.pdf"
                                       target="_blank">Rappel produits</a></li>
                                <li><a href="https://recrute.but.fr/postulez" target="_blank" className="a_brouille c_brouille">BUT recrute</a></li>
                                <li><a href="/but-pro" target="_blank" className="gtm-footer-link">But Pro</a></li>
                                <li><a href="https://But-corporate.fr" target="_blank" className="a_brouille c_brouille">But-corporate.fr</a></li>
                                <li><a href="/Informations/Mentions-legales" className="a_brouille c_brouille">Mentions
                                    légales</a></li>
                                <li><a href="/Common/Services/DonneesPersonnelles" className="a_brouille c_brouille">Données
                                    personnelles</a></li>
                                <li><a href="#" onClick={() => {
                                    tC.privacyCenter.showPrivacyCenter();
                                    return false
                                }}
                                       className="a_brouille c_brouille">Gérer mes cookies</a></li>
                                <li><a href="/aide/plan-site.php" className="a_brouille c_brouille">Plan du site</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="menu">
                    <p className="titre_liste">
                        Marketplace
                    </p>
                    <ul>
                        <li>
                            <p className="sous_titre_liste">
                                Informations
                            </p>
                            <ul>
                                <li><a href="/marketplace-but" className="a_brouille c_brouille">Qu'est-ce que la
                                    Marketplace ?</a></li>
                                <li><a href="/marketplace-vendeurs" className="a_brouille c_brouille">Vendre sur la
                                    Marketplace But</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="sous_titre_liste">
                                Aide sur la Marketplace
                            </p>
                            <ul>
                                <li><a href="https://media.but.fr/PDF/BUT_CGV_Marketplace.pdf" target="_blank"
                                       className="a_brouille c_brouille">CGV Marketplace</a></li>
                                <li><a href="https://media.but.fr/PDF/BUT_CGU_Marketplace.pdf" target="_blank"
                                       className="a_brouille c_brouille">CGU Marketplace</a></li>
                                <li><a href="https://media.but.fr/PDF/BUT_CGU_prestataire_paiement.pdf" target="_blank"
                                       className="a_brouille c_brouille">Prestataire de paiement</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="titre_liste">Publicité</p>
                            <ul>
                                <li><a href="https://retailium-media.com/" target="_blank"
                                       className="a_brouille c_brouille">Retailium Média</a></li>
                            </ul>
                        </li>
                        <li>
                            <p className="titre_liste">
                                Les marques exclusives
                            </p>
                            <ul>
                                <li><a href="https://www.lineanatura.fr/" target="_blank"
                                       className="a_brouille c_brouille">Linea Natura</a></li>
                                <li><a href="https://www.modern-living.fr/" target="_blank"
                                       className="a_brouille c_brouille">Modern Living</a></li>
                                <li><a href="https://www.time-collection.fr/" target="_blank"
                                       className="a_brouille c_brouille">Time</a></li>
                                <li><a href="https://www.zandiara.fr/" target="_blank"
                                       className="a_brouille c_brouille">Zandiara</a></li>
                                <li><a href="https://www.bxgaming.fr/" target="_blank"
                                       className="a_brouille c_brouille">BX Gaming</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="socials_networks">
                <p>Suivez-nous sur les réseaux !</p>
                <ul>
                    <li>
                        <a rel="noopener noreferrer" title="Instagram" target="_blank" className="a_brouille"
                           href="https://www.instagram.com/but/">
                            <img width={29.994} height={30} alt="Instagram"
                                 src="https://media.but.fr/Sources/Default/Images/Footer/logo-instagram.svg" />
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" title="Tik-Tok" target="_blank"
                           href="https://www.tiktok.com/@magasinsbut" className="a_brouille">
                            <img width={26.374} height={30} alt="Tik-Tok"
                                 src="https://media.but.fr/Sources/Default/Images/Footer/logo-tiktok.svg" />
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" title="Facebook" target="_blank" className="a_brouille"
                           href="http://www.facebook.com/but">
                            <img width={13.211} height={25.625} alt="Facebook"
                                 src="https://media.but.fr/Sources/Default/Images/Footer/logo-facebook.svg" />
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" title="Pinterest" target="_blank" className="a_brouille"
                           href="http://pinterest.com/magasinsbut/">
                            <img width={24.374} height={30} alt="Pinterest"
                                 src="https://media.but.fr/Sources/Default/Images/Footer/logo-pinterest.svg" />
                        </a>
                    </li>
                    <li>
                        <a rel="noopener noreferrer" title="Twitter" target="_blank" href="https://twitter.com/But" className="a_brouille">
                            <img width={28.565} height={23.208} alt="X" src="https://media.but.fr/Sources/Default/Images/Footer/logo-x.svg" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
};
export default FooterMenu;