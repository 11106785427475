import {getAppStore} from "@/redux/store"
import {Provider} from 'react-redux'
import App from 'next/app'
import AuthProvider from "@/components/AuthProvider"
import {UAParser} from '@/libs/ua-parser'
import {DEFAULT_TEMPLATE_VERSION, MOBILE_TEMPLATE_VERSION} from "@/utils/constants";

/*
export function reportWebVitals(metric) {
    //TODO SBE Add metrics log to Rum
}
*/

function MyApp({Component, pageProps, deviceType, isServer}) {
    const store = getAppStore(deviceType)
    const props = {...pageProps, templateVersion: deviceType}
    return (
        <Provider store={store}>
            <AuthProvider deviceType={deviceType} isServer={isServer}> <Component {...props} /> </AuthProvider>
        </Provider>
    )
}

MyApp.getInitialProps = async (appContext) => {
    const isServer = !!appContext.ctx.req
    let deviceType = ''
    if (isServer) {
        deviceType = appContext.ctx.req.headers['cloudfront-is-mobile-viewer'] == 'false' ? DEFAULT_TEMPLATE_VERSION : MOBILE_TEMPLATE_VERSION
    } else {
        const userAgent = navigator.userAgent
        const ua = new UAParser(userAgent).getResult();
        const uaDeviceType = ua.device.type ?
            ua.device.type.charAt(0).toUpperCase()
            + ua.device.type.slice(1) : DEFAULT_TEMPLATE_VERSION
        deviceType = uaDeviceType == DEFAULT_TEMPLATE_VERSION ? DEFAULT_TEMPLATE_VERSION : MOBILE_TEMPLATE_VERSION
    }
    const appProps = await App.getInitialProps(appContext)
    return {...appProps, deviceType, isServer}
}
export default MyApp
