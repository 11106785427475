import {
    CONFIRM_RECEPTION_ORDER,
    FETCH_ORDER_DATA,
    FETCH_ORDER_SHOP_DATA,
    MESSAGES_HISTORY,
    REASONS_LISTED,
    INCIDENT_OPENED,
    INCIDENT_CLOSED,
    UPDATE_SENDING_MESSAGE,
    UPDATE_IS_SENDING_MESSAGE_TO_OPERATOR,
    UPDATE_IS_SENDING_INVOICE_REQUEST,
    UPDATE_MESSAGE_DOCUMENTS,
    MESSAGE_SENT,
    MESSAGE_FAILED,
    UPDATE_REASON,
    CHANGE_SELECTED_REASON,
    UPDATE_MESSAGE_CONTENT,
    UPDATE_MESSAGE_WORKFLOW_TYPE,
    RESET_MESSAGE_ERROR,
    RESET_MESSAGE_SENT_WITH_FILE,
    MESSAGE_SENT_WITH_FILE,
    MESSAGE_SENT_INCIDENT,
    SHOW_LOADER,
    OPERATOR_MESSAGE,
    CLOSE_INCIDENT_REASONS_LISTED,
    UPDATE_BREADCRUMB_DATA,
    UPDATE_CURRENT_PAGE_DATA,
    RESET_ORDER_DETAILS
} from '../actionsTypes'

const initialState = {
    order: null,
    isConfirmedReceptionOrder: false,
    shop: {},
    isSendingMessage: false,
    isSendingMessageToOperator: false,
    isSendingInvoiceRequest: false,
    messages: [],
    messageDocuments: [],
    selectedItem: null,
    selectedReasonCode: 'Choix',
    messageRequestSentContext: '',
    messageServerErrorResponse: {},
    messageContent: '',
    reasonType: '',
    reasonSelectedLabel: '',
    reasonSelectedType: '',
    openMessage: null,
    validSizeServer: true,
    messageSentWithFile: false,
    showLoader: false,
    showLitige: false,
    showSendMessageToOperatorButton: false,
    selectCloseIncidentFirstClick: false,
    canRequestInterventionBut: false,
    levelData:null,
    currentPage: 'order'
};

const OrderDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDER_DATA:
            return {
                ...state,
                order: action.payload.order,
            }
        case CONFIRM_RECEPTION_ORDER:
            return {
                ...state,
                isConfirmedReceptionOrder: true,
            }
        case FETCH_ORDER_SHOP_DATA:
            return {
                ...state,
                shop: action.payload.shopData,
            }
        case MESSAGES_HISTORY:
            return {
                ...state,
                messages: action.payload.messages
            }
        case REASONS_LISTED: {
            return {
                ...state,
                reasonsList: action.payload.reasonsList,
                orderItemDetailsDisplay: action.payload.orderItemDetailsDisplay,
                selectedItem: action.payload.selectedItem,
                selectedChildOrder: action.payload.selectedChildOrder,
                selectedOrderId: action.payload.selectedOrderId,
                selectedOrderTime: action.payload.selectedOrderTime,
                selectedOrderDate: action.payload.selectedOrderDate,
                reasonType: action.payload.reasonType
            }
        }
        case INCIDENT_OPENED: {
            const newSelectedItem = {...state.selectedItem, canOpenIncident: action.payload.canOpenIncident}
            let childOrders = state.order.childOrders;
            childOrders[action.payload.childOrderIndex].items[action.payload.itemIndex].canOpenIncident = false;
            return {
                ...state,
                selectedItem: newSelectedItem,
                childOrders: childOrders
            }
        }
        case INCIDENT_CLOSED: {
            if (action.payload.closeIncidentSuccess) {
                let childOrders = state.order.childOrders;
                childOrders[action.payload.childOrderIndex].hasIncident = false;
                childOrders[action.payload.childOrderIndex].items[action.payload.index].canOpenIncident = true;
                childOrders[action.payload.childOrderIndex].closeIncidentSuccess = action.payload.closeIncidentSuccess
                return {
                    ...state,
                    childOrders: childOrders
                }
            } else {
                return {
                    ...state,
                }
            }
        }
        case UPDATE_SENDING_MESSAGE :
            return {
                ...state,
                isSendingMessage: action.isSendingMessage
            }
        case UPDATE_IS_SENDING_MESSAGE_TO_OPERATOR :
            return {
                ...state,
                isSendingMessageToOperator: action.isSendingMessageToOperator
            }
        case UPDATE_IS_SENDING_INVOICE_REQUEST :
            return {
                ...state,
                isSendingInvoiceRequest: action.isSendingInvoiceRequest
            }
        case MESSAGE_FAILED :
            return {
                ...state,
                messageRequestSentContext: action.messageRequestSentContext,
                isSendingMessage: false,
                isSendingMessageToOperator: false,
                isSendingInvoiceRequest: false,
                validSizeServer: action.validSizeServer,
                messageServerErrorResponse: action.messageServerErrorResponse
            }
        case MESSAGE_SENT :
            return {
                ...state,
                messageSent: true,
                messageRequestSentContext: action.payload.messageRequestSentContext,
                selectedReasonCode: action.payload.selectedReasonCode,
                messageContent: action.payload.messageContent,
                reasonSelectedLabel: action.payload.reasonSelectedLabel,
                reasonSelectedType: action.payload.reasonSelectedType,
                orderItemDetailsDisplay: action.payload.orderItemDetailsDisplay,
                isSendingMessage: false,
                isSendingMessageToOperator: false
            }
        case MESSAGE_SENT_INCIDENT :
            return {
                ...state,
                messageSent: true,
                messageRequestSentContext: action.payload.messageRequestSentContext,
                messageContent: action.payload.messageContent,
                orderItemDetailsDisplay: action.payload.orderItemDetailsDisplay,
                isSendingMessage: false,
                isSendingMessageToOperator: false
            }
        case UPDATE_REASON: {
            return {
                ...state,
                selectedReasonCode: action.selectedReasonCode,
                reasonSelectedLabel: action.reasonSelectedLabel,
                reasonSelectedType: action.reasonSelectedType
            }
        }
        case CHANGE_SELECTED_REASON: {
            return {
                ...state,
                selectedReasonCode: action.selectedReasonCode,
                reasonSelectedLabel: action.reasonSelectedLabel,
                reasonSelectedType: action.reasonSelectedType
            }
        }
        case UPDATE_MESSAGE_CONTENT: {
            return {
                ...state,
                messageContent: action.messageContent
            }
        }
        case UPDATE_MESSAGE_DOCUMENTS: {
            return {
                ...state,
                messageDocuments: action.messageDocuments
            }
        }
        case UPDATE_MESSAGE_WORKFLOW_TYPE : {
            return {
                ...state,
                openMessage: action.payload.openMessage
            }
        }
        case RESET_MESSAGE_ERROR : {
            return {
                ...state,
                validSizeServer: true
            }
        }
        case RESET_MESSAGE_SENT_WITH_FILE :
            return {
                ...state,
                messageSentWithFile: action.messageSentWithFile
            }
        case MESSAGE_SENT_WITH_FILE :
            return {
                ...state,
                messageSentWithFile: true
            }
        case SHOW_LOADER: {
            const newSelectedItem = {...state.selectedItem, showLoader: action.payload.showLoader}
            return {
                ...state,
                selectedItem: newSelectedItem
            }
        }
        case OPERATOR_MESSAGE:{
            let childOrders = state.order.childOrders;
            childOrders[action.payload.orderInfos.childOrderIndex].items[action.payload.orderInfos.itemIndex].showLitige = action.payload.showLitige;
            childOrders[action.payload.orderInfos.childOrderIndex].items[action.payload.orderInfos.itemIndex].canRequestInterventionBut = action.payload.canRequestInterventionBut;
            return {
                ...state,
                childOrders: childOrders
            }
        }
        case CLOSE_INCIDENT_REASONS_LISTED: {
            return {
                ...state,
                closeIncidentReasons: action.payload.closeIncidentReasons,
                reasonType: action.payload.reasonType,
                selectCloseIncidentFirstClick: action.payload.selectCloseIncidentFirstClick
            }
        }
        case UPDATE_BREADCRUMB_DATA:
            return {
                ...state,
                levelData: action.payload.levelData
            }
        case UPDATE_CURRENT_PAGE_DATA:
            return {
                ...state,
                currentPage: action.payload.currentPage
            }
        case RESET_ORDER_DETAILS:
            return {
                ...state,
                order: null
            }
        default:
            return state;
    }
};

export default OrderDetailsReducer;

