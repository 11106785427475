import {
    DESTOCKED_PRODUCTS_INFOS_FETCHED,
    GET_PRODUCT_REVIEWS,
    GET_CARAC_PRODUCT,
    HANDLE_SHOP_OVERLAY,
} from "../actionsTypes"
import {
    getDestockedProducts,
    getProductReviewsData,
    getCaracProductData
} from "@/services/destockedProductServices";


export const getDestockedProductsByShop = (shopId) => {

    return (dispatch) => {
        getDestockedProducts(shopId)
            .then(response => {
                 dispatch({
                    type: DESTOCKED_PRODUCTS_INFOS_FETCHED,
                    payload: {destockedProductsData: response.data.items ? response.data.items : []}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };
}

export const getProductReviews = (destockedProductID, pageSize) => {

    return (dispatch) => {
        getProductReviewsData(destockedProductID, pageSize)
            .then(response => {
                dispatch({
                    type: GET_PRODUCT_REVIEWS,
                    payload: {productReviewData: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };
}

export const getCaracProduct = (destockedProductID) => {
    return (dispatch) => {
        getCaracProductData(destockedProductID)
            .then(response => {
                dispatch({
                    type: GET_CARAC_PRODUCT,
                    payload: {caracProductData: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    };
}

export const handleShopOverlay = (state) => {
    return function (dispatch) {
        dispatch({
            type: HANDLE_SHOP_OVERLAY,
            payload: {
                opened: state
            }
        });
    }

}