import Axios from 'axios'
import Cookies from 'js-cookie'
import getConfig from 'next/config'
import Utils from "@/utils/Default/Utils";

const {publicRuntimeConfig} = getConfig();
const ApiCaller = Axios.create({
    baseURL: publicRuntimeConfig.frontBaseUrl,
});

function handleToken(params = {}) {
    return new Promise((resolve, reject) => {
        if (Object.keys(params).length !== 0) {
            Axios.get("/Api/Rest/WebUsers/Identity", {params: params}).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        } else {
            Axios.get("/Api/Rest/WebUsers/Identity").then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        }

    });
}

const hasOldWebUserIdInUrl = (url) => {
    return Utils.getUserIdFromCookie() !== appData.webUserId
}

const changeWebUserIdUrl = (currentUrl, oldWebUserId, newWebUserId) => {
    return currentUrl.replace(oldWebUserId, newWebUserId);
}
const isTokenExpired = () => {
    let navigationTokenExpiration = Cookies.get('navigationTokenExTime');
    let currentDateTime = Math.round(+new Date() / 1000);
    return (!navigationTokenExpiration || currentDateTime >= navigationTokenExpiration);
}

ApiCaller.interceptors.request.use(async (config) => {
    let data = {};
    let result = null;
    if (isTokenExpired()) {
        if (config.url.indexOf('/Shops/Appointment') !== -1) {
            data = {
                documentNumber: appData.orderInfos.documentNumber,
                wpCustomerId: appData.orderInfos.wpCustomerId,
            };
            result = await handleToken(data);
        } else if (config.method === 'put' && config.url.indexOf("Cart?View=compact") != -1) {
            result = await handleToken();
        }
        if (result !== null) {
            Cookies.set('navigationToken', result.headers['navigation-token'], {
                domain: appData.cookieDomain,
                secure: true,
                sameSite: "None"
            })
            Cookies.set('navigationTokenExTime', result.headers['navigation-token-ex-time'], {
                domain: appData.cookieDomain,
                secure: true,
                sameSite: "None"
            })
            if (hasOldWebUserIdInUrl(config.url)) {
                config.url = changeWebUserIdUrl(config.url,appData.webUserId, result.data.userId)
                appData.webUserId = result.data.userId
            }
        }
    }
    if ((config.method === 'put' || config.method === 'post') && config.url.includes('/Cart') && !config.rowData) {
        const oldWebUserId = config.url.match(/WebUsers\/(.*?)\/Cart/)[1];
        config.url = config.url = changeWebUserIdUrl(config.url, oldWebUserId, appData.webUserId)
        if (typeof Cookies.get('cart') !== 'undefined') {
            localStorage.setItem('cart', Cookies.get('cart'));
            Cookies.remove('cart');
        }
        config.data.cart = localStorage.getItem('cart');
    }
    let IE;
    if (config.method === 'post' && !config.rowData) {
        if (/*@cc_on!@*/false || !!document.documentMode) {
            IE = true;
        } else {
            IE = false;
        }
        let data
        if (IE) {
            data = []
            Object.keys(config.data).forEach((key) => {
                let parsedData = Utils.parseUrlParameterForIE(key, config.data[key])
                data.push(parsedData)
            });

            config.data = Utils.prepareAndParseConfigDataForIE(data)

        } else {
            data = new URLSearchParams();
        }
        if (!IE) {
            Object.keys(config.data).forEach((key) => {
                data.append(key, config.data[key]);
            });
            config.data = data;
        }
    }
    config.baseURL = '/Api/Rest';
    if (config.overrideHeaders === true) {
        if (!config.withoutDefaultContentType) {
            config.headers.common['content-type'] = 'application/json'
        }
        return config
    } else {
        config.headers.common['WORDING-VERSION'] = 'Default'
        config.headers.common['navigation-token'] = Cookies.get('navigationToken')
        config.headers.common['sellerInfos'] = Cookies.get('sellerInfos')
        //You should use URLSearchParams() with application/x-www-form-urlencoded according to axios documentation
        if (!IE) {
            config.headers.post['content-type'] = 'application/x-www-form-urlencoded';
        }
        if (!!appData.navigationMetaInfosConfigs) {
            var navigationMetaInfosConfigs = appData.navigationMetaInfosConfigs;
            Object.keys(navigationMetaInfosConfigs).forEach(function (key) {
                var navigationMetaInfoName = navigationMetaInfosConfigs[key].pattern;
                let navigationMetaInfoValue = Cookies.get(navigationMetaInfoName == appData.sparkowUserIdPatternName ? appData.sparkowUserIdCookieName : navigationMetaInfoName);
                if (typeof navigationMetaInfoValue != "undefined" && navigationMetaInfoValue != undefined) {
                    config.headers.common[navigationMetaInfoName] = navigationMetaInfoValue

                }
            }, config);
        }
        return config
    }
});

ApiCaller.interceptors.response.use((config) => {
    if (config.headers.hasOwnProperty('navigation-token')) { //TODO To check why it necessery
        Cookies.set('navigationToken', config.headers['navigation-token'], {
            domain: appData.cookieDomain,
            secure: true,
            sameSite: "None"
        });
        Cookies.set('navigationTokenExTime', config.headers['navigation-token-ex-time'], {
            domain: appData.cookieDomain,
            secure: true,
            sameSite: "None"
        })
    }
    if (!!appData.navigationMetaInfosConfigs) {
        var navigationMetaInfosConfigs = appData.navigationMetaInfosConfigs;
        Object.keys(navigationMetaInfosConfigs).forEach(function (key) {

            var navigationMetaInfoName = navigationMetaInfosConfigs[key].pattern;

            var navigationMetaInfoValue = '';
            if (navigationMetaInfoName && config.headers.hasOwnProperty(navigationMetaInfoName.toLowerCase())) {

                var navigationTokenMetaInfoHeaderValue = config.headers[navigationMetaInfoName.toLowerCase()];
                if (typeof navigationTokenMetaInfoHeaderValue != 'undefined' && navigationTokenMetaInfoHeaderValue != 'undefined'
                    && navigationTokenMetaInfoHeaderValue != null) {

                    navigationMetaInfoValue = navigationTokenMetaInfoHeaderValue;
                }
            }
            var navigationMetaInfoConfigs = navigationMetaInfosConfigs[key];
            var lifeTime = parseFloat(navigationMetaInfoConfigs.duration) / (60 * 60 * 24);
            // Ajouter dans le cadre de tracking sparkow avec libraire Octipas
            if (navigationMetaInfoConfigs.pattern !== appData?.sparkowUserIdPatternName && typeof navigationTokenMetaInfoHeaderValue != 'undefined' && navigationTokenMetaInfoHeaderValue != 'undefined' && navigationMetaInfosConfigs[key].httpOnly == 'false') {
                Cookies.set(navigationMetaInfoConfigs.pattern, navigationMetaInfoValue, {
                    path: navigationMetaInfoConfigs.path,
                    domain: navigationMetaInfoConfigs.domain,
                    expires: lifeTime
                });
            }
        }, config);
    }
    let body = config.data;
    try {
        if (body !== null) {
            if (typeof body.debugData !== 'undefined') {
                let debugBarData = Utils.parseJson(body.debugData);
                delete body.debugData;
                let id = debugBarData.__meta.id;
                if (typeof phpdebugbar !== 'undefined')
                    phpdebugbar.addDataSet(debugBarData, id);
            } else if (Array.isArray(body) && typeof body['debugData'] !== 'undefined') {
                let debugBarData = body.pop();
                debugBarData = Utils.parseJson(debugBarData);
                let id = debugBarData.__meta.id;
                if (typeof phpdebugbar !== 'undefined')
                    phpdebugbar.addDataSet(debugBarData, id);
            } else if (typeof body['cartStorage'] !== 'undefined') {
                localStorage.setItem('cart', body['cartStorage']);
            }

        }
    } catch (err) {
        console.log(err);
    }

    return config

});


//TODO SES  Handle debugbar data async

export default ApiCaller;
