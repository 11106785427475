import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import EventList from "./EventList";
import ShopContact from "./ShopContact";
import * as  shopPageAnimation from "@/animations/Mobile/shopPage";
import ShopOverlayHeader from "./ShopOverlayHeader";
import ShopDetails from "./ShopDetails";
import WharehouseDetails from "./WharehouseDetails";
import ShopPresentation from "./ShopPresentation";
import {handleShopOverlay} from '@/redux/reducers/Mobile/shop/destockedProductListActions'
import ShopPageMap from './ShopPageMap';
import {Tab, Nav} from 'react-bootstrap'
import {fetchShopData} from "@/redux/reducers/Mobile/header/headerActions";

const ShopPageOverlay = (props) => {

    const {
        shopId,
        shopAndWharehouseData,
        shopEventListData,
        opened,
        handleShopOverlay,
        warehouseInfos,
        googleMapsKey,
        shopInfos
    } = props;

    useEffect(() => {
        if (shopId && !shopAndWharehouseData) {
            props.fetchShopData(shopId, true)
        }
        if (shopAndWharehouseData && shopAndWharehouseData.shopInfos.id) {
            shopPageAnimation.init();

        }
    }, [])

    let shopInfosSchedule = (shopInfos?.schedule) ? shopInfos.schedule : null
    if (shopAndWharehouseData) {
        return (
            <section
                className={opened ? "section-store section-store--overlay open" : "section-store section-store--overlay"}>
                <ShopOverlayHeader shopAndWharehouseData={shopAndWharehouseData}
                                   handleShopOverlay={handleShopOverlay}/>
                {(shopEventListData) ? <EventList shopEventListData={shopEventListData}
                                                  shopAndWharehouseData={shopAndWharehouseData}/> : null}

                <Tab.Container id="left-tabs-example" defaultActiveKey="depot">
                    <div className="store-fiche__tabs">
                        <Nav className="nav-tabs">
                            <li>
                                <Nav.Link eventKey="magasin">Magasin</Nav.Link>
                            </li>
                            <li>
                                <Nav.Link eventKey="depot">Retrait marchandise</Nav.Link>
                            </li>
                        </Nav>
                    </div>
                    <div className="store-fiche-tab-content tab-content">
                        <Tab.Content>
                            <Tab.Pane eventKey="magasin">
                                <ShopDetails shopAndWharehouseData={shopAndWharehouseData}
                                             openingShopSchedule={shopInfosSchedule}
                                             shopNextDayOpen={shopAndWharehouseData.shopInfos.shopNextDayOpen}
                                             shopNextDayOpenSchedule={shopAndWharehouseData.shopInfos.shopNextDayOpenSchedule}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="depot">
                                <WharehouseDetails warehouseInfos={warehouseInfos}
                                                   shopName={shopAndWharehouseData.shopInfos.name}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
                <ShopContact contact={shopAndWharehouseData.shopInfos.contact}/>
                <ShopPresentation shopAndWharehouseData={shopAndWharehouseData}/>
                <div className="shopOverlay">
                    {opened ? <ShopPageMap googleMapId={'google-map-overlay'}
                                           productshowedType={'destocked-product-overlay'}
                                           shopAndWharehouseData={shopAndWharehouseData}
                                           googleMapsKey={googleMapsKey}/> : null}
                </div>
                <div className="store-fiche-recrute">
                    <a target="_blank" href="https://recrute.but.fr/" className="btn btn-block btn-blue"
                       style={{color: '#ffff'}}>But recrute</a>
                </div>
            </section>
        )
    } else {
        return null
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        shopAndWharehouseData: state.shop.shopAndWharehouseData,
        warehouseInfos: state.shop.shopAndWharehouseData ? state.shop.shopAndWharehouseData.warehouseInfos : null,
        shopInfos : state.shop.shopAndWharehouseData?.shopInfos,
        googleMapsKey: ownProps.googleMapsKey,
        shopId: ownProps.shopId,
        shopEventListData: state.header.shopEventListData,
        opened: state.shop.opened,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleShopOverlay: (state) => dispatch(handleShopOverlay(state)),
        fetchShopData: (shopId,IsSliding) => dispatch(fetchShopData(shopId,IsSliding)),


    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOverlay);