import { ThunkResult } from "@/models/common/core/ThunkResult";
import { Cart } from "@/models/scope/cart/Cart";
import { getUserIdFromCookie, getBasketData } from "@/utils/responsive/utils";
import {
    CART_DATA_UPDATE,
    CART_DATA_UPDATE_ERROR,
    LAST_ADDED_ITEM
} from "@/redux/reducers/responsive/actionsTypes";
import {
    CART_DATA_UPDATE_ERROR_TYPES,
    CART_DATA_UPDATE_TYPES, CART_GLD_UPDATED_TYPES, CART_ITEMS_UPDATED_TYPES,
    LAST_ADDED_ITEM_TYPES, UPDATE_CHANGE_QUANTITY_STATE_TYPES
} from "redux/reducers/responsive/cart/cartTypes";
import { checkOlapicBloc } from "@/animations/responsive/productSheetPage";
import { addToCart } from "@/services/cartServices";
import OctipasMerch from '@/vendors/OctipasMerch'
import { addToCartProductSuccessTracking, addToCartProductFailledTracking } from "@/animations/responsive/productPageTracking";
import { SOLD_BY_BUT, SOLD_BY_MKP } from "@/utils/constants";
import ApiCaller from "@/services/ApiCaller/apiCaller";
import {CART_GLD_UPDATED, CART_ITEMS_UPDATED, UPDATE_CHANGE_QUANTITY_STATE} from "@/redux/reducers/Mobile/actionsTypes";
import {
    closeModal,
    hideCartLoader,
    hideLoader,
    showCartLoader,
    showLoader
} from "@/animations/responsive/cart/cartPage";
import Utils from "@/libs/Utils";
import {updateQuantitySuccessTracking} from "@/redux/reducers/responsive/cart/toRemove/CartActions";

interface Params {
    productId: string;
    quantity: number;
    offerId?: number;
    sellerName?: string;
    callback?: () => void;
    trackingData?: {};
    warrantyCode?: string;
}

export function addToCartAction(params: Params): ThunkResult<CART_DATA_UPDATE_TYPES | CART_DATA_UPDATE_ERROR_TYPES | LAST_ADDED_ITEM_TYPES> {
    const { productId, quantity, offerId, sellerName, trackingData } = params;
    // productSheetAnimations.showLoader()
    let url = "/WebUsers/" + getUserIdFromCookie() + "/Cart?View=compact";
    //todo recuperer comingFrom
    let addedFrom = (window.appData.comingFrom == 't2s') ? 't2s' : 'nav';
    let hasOlapicContent = checkOlapicBloc();
    let data = {
        action: "addProducts",
        items: offerId ? [{
            id: productId,
            qty: quantity,
            addedFrom: addedFrom,
            offerId: offerId,
            hasOlapicContent: hasOlapicContent
        }] : [{
            id: productId,
            qty: quantity,
            addedFrom: addedFrom,
            hasOlapicContent: hasOlapicContent
        }]
    };
    return function (dispatch) {
        addToCart(url, data)
            .then((response: Cart) => {
                let modalToShow = '';
                // productSheetAnimations.hideLoader();
                if (response.consolidationMessages && response.consolidationMessages[0]
                    && response.consolidationMessages[0].code === "prodcut_added_to_cart") {
                    addToCartProductSuccessTracking(trackingData, quantity)
                    OctipasMerch?.createOrReplaceBasket(getBasketData(response.items))
                    modalToShow = 'successModal'
                } else {
                    addToCartProductFailledTracking(trackingData)
                    modalToShow = 'subscriptionModal'
                }
                const lastAddedItem = response.items.find(item => ((item.ean13 == productId) && (item.soldBy ==  (offerId ? SOLD_BY_MKP : SOLD_BY_BUT)))) || {};
                dispatch({
                    type: CART_DATA_UPDATE,
                    payload: {
                        totalQuantity: response.totalQuantities,
                        shopId: response.shopId,
                        consolidationMessages: response.consolidationMessages,
                        productSellerName: sellerName,
                        showModal: modalToShow,
                        cart: response,
                    }
                });
                dispatch({
                    type: LAST_ADDED_ITEM,
                    payload: lastAddedItem
                });
                if (!!params.warrantyCode) {
                    selectExtendedWarranty(productId, params.warrantyCode, true);
                }
            }).catch((error) => {
                addToCartProductFailledTracking(trackingData)
                console.log(error);
                dispatch({
                    type: CART_DATA_UPDATE_ERROR,
                    payload: {
                        cartUpdateError: true,
                    }
                });
            }).finally(() => {
                params.callback && params.callback();
            });
    }

}
export const selectExtendedWarranty = (productId: string, parentProductId: string, isChecked: boolean) => {
    let url = "/WebUsers/" + getUserIdFromCookie() + "/Cart?View=cart";
    let data = {
        action: (!isChecked) ? "removeProductsWarranty" : "addProductsWarranty",
        items: [{ id: productId, service: { code: parentProductId } }]
    };
    return addToCart(url, data);
}
// Fetch Cart Summary
export function fetchCartSummary(context: string | null = null): ThunkResult<CART_ITEMS_UPDATED_TYPES>  {
    return function (dispatch, getState) {
        const userId = getUserIdFromCookie();
        ApiCaller.post(`/WebUsers/${userId}/Cart?View=cart`, { context: "InStore" })
            .then((response) => {
                const cartObject = response.data;
                const successMessages = cartObject?.messages?.success || [];
                const warningMessages = cartObject?.messages?.warning || [];
                const errorMessages = cartObject?.messages?.error || [];
                const consolidationMessages = cartObject?.consolidationMessages || [];



                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {
                        cartObject: { ...cartObject, messages: { success: successMessages, warning: warningMessages, error: errorMessages }, consolidationMessages },
                        totalQuantity: cartObject.totalQuantities
                    }
                });
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// Update Product Quantity in Cart
export function updateProductQuantityInCart(productId: string, quantity: number) : ThunkResult<CART_ITEMS_UPDATED_TYPES | UPDATE_CHANGE_QUANTITY_STATE_TYPES | LAST_ADDED_ITEM_TYPES> {
    showLoader(productId);
    return function (dispatch) {
        const data = {
            action: "updateProductsQuantity",
            items: [{ id: productId, qty: quantity }]
        };

        ApiCaller.put(`/WebUsers/${Utils.getUserIdFromCookie()}/Cart?View=cart`, data)
            .then((response) => {
                hideLoader(productId);
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: { cartObject: response.data, totalQuantity: response.data.totalQuantities }
                });
                dispatch({
                    type: UPDATE_CHANGE_QUANTITY_STATE,
                    payload: { itemId: productId, stateValue: 'success' }
                });
                interface CartItem {
                    productId: string;
                    sellerName: string;
                    sellerId: string;
                }

                // Handle tracking
                const resultData = response.data;
                if (resultData.consolidationMessages[0]?.code === "cart_stock_reservation" && resultData.consolidationMessages[0].type === 1) {
                    const item = (resultData.items as CartItem[]).find((prod: CartItem) => prod.productId === productId);
                    if (item) {
                        updateQuantitySuccessTracking({ sellerName: item.sellerName, sellerId: item.sellerId });
                    }
                }

                OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items));
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

// Delete Product from Cart
export function deleteProductFromCart(itemId: string) : ThunkResult<CART_ITEMS_UPDATED_TYPES > {
    showCartLoader();
    return function (dispatch) {
        const data = {
            action: "removeProducts",
            items: [{ id: itemId }]
        };

        ApiCaller.put(`/WebUsers/${Utils.getUserIdFromCookie()}/Cart?View=cart`, data)
            .then((response) => {
                closeModal(`list-panier__row_model_delete-${itemId}`);
                hideCartLoader();
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: { cartObject: response.data, totalQuantity: response.data.totalQuantities }
                });
                OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items));
            })
            .catch((error) => {
                console.error(error);
            });
    };
}
export function deleteProductsFromCart(): ThunkResult<CART_ITEMS_UPDATED_TYPES >  {
    return function (dispatch, getState) {
        const cartItems: Array<{ ean13: string }> = getState().header?.cartItems || [];
        const data = {
            action: "removeProducts",
            items: cartItems.map(item => ({id: item.ean13}))
        };
        ApiCaller.put("/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=cart", data)
            .then(response => {
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cartObject: response.data, totalQuantity: response.data.totalQuantities}
                });
                OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items))
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function updateProductsWarranty(productId: string, code: string, gldAction: string): ThunkResult<CART_GLD_UPDATED_TYPES >  {
    return function (dispatch) {
        const data: {
            action: string;
            items: Array<{ id: string; service: { code: string } }>;
        } = {
            action: gldAction,
            items: [{ id: productId, service: { code } }]
        };

        ApiCaller.put("/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=cart", data)
            .then(response => {
                dispatch({
                    type: CART_GLD_UPDATED,
                    payload: {cartObject: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }

}
