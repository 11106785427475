import {
    COMMON_INIT_DATA
} from '../actionsTypes'
import {getRequiredCommonData} from "../../../../services/commonDataServices";
import {initAppDataKeyValues} from "../../../../animations/Default/commonPage";
import {
    CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE, CUSTOMER_API,
    CUSTOMER_PAGE, DEFAULT_TEMPLATE_VERSION, MOBILE_TEMPLATE_VERSION
} from "../../../../utils/constants";
import Utils from "../../../../utils/Default/Utils";
import ApiCaller from "../../../../services/ApiCaller/apiCallerDefault";
import {fetchCartData as defaultFetchCartData} from "@/redux/reducers/Default/header/headerActions.js";
import {MENUDATA_FETCHED} from "@/redux/reducers/responsive/actionsTypes";
import {fetchCartData} from "@/redux/reducers/Mobile/header/headerActions";

export const fetchRequiredCommonData = (templateVersion, context) => {

    return function (dispatch) {
        getRequiredCommonData(CUSTOMER_API)
            .then(response => {
                dispatch({
                    type: COMMON_INIT_DATA,
                    payload: {data: response.data}
                });
                initAppDataKeyValues([], [], response.data.trackingUrlFapi, CUSTOMER_PAGE, '', response.data.isDQENormalisationActive, response.data.shopCircleVersion, response.data.logoVersion, null, '', '', response.data.isWebloyaltyActive, templateVersion, false, response.data.isPlanner3dActive);

                templateVersion=== MOBILE_TEMPLATE_VERSION ? dispatch(fetchCartData()) : dispatch(defaultFetchCartData())
            })
            .catch((error) => {
                if (context !== CUSTOMER_ACCOUNT_ORDER_DETAILS_PAGE) {
                    Utils.redirectTo("/Customer/Account/Identification")
                }
                console.log(error);
            })
    }

}
export const getCustomMenu = (deviceType, userAgent,productListMenuIdNav,onlyFirstLevel, cUserID, ssr= true, requestUrl = "") => {
    let data = {};
    data.wordingVersion = deviceType && deviceType.toLowerCase() === MOBILE_TEMPLATE_VERSION.toLowerCase() ? MOBILE_TEMPLATE_VERSION : DEFAULT_TEMPLATE_VERSION;
    requestUrl !== "" ? data.RequestUrl = requestUrl : null
    cUserID = cUserID ?? null;

    return new Promise((resolve, reject) => {
        if (cUserID !== null) {
            ApiCaller.defaults.headers['CUserID'] = cUserID;
        }
        ApiCaller.defaults.headers['User-Agent'] = userAgent;
        let url = '/V1/CMS/Menu';
        console.log('INFO', 'Calling ' + url + ' Api ... With params: ', JSON.stringify(data))
        ApiCaller.get(url, {
            params: data
        })
            .then((response) => {
                console.log('INFO', url + ' Api responded with success')
                resolve(response.data);
            })
            .catch((error) => {
                console.error('ERROR', 'V1/CMS/Menu Api responded failed : '+ error)
                reject(error)
            });
    });
};

export const getDefaultMenu = (deviceType, userAgent) => {
    let data = {wordingVersion: deviceType && deviceType.toLowerCase() === MOBILE_TEMPLATE_VERSION.toLowerCase() ? MOBILE_TEMPLATE_VERSION : DEFAULT_TEMPLATE_VERSION};
    return function (dispatch) {
        ApiCaller.defaults.headers['User-Agent'] = userAgent;
        ApiCaller.get("V1/CMS/Menu", {params: data})
            .then(response => {
                dispatch({
                    type: MENUDATA_FETCHED,
                    payload: {menuItems: response.data.menuItems, isDefaultMenu: true}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}


