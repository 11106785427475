import Axios from 'axios'
import getConfig from 'next/config'
import * as Sentry from "@sentry/nextjs";

const {serverRuntimeConfig} = getConfig();
const ApiCallerSSR = Axios.create({
    baseURL: serverRuntimeConfig.backBaseUrl,
    timeout: serverRuntimeConfig.timeout,
});
ApiCallerSSR.interceptors.request.use(async (config) => {
    config.headers.common['Origin'] = serverRuntimeConfig.origin
    config.headers.common['Host'] = serverRuntimeConfig.origin
    config.headers.common['but-key'] = serverRuntimeConfig.butKey
    if (!!serverRuntimeConfig.referer)
        config.headers.common['Referer'] = serverRuntimeConfig.referer
    return config;
});
ApiCallerSSR.interceptors.response.use(async (config) => {
    return config;
}, function (error) {
    try {
        let headers = new Headers(error.response.headers);
        const httpRedirectCodes = [301, 302, 307]
        if (
            httpRedirectCodes.includes(error.response.status) &&
            headers.get("navigateTo")
        ) {
            ApiCallerSSR.defaults.serverRequestConfig.writeHead(error.response.status, {
                Location: headers.get("navigateTo"),
            });
            ApiCallerSSR.defaults.serverRequestConfig.end();
        } else if (error.response.status === 404) {
            ApiCallerSSR.defaults.serverRequestConfig.writeHead(302, {
                Location: "/Common/Page/NotFound",
            });
            ApiCallerSSR.defaults.serverRequestConfig.end();
        }
    } catch (error) {
        console.log("error", error)
    }
    const httpCodesNotCapture = [301, 302, 307];
    if (!httpCodesNotCapture.includes(error.response.status)) {
        Sentry.captureException(error);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export const setAxiosServerConfig = (res) => {
    ApiCallerSSR.defaults.serverRequestConfig = res;
}

export default ApiCallerSSR



