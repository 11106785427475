/* ####################### PLANNER ########################### */
export const LOAD_KITCHEN_PROJECT_LIST = 'LOAD_KITCHEN_PROJECT_LIST';
export const UPDATE_KITCHEN_PROJECT_TITLE = 'UPDATE_KITCHEN_PROJECT_TITLE';
export const KITCHEN_PROJECT_ERROR_MESSAGE = 'KITCHEN_PROJECT_ERROR_MESSAGE';

/* ####################### Customer ########################### */
export const LOAD_CUSTOMER_INFO = 'LOAD_CUSTOMER_INFO';
export const LOAD_CUSTOMER_ADDRESS = 'LOAD_CUSTOMER_ADDRESS';
export const UPDATE_CUSTOMER_CONFIRMED = 'UPDATE_CUSTOMER_CONFIRMED';
export const LOAD_CUSTOMER_NEWSLETTERS = 'LOAD_CUSTOMER_NEWSLETTERS';
export const LOAD_CUSTOMER_NEWSLETTERS_MESSAGE = 'LOAD_CUSTOMER_NEWSLETTERS_MESSAGE';
export const RESET_CUSTOMER_NEWSLETTERS_MESSAGE = 'RESET_CUSTOMER_NEWSLETTERS_MESSAGE';
export const UPDATE_CUSTOMER_INFOS = 'UPDATE_CUSTOMER_INFOS';
export const UPDATE_CUSTOMER_BILLING_ADDRESS = 'UPDATE_CUSTOMER_BILLING_ADDRESS';
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS = 'UPDATE_CUSTOMER_SHIPPING_ADDRESS';
export const UPDATE_CUSTOMER_SHIPPING_ADDRESS_INFOS = 'UPDATE_CUSTOMER_SHIPPING_ADDRESS_INFOS';
export const UPDATE_CUSTOMER_BILLING_ADDRESS_INFOS = 'UPDATE_CUSTOMER_BILLING_ADDRESS_INFOS';
export const UPDATE_ADDRESS_LATITUDE_LONGITUDE = 'UPDATE_ADDRESS_LATITUDE_LONGITUDE';
export const NEW_PASSWORD_CONFIRMED = 'NEW_PASSWORD_CONFIRMED';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR';
export const DESABLE_DQE_AUTOCOMPLETE = 'DESABLE_DQE_AUTOCOMPLETE';
export const ADDRESS_CHECKED = 'ADDRESS_CHECKED';
export const DISABEL_RNVP_BLOCK = 'DISABEL_RNVP_BLOCK';

/* ####################### SOCIAL MEDIA ########################### */
export const LOAD_SOCIAL_MEDIA_INFOS = 'LOAD_SOCIAL_MEDIA_INFOS';

/* ####################### SHOP ################################# */
export const FETCH_SHOP_DATA = 'FETCH_SHOP_DATA';
export const SHOP_EVENT_LIST_DATA = 'SHOP_EVENT_LIST_DATA';
export const HANDLE_SHOP_OVERLAY = 'HANDLE_SHOP_OVERLAY';
export const PRIMARY_SHOP_DATA_FETCHED= 'PRIMARY_SHOP_DATA_FETCHED';
export const SHOP_DATA_FETCHED = 'SHOP_DATA_FETCHED';

/* ####################### ORDER ################################# */
export const FETCH_ORDER_LIST_DATA = 'FETCH_ORDER_LIST_DATA';
export const FETCH_ORDER_DATA = 'FETCH_ORDER_DATA';
export const CONFIRM_RECEPTION_ORDER = 'CONFIRM_RECEPTION_ORDER';
export const FETCH_ORDER_SHOP_DATA = 'FETCH_ORDER_SHOP_DATA';
export const MESSAGES_HISTORY = 'MESSAGES_HISTORY';
export const CLOSE_INCIDENT_REASONS_LISTED = 'CLOSE_INCIDENT_REASONS_LISTED';
export const REASONS_LISTED = 'REASONS_LISTED';
export const INCIDENT_OPENED = 'INCIDENT_OPENED';
export const INCIDENT_CLOSED = 'INCIDENT_CLOSED';
export const UPDATE_REASON = 'UPDATE_REASON';
export const UPDATE_MESSAGE_CONTENT = 'UPDATE_MESSAGE_CONTENT';
export const UPDATE_SENDING_MESSAGE = 'UPDATE_SENDING_MESSAGE';
export const UPDATE_IS_SENDING_MESSAGE_TO_OPERATOR = 'UPDATE_IS_SENDING_MESSAGE_TO_OPERATOR';
export const UPDATE_MESSAGE_DOCUMENTS = 'UPDATE_MESSAGE_DOCUMENTS';
export const UPDATE_MESSAGE_WORKFLOW_TYPE = 'UPDATE_MESSAGE_WORKFLOW_TYPE';
export const UPDATE_IS_SENDING_INVOICE_REQUEST = 'UPDATE_IS_SENDING_INVOICE_REQUEST';
export const INVOICE_REQUEST_SENT = 'INVOICE_REQUEST_SENT';
export const MESSAGE_SENT_WITH_FILE = 'MESSAGE_SENT_WITH_FILE';
export const MESSAGE_SENT = 'MESSAGE_SENT';
export const MESSAGE_FAILED = 'MESSAGE_FAILED';
export const CHANGE_SELECTED_REASON = 'CHANGE_SELECTED_REASON';
export const RESET_MESSAGE_ERROR = 'RESET_MESSAGE_ERROR';
export const RESET_MESSAGE_SENT_WITH_FILE = 'RESET_MESSAGE_SENT_WITH_FILE';
export const MESSAGE_SENT_INCIDENT = 'MESSAGE_SENT_INCIDENT';
export const SHOW_LOADER = 'SHOW_LOADER';
export const OPERATOR_MESSAGE = 'OPERATOR_MESSAGE';
export const RESET_ORDER_DETAILS = 'RESET_ORDER_DETAILS';
export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';

/* ####################### ORDER TRACKING################################# */
export const FETCH_ORDER_TRACKING_INFOS = 'FETCH_ORDER_TRACKING_INFOS';
export const FETCH_ORDER_MKP_TRACKING_INFOS = 'FETCH_ORDER_MKP_TRACKING_INFOS';
export const FETCH_ONE_ORDER_INFOS_TRACKING = 'FETCH_ONE_ORDER_INFOS_TRACKING';
export const FETCH_ONE_ORDER_INFOS_TRACKING_MIXED_ORDER = 'FETCH_ONE_ORDER_INFOS_TRACKING_MIXED_ORDER';
export const ORDER_DETAILS_PAGE = 'ORDER_DETAILS_PAGE';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const COMMON_INIT_DATA = 'COMMON_INIT_DATA';
export const UPDATE_BREADCRUMB_DATA = 'UPDATE_BREADCRUMB_DATA';
export const UPDATE_CURRENT_PAGE_DATA = 'UPDATE_CURRENT_PAGE_DATA';

/* ####################### ORDER APPOINTMENTS################################# */
export const CONFIRMATION_APPOINTMENTS = 'CONFIRMATION_APPOINTMENTS';
export const UPDATE_ERROR_ORDER_TRACKING_LIST = 'UPDATE_ERROR_ORDER_TRACKING_LIST';

/* ####################### ProductSheet ########################### */
export const OFFERS_DATA_FETCHED = 'OFFERS_DATA_FETCHED';
export const AVAILABILITY_DATA_FETCHED = 'AVAILABILITY_DATA_FETCHED';
export const REVIEWS_DATA_FETCHED = 'REVIEWS_DATA_FETCHED'
export const QUESTIONS_DATA_FETCHED = 'QUESTIONS_DATA_FETCHED';
export const QUESTIONS_DATA_SEND = 'QUESTIONS_DATA_SEND';
export const HANDLE_ASK_QUESTION_LOADER = 'HANDLE_ASK_QUESTION_LOADER';
export const MENUDATA_FETCHED = 'MENUDATA_FETCHED';
export const UPDATE_SELECTED_ZIP_CITY_FOR_STOCK_LOC = 'UPDATE_SELECTED_ZIP_CITY_FOR_STOCK_LOC';
export const BUNDLE_OFFERS_DATA_FETCH = 'BUNDLE_OFFERS_DATA_FETCH';
export const BUNDLE_OFFERS_PENDING_STATUS_FETCH = 'BUNDLE_OFFERS_PENDING_STATUS_FETCH';

/* ####################### Cart ########################### */
export const CART_DATA_UPDATE = 'CART_DATA_UPDATE';
export const CART_DATA_UPDATE_ERROR = 'CART_DATA_UPDATE_ERROR';
export const LAST_ADDED_ITEM = 'LAST_ADDED_ITEM'
/* ####################### Availbility ########################### */
export const STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED = 'STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED';
export const BUNDLE_AVAILABILITY_DATA_FETCHED = 'BUNDLE_AVAILABILITY_DATA_FETCHED';
export const UPDATE_ERROR_STATUS = 'UPDATE_ERROR_STATUS';
export const UPDATE_AVAILABILITY_ERROR_STATUS = 'UPDATE_AVAILABILITY_ERROR_STATUS';
export const UPDATE_AVAILABILITYf_ERROR_STATUS = 'UPDATE_AVAILABILITY_ERROR_STATUS';
export const SHOP_CIRCLE_FETCHED = 'SHOP_CIRCLE_FETCHED';
export const UPDATE_LAYER_PENDING_STATUS = 'UPDATE_LAYER_PENDING_STATUS';
export const UPDATE_AVAILABILITY_PENDING_STATUS = 'UPDATE_AVAILABILITY_PENDING_STATUS';
export const UPDATE_CITY_LIST_DATA = 'UPDATE_CITY_LIST_DATA';
export const SHIPPING_AVAILABILITY_DATA_FETCHED = 'SHIPPING_AVAILABILITY_DATA_FETCHED';

/* ###################### Tracking ######################## */
export const TAGGING_FETCHED = 'TAGGING_FETCHED';

/* ####################### Shipping ########################### */
export const SHIPPING_CART_ITEMS_UPDATED = 'SHIPPING_CART_ITEMS_UPDATED';
/* ####################### SCAN################################# */
export const SCAN_ACTIVATION_ERROR_HANDLER = 'SCAN_ACTIVATION_ERROR_HANDLER'
export const INIT_CART_DATA_VIEW_SHIPPING = 'INIT_CART_DATA_VIEW_SHIPPING'
