import { useEffect, useRef, useState } from "react";

export const useUpdateEffect = (callback, dependencies) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            callback();
        }

    }, dependencies);
}

export const useLifeCycleEffect = (callBackMount, callbackUpdate) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            callBackMount()
        } else {
            callbackUpdate();
        }
    });
}

export const useLifeCycleEffectWithDependencies = (callBackMount, callbackUpdate, dependencies) => {
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            callBackMount()
        } else {
            callbackUpdate();
        }

    }, dependencies);
}

export const useBodyOverflow = (hideOverflow) => {
    useEffect(() => {
        // Function to toggle body overflow
        const toggleBodyOverflow = () => {
            document.body.style.overflow = hideOverflow ? 'hidden' : 'auto';
        };

        // Call the function to toggle overflow when the component mounts
        toggleBodyOverflow();

        // Cleanup function to reset overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [hideOverflow]);
}

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return scrollPosition;
}

export const useWindowSize = () => {
    const [windowWidth, setWindowWidth] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowWidth;
}