import { Reducer } from "redux";
import { ProductAvailabilityState } from "@/models/appState/ProductAvailabilityState";
import { BundleSaleability } from "@/models/scope/productSheet/BundleSaleability";
import {
    AVAILABILITY_DATA_FETCHED,
    BUNDLE_AVAILABILITY_DATA_FETCHED,
    SHIPPING_AVAILABILITY_DATA_FETCHED,
    SHOP_CIRCLE_FETCHED,
    STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED,
    UPDATE_AVAILABILITY_PENDING_STATUS,
    UPDATE_CITY_LIST_DATA,
    UPDATE_ERROR_STATUS,
    UPDATE_LAYER_PENDING_STATUS,
    UPDATE_SELECTED_ZIP_CITY_FOR_STOCK_LOC,
} from "@/redux/reducers/responsive/actionsTypes";
import { ProductAvailabilityActionTypes } from "@/redux/reducers/responsive/product/productAvailabilityActionTypes";
import { STOCK_LOCATOR_ERROR_TYPE } from "@/utils/constants";

const initialState: ProductAvailabilityState = {
    availabilityByShops: [],
    detailedAvailablity: {},
    genericAvailability: {},
    stockLocatorAvailabilityByShops: undefined,
    shippingAvailabilityByShop: undefined,
    cityList: [],
    errorStatus: { type: STOCK_LOCATOR_ERROR_TYPE.NONE, status: false },
    shopCircle: [],
    layerPendingStatus: false,
    availabilityPendingStatus: true,
    selectedZipCodeCityInStockLoc: undefined,
    bundleSaleability: [],
};

const productAvailabilityReducer: Reducer<ProductAvailabilityState, ProductAvailabilityActionTypes> = (state = initialState, action): ProductAvailabilityState => {
    switch (action.type) {
        case AVAILABILITY_DATA_FETCHED:
            return {
                ...state,
                availabilityByShops: action.payload.availabilityByShops,
                detailedAvailablity: action.payload.detailedAvailablity,
                genericAvailability: action.payload.genericAvailability,
                availabilityPendingStatus: false,
            };
        case STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED:
            return {
                ...state,
                stockLocatorAvailabilityByShops: action.payload.stockLocatorAvailabilityByShops,
            };
        case SHIPPING_AVAILABILITY_DATA_FETCHED:
            return {
                ...state,
                shippingAvailabilityByShop: action.payload.shippingAvailabilityByShop,
            };
        case BUNDLE_AVAILABILITY_DATA_FETCHED:
            const { productId, saleability, pendingStatus } = action.payload;
            let newBundleSaleability = [...state.bundleSaleability];
            const productSaleabilitIndex = newBundleSaleability.findIndex(item => item.hasOwnProperty(productId));
            const newEntry: BundleSaleability = { [productId]: { saleability, pendingStatus } };
            if (productSaleabilitIndex >= 0) {
                newBundleSaleability[productSaleabilitIndex] = newEntry;
            } else if (!!saleability) {
                newBundleSaleability = [
                    ...state.bundleSaleability,
                    newEntry,
                ];
            }
            return {
                ...state,
                bundleSaleability: newBundleSaleability,
            };
        case UPDATE_ERROR_STATUS:
            return {
                ...state,
                errorStatus: action.payload,
            };
        case SHOP_CIRCLE_FETCHED:
            return {
                ...state,
                shopCircle: action.payload.shopCircle
            };
        case UPDATE_LAYER_PENDING_STATUS:
            return {
                ...state,
                layerPendingStatus: action.payload.pendingStatus
            };
        case UPDATE_AVAILABILITY_PENDING_STATUS:
            return {
                ...state,
                availabilityPendingStatus: action.payload.pendingStatus
            };
        case UPDATE_CITY_LIST_DATA:
            return {
                ...state,
                cityList: action.payload.cityList
            };
        case UPDATE_SELECTED_ZIP_CITY_FOR_STOCK_LOC:
            return {
                ...state,
                selectedZipCodeCityInStockLoc: action.payload.selectedZipCodeCity
            };
        default:
            return state;
    }
};

export default productAvailabilityReducer;