import ApiCaller from './ApiCaller/apiCaller'

export const getDestockedProducts = (shopId) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Shops/' + shopId + '/DestockedProducts')
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', '/Shops/' + shopId + '/DestockedProducts Api responded failed : ' + error)
                reject(error)
            });
    });
};

export const getProductReviewsData = (destockedProductID, pageSize) => {
    let url = '/Catalog/Products/' + destockedProductID + '/Reviews.json';
    if (pageSize) {
        url = url + '?PageSize=' + pageSize;
    }
    return new Promise((resolve, reject) => {
        ApiCaller.get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', url + " Api responded failed : " + error)
                reject(error)
            });
    });
};

export const getCaracProductData = (destockedProductID) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get('/Catalog/Products/' + destockedProductID + '?View=Full')
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', '/Catalog/Products/' + destockedProductID + '?View=Full Api responded failed : ' + error)
                reject(error)
            });
    });
};
