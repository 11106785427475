import {TYPES} from "./socialMediaActions";
import {LOAD_SOCIAL_MEDIA_INFOS} from "../actionsTypes";

const initialState = {
    userInfo: {},
    reachFiveId: "",
    providersNumbers: 0,
    socialMediaInfos: {}
};


const SocialMediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case  LOAD_SOCIAL_MEDIA_INFOS :
            return {
                ...state,
                socialMediaInfos: action.payload.socialMedia
            }
        case TYPES.LOGIN_ASSOCIATION: {
            const userProviders = action.payload.data.providers;
            return {
                ...state,
                userInfo: action.payload.data,
                reachFiveId: action.payload.data.id,
                providersNumbers: userProviders.length
            }
        }
        case TYPES.PROVIDE_NUMBER_CHANGED: {
            return {
                ...state,
                providersNumbers: state.providersNumbers - 1,
                userInfo: {
                    ...state.userInfo,
                    providers: state.userInfo.providers.filter(provider => provider != action.payload.provider)
                }
            }
        }
        default:
            return state;
    }

};

export default SocialMediaReducer;