import React from "react";
import Script from "next/script";
import { getBuildId } from "@/utils/communUtil";
import HeadDetails from "@/components/Mobile/Layout/Head";
import Scripts from "@/components/Mobile/Layout/Head/scripts";
import { getTitle } from "@/components/responsive/commun/SPAHead/PrefetchDataPages";

const InStoreHeadAndScripts = (props) => {
    return (
        <>
            <HeadDetails title={getTitle(props.pathName)}>
                <link rel="stylesheet" href={"/assets/css/responsive/main.css?v=" + getBuildId()} />
                <link rel="stylesheet" href={"/assets/css/responsive/style.css?v=" + getBuildId()} />
                <link rel="stylesheet" href={"/assets/css/mobile/headerFooter.css?v=" + getBuildId()} />
                <link rel="stylesheet" href={"/assets/css/responsive/scan.css?v=" + getBuildId()} />
            </HeadDetails>
            <Scripts>
                <Script src={"/assets/js/Mobile/jquery.validate.js?v=" + getBuildId()} strategy="beforeInteractive" />
                <Script
                    type="text/tc_privacy"
                    data-category="3"
                    src={"/assets/js/octipasMerchLibraryConfig.js?v=" + getBuildId()}
                />
            </Scripts>
        </>
    );
};

export default InStoreHeadAndScripts;
