import React, {memo} from 'react';

const ShopLink = memo(({shopInfos}) => {

    if (shopInfos && shopInfos.shortName) {
        return (
            <a className="store gtm-header rattache" data-gtm-libelle="magasins" id="store" href={shopInfos.url} title="Mon magasin" data-popin="store" >BUT {shopInfos.shortName}</a>
        );
    }else {
        return (
            <a data-gtm-libelle="magasins" id="store" href="/magasins/recherche-magasins" title="Mon magasin"
               className="store gtm-header">Choisir mon magasin</a>
        );

    }
})

export default ShopLink;