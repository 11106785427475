import React, {memo} from "react";
import Script from "next/script";
import {getBuildId} from "@/utils/communUtil";
import {connect} from "react-redux";


const Scripts = memo((props) => {

    const {jqueryUiIsLoaded, isZipCodeInputClicked, isLocateMeClicked} = props;
    return (
        <>
            <Script src={"/assets/js/actionTrigger.min.js?v="+getBuildId()} strategy="beforeInteractive"/>
            <Script src={"/assets/js/runDeferTag.min.js?v="+getBuildId()} strategy="beforeInteractive"/>
            <Script src={"/assets/js/jquery.min.js?v="+getBuildId()} strategy="beforeInteractive"/>
            <Script src="/assets/js/bootstrap.min.js" strategy="beforeInteractive"/>
            <Script src="/assets/js/imageMapResizer.min.js" strategy="beforeInteractive"/>
            <Script src="/assets/js/slick.min.js" strategy="beforeInteractive"/>
            {jqueryUiIsLoaded &&
                <Script src={"/assets/js/jquery-ui-light.min.js?v=" + getBuildId()}/>}
            {isZipCodeInputClicked && !isLocateMeClicked &&
                <Script src={"/assets/js/Mobile/jquery.dqe.min.js?v=" + getBuildId()}/>}
            {props.children}
        </>
    )


})
const mapStateToProps = (state) => {
    return {
        jqueryUiIsLoaded: state.header.jqueryUiIsLoaded,
        isZipCodeInputClicked: state.header.isZipCodeInputClicked,
        isLocateMeClicked: state.header.isLocateMeClicked,
    };
};
export default connect(mapStateToProps, null)(Scripts);