import {
    LOAD_CUSTOMER_INFO,
    LOAD_CUSTOMER_NEWSLETTERS,
    UPDATE_CUSTOMER_INFOS,
    NEW_PASSWORD_CONFIRMED,
    UPDATE_CUSTOMER_CONFIRMED,
    NEW_PASSWORD_ERROR,
    ADDRESS_CHECKED,
    UPDATE_CUSTOMER_BILLING_ADDRESS,
    UPDATE_CUSTOMER_SHIPPING_ADDRESS,
    DISABEL_RNVP_BLOCK,
    UPDATE_CUSTOMER_SHIPPING_ADDRESS_INFOS,
    UPDATE_CUSTOMER_BILLING_ADDRESS_INFOS,
    LOAD_CUSTOMER_NEWSLETTERS_MESSAGE,
    RESET_CUSTOMER_NEWSLETTERS_MESSAGE
} from "../actionsTypes"

const initialState = {
    customerObject: [],
    customerAddressObject: [],
    customerOptions: [],
    isConfirmed: false,
    waitingMessage: null,
    isError: false,
    normalisationDataObject: null,
    normalisationDataObjectHash: null,
    callbackFunctions: null,
    redirectToIfRNVPNotValidate: null,
    rndvBillingAddressData: null,
    rndvShippingAddressData: null,
    showRNVPBlock: false,
    validateFormAndSubmitFunction: null,
    errorResponse: 0,
    shouldDQEComponentUpdate: true
}


const CustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case  LOAD_CUSTOMER_INFO :
            const customerObjectData = {
                ...action.payload.customer.customerData.customer,
                billingAddress: action.payload.customer.customerData.billingAddress,
                shippingAddress: action.payload.customer.customerData.shippingAddress,
                billingAddressIsShippingAddress: action.payload.customer.customerData.billingAddressIsShippingAddress,
            }
            return {
                ...state,
                customerObject: customerObjectData
            }
        case  UPDATE_CUSTOMER_BILLING_ADDRESS :
            return {
                ...state,
                customerObject: {...state.customerObject, billingAddress: action.payload.address}
            }
        case  UPDATE_CUSTOMER_INFOS :
            return {
                ...state,
                customerObject: action.payload.customer
            }
        case  UPDATE_CUSTOMER_SHIPPING_ADDRESS :
            return {
                ...state,
                customerObject: {...state.customerObject, shippingAddress: action.payload.address}
            }
        case  UPDATE_CUSTOMER_SHIPPING_ADDRESS_INFOS :
            return {
                ...state,
                customerObject: {
                    ...state.customerObject, shippingAddress: {
                        ...state.customerObject.shippingAddress,
                        zipCode: action.payload.zipCode,
                        city: action.payload.city,
                        streetAddress: action.payload.streetAddress,
                        normalizationStatus: action.payload.normalizationStatus,
                        inseeCode: action.payload.inseeCode
                    }
                }
            }
        case  UPDATE_CUSTOMER_BILLING_ADDRESS_INFOS :
            return {
                ...state, customerObject: {
                    ...state.customerObject, billingAddress: {
                        ...state.customerObject.billingAddress,
                        zipCode: action.payload.zipCode,
                        city: action.payload.city,
                        streetAddress: action.payload.streetAddress,
                        normalizationStatus: action.payload.normalizationStatus,
                        inseeCode: action.payload.inseeCode
                    }
                }
            }
        case  LOAD_CUSTOMER_NEWSLETTERS :
            return {
                ...state,
                customerOptions: action.payload.options,
                waitingMessage: null
            }
        case  LOAD_CUSTOMER_NEWSLETTERS_MESSAGE :
            return {
                ...state,
                waitingMessage: action.payload.message,
                customerOptions: {}
            }
        case  RESET_CUSTOMER_NEWSLETTERS_MESSAGE :
            return {
                ...state,
                waitingMessage: action.payload.message,
                customerOptions: {}
            }
        case  NEW_PASSWORD_CONFIRMED :
            return {
                ...state,
                isConfirmed: action.payload.isConfirmed
            }
        case  UPDATE_CUSTOMER_CONFIRMED :
            return {
                ...state,
                isConfirmed: action.payload.isConfirmed
            }
        case  NEW_PASSWORD_ERROR :
            return {
                ...state,
                isError: action.payload.isError
            }
        case  DISABEL_RNVP_BLOCK :
            return {
                ...state,
                showRNVPBlock: action.payload.showRNVPBlock,
            }
        case ADDRESS_CHECKED:
            let newState = {
                ...state,
                showRNVPBlock: action.payload.showRNVPBlock,
                validateFormAndSubmitFunction: action.payload.validateFormAndSubmitFunction,
                redirectToIfRNVPNotValidate: (action.payload.redirectToIfRNVPNotValidate) ?
                    action.payload.redirectToIfRNVPNotValidate : null
            }
            if (action.payload.type) {
                newState.type = action.payload.type
            }
            newState.rndvBillingAddressData = action.payload.billingAddressData ? action.payload.billingAddressData : []
            newState.rndvShippingAddressData = action.payload.shippingAddressData ? action.payload.shippingAddressData : []
            return newState
        default:
            return state;
    }

};

export default CustomerReducer
