import { Button } from "@/components/responsive/commun/button";
import ScanIcon from '@/components/responsive/pictograms/ScanIcon';
import { useRouter } from 'next/router'
import {useDispatch} from "react-redux";
import {isEligibleToScan, redirectTo} from "@/utils/Mobile/utils";
import {fetchGeoloc} from "@/redux/reducers/Mobile/scan/scanActions";
import {SCAN_ACTIVATION_ERROR_HANDLER} from "@/redux/reducers/responsive/actionsTypes";

export default function ScannerButton({ isIconOnly }) {
    const router = useRouter()
    const dispatch = useDispatch()
    const redirectToScanScreen = () => {
        router.push('/inStore/scan/screen', '/InStore/Scan/Screen')
    }

    const handleScanButtonClick = () => {
        if(isIconOnly) {
            if (window.location.pathname === '/') {
                appData.actionTrigger.dispatch({
                    trigger: "clicScanPicto",
                });
            }
        } else {
            appData.actionTrigger.dispatch({
                trigger: "clickScanProducts",
            });
        }
        if (isIconOnly && !isEligibleToScan()) {
            if (router.pathname !== '/inStore/scan/activate') {
                redirectTo('/InStore/Scan/Activate')
            }
            return
        }
        const currentUrl = window.location.href;
        localStorage.setItem('previousPageUrl', currentUrl);
        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition(function (position) {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                appData.actionTrigger.dispatch({
                    trigger: "geolocSuccess",
                    resolveCallback: function () {
                        dispatch(fetchGeoloc('ByLatLong', lat + "," + lng, redirectToScanScreen, isIconOnly, isEligibleToScan()));
                    }
                });
            }, function (error) {
                appData.actionTrigger.dispatch({
                    trigger: "geolocFailed",
                });
                dispatch({
                    type: SCAN_ACTIVATION_ERROR_HANDLER,
                    payload: {
                        errors: {
                            isErrorGeolocRefused: true,
                            isErrorGeolocHS: false,
                            isErrorCamRefused: false,
                            isErrorOutOfShop: false,
                            isErrorShopNotEligible: false
                        }
                    }
                })
            });
        } else {
            dispatch({
                type: SCAN_ACTIVATION_ERROR_HANDLER,
                payload: {
                    errors: {
                        isErrorGeolocHS: true,
                        isErrorCamRefused: false,
                        isErrorOutOfShop: false,
                        isErrorShopNotEligible: false,
                        isErrorGeolocRefused: false
                    }
                }
            })
        }
    };

    const buttonProps = {
        isLink: true,
        enabled: true,
        onClick: handleScanButtonClick,
    };

    if (!isIconOnly) {
        buttonProps.type = "tertiary";
        buttonProps.size = "medium";
    }
    return (
        <div className="scandit-button">
            <Button {...buttonProps}>
                {isIconOnly ? <ScanIcon /> : <> <ScanIcon /> SCANNER MES PRODUITS </>}
            </Button>
        </div>
    );
}
