import ApiCaller from "@/services/ApiCaller/apiCaller";
import Utils from '@/libs/Utils';
import OctipasMerch from "@/utils/responsive/OctipasMerch";
import {getBasketData, getUserIdFromCookie} from "@/utils/Mobile/utils";
import {CART_DATA_UPDATE, HANDLE_EMAIL_CHANGE, CART_ITEMS_UPDATED, CART_GLD_UPDATED, UPDATE_CHANGE_QUANTITY_STATE} from "@/redux/reducers/Mobile/actionsTypes";
import {
    cartHideLoader,
    cartShowLoader, checkOlapicBloc,
    closeModal,
    hideCartLoader,
    hideLoader,
    showCartLoader,
    showLoader, showModal
} from "@/animations/responsive/cart/cartPage";


export function addToCartProductSuccessTracking(trackingData,quantity) {
    appData.actionTrigger.dispatch({
        trigger: "addToCartProductSheet",
        payload: {
            eventLabel: trackingData.eventLabel,
            productVendorType: trackingData.productVendorType,
            productVendorName: trackingData.productVendorName,
            productVendorId: trackingData.productVendorId,
            productOffersNb: trackingData.productOfferNb,
            price: trackingData.price,
            quantity: quantity
        }
    });
}

export function fetchCartSummary(context = null) {
    return function (dispatch, getState) {
        let id = getUserIdFromCookie();
        ApiCaller.post('/WebUsers/' + id + '/Cart?View=cart', {context: "InStore"})
            .then(response => {
                let successMessages = (getState().cart?.cartObject.messages && getState().cart?.cartObject.messages.success) ? getState().cart?.cartObject.messages.success : []
                let warningMessages = (getState().cart?.cartObject.messages && getState().cart?.cartObject.messages.warning) ? getState().cart?.cartObject.messages.warning : []
                let errorMessages = (getState().cart?.cartObject.messages && getState().cart?.cartObject.messages.error) ? getState().cart?.cartObject.messages.error : []
                let consolidationMessages = getState().cart?.cartObject.consolidationMessages ? getState().cart?.cartObject.consolidationMessages : []
                let cartObject = response.data
                if (response.data.messages && response.data.messages.success && response.data.messages.success.length > 0) {
                    response.data.messages.success.map((message) => {
                            if (successMessages.indexOf(message) < 0) {
                                successMessages.push(message)
                            }
                        }
                    )
                }
                if (response.data.messages && response.data.messages.warning && response.data.messages.warning.length > 0) {
                    response.data.messages.warning.map((message) => {
                            if (warningMessages.indexOf(message) < 0) {
                                warningMessages.push(message)
                            }
                        }
                    )
                }
                if (response.data.messages && response.data.messages.error && response.data.messages.error.length > 0) {
                    response.data.messages.error.map((message) => {
                            if (errorMessages.indexOf(message) < 0) {
                                errorMessages.push(message)
                            }
                        }
                    )
                }
                if (response.data.consolidationMessages && response.data.consolidationMessages.length > 0) {
                    response.data.consolidationMessages.map((message) => {
                            if (consolidationMessages.filter(msg => (msg.code == message.code && msg.type == message.type && msg.message == message.message)).length < 1) {
                                consolidationMessages.push(message)
                            }
                        }
                    )
                }
                if (context == 'cart') {
                    //OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items))
                }
                cartObject.messages = {'success': successMessages, 'warning': warningMessages, 'error': errorMessages}
                cartObject.consolidationMessages = consolidationMessages
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cartObject: cartObject, totalQuantity: response.data.totalQuantities}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
export function addToCartProductFailledTracking() {
    appData.actionTrigger.dispatch({
        trigger: "addToCartFailed"
    });
}
 export function  updateQuantitySuccessTracking(trackingData){
     appData.actionTrigger.dispatch({
         trigger: "addToCartSummary",
         payload: {
             soldBy: trackingData.soldBy,
             sellerName: trackingData.sellerName,
             sellerId: trackingData.sellerId
         }
     });
}

export function addCart(params) {
    const {productId, quantity, offerId, trackingData, sellerName} = params
    cartShowLoader()
    let url = "/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=compact";
    let addedFrom = (appData.comingFrom == 't2s') ? 't2s' : 'nav'
    let hasOlapicContent = checkOlapicBloc();
    let data = {};
    data.action = "addProducts"
    data.items = offerId ? [{id: productId, qty: quantity, addedFrom: addedFrom, offerId: offerId, hasOlapicContent: hasOlapicContent }] : [{
        id: productId,
        qty: quantity,
        addedFrom: addedFrom,
        hasOlapicContent: hasOlapicContent
    }];
    return function (dispatch) {
        ApiCaller.put(url, data)
            .then(response => {
                cartHideLoader();
                if (response.data.consolidationMessages && response.data.consolidationMessages[0] && response.data.consolidationMessages[0].code === "prodcut_added_to_cart") {
                    showModal('list-panier__row_model_add');
                    addToCartProductSuccessTracking(trackingData, quantity)
                    OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items))
                } else {
                    addToCartProductFailledTracking()
                    showModal('stock-reservation-error');
                }
                dispatch({
                    type: CART_DATA_UPDATE,
                    payload: {
                        totalQuantity: response.data.totalQuantities,
                        shopId: response.data.shopId,
                        consolidationMessages: response.data.consolidationMessages,
                        productSellerName: sellerName
                    }
                });
            })
            .catch((error) => {
                addToCartProductFailledTracking()
                //TODO SES thow exception
                console.error(error);
            })
    }

}


export function handleEmailChange(email) {
    return function (dispatch) {
        dispatch({
            type: HANDLE_EMAIL_CHANGE,
            payload: {email: email}
        })


    }

}

export function updateProductQuantityInCart(productId, quantity) {
    showLoader(productId);
    return function (dispatch) {
        const data = {}
        data.action = "updateProductsQuantity"
        data.items = [{id: productId, qty: quantity}];
        ApiCaller.put("/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=cart", data)
            .then(response => {
                let trackingData = null;
                hideLoader(productId);
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cartObject: response.data, totalQuantity: response.data.totalQuantities}
                });
                dispatch({
                    type: UPDATE_CHANGE_QUANTITY_STATE,
                    payload: {itemId: productId, stateValue: 'success'}
                });
                let resultData=response.data;
                if(resultData.consolidationMessages[0] && resultData.consolidationMessages[0].code==="cart_stock_reservation" && resultData.consolidationMessages[0].type===1 ){
                    let item = resultData.items.filter(prod => prod.productId === productId);
                    trackingData = {
                        sellerName: item[0].sellerName,
                        sellerId: item[0].sellerId
                    };
                }
                if (trackingData !== null ) {
                    updateQuantitySuccessTracking(trackingData)
                }
                OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items))
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}


export function deleteProductFromCart(itemId) {
    showCartLoader();
    return function (dispatch) {

        const data = {}
        data.action = "removeProducts"
        data.items = [{id: itemId}];
        ApiCaller.put("/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=cart", data)
            .then(response => {
                //close delete product modal
                closeModal('list-panier__row_model_delete-' + itemId)
                hideCartLoader();
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cartObject: response.data, totalQuantity: response.data.totalQuantities}
                });
                OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items))
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function deleteProductsFromCart() {
    return function (dispatch, getState) {
        let cartItems = getState().header?.cartItems
        const data = {
            action: "removeProducts",
            items: cartItems.map(item => ({id: item.ean13}))
        };
        ApiCaller.put("/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=cart", data)
            .then(response => {
                dispatch({
                    type: CART_ITEMS_UPDATED,
                    payload: {cartObject: response.data, totalQuantity: response.data.totalQuantities}
                });
                OctipasMerch.createOrReplaceBasket(getBasketData(response.data.items))
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}

export function updateProductsWarranty(productId, code, gldAction) {
    return function (dispatch) {
        const data = {}
        data.action = gldAction
        data.items = [{id: productId, service: {code: code}}];
        ApiCaller.put("/WebUsers/" + Utils.getUserIdFromCookie() + "/Cart?View=cart", data)
            .then(response => {
                dispatch({
                    type: CART_GLD_UPDATED,
                    payload: {cartObject: response.data}
                });
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }

}

export const updateCustomerButCard = () => {
    return function (dispatch) {
        ApiCaller.patch("/Customers/" + appData.webUserId + "/Infos")
            .then(response => {
            })
            .catch((error) => {
                //TODO SES thow exception
                console.log(error);
            })
    }
}
