import React, {memo} from 'react';

const SearchBar = memo((props) => {

    return(
        <form data-gtm-libelle="recherche" className="search gtm-header" onSubmit={props.onSubmit} action="/Common/Search/SearchProductsList" method="get">
            <input onClick={props.onClick} name="KeyWords" data-gtm-libelle="recherche" autoComplete="off"  placeholder="Rechercher un produit, un magasin..." className="field-search-popup-input field-input" id="autocomplete" type="search"
                   onChange={props.onChange}/>
            <button aria-label="Chercher" className="btn"><svg xmlns="http://www.w3.org/2000/svg" width="27.154" height="27.154" viewBox="0 0 27.154 27.154"><g transform="translate(1.273 13.057) rotate(-45)"><path d="M8.333-.9A9.233,9.233,0,1,1-.9,8.333,9.243,9.243,0,0,1,8.333-.9Zm0,16.666A7.433,7.433,0,1,0,.9,8.333,7.441,7.441,0,0,0,8.333,15.766Z" transform="translate(0 0)" fill="#fff"/><path d="M0,10.7a.9.9,0,0,1-.9-.9V0A.9.9,0,0,1,0-.9.9.9,0,0,1,.9,0V9.8A.9.9,0,0,1,0,10.7Z" transform="translate(8.333 16.666)" fill="#fff"/></g></svg></button>
        </form>
    )
})

export default SearchBar;