import React, {useState, useEffect, memo} from 'react';
import {initOwlCarousel, removeClassOwlLoaded, initBannersFlipDown} from "@/animations/Default/headerPage";
import Script from "next/script";
import {connect} from "react-redux";

const BannerBlocDefault = memo(({showBanners, bannersBlocList, hasCountDown}) => {
    const [bannersList, setBannersList] = useState(showBanners && bannersBlocList && bannersBlocList.length > 0 ? [bannersBlocList[0]] : []);
    const [firstLoad, setFirstLoad] = useState(true);
    useEffect(() => {
        if (bannersList.length > 0) {
            removeClassOwlLoaded()
            setFirstLoad(false)
            setBannersList(bannersBlocList)
        }
    }, [])

    useEffect(() => {
        if (bannersList.length > 0 && !firstLoad) {
            if(hasCountDown){
                initBannersFlipDown(bannersList);
            }
            initOwlCarousel();
        }
    }, [bannersList])
    if (bannersList.length > 0) {
        return (<>
                {hasCountDown && <Script src="/assets/js/flipdown.min.js" strategy="beforeInteractive"/>}
                {bannersList.map((item, index) => {
                    return (
                        <>
                            <span key={index} dangerouslySetInnerHTML={{__html: item.htmlRelatedContent}}/>
                        </>
                    )
                })}
            </>
        );
    }
    return null;
});

const mapStateToProps = (state, ownProps) => {
    return {
        showBanners: ownProps.showBanners ?? null,
        bannersBlocList: ownProps.bannersBlocList ?? null,
        hasCountDown: ownProps.hasCountDown ?? null
    }
}

export default connect(mapStateToProps, null)(BannerBlocDefault);