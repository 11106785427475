import React, { useState, useEffect, memo } from 'react';
import { initSlickCarousel } from "@/animations/Mobile/headerPage";
import {connect} from "react-redux";

const BannerBlocMobile = memo(({ showBanners, bannersBlocList }) => {
    const [bannersList, setBannersList] = useState(showBanners && bannersBlocList?.length > 0 ? [bannersBlocList[0]] : []);
    const [firstLoad, setFirstLoad] = useState(true);
    useEffect(() => {
        if (bannersList.length > 0) {
            setFirstLoad(false)
            setBannersList(bannersBlocList)
        }
    }, [])

    useEffect(() => {
        if (bannersList.length > 0 && !firstLoad) {
            initSlickCarousel();
        }
    }, [bannersList])
    if (bannersList.length > 0) {
        return (<>
            {bannersList.map((item, index) => {
                return (
                    <span className="slick-slide" key={index} dangerouslySetInnerHTML={{ __html: item.htmlRelatedContent }} />
                )
            })}
        </>
        );
    }
    return null;
});

const mapStateToProps = (state, ownProps) => {
    return {
        showBanners: ownProps.showBanners ?? null,
        bannersBlocList: ownProps.bannersBlocList ?? null
    }
}

export default connect(mapStateToProps, null)(BannerBlocMobile);