import React, {memo} from "react";
import Utils from "@/utils/Default/Utils";
import {CUSTOMER_AREA_PAGE, NAVIGATION_PAGE} from "@/utils/constants";
import Link from 'next/link'
import {useRouter} from "next/router";
import {useDispatch} from "react-redux";
import {updateCurrentPage} from "@/redux/reducers/responsive/order/orderActions";


const BreadCrumbElement = ({id, label,url,context}) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const onClickBreadCrumb = () => {
        if (url === router.asPath) {
            dispatch(updateCurrentPage('order'))
        }
    }
    return (
        <span key={id}>
            {context === CUSTOMER_AREA_PAGE ? <Link href={url} passHref={true}>
                <a onClick={onClickBreadCrumb} title={label}>
                    <span>{label}</span>
                </a>
            </Link> : <span>{label}</span>}
        </span>
    )
}
const ProductListBreadCrumb = ({levelsNavigation, parentsCategories, context}) => {
    let index = 1;
    let parentsSparkowShopLabel = ['Boutiques', 'Marques', 'Themes'];
    return levelsNavigation.map((levelNavigation, i) => {
        if (i + 1 === levelsNavigation.length) {
            index++;
            return (
                <span key={levelNavigation.id}>
                    <strong>{levelNavigation.Label}</strong>
                    {context !== CUSTOMER_AREA_PAGE && context !== NAVIGATION_PAGE  && <meta content={index}/>}
                </span>
            );
        } else if (parentsCategories && parentsCategories[i] && parentsCategories[i].label) {
            let item = parentsCategories[i];
            index++;
            if (context === CUSTOMER_AREA_PAGE) {
                return (
                    <span key={item.id}>
                    <Link href={item.url} passHref={true}>
                        <a title={item.label}>
                            <span>{item.label}</span>
                        </a>
                       </Link>
                    </span>

                );
            } else if (parentsSparkowShopLabel.indexOf(item.label) === -1) {
                return (
                    <span key={item.id}>
                        <a href={Utils.getMetaInfosValueByCode("SeoUrl", item.metaInfos)}
                           title={item.label}>
                            <span>{item.label}</span>
                        </a>
                        <meta content={index}/>
                    </span>
                )
            } else {
                return (
                    <BreadCrumbElement key={levelNavigation.id} id={item.id} label={item.label}/>
                )
            }
        } else {
            if (levelNavigation && levelNavigation.Label) {
                return (
                    <BreadCrumbElement key={levelNavigation.id} context={context} id={levelNavigation.id} label={levelNavigation.Label} url={levelNavigation.url}/>
                )
            }
            return null
        }
    })
}

const FirstLevel = ({context, userId}) => {
    if (context === CUSTOMER_AREA_PAGE) {
        return (
            <Link href={`/Customer/Account/Dashboard/Id/${userId}`} passHref={true}>
                <a title="Mon compte">
                    <span>Mon compte</span>

                </a>
            </Link>
        )
    } else if (context === NAVIGATION_PAGE) {
        return (
            <a href="/" title="Accueil BUT">
                <span>Accueil BUT</span>
            </a>
        )
    } else {
        return (
            <>
                <a href="/" title="Accueil BUT">
                    <span>Accueil BUT</span>
                </a>
                <meta content="1"/>
            </>
        )
    }
}

export const Breadcrumb = memo((props) => {
    return (
        <div className={`breadcrumb ${(props.context === CUSTOMER_AREA_PAGE) ? 'customerArea' : ''}`}>
            <div className="levels">
                <span>
                    {FirstLevel(props)}
                </span>
                {props.context === NAVIGATION_PAGE ? <span><strong>Résultats pour "{props.keyWord}"</strong></span> :
                    <ProductListBreadCrumb levelsNavigation={props.levelsNavigation}
                                           parentsCategories={props.parentsCategories}
                                           context={props.context}/>
                }
            </div>
        </div>
    );
});
