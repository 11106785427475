import React from "react";
import PropTypes from "prop-types";
import {removeAccents} from "@/utils/Mobile/utils"

const linkRedirectionFirstLevel = (event, link, redirectionUrl) => {
    event.preventDefault();
    appData.actionTrigger.dispatch({
        trigger: "linkClicItemRedirectionFirstLevel",
        payload: {
            link: removeAccents(link).toLowerCase()
        },
        resolveCallback: function () {
            window.location.href = redirectionUrl;
        }
    });
}
export const FirstLevelMenuItem = (props) => {
    const addLevel = (event) => {
        event.preventDefault();
        props.addLevel(props.item, props.item[0])
    }
    if (props.item[7].length > 0) {
        return (
            <li>
                <a onClick= {addLevel}
                   href= {props.item[3]}
                   className="level__btn gtm-menu-rubrique"
                   dangerouslySetInnerHTML={{__html: (props.item[1] ? props.item[1] + '<span class="icon-chevron gtm-menu-rubrique"  />' : props.item[0] + '<span class="icon-chevron gtm-menu-rubrique" />')}}
                />
            </li>
        )
    } else {
        return (<li><a onClick={(event)=>{linkRedirectionFirstLevel(event, props.item[0], props.item[3])}}
                       href={props.item[3]}
                       className="level__btn gtm-header-redirection "
                       dangerouslySetInnerHTML={{__html: props.item[1] ? props.item[1] : props.item[0]}}/>
        </li>);
    }
}

FirstLevelMenuItem.propTypes = {
    item: PropTypes.array,
    key: PropTypes.number
};

FirstLevelMenuItem.defaultProps = {
    item: {},
    key: 0
};