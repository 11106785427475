import {useEffect, useState} from "react"
import {dispatchIdentityData, handleToken} from "@/services/userServices";
import {FullPageLoader} from "./responsive/customerAccount/FullPageLoader"
import Utils from "@/utils/Default/Utils";
import React from 'react'
import Cookies from "js-cookie";
import CustomerAccountLayout from "./responsive/customerAccount/CustomerAccountLayout";
import {getContext, getNavigationLevel} from "./responsive/commun/SPAHead/PrefetchDataPages";
import HeadAndScripts from "@/components/responsive/commun/SPAHead/HeadAndScripts";
import {useRouter} from 'next/router';
import {pagesRequiredAuth} from '@/config/pagesRequiredAuth'
import {CUSTOMER_PAGE, GROUP_USER_WEB_BY_TOKEN, IN_STORE_PAGES} from "@/utils/constants";
import {connect, useDispatch} from "react-redux";
import MonitorInactivity from "@/monitors/MonitorInactivity";
import {isEligibleToScan} from "@/utils/Mobile/utils";

const AuthProvider = ({ deviceType, isServer, children ,timeToDisconnect}) => {
    const [authenticated, setIsAuthenticated] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const router = useRouter()
    const pathName = router.pathname
    const isPageRequireAuth = pagesRequiredAuth.includes(pathName)
    const sellerData = Utils.getCookieValue('sellerInfos')
    const isSeller = sellerData != null && sellerData !== 'undefined' && sellerData !== ''
    const isInStorePage = router.pathname.startsWith('/inStore')
    const isInStorePurchasePage = router.pathname.startsWith('/inStore/purchase')
    const dispatch = useDispatch()
    useEffect(() => {
        if (isInStorePurchasePage && !isEligibleToScan()) {
            Utils.redirectTo("/")
        }
    })
    useEffect(async () => {
        if (isPageRequireAuth) {
            const navigationToken = Cookies.get('navigationToken');
            if (navigationToken) {
                const fetchIdentityData = await handleToken();
                dispatch(dispatchIdentityData(fetchIdentityData));
                if (fetchIdentityData.group == "WebUsers" || fetchIdentityData.group == "callCenter" || (!pathName.includes("orderDetails") && fetchIdentityData.group == GROUP_USER_WEB_BY_TOKEN)) {
                    isInStorePage ? Utils.redirectTo("/Purchase/Identification/Index") : Utils.redirectTo("/Customer/Account/Identification")
                } else {
                    setIsLoading(false)
                    setIsAuthenticated(true)
                }
            } else {
                isInStorePage ? Utils.redirectTo("/Purchase/Identification/Index") : Utils.redirectTo("/Customer/Account/Identification")
            }
        }
    }, [dispatch, isPageRequireAuth])

    return (<>
        {isSeller && timeToDisconnect > 0 && authenticated  &&
            <MonitorInactivity redirectTo={'/Customer/Account/Disconnect'} timeout={timeToDisconnect}/>}
        {isPageRequireAuth && !isInStorePage ?
            <>
                <HeadAndScripts context={CUSTOMER_PAGE} pathName={pathName} deviceType={deviceType}/>
                {(!isServer || !isLoading && authenticated) ?
                    <CustomerAccountLayout pathName={pathName} context={getContext(pathName)} deviceType={deviceType}>
                        {children}
                    </CustomerAccountLayout> : <FullPageLoader/>} </>

            : isInStorePage ?
                <>
                    <HeadAndScripts context={IN_STORE_PAGES} pathName={pathName} deviceType={deviceType}/>
                    {!isPageRequireAuth || (!isServer || !isLoading && authenticated) ?
                        <>
                            {children}
                        </> : <FullPageLoader/>}
                </> :

                <> {children} </>}
    </>)
};
const mapStateToProps = (state, ownProps) => {
    return {
        deviceType: ownProps.deviceType,
        isServer: ownProps.isServer,
        children: ownProps.children,
        timeToDisconnect: state?.header?.timeToDisconnect ?? state?.identity?.timeToDisconnect,
    }
}
const mapDispatchToProps = dispatch => {
    return {}
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
