import {
    SHIPPING_AVAILABILITY_DATA_FETCHED,
    AVAILABILITY_DATA_FETCHED,
    STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED,
    CITY_SEARCHRESULTS_FETCHED,
    DELETE_CITYlIST_STATE,
    UPDATE_GEO_ERROR_BLOC_STATUS,
    UPDATE_ZIPCODE_KEYWORDS_IN_STATE,
    UPDATE_CITY_AND_ZIPCODE_IN_STATE
} from '../actionsTypes'

const initialState = {
    // productId : appData.productId,
    availabilityData : {},
    genericAvailabilitytData : {},
    stockLocatorAvailabilityData : {},
    storeSearchKey : "",
    locationsData : [],
    cityKeywords : "",
    shippingAvailabilityData:[],
    cityList: {},
    stockLocatorZipCodekeywords: '',
    stockLocatorCitykeywords: '',
    // commercialOperations: appData.commercialOperations ? Utils.prepareCommercialOperationListByType() : null,
    // eligibilityToPrivateSale: appData.eligibilityToPrivateSale,
    showGeoErrorBloc: false,
    isFirstLoad:true

};

const productAvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHIPPING_AVAILABILITY_DATA_FETCHED:
            let newAavailabilityData = action.payload.shippingAvailabilityData
            let productId = action.payload.productId
            return {
                ...state,
                shippingAvailabilityData: {...state.shippingAvailabilityData, [productId]: newAavailabilityData}
            }
        case AVAILABILITY_DATA_FETCHED:
            return {
                ...state,
                availabilityData : action.payload.availabilityData,
                isFirstLoad:false,
                genericAvailabilitytData : action.payload.genericAvailabilitytData
            }
        case STOCK_LOCATOR_AVAILABILITY_DATA_FETCHED:
            return {
                ...state,
                stockLocatorAvailabilityData:action.payload.stockLocatorAvailabilityData,
                cityList : {}

            }
        case CITY_SEARCHRESULTS_FETCHED :
            return {
                ...state,
                cityList : action.payload.locationsData,
                stockLocatorZipCodekeywords : action.payload.keywords
            }
        case DELETE_CITYlIST_STATE :
            return {
                ...state,
                cityList: {},
            }
        case UPDATE_GEO_ERROR_BLOC_STATUS :
            return {
                ...state,
                showGeoErrorBloc: action.element.value
            }
        case UPDATE_ZIPCODE_KEYWORDS_IN_STATE :
            return {
                ...state,
                stockLocatorZipCodekeywords: action.value,

            }

        case UPDATE_CITY_AND_ZIPCODE_IN_STATE :
            return {
                ...state,
                stockLocatorZipCodekeywords: action.zipCode,
                stockLocatorCitykeywords: action.city,
                cityList: {}
            }

        default:
            return state;
    }
};

export default productAvailabilityReducer;