import {Fragment, memo} from 'react'
import StoreSchedule from "./StoreSchedule";

const WharehouseDetails = memo((props) => {
    if (props.warehouseInfos) {
        return (
            <Fragment>
                <div id="depot" className="tab-pane fade active show">
                    <div className="store-fiche-tab-content__title">
                        <span className="city">{props.shopName}</span>
                    </div>
                    <span className="store-fiche-tab-content__address">{props.warehouseInfos.address.streetAddress}
                        <br/>{props.warehouseInfos.address.zipCode} {props.warehouseInfos.address.city} </span>
                    <div className="collapse show" id="horaires-depot">
                        <StoreSchedule openingSchedule={props.warehouseInfos.schedule}/>
                    </div>
                </div>
            </Fragment>
        )
    }
    return null

})
export default WharehouseDetails;