import {CHANGE_KEYWORD, SEARCH_RESULTS_FETCHED, SEARCH_STARTED, TOP_SEARCHES_RESULTS_FETCHED} from "../actionsTypes";

const initialState = {
    keywords: "",
    results: null,
    searchStarted: false,
    topSearchesResults: null
};

const SearchBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case  SEARCH_STARTED:
            return {
                ...state,
                searchStarted: true
            }
        case SEARCH_RESULTS_FETCHED:
            return {
                ...state,
                keywords: action.payload.keywords,
                results: action.payload.searchResults
            }
        case TOP_SEARCHES_RESULTS_FETCHED:
            return {
                ...state,
                topSearchesResults: action.payload.topSearchesResults
            }
        case CHANGE_KEYWORD:
            return {
                ...state,
                keywords: action.keywords
            }
        default:
            return state;
    }

};

export default SearchBarReducer;