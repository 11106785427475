import React from 'react';
import {dispatchEventSTPUSHUpdate} from "@/animations/Default/commonPage";

const HelpLink = () => {
    return (
        <a href="#" className="help" id="Header_HorsTunnel_smart_tribune" onClick={() => {
            dispatchEventSTPUSHUpdate(['classic-5560'])
        }} title="aide et contact">Aide et contact</a>
    )
}
export default HelpLink;