import {UPDATE_IN_STORE_ORDER_DETAILS} from "@/redux/reducers/Mobile/actionsTypes";

const initialState = {
    orderId: null,
    orderDate: null,
    orderTime: null,
    qty: null,
    total: null,
    subTotal: null,
    items: [],
    crossedOutPricesWithoutTax: null,
    totalWarrantyCost: null,
    documentNumber: null,
    isForeignShipping: null,
    orderVouchers: []
};

const inStoreOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_IN_STORE_ORDER_DETAILS:
            return {
                ...state,
                orderId: action.payload.orderId,
                orderDate: action.payload.orderDate,
                orderTime: action.payload.orderTime,
                qty: action.payload.qty,
                total: action.payload.total,
                subTotal: action.payload.subTotal,
                items: action.payload.items,
                crossedOutPricesWithoutTax: action.payload.crossedOutPricesWithoutTax,
                totalWarrantyCost: action.payload.totalWarrantyCost,
                documentNumber: action.payload.documentNumber,
                isForeignShipping: action.payload.isForeignShipping,
                orderVouchers: action.payload.orderVouchers,

            }
        default:
            return state;
    }
};

export default inStoreOrderReducer;

