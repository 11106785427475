import {KITCHEN_PROJECT_ERROR_MESSAGE, LOAD_KITCHEN_PROJECT_LIST, UPDATE_KITCHEN_PROJECT_TITLE} from "../actionsTypes";

const initialState = {
    kitchenProjects: null,
    errorMessage: ''
}
const PlannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case  LOAD_KITCHEN_PROJECT_LIST :
            return {
                ...state,
                kitchenProjects: action.payload.kitchenProjects
            }
        case  UPDATE_KITCHEN_PROJECT_TITLE :
            return {
                ...state,
                kitchenProjects: action.payload.kitchenProjects
            }
        case  KITCHEN_PROJECT_ERROR_MESSAGE :
            return {
                ...state,
                errorMessage: action.payload.errorMessage
            }
        default:
            return state;
    }
};
export default PlannerReducer