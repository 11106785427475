import cart from './cart.js' ;
import $ from 'jquery'

export const cartPageInit = () => {
    cart.init();
}

export const checkWarrantyRadio = (productId) => {
    $("#r1-" + productId).prop('checked', true);
}
export const showLoader = (itemId) => {
    $('.loader' + itemId).removeAttr("style");
}
export const hideLoader = (itemId) => {
    $('.loader' + itemId).hide();
}


/* afficher le bloc loader */

export const showItemBlocLoader = (itemId) => {
    $('#bloc_loader-' + itemId).css({'display': 'block'});
}

export const hideItemBlocLoader = (itemId) => {
    $('#bloc_loader-' + itemId).hide();
}

export const openFancyBoxPopinCartItemExhausted = () => {
    $.fancybox.open([{
        href: '#popin-mkp-nonAssure'
    }], {padding: 0})
}


export const openFancyBoxPopinShippingMode = () => {
    if ($('#popin-mkp-ml').length) {
        $.fancybox.open([{
            href: '#popin-mkp-ml'
        }], {padding: 0})
        $('.fancybox-inner').css({'overflow-x': 'hidden'});
    }
}

export const openFancyBoxPopin = (idPopin) => {
    if ($('#' + idPopin).length) {
        $.fancybox.open([{
            href: '#' + idPopin
        }], {padding: 0})
        $('.fancybox-inner').css({'overflow-x': 'hidden'});
    }
}

export const openCartFancyBoxPopin = (idPopin) => {
    if ($('#' + idPopin).length) {
        $.fancybox.open([{
            href: '#' + idPopin
        }], {padding: 0})
        $('.fancybox-inner').css({'overflow-x': 'hidden'});
    }
}


export const openCreationOrderFailureFancyBoxPopin = (idPopin, closeFunction) => {
    if ($('#' + idPopin).length) {
        $.fancybox.open([{
            href: '#' + idPopin,
            afterClose: closeFunction
        }], {padding: 0})
        $('.fancybox-inner').css({'overflow-x': 'hidden'});
    }
}

export const openFancyBoxPopinCartItemNotSupported = () => {
    $.fancybox.open([{
        href: '#popin-mkp-noRet'
    }], {padding: 0})
}

export const closeCartItemPopin = () => {
    $.fancybox.close(true);
}

export const resizeFancyBoxPopinReducedProductSheet = (id, unitPrice, unitCrossedOutPrice) => {
    if ($('#' + id).length > 0) {
        $('#' + id).css({'width': 'auto'});
    }
    if (unitCrossedOutPrice && unitCrossedOutPrice > unitPrice ) {
        $('.old-price').show()
        $('.old-price').html(unitCrossedOutPrice)
    } else {
        $('.old-price').hide();
    }
    $('.current-price').html(unitPrice)
    $.fancybox.open([{
        href: '#'+id
    }], {padding: 0})
}

export const closeErrorBloc = (e) => {
    e.preventDefault();
    $('.mess_error').slideUp();
};

export const closeDispoBloc = (e) => {
    e.preventDefault();
    $('.mess_dispo').slideUp();
};
export const addCheckInput = () =>{

    $("[name='periode']").click(function(){
        $(this).prop("checked", true)
    })
}
export const resetSelectedHour = (id) =>{
    $(id).val('');
}
export const initializeSelectOption = (id) =>{
    $(id).val('true');
}