import Cookies from "js-cookie";
import {FILTER_AVAILABILITY_SHOP_ID_LIST_CODE} from "../constants";

const Utils = {
    parseJson(data) {
        let response = null;
        try {
            response = JSON.parse(data);
        } catch (e) {
            response = data;
        }
        return response;
    },
    removeAccents(s) {
        let ko = 'ÀÁÂÃÄÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÇçàáâãèéêëîïòôõöùúûüñ';
        let ok = 'AAAAAEEEEIIIIOOOOOUUUUCcaaaaeeeeiioooouuuun';
        let len = s.length;
        let p;
        let c;
        let result = "";
        for (let i = 0; i < len; i++) {
            c = s.charAt(i);
            p = ko.indexOf(c);
            result += p == -1 ? c : ok.charAt(p);
        }
        return result;
    },
    removeSpecialCharAndAccents(s, replaceFunction = '') {
        let str = this.removeAccents(s);
        let regex = new RegExp(/([^A-Za-z0-9\-\_\.\ ])/);
        if (regex.test(str)) {
            return str.replace(/[^A-Za-z0-9\-\_\.\ ]/gi, replaceFunction)
        }
        return str;
    },
    getShopCirleFromLocalStorage() {
        let shopListData = []
        if (this.isValidShopCircle()) {
            let shopCircleData = JSON.parse(localStorage.getItem('shopCircle'));
            if (shopCircleData["circleShops"]) {
                shopListData = shopCircleData["circleShops"];
            }
        }
        return shopListData;
    },

    getShopCirleDataFromLocalStorage() {
        let shopCircle = []
        if (this.isValidShopCircle()) {
            let shopCircleData = JSON.parse(localStorage.getItem('shopCircle'));
            if (shopCircleData) {
                shopCircle = shopCircleData;
            }
        }
        return shopCircle;
    },
    getFormData(formCssSelector) {
        let formObject = $(formCssSelector).serializeArray()
        let formData = {}
        formObject.forEach(function (item) {
            formData[item.name] = item.value;
        })
        return formData

    },

    getProductAvailabilityApiParams(allShops, productId) {
        let shopCircle = this.getShopCirleFromLocalStorage();
        let shopCircleData = this.getShopCirleDataFromLocalStorage();
        let view = "ProductSheet"

        if (allShops) {
            view = "StockLocator"
        }
        if (shopCircle.length > 0) {
            let shopIdList = [];
            let counter = 1;
            shopIdList.push(shopCircle[0].id);
            const stockLocatorShopsCount = localStorage.getItem('stockLocatorShopsCount');
            if (allShops) {
                view = "StockLocator"
                while (shopCircle[counter] && shopIdList.length < parseInt(appData.stockLocatorShopsCount)) {
                    if (shopCircle[counter].isMerchant === true) {
                        shopIdList.push(shopCircle[counter].id);
                    }
                    counter++;
                }
            } else {
                while (shopCircle[counter] && shopIdList.length < parseInt(stockLocatorShopsCount)) {
                    if (shopCircle[counter].isMerchant === true) {
                        shopIdList.push(shopCircle[counter].id);
                    }
                    counter++;
                }
            }

            let shopIdListParam = (shopIdList.join()) ? "&ShopIds=" + shopIdList.join() : "";
            return "ProductIds=" + productId + ",1" + shopIdListParam + "&View=" + view + "&ZipCode=" + shopCircleData.zipCode + "&City=" + shopCircleData.city;
        } else {
            return "ProductIds=" + productId + ",1" + "&View=" + view;
        }

    },
    getProductsAvailabilityApiParamsForProductList(productListItems) {
        let shopCircleData = this.getShopCirleDataFromLocalStorage();
        let productsIds = '';
        productListItems.map(item => {
            productsIds += item.ean + ',1;'
        })
        if (shopCircleData && shopCircleData.primaryShop) {
            return "ProductIds=" + productsIds.slice(0, -1) + "&ShopIds=" + shopCircleData.primaryShop.id + "&View=ProductList" + "&ZipCode=" + shopCircleData.zipCode + "&City=" + shopCircleData.city;
        } else {
            return "ProductIds=" + productsIds.slice(0, -1) + "&View=ProductList";
        }
    },
    formatPrice(price) {
        return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(price)
    },
    formatDimension(dimension) {
        return new Intl.NumberFormat('fr-FR',).format(dimension)

    },
    getMetaInfosValueByCode(code, data) {
        let codeIndex = data?.codes?.indexOf(code);
        if (codeIndex > -1) {
            return data.items[codeIndex].value;
        }
        return "";
    },
    getHashFromObject(object) {
        let stringFromObject = JSON.stringify(object);
        let hash = 0, i, chr;
        if (stringFromObject.length === 0) return hash;
        for (i = 0; i < stringFromObject.length; i++) {
            chr = stringFromObject.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    },
    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        if (name === "Filters") {
            return results[2].replace(/%22/g, "\"")
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    redirectTo: (url) => {
        window.location.href = url
    },

    getUserIdFromCookie() {
        let CookieAsArray = []
        let groupAndIdArray = []
        let formattedCookieArray = []
        formattedCookieArray['userId'] = 'dXNlcklkPS0xO2dyb3VwPXdlYnVzZXI='; /* token with no userId */
        if (Cookies.get('navigationToken')) {
            CookieAsArray = atob(Cookies.get('navigationToken')).split(':');
            groupAndIdArray = CookieAsArray[1].split(';')
            groupAndIdArray.forEach((element) => {
                formattedCookieArray[element.split("=")[0]] = element.split("=")[1]
            });
        }
        return formattedCookieArray['userId']
    },
    isValidShopCircle() {
        if (localStorage.getItem('shopCircle') && Cookies.get('availabilityShopCircle')) {
            let shopCircle = JSON.parse(localStorage.getItem('shopCircle'));
            let date = new Date(shopCircle.modificationDate);
            let dateYearLater = new Date(date.setMonth(date.getMonth() + 12))
            let now = new Date();
            let shopCircleVersion = localStorage.getItem('shopCircleVersion')
            if (shopCircle.version != shopCircleVersion || (dateYearLater <= now)) {
                localStorage.removeItem('shopCircle')
                return false;
            }
            return true;
        } else {
            localStorage.removeItem('shopCircle')
        }
        return false;
    },
    initializeLocalStorageShopCircle(shopCircleVersion) {
        if (Cookies.get('shopCircle') && JSON.parse(Cookies.get('shopCircle')).version == shopCircleVersion) {
            localStorage.setItem('shopCircle', Cookies.get('shopCircle'));
        }
        Cookies.remove('shopCircle');
    },

    getPrimaryShopId() {
        let primaryShopId = "";
        if (this.isValidShopCircle()) {
            var shopCircleData = JSON.parse(localStorage.getItem('shopCircle'));
            if (shopCircleData["primaryShop"]) {
                return shopCircleData["primaryShop"].id;
            }
        }
        return primaryShopId;
    },

    ellipsisTitle(brand, shortTitle, maxLength) {
        let productTitle = '';

        if (!shortTitle) {
            shortTitle = '';
        }
        if (brand && brand != " ") {
            let lengthTitle = brand.length + shortTitle.length;
            if (lengthTitle > maxLength) {
                productTitle = '<strong>' + brand + '</strong><br/><span>' + shortTitle.substr(0, maxLength - brand.length) + '..' + '</span>';

            } else {
                productTitle = '<strong>' + brand + '</strong><br/><span>' + shortTitle + '</span>';
            }
        } else {
            if (shortTitle.length > maxLength) {
                productTitle = '<strong></strong><br/><span>' + shortTitle.substr(0, maxLength) + '..' + '</span>';
            } else {
                productTitle = '<strong></strong><br/><span>' + shortTitle + '</span>';
            }
        }
        return (productTitle);
    },
    getGroupeFeatures(code, data) {
        const codeIndex = data.codes.indexOf(code);
        if (codeIndex > -1) {
            return data.items[codeIndex];
        }
        return false;
    },

    validateEmail(email) {
        var regExp = /^(?:[a-z0-9!#$%&'*+\/=?^_'{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_'{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
        return regExp.test(email);
    },

    validatePhone(phone) {
        var phoneRegExp = /^(06|07|\+336|\+337)[0-9]{8}$/
        if (!phone.match(phoneRegExp)) {
            return false
        }
        return true;
    },

    browserHistory: {
        pushState(currentSort, appliedFiltersValues, pageIndex, keyWords) {
            var url = "";
            var separator = "?";

            // Keywords
            if (keyWords) {
                url += separator + "KeyWords=" + keyWords;
                separator = "&";
            }

            // Sort
            if (currentSort) {
                url += separator + "SortedBy=" + currentSort;
                separator = "&";
            }

            // Filters
            if (appliedFiltersValues) {
                url += separator + "Filters=" + appliedFiltersValues;
                separator = "&";
            }

            // PageIndex
            if (pageIndex) {
                url += separator + "PageIndex=" + pageIndex;
                separator = "&";
            }

            if (url) {
                history.pushState(null, null, url);
            } else {
                history.pushState(null, null, window.location.pathname);
            }
        },
        pushParams(key, value) {
            var url = "";
            var separator = "?";

            if (key) {
                url += separator + key + "=" + value;
                separator = "&";
            }

            if (url) {
                history.pushState(null, null, url);
            } else {
                history.pushState(null, null, window.location.pathname);
            }
        },
        pushContextUrl(contextUrl) {
            if (contextUrl) {
                history.pushState(null, null, contextUrl);
            } else {
                history.pushState(null, null, window.location.pathname);
            }
        }
    },
    /**
     * validation des champs formulaire inscription vendeur
     * @param params
     * @returns {null|jsonObject}
     */
    isValidSellerRegistrationForm(params) {

        // Règles champ Texte
        if (!((params.lastname.match('[A-z]')) && (params.lastname.length > 1)) ||
            !((params.firstname.match('[A-z]')) && (params.firstname.length > 1)) ||
            !((params.role.match('[A-z]')) && (params.role.length > 1)) ||
            !((params.email.match('[A-z]')) && (params.email.length > 1)) ||
            !((params.socialReason.match('[A-z]')) && (params.socialReason.length > 1)) ||
            !((params.urlWebSite.match('[A-z]')) && (params.urlWebSite.length > 1))
        ) {
            return null;
        }

        // Règles champ Email
        const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_'{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_'{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
        if (!params.email.match(emailRegex)) {
            return null;
        }

        // Règles champ Téléphone
        const phoneRegex = /\+?\(?([0-9]{3})\)?[-.]?\(?([0-9]{3})\)?[-.]?\(?([0-9]{4})\)?/; // tous types de téléphone
        if (!params.phone.match(phoneRegex)) {
            return null;
        }

        // Règle sur le champ URL
        const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
        if (!params.urlWebSite.match(urlRegex)) {
            return null;
        }

        // Règles à la sélection d'un élément dans un select obligatoire
        if ((params.shopsNumber.match('selectionner')) || (params.shippingCountry.match('selectionner'))) {
            return null;
        }


        // Règles à la sélection d'un élément dans un multiSelect obligatoire
        let mainMarket = $('#marche').val()
        let carrierDelivery = $('#transporteur').val()
        let methodDelivery1 = $('#livraisonSup').val()
        let methodDelivery2 = $('#livraisonInf').val()
        let avgDeliveryTime = $('#delai').val()
        if (mainMarket.length === 0 || carrierDelivery.length === 0 || methodDelivery1.length === 0 || methodDelivery2.length === 0 || avgDeliveryTime.length === 0) {
            return null;
        }
        // Règle sur le champ siretNumber
        if (params.siretNumber.length > 0 && !params.siretNumber.match(/^\d{14}$/)) {
            return null;
        }

        // Règle sur le champ catalogsNumber, stockNumber, integrateButNumber
        if (params.catalogsNumber.length === 0 || params.stockNumber.length === 0 || params.integrateButNumber.length === 0) {
            return null
        }
        if (isNaN(params.catalogsNumber) || isNaN(params.stockNumber) || isNaN(params.integrateButNumber)) {
            return null;
        }

        if (params.otherMkp === '') {
            return null
        }
        if (params.otherMkp === 'oui') {
            if (params.methodConnectionMKP.match('selectionner')) {
                return null
            }
            if (params.shopName1.length === 0 || params.lienMKP1.length === 0 || params.noteMKP1.length === 0) {
                return null
            }
            if (isNaN(params.noteMKP1)) {
                return null
            }
            if (!params.lienMKP1.match(urlRegex)) {
                return null;
            }

            if (params.firstTrigger) {
                if (params.shopName2.length === 0 || params.lienMKP2.length === 0 || params.noteMKP2.length === 0) {
                    return null;
                }
                if (isNaN(params.noteMKP2)) {
                    return null
                }
                if (!params.lienMKP2.match(urlRegex)) {
                    return null;
                }
            }

            if (params.secondTrigger) {
                if (params.shopName3.length > 1 || params.lienMKP3.length > 1 || params.noteMKP3.length > 1) {
                    if (params.shopName3.length === 0 || params.lienMKP3.length === 0 || params.noteMKP3.length === 0) {
                        return null;
                    }
                    if (isNaN(params.noteMKP3)) {
                        return null
                    }
                    if (!params.lienMKP3.match(urlRegex)) {
                        return null;
                    }
                }
            }
        }
        params.mainMarket = mainMarket
        params.carrierDelivery = carrierDelivery
        params.methodDelivery1 = methodDelivery1
        params.methodDelivery2 = methodDelivery2
        params.avgDeliveryTime = avgDeliveryTime
        return params;
    },
    /**
     * Preparer les informations d'un vendeur
     * @param data
     * @returns {jsonObject}
     */
    prepareDataRegisterSellerForRequest(data) {
        const customerData = {};
        for (let i = 0, l = data.mainMarket.length; i < l; i++) {
            customerData['mainMarket[' + i + ']'] = data.mainMarket[i]
        }
        for (let i = 0, l = data.carrierDelivery.length; i < l; i++) {
            customerData['carrierDelivery[' + i + ']'] = data.carrierDelivery[i]
        }
        for (let i = 0, l = data.methodDelivery1.length; i < l; i++) {
            customerData['methodDelivery1[' + i + ']'] = data.methodDelivery1[i]
        }
        for (let i = 0, l = data.methodDelivery2.length; i < l; i++) {
            customerData['methodDelivery2[' + i + ']'] = data.methodDelivery2[i]
        }
        for (let i = 0, l = data.avgDeliveryTime.length; i < l; i++) {
            customerData['avgDeliveryTime[' + i + ']'] = data.avgDeliveryTime[i]
        }
        customerData['lastname'] = data.lastname;
        customerData['firstname'] = data.firstname;
        customerData['role'] = data.role;
        customerData['email'] = data.email;
        customerData['phone'] = data.phone;
        customerData['socialReason'] = data.socialReason;
        customerData['siretNumber'] = data.siretNumber;
        customerData['deaRegister'] = data.deaRegister;
        customerData['intracommunityNumber'] = data.intracommunityNumber;
        customerData['urlWebSite'] = data.urlWebSite;
        customerData['shopsNumber'] = data.shopsNumber;
        customerData['otherMkp'] = data.otherMkp;
        customerData['shopName1'] = data.shopName1;
        customerData['lienMKP1'] = data.lienMKP1;
        customerData['noteMKP1'] = data.noteMKP1;
        customerData['shopName2'] = data.shopName2;
        customerData['lienMKP2'] = data.lienMKP2;
        customerData['noteMKP2'] = data.noteMKP2;
        customerData['shopName3'] = data.shopName3;
        customerData['lienMKP3'] = data.lienMKP3;
        customerData['noteMKP3'] = data.noteMKP3;
        customerData['methodConnectionMKP'] = (data.methodConnectionMKP !== 'selectionner') ? data.methodConnectionMKP : '';
        customerData['catalogsNumber'] = data.catalogsNumber;
        customerData['stockNumber'] = data.stockNumber;
        customerData['integrateButNumber'] = data.integrateButNumber;
        customerData['shippingCountry'] = data.shippingCountry;
        customerData['revenuePreviousYear'] = data.revenuePreviousYear;
        return customerData;
    },
    prepareDataCreateAccountForRequest(data, view) {
        const customerData = {};
        customerData['profile[email]'] = data.email;
        customerData['profile[password]'] = data.password;
        customerData['billingAddress[city]'] = view == "light" ? data.city : data.billingAddress_city;
        customerData['billingAddress[zipCode]'] = view == "light" ? data.zipCode : data.billingAddress_zipCode;
        customerData['subscription[sms]'] = data.subscriptionSms == 'true' ? 1 : 0;
        customerData['subscription[courrier]'] = data.subscriptionMail == 'true' ? 1 : 0;
        customerData['subscription[mail]'] = data.subscriptionEmail == 'true' ? 1 : 0;
        customerData['subscription[mail]'] = data.subscriptionEmail == 'true' ? 1 : 0;
        customerData['stayConnected'] = data.stayConnected;
        customerData['view'] = view;

        if (view !== "light") {
            customerData['billingAddress[streetAddress]'] = data.billingAddress_streetAddress;
            customerData['billingAddress[streetAddressComplement]'] = data.billingAddress_streetAddressComplement;
            customerData['billingAddress[latitude]'] = data.billingAddress_latitude;
            customerData['billingAddress[longitude]'] = data.billingAddress_longitude;
            customerData['billingAddress[inseeCode]'] = data.billingAddress_inseeCode;
            customerData['subscription[partnerOffer]'] = data.partnerOffer;
            customerData['subscription[postalOrPhone]'] = data.postalOrPhone;
            customerData['profile[firstname]'] = data.firstName;
            customerData['profile[lastname]'] = data.lastName;
            customerData['profile[civility]'] = data.civility;
            customerData['profile[phone]'] = data.phone ? data.phone : null;
            customerData['billingAddress[isBilling]'] = 1;
            customerData['billingAddress[isShipping]'] = data.billingAddress_isShipping;
            if (appData.isDQENormalisationActive) {
                customerData['billingAddress[normalizationStatus]'] = data.billing_normalizationStatus;
            }
            if (data.billingAddress_isShipping == 0) {
                customerData['shippingAddress[firstname]'] = data.shippingAddress_firstName;
                customerData['shippingAddress[lastname]'] = data.shippingAddress_lastName;
                customerData['shippingAddress[streetAddress]'] = data.shippingAddress_streetAddress;
                customerData['shippingAddress[streetAddressComplement]'] = data.shippingAddress_streetAddressComplement;
                customerData['shippingAddress[zipCode]'] = data.shippingAddress_zipCode;
                customerData['shippingAddress[city]'] = data.shippingAddress_city;
                customerData['shippingAddress[latitude]'] = data.shippingAddress_latitude;
                customerData['shippingAddress[longitude]'] = data.shippingAddress_longitude;
                customerData['shippingAddress[inseeCode]'] = data.shippingAddress_inseeCode;
                customerData['shippingAddress[phone]'] = data.shippingAddress_phone;
                if (appData.isDQENormalisationActive) {
                    customerData['shippingAddress[normalizationStatus]'] = data.shipping_normalizationStatus;
                }
            }
        }

        return customerData;
    },
    createCustomerMetaInfos(tab) {
        let codes = [];
        let items = [];
        let item = null;

        for (var key in tab) {
            codes.push(key);
            item = {'code': key, 'value': tab[key]}
            items.push(item);
        }
        return ({'codes': codes, 'items': items})
    },

    getUrlToRedirectByContext(context) {
        let urlToRedirect = '/Purchase/Cart/Shipping'
        if (context === 'customerSpace') {
            urlToRedirect = (appData.urlToRedirect) ? appData.urlToRedirect : "/Customer/Account/Dashboard/Id/{id}"
        }
        return urlToRedirect
    },
    getCurrentUrl() {
        return window.location.href.split('?')[0]
    },

    parseUrlParameterForIE(keyword, data) {
        let keyObj = {};
        let finalObjData = []
        if (keyword.indexOf('[') > -1) {
            let keywordData = keyword.split("[");
            let keywordKey = keywordData[0]
            let subKey = keywordData[1].slice(0, -1);
            keyObj[subKey] = data
            finalObjData[keywordKey] = keyObj
        } else {
            finalObjData[keyword] = data
        }
        return finalObjData
    },

    prepareAndParseConfigDataForIE(data) {
        let target = {};
        for (let i = 0; i < data.length; i++) {
            let obj = data[i]
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                        target[prop] = {...target[prop], ...obj[prop]};
                    } else {
                        target[prop] = obj[prop];
                    }
                }

            }
        }
        return target
    },

    getFormatedCurrentDate() {
        let today = new Date();
        let day = (today.getDate() >= 10) ? today.getDate() : "0" + today.getDate();
        let currentMonth = today.getMonth() + 1;
        let month = (currentMonth >= 10) ? currentMonth : "0" + currentMonth;
        let year = today.getFullYear();
        return year + "-" + month + "-" + day;
    },
    getFormatedDate(date) {
        let splitedDate = date.split("/")
        return splitedDate[2] + "-" + splitedDate[1] + "-" + splitedDate[0];
    },

    isValidLatitudeOrLongitude(element, indexOf) {
        return element && element != '' && (element.indexOf(indexOf) > 0 || element.indexOf(indexOf) === -1);
    },

    getFormatedCurrentDateTime() {
        let today = new Date();
        let hours = (today.getHours() >= 10) ? today.getHours() : "0" + today.getHours();
        let minutes = (today.getMinutes() >= 10) ? today.getMinutes() : "0" + today.getMinutes();
        let seconds = (today.getSeconds() >= 10) ? today.getSeconds() : "0" + today.getSeconds();
        return Utils.getFormatedCurrentDate() + ' ' + hours + ':' + minutes + ':' + seconds;
    },

    getTime(date) {
        let dateTimeSplited = date.split(" ");
        const dateSplited = dateTimeSplited[0].split("/");
        const timeSplited = dateTimeSplited[1].split(":");
        const deadline = new Date(dateSplited[2],dateSplited[1]-1,dateSplited[0],timeSplited[0],timeSplited[1],timeSplited[2]);
        return deadline.getTime()
    },

    /**
     * retourner le code de livraison
     * @param code
     * @returns {null|jsonObject}
     */
    getShippingSimpleCode(code) {
        let simpleCode = code
        if (code.indexOf('ShippingByTransporter') !== -1) {
            simpleCode = 'ShippingByTransporter';
        } else if (code.indexOf('VanRental') !== -1) {
            simpleCode = 'VanRental';
        } else if (code.indexOf('ShipFromStoreByTransporter') !== -1 && code.indexOf('C2') !== -1) {
            simpleCode = 'ShippingByTransporter';
        } else if (code.indexOf('ShipFromStoreByTransporter') !== -1 && code.indexOf('C1') !== -1) {
            simpleCode = 'ShippingRelayPoint';
        } else {
            simpleCode = code;
        }
        return simpleCode
    },
    /**
     * retourner première lettre en majuscule pour une chaine de caractère
     * @param string
     * @returns newString
     */
    capitalizeFirstLetter(string) {
        let newString = '';
        let strArray = string.split(' ');
        for (let i = 0; i < strArray.length; i++) {
            newString = newString + ' ' + strArray[i].charAt(0).toUpperCase() + strArray[i].slice(1).toLowerCase();
        }
        return newString;
    },
    /**
     * @param name
     * @returns {*|jQuery|string|undefined}
     */
    getValueAppointmentDate(name) {
        return $('input[name ="' + name + '"]').val()
    },
    /**
     * validation des champs formulaire prendre rdv
     * @param params
     * @param onlyAppointmentChecked
     * @returns {null|jsonObject}
     */
    isValidAppointmentDataRegistrationForm(params, onlyAppointmentChecked) {
        if (onlyAppointmentChecked) {
            if (params.appointments[0].date && params.appointments[0].scheduleId && params.appointments[0].scheduleId !== 'selectionner'
                && params.appointments[0].date === $('#date_rdv').val()) {
                return params
            }
        } else {
            const appointmentsChecked = params.appointments;
            if (appointmentsChecked.length > 0) {
                for (let i = 0, l = appointmentsChecked.length; i < l; i++) {
                    if (appointmentsChecked[i].scheduleId === 'selectionner' || appointmentsChecked[i].date !== $('#' + appointmentsChecked[i].selectedDateId).val() || appointmentsChecked[i].scheduleId === '') {
                        return null
                    }
                }
                return params;
            }
        }
        return null;
    },
    /**
     * validation des champs formulaire modifier rdv unique
     * @param params
     * @returns {null|jsonObject}
     */
    isValidAppointmentDataUpdateForm(params) {
        if (params.appointments[0].date && params.appointments[0].scheduleId && params.appointments[0].scheduleId !== 'selectionner' &&
            params.appointments[0].date === $('#date_prod').val()) {
            return params
        }
        return null
    },
    /**
     * Preparer les lignes des commandes du rdv unique
     * @param data
     * @param items
     * @param mainRecipient
     * @param context
     * @returns {jsonObject}
     */
    prepareDataRegisterAppointment(data, items, mainRecipient, context) {
        const appointmentData = {};
        appointmentData['shopId'] = data.shopId
        appointmentData['origine'] = data.origine
        appointmentData['appointments[0][scheduleId]'] = data.appointments[0].scheduleId
        appointmentData['appointments[0][wpCustomerId]'] = data.appointments[0].wpCustomerId
        appointmentData['appointments[0][nosCustomerId]'] = data.appointments[0].nosCustomerId
        appointmentData['appointments[0][mail]'] = mainRecipient.email
        appointmentData['appointments[0][phone]'] = mainRecipient.phone
        appointmentData['appointments[0][firstName]'] = data.appointments[0].firstName
        appointmentData['appointments[0][lastName]'] = data.appointments[0].lastName
        appointmentData['appointments[0][civility]'] = data.appointments[0].civility
        appointmentData['appointments[0][date]'] = data.appointments[0].date
        appointmentData['appointments[0][type]'] = data.appointments[0].type
        appointmentData['appointments[0][order][0][documentNo]'] = data.appointments[0].order[0].documentNo
        appointmentData['appointments[0][order][0][orderId]'] = data.appointments[0].order[0].orderId
        appointmentData['appointments[0][order][0][id]'] = data.appointments[0].order[0].id
        appointmentData['appointments[0][order][0][idCrypt]'] = data.appointments[0].order[0].idCrypt
        appointmentData['appointments[0][order][0][orderDate]'] = data.appointments[0].order[0].orderDate
        for (let i = 0, l = items.length; i < l; i++) {
            appointmentData['appointments[0][order][0][orderLines][' + i + '][id]'] = items[i].id
            appointmentData['appointments[0][order][0][orderLines][' + i + '][productId]'] = items[i].productId
            appointmentData['appointments[0][order][0][orderLines][' + i + '][ean13]'] = items[i].ean13
            appointmentData['appointments[0][order][0][orderLines][' + i + '][qtyAppointment]'] = items[i].qtyAppointment
            appointmentData['appointments[0][order][0][orderLines][' + i + '][packageId]'] = items[i].package['id']

        }
        return appointmentData;
    },

    /**
     * Preparer les lignes des commandes du rdv multiple
     * @param data
     * @param mainRecipient
     * @param context
     */
    prepareDataRegisterAppointments(data, mainRecipient, context) {
        const appointmentData = {};
        const appointmentsChecked = data.appointments.filter(appointment => appointment.date && appointment.scheduleId)
        let appointmentsObject = {};
        for (let i = 0, l = appointmentsChecked.length; i < l; i++) {
            const key = appointmentsChecked[i].date + '::' + appointmentsChecked[i].scheduleId
            if (appointmentsObject[key]) {
                let orderLines = appointmentsObject[key].order[0].orderLines;
                orderLines.push(appointmentsChecked[i].order[0].orderLines[0]);
                appointmentsObject[key].order[0].orderLines = orderLines
            } else {
                appointmentsObject[key] = appointmentsChecked[i];
            }
        }
        const appointmentsArray = Object.values(appointmentsObject)
        appointmentData['shopId'] = data.shopId
        appointmentData['origine'] = data.origine
        for (let i = 0, l = appointmentsArray.length; i < l; i++) {
            appointmentData['appointments[' + i + '][scheduleId]'] = appointmentsArray[i].scheduleId
            appointmentData['appointments[' + i + '][wpCustomerId]'] = appointmentsArray[i].wpCustomerId
            appointmentData['appointments[' + i + '][nosCustomerId]'] = appointmentsArray[i].nosCustomerId
            appointmentData['appointments[' + i + '][mail]'] = mainRecipient.email
            appointmentData['appointments[' + i + '][phone]'] = mainRecipient.phone
            appointmentData['appointments[' + i + '][firstName]'] = appointmentsArray[i].firstName
            appointmentData['appointments[' + i + '][lastName]'] = appointmentsArray[i].lastName
            appointmentData['appointments[' + i + '][civility]'] = appointmentsArray[i].civility
            appointmentData['appointments[' + i + '][date]'] = appointmentsArray[i].date
            appointmentData['appointments[' + i + '][type]'] = appointmentsArray[i].type
            appointmentData['appointments[' + i + '][order][0][documentNo]'] = appointmentsArray[i].order[0].documentNo
            appointmentData['appointments[' + i + '][order][0][orderId]'] = appointmentsArray[i].order[0].orderId
            appointmentData['appointments[' + i + '][order][0][id]'] = appointmentsArray[i].order[0].id
            appointmentData['appointments[' + i + '][order][0][idCrypt]'] = appointmentsArray[i].order[0].idCrypt
            appointmentData['appointments[' + i + '][order][0][orderDate]'] = appointmentsArray[i].order[0].orderDate
            const orderLines = appointmentsArray[i].order[0].orderLines
            for (let j = 0, l = orderLines.length; j < l; j++) {
                appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][id]'] = orderLines[j].id
                appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][productId]'] = orderLines[j].productId
                appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][ean13]'] = orderLines[j].ean13
                appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][qtyAppointment]'] = orderLines[j].qtyAppointment
                appointmentData['appointments[' + i + '][order][0][orderLines][' + j + '][packageId]'] = orderLines[j].package['id']
            }
        }
        return appointmentData;
    },
    reloadPage() {
        window.location.reload();
    },
    /**
     * Formater une date dd/mm/yyyy
     * @param date
     * @returns {string}
     */
    formatDate(date) {
        const d = new Date(date)
        return ("0" + d.getDate()).slice(-2) + "/" + ("0" + (d.getMonth() + 1)).slice(-2) + "/" + d.getFullYear()
    },

    /**
     * Formater une date dd/mm/yyyy H:i:s
     * @param date
     * @returns {string}
     */
    formatDateTime(dateTime) {
        const dt = new Date(dateTime)
        const formattedDate = (dt.getDate()).toString().padStart(2, '0') + '/' +
            (dt.getMonth() + 1).toString().padStart(2, '0') + '/' +
            (dt.getFullYear()).toString().padStart(4, '0') + ' '
        if (dateTime.includes(":")) {
            return formattedDate +
                (dt.getHours()).toString().padStart(2, '0') + ':' +
                (dt.getMinutes()).toString().padStart(2, '0') + ':' +
                (dt.getSeconds()).toString().padStart(2, '0')
        } else {
            return formattedDate
        }

    },
    /**
     * retourner la valeur de la durée d'attente pour l'upload des fichiers en seconde
     * @param filesSize
     * @returns int
     */
    getUploadTimeOutValue(filesSize) {
        if (filesSize < 4194304) {
            return 4000;
        } else {
            return 7000
        }
    },
    getCookieValue(cookieName) {
        if (typeof Cookies.get(cookieName) !== 'undefined') {
            return Cookies.get(cookieName)
        }
        return null
    },
    setCookieValue (cookieName, value) {
        Cookies.set(cookieName, value);
    },
    checkCookies(category) {
        let cookie = this.getCookieValue('TC_PRIVACY')
        let categoryCookie = encodeURI(cookie).split("@");
        return (categoryCookie && categoryCookie[2] && (categoryCookie[2].split(',').map(Number).includes(category)))
    },
    handleLastSearchesExpiration() {
        if (this.checkCookies(3)) {
            let lastSearchesStorage = localStorage.getItem('lastSearches')
            if (lastSearchesStorage) {
                let items = JSON.parse(lastSearchesStorage)
                var today = new Date();
                items.forEach((item, key) => {
                    if (today.getTime() > item.expiry) {
                        items.splice(key, 1);
                    }
                })
                localStorage.setItem('lastSearches', JSON.stringify(items))
            }
        }
    },
    updateLastSearchesStorage(lastSearchItem, context) {
        if (this.checkCookies(3)) {
            let lastSearchesStorage = localStorage.getItem('lastSearches')
            let items = lastSearchesStorage ? JSON.parse(lastSearchesStorage) : []
            let url = ''
            switch (context) {
                case 'shop':
                    url = lastSearchItem.shopUrl;
                    break;
                case 'category':
                    url = lastSearchItem.categoryUrl;
                    break;
                case 'topSearch':
                case 'keyPress':
                    url = lastSearchItem.url;
                    break;
                default :
                    url = '';
                    break;
            }
            var today = new Date();
            var date = new Date();
            date.setDate(date.getDate() + 93);
            items.forEach((item, key) => {
                if (item.text === lastSearchItem.label) {
                    items.splice(key, 1);
                }
            })
            if (items.length >= 4) {
                items.splice(4, 1);
            }
            items.unshift({
                text: lastSearchItem.label,
                url: url,
                searchDate: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
                expiry: date.getTime()
            })
            localStorage.setItem('lastSearches', JSON.stringify(items))
        }
    },
    replaceRegExpInvalidCharacters(s) {
        return s.replace(/[°"§%()\[\]{}=\\?´`'#<>|,;.:+_]+/g, "").replace(/-+/g, "-");
    },
    /**
     * retourner si un nombre est dans l'intervalle [min,max]
     * @param number
     * @param min
     * @param max
     * @returns {boolean}
     */
    isNumberInRange(number, min, max) {
        return number >= min && number <= max;
    },

    getFetchingProductsAvailabilitiesIsAllowed(filters, madIsActifInFiltredProductList) {
        let allowFetchingProductsAvailabilities = true
        let availabilityFilter = filters?.filter(item => item[1] === FILTER_AVAILABILITY_SHOP_ID_LIST_CODE)
        let availabilityFilterItem = availabilityFilter && availabilityFilter[0] && availabilityFilter[0][5] ? availabilityFilter[0][5].filter(item => item[0] === 'AvailabilityShopIdList' && item[5] == true) : null;
        let availabilityShopIdListFilterIsApplied = availabilityFilterItem?.length > 0;
        if (availabilityShopIdListFilterIsApplied) {
            allowFetchingProductsAvailabilities = madIsActifInFiltredProductList
        }
        return allowFetchingProductsAvailabilities;
    },
    sortShopCircleByDistance(circleShops, primaryShop) {
        let sortedShops = [];
        let shopCircleIds = [];
        circleShops.map(shop => {
            sortedShops.push([shop.id, shop.distance])
        })
        sortedShops.sort(function (shop1, shop2) {
            return shop1[1] - shop2[1];
        });
        shopCircleIds.push(primaryShop);
        for (let i = 0; i < 5; i++) {
            if (sortedShops[i][0] != primaryShop) {
                shopCircleIds.push(sortedShops[i][0])
            }
        }
        return shopCircleIds.slice(0, 4);
    },
    getAvailabilityShopCircleData(shopCircleData) {
        return {
            "modificationDate": shopCircleData.modificationDate,
            "primaryShop": shopCircleData.primaryShop.id,
            "version": shopCircleData.version,
            "circleShops": this.sortShopCircleByDistance(shopCircleData.circleShops, shopCircleData.primaryShop.id)
        }
    },
    getDataFromShopCircle(data) {
        if (this.isValidShopCircle()) {
            let shopCircleData = JSON.parse(localStorage.getItem('shopCircle'));
            return shopCircleData[data] ? shopCircleData[data] : '';
        }
        return '';
    },
    getDistanceToShop(shopId) {
        if (this.isValidShopCircle()) {
            let circleShops = this.getDataFromShopCircle('circleShops');
            let shopData = circleShops?.filter(shop => shop.id == shopId);
            return shopData?.length > 0 ? shopData[0].distance !== undefined ? shopData[0].distance : '' : ''
        }
        return ''
    },
    getBasketData(items) {
        let basketLinesItems = []
        let basketLinestotalAmount = 0;
        items.map(item => {
            basketLinesItems.push({

                "ProductId": item.productId,
                "Quantity": item.quantity,
                "Price": item.unitPrice * item.quantity
            })
            basketLinestotalAmount += parseFloat(item.unitPrice * item.quantity);
        })

        return {
            "BasketLines": basketLinesItems,
            "Field": "EAN_13",
            "TotalAmount": parseFloat(basketLinestotalAmount.toFixed(2))
        }
    },
    convertDateToString(date) {
        let arrayDate = date.split(/[- :]/);
        return arrayDate[2] + '/' + arrayDate[1] + '/' + arrayDate[0];
    },
    foundSchedule(promessDates, appointmentDate, scheduleId) {
        const promessDate = promessDates.find(promessDate => promessDate.date === appointmentDate.split("-").reverse().join("/"));
        if (promessDate && promessDate.scheduleList) {
            return promessDate.scheduleList.find(schedule => schedule.id === scheduleId)
        }
        return null;
    },
    validateJson(json) {
        try {
            return (JSON.parse(json));
        } catch (e) {
            return false;
        }
    },
    getProductAvailabilityParams(shopCircle, productIdFromCart, zipCode, city) {
        if (shopCircle.length > 0) {
            var shopIdList = [];
            var counter = 1;
            shopIdList.push(shopCircle[0].id);
            while (shopCircle[counter] && shopIdList.length < parseInt(appData.stockLocatorShopsCount)) {
                if (shopCircle[counter].isMerchant === true) {
                    shopIdList.push(shopCircle[counter].id);
                }
                counter++;
            }
            var shopIdListParam = (shopIdList.join()) ? "&ShopIds=" + shopIdList.join() : "";
            return "ProductIds=" + productIdFromCart + ",1" + shopIdListParam + "&ZipCode=" + zipCode + "&City=" + city + "&View=StockLocator";
        } else {
            return "ProductIds=" + productIdFromCart + ",1" + "&ZipCode=" + zipCode + "&City=" + city + "&View=StockLocator";
        }

    },
    hasWhiteSpace(stringValue) {
        return stringValue.indexOf(' ') > 0;
    },
    prepareAllShopsProductAvailabilityFilterValue (filters){
        const availabilityFilter = (filters?.length > 0 ) ? filters.filter(item => item[1] === FILTER_AVAILABILITY_SHOP_ID_LIST_CODE) : [];
        if (availabilityFilter?.length > 0) {
            let value = [];
            availabilityFilter[0][5].map(item => {
                if (item[2] !== 'all' && item[7] > 0) {
                    value.push(item[2])
                }
            });
            return value;
        }
        return [];
    },
    getUpdatedUrl(firstLoad, currentUrl, pageIndex, categoryView = null) {
        let url = firstLoad ? currentUrl : window.location.href
        let pageIndexValue = pageIndex.toString().replace('PageIndex=', '');
        let urlParams='';
        if (url.indexOf("PageIndex") >= 0) {
            urlParams = pageIndexValue > 1 ? url.replace(/PageIndex=[0-9]+/i, url.indexOf("?") >= 0 ? "PageIndex=" + pageIndexValue : "?PageIndex=" + pageIndexValue) : url.replace(/[?|&]PageIndex=[0-9]+/i, '')
            return urlParams.includes('view') ? urlParams : urlParams + (categoryView ? `&view=${categoryView}` : '');
        }
        urlParams = (pageIndexValue > 1 ? url.indexOf("?") >= 0 ? url + '&PageIndex=' + pageIndexValue : url + '?PageIndex=' + pageIndexValue : url);
        return urlParams.includes('view') ? urlParams : urlParams + (categoryView ? `&view=${categoryView}` : '');
    }
}
export  const isConsentment = () => {
    if (typeof  Cookies.get('TC_PRIVACY') !== 'undefined') {
        let cookie = Cookies.get('TC_PRIVACY')
        let categoryCookie = encodeURI(cookie).split("@");
        return  !!(categoryCookie && categoryCookie[2] && (categoryCookie[2].split(',').map(Number).includes(3)))
    }
    return false ;
}
export default Utils;
