import {useState, useEffect, FunctionComponent} from "react";
import React, {Component} from "react";
import { connect } from "react-redux";
import {
    DEFAULT_TEMPLATE_VERSION,
    MOBILE_TEMPLATE_VERSION,
} from "@/utils/constants";
import MobileHeader from "@/components/Mobile/Layout/Header/Header";
import DefaultHeader from "@/components/Default/Layout/Header/Header";
import MenuContainer from "@/components/Default/Common/Menu/MenuContainer";
import MobileFooter from "@/components/Mobile/Layout/Footer/Footer";
import DefaultFooter from "@/components/Default/Layout/Footer/Footer";
import { getCustomMenu } from "@/redux/reducers/responsive/common/CommonActions";
import { getPageClassName } from "@/components/responsive/customerAccount/PrefetchDataPages"
import { RootState } from "@/models/appState/RootState";
import { MenuItems } from "@/models/common/header/MenuItems";
import BannerBlocDefault from "@/components/Default/Layout/Header/BannerBloc";
import BannerBlocMobile from "@/components/Mobile/Layout/Header/BannerBloc";

interface Props {
    templateVersion: string;
    context: string;
    children: JSX.Element;
    pathName: string;
    shopCircleVersion?: string;
    footerTrackingFlow?: string;
    currentStep: string;
    footerTrackingScopeData?: string;
    headerTrackingScopeData?: string;
    logoVersion: string;
    googleMapsKey?: string;
    ssr: boolean;
    menuItems: MenuItems;
    aboveMenuItems: MenuItems;
    isDQENormalisationActive?: boolean;
    productId?: string;
    showBanners?: boolean;
    bannersBlocList?: string;
    hasCountDown?: string;
    title?: string;
    showFooter?: boolean;
}

const ResponsiveLayout: FunctionComponent<Props> = (props) => {

    const {
        templateVersion,
        context,
        children,
        pathName,
        shopCircleVersion,
        footerTrackingFlow,
        currentStep,
        footerTrackingScopeData,
        headerTrackingScopeData,
        logoVersion,
        googleMapsKey,
        ssr,
        productId,
        showBanners,
        bannersBlocList,
        hasCountDown,
        title,
        showFooter
    } = props;
    const [menuItems, setMenuItems] = useState([]);
    const [firstLoad, setFirstLoad] = useState(ssr);
    const [aboveMenuItems, setAboveMenuItems] = useState([]);
    const pageClassName = getPageClassName(pathName);

    useEffect(() => {
        // TODO Déplacer getCustomMenu dans l'action et faire les adaptations
        getCustomMenu(templateVersion, navigator.userAgent, null, false, '', false).then((response) => {
            setMenuItems(response.menuItems.values)
            templateVersion == DEFAULT_TEMPLATE_VERSION ? setAboveMenuItems(response.aboveMenuItems?.values) : setAboveMenuItems([])
        })
        setFirstLoad(false);
    }, [])

    return (
        <div className={pageClassName ? pageClassName : ''} id="page" data-template-version={templateVersion}>
            {templateVersion === MOBILE_TEMPLATE_VERSION ?
                <>
                    {(context == 'categoriesPage' || context == 'productSheet') &&
                        <div id="main-header__infobar" className="banner-black">
                            <BannerBlocMobile showBanners={showBanners} bannersBlocList={bannersBlocList}/>
                        </div>}
                    <MobileHeader context={currentStep}
                                  menuItems={firstLoad ? props.menuItems : menuItems}
                                  wordingVersion={MOBILE_TEMPLATE_VERSION}
                                  currentPage={context}
                                  headerTrackingScopeData={headerTrackingScopeData}
                                  logoVersion={logoVersion}
                                  googleMapsKey={googleMapsKey}
                                  productId={productId}
                                  shopCircleVersion={shopCircleVersion}
                                  title={title}
                    />
                    {children}
                    {showFooter && <MobileFooter/>}
                </> : <>
                    <header id="main-header" className="main-header">
                        <div id="main-header__infobar" className="main-header__infobar owl-carousel owl-loaded">
                            <BannerBlocDefault showBanners={showBanners} bannersBlocList={bannersBlocList}
                                        hasCountDown={hasCountDown}/>
                        </div>
                        <DefaultHeader currentStep={currentStep}
                                       shopCircleVersion={shopCircleVersion}
                                       headerTrackingScopeData={headerTrackingScopeData}
                                       logoVersion={logoVersion}
                                       productId={productId}
                        />
                        <MenuContainer menuItems={ssr ? props.menuItems : menuItems}
                                       aboveMenuItems={ssr ? props.aboveMenuItems : aboveMenuItems}
                                       currentPage={context}/>
                    </header>
                    {children}
                    {showFooter &&
                        <footer id="container_footer">
                            <div id="footer">
                                {/* TODO TS: delete @ts-ignore once the layout part is fully migrated to type script */}
                                {/* @ts-ignore */}
                                <DefaultFooter footerTrackingFlow={footerTrackingFlow}
                                               footerTrackingScopeData={footerTrackingScopeData}/>
                            </div>
                        </footer>
                    }
                </>}
        </div>
    );

}

const mapStateToProps = (state: RootState, ownProps: Props) => {
    return {

        templateVersion: ownProps.templateVersion,
        currentStep: ownProps.currentStep,
        footerTrackingFlow: state.tracking.footerTrackingFlow,
        menuItems: ownProps.menuItems ?? null,
        aboveMenuItems: ownProps.aboveMenuItems ?? null,
        headerTrackingScopeData: ownProps.headerTrackingScopeData ?? null,
        footerTrackingScopeData: ownProps.footerTrackingScopeData ?? null,
        isDQENormalisationActive: ownProps.isDQENormalisationActive ?? null,
        googleMapsKey: ownProps.googleMapsKey ?? null,
        shopCircleVersion: ownProps.shopCircleVersion ?? null,
        logoVersion: ownProps.logoVersion ?? null,
        showBanners: !!ownProps.showBanners,
        bannersBlocList: ownProps.bannersBlocList ?? null,
        hasCountDown: ownProps.hasCountDown ?? null,
        title: ownProps.title ?? null,
        showFooter: ownProps.showFooter ?? true
    }
}

export default connect(mapStateToProps, null)(ResponsiveLayout);
