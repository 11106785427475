import Cookies, { CookieAttributes } from "js-cookie";

export const getCookieValue = (cookieName: string): string | undefined => {
    if (typeof Cookies.get(cookieName) !== 'undefined') {
        return Cookies.get(cookieName)
    }
    return
}

export const removeCookie = (cookieName: string, options?: CookieAttributes): void => {
    Cookies.remove(cookieName, options);
}

export const setCookie  = (cookieName: string, value: string, options?: CookieAttributes): void => {
    Cookies.set(cookieName, value, options);

}