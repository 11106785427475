import React, {memo} from 'react'

const HeaderCart = memo(({totalQuantity}) => {
    return (
        <div className="col text-right">
            <a href="/Purchase/Cart/Summary" className="top-bar__nav__icone bakset">
                {totalQuantity > 0 ?
                    <span className="gtm-header top-bar__nav__right__cart-number-items" data-gtm-libelle="panier">{totalQuantity}</span> : null }
                <i data-gtm-libelle="panier" className="icon-3x icon-panier-v2"/>
            </a>
        </div>
    );
});
export default HeaderCart;