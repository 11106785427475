import {Fragment} from 'react'
import {REVIEWS_DISPLAY_THRESHOLD} from "@/utils/constants";


const DestockedProductOverlay = (props) => {

    function renderShop() {
        let urlShop = "https://www.google.fr/maps/dir/" + props.shopAndWharehouseData.shopInfos.geolocation.latitude + "," + props.shopAndWharehouseData.shopInfos.geolocation.longitude;
        return (
            <div className={"store-ficheproduit__btn-btm" + " " + "store-ficheproduit__btn-btm" + props.product.ean13}>
                <a target="_blank" href={urlShop} className=" btn btn-block-sm btn-red py-3 mt-2"><span>Se rendre en magasin</span></a>
            </div>
        );
    }

    function renderImageProduct() {
        return (
            <div id="ficheResumeCarousel">
                <div className="item">
                    <span className="fiche-bundle-product__table__sitem__img">
                     <img src={props.product.productPictures['smallDestocked']} className="th" alt/>
                    </span>
                </div>

            </div>
        );
    }

    function renderReviewsStar(rate) {
        let displayReviewsStar = [];
        let nbrReviewsShowed = [1, 2, 3, 4, 5];
        nbrReviewsShowed.map((childrenItem, i) => {
            displayReviewsStar.push(
                (childrenItem <= rate) ? <span><img
                        src="/Kawa/Sources/Mobilev2/images/ProductList/etoile_pleine.svg"/></span>
                    : (rate > childrenItem - 1) ? <span><img
                        src="/Kawa/Sources/Mobilev2/images/ProductList/etoile_pleine.svg"/></span>
                    : <span><img
                        src="/Kawa/Sources/Mobilev2/images/ProductList/etoile_vide.svg"/></span>
            );
        });
        return displayReviewsStar;
    }

    function renderdestockedProductReviews() {
        let destockedProductReviews = props.productReviewData.items;
        let displayDestockedProductReviews = [];

        if (destockedProductReviews) {
            destockedProductReviews.map((childrenItem, i) => {
                displayDestockedProductReviews.push(
                    <Fragment>
                        <div className="panel-fiche__collapse__avis">
                            <div className="panel-fiche__collapse__avis__content">
                                <div className="panel-fiche__collapse__avis__content__row">
                                    <div className="panel-fiche__collapse__avis__content__row__left">
                                        <span>{childrenItem.title}</span>
                                        <div>
                                            {props.productReviewData.reviewListStatistics.total >= REVIEWS_DISPLAY_THRESHOLD && renderReviewsStar(childrenItem.rate)}
                                        </div>
                                    </div>
                                    <div className="panel-fiche__collapse__avis__content__row__right">
                                          <span>{childrenItem.pseudo}
                                              {(childrenItem.orderDate) ? (childrenItem.displayDate) : ''}</span>
                                        {(childrenItem.orderDate) ?
                                            <span>Suite à une commande du {childrenItem.orderDate} </span> : ''}
                                    </div>
                                </div>
                                <p>{childrenItem.content}</p>
                            </div>
                        </div>
                    </Fragment>
                );
            });
        }

        return (
            <div className="collapse" id={("avis").concat(props.productshowedType).concat(props.product.ean13)}>
                <div>
                    <div className="panel-fiche__collapse__avis">
                        <div className="panel-fiche__collapse__avis__control">
                            <span>Soumis à un contrôle</span><a href="#" data-toggle="modal"
                                                                data-target="#exampleModalLong"
                                                                className="icon-info icon-lg"></a></div>
                    </div>
                    {displayDestockedProductReviews}
                </div>
                {(destockedProductReviews.length < props.productReviewData.reviewListStatistics.total) ?
                    <div onClick={handleSeeMoreClick} className="panel-fiche__collapse__btn-avis">
                        <a href="#" className="btn btn-default btn-lightgray">Voir plus d'avis</a>
                    </div> : ''
                }
            </div>
        );
    }

    function renderDesc() {
        let displayDesc = [];
        let description = props.caracProductData.productAdvantages;
        if (description) {
            description.map((childrenItem, i) => {
                displayDesc.push(
                    <Fragment>
                        <span dangerouslySetInnerHTML={{__html: childrenItem}}/><br/>
                    </Fragment>
                );
            });
        }
        return (
            <div className="panel-fiche__collapse">
                <a href="#" className="panel-fiche__collapse__title collapsed" data-toggle="collapse"
                   data-target={("#desc").concat(props.productshowedType).concat(props.product.ean13)}><h2
                    className="panel-fiche__title">Description</h2><span
                    className="icon-expand icon-sm"></span></a>
                <div className="collapse"
                     id={("desc").concat(props.productshowedType).concat(props.product.ean13)}>
                    <div className="panel-fiche__collapse__desc">
                        <p>{displayDesc}</p>
                    </div>
                </div>
                <span className="panel-fiche__collapse__border"></span>
            </div>
        );
    }

    function renderCarac() {
        let features = props.caracProductData.features;
        let currentFeature = "";
        let displayFeatures = [];
        if (features) {
            features.map((childrenItem, i) => {
                displayFeatures.push(
                    <Fragment>
                        {
                            (childrenItem.group != "Pictos") ?

                                (currentFeature != childrenItem.group) ?
                                    (currentFeature = childrenItem.group )
                                    && ((currentFeature != '') ?
                                    <span className="skill-row-title">{childrenItem.group}</span> : null)

                                    : null
                                : null

                        }
                        {
                            (childrenItem.group != "Pictos") ?
                                <div className={((i % 2) != 0) ? "skill-row skill-row--gray" : "skill-row"}>
                                    <div className="skill-row__left"><span>{childrenItem.label}</span></div>
                                    <div className="skill-row__right"><span
                                        className="strong">{childrenItem.value}</span></div>
                                </div> : null
                        }
                    </Fragment>
                );
            });
        }
        return (
            <Fragment>
                <div className="panel-fiche__collapse"
                     id={("skillDIV-").concat(props.productshowedType).concat(props.product.ean13)}>
                    <a href="#" className="panel-fiche__collapse__title collapsed" data-toggle="collapse"
                       data-target={("#skill").concat(props.productshowedType).concat(props.product.ean13)}><h2
                        className="panel-fiche__title ">Caractéristiques</h2><span
                        className="icon-expand icon-sm"></span></a>
                    <div className="collapse" id={("skill").concat(props.productshowedType).concat(props.product.ean13)}>
                        <div className="panel-fiche__collapse__content">
                            {displayFeatures}
                        </div>
                    </div>
                    <span className="panel-fiche__collapse__border"></span>
                </div>
            </Fragment>
        );
    }

    function handleSeeMoreClick() {
        props.incrementTotalReviewsPageSize();
        props.getProductReviews(props.product.ean13, props.totalReviewsPageSize);
    };

    const {brand, title,percentage,destockedProductPrice, productPrice } = props.product;
    return (
        <Fragment>
            <div className={"store-ficheproduit" + " " + "store-ficheproduit" + props.product.ean13}>
                <section className="section-fiche-resume">
                    <div className="fiche-carousel">
                        {percentage > 0 && <div className="fiche-carousel__promo">-{props.product.percentage}<span>%</span></div>}
                        {renderImageProduct()}
                    </div>
                    <div className="fiche-resume nopadding-bottom">
                        <h1 className="fiche-resume__title">{(brand && brand !== '-') && brand} {title}</h1>
                        <div className="fiche-resume__infos">
                            {
                                (props.productReviewData && props.productReviewData.reviewListStatistics.total >= REVIEWS_DISPLAY_THRESHOLD) ?
                                    <a href="#" id="link_avis" className="fiche-resume__infos__avis">
                                        {renderReviewsStar(props.productReviewData.reviewListStatistics.rateAverage)}
                                        <span
                                            className="fiche-resume__infos__avis__nb">({props.productReviewData.reviewListStatistics.total})</span>
                                    </a>
                                    : null
                            }
                            <div className="fiche-resume__infos__price">
                                {(productPrice > 0 && destockedProductPrice > 0 ) && <div className="crossed-price"><span>{productPrice} &euro;*</span></div>}
                                <div className="fiche-resume__infos__price__content">
                                    {destockedProductPrice > 0 ?
                                        <span className="price">{destockedProductPrice} &euro;</span> :
                                        (productPrice > 0 && <span className="price">{productPrice} &euro;</span>) }
                                    {
                                        (props.caracProductData.ecoFurniturePrice && props.caracProductData.ecoFurniturePrice> 0 ) ?
                                            <span className="eco">dont {props.caracProductData.ecoFurniturePrice} € d'éco-mobilier </span>
                                            : (props.caracProductData.ecoContributionPrice && props.caracProductData.ecoContributionPrice> 0) ?
                                            <span className="eco">dont {props.caracProductData.ecoContributionPrice} € d'éco-participation </span>
                                            :''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-fiche">
                    {renderDesc()}
                    {renderCarac()}
                </section>
                <section className="section-fiche">
                    <div className="panel-fiche__collapse">
                        <a href="#" id="all_avis"
                           className="panel-fiche__collapse__title panel-fiche__collapse__title--avis collapsed"
                           data-toggle="collapse"
                           data-target={("#avis").concat(props.productshowedType).concat(props.product.ean13)}>
                            <div className="panel-fiche__collapse__title__avis">
                                <h1 className="panel-fiche__collapse__title__avis__title panel-fiche__title panel-fiche__title2">Avis
                                    clients</h1>
                                {
                                    (props.productReviewData && props.productReviewData.reviewListStatistics.total >= REVIEWS_DISPLAY_THRESHOLD) ?
                                        <div className="panel-fiche__collapse__title__avis__stars">
                                            {renderReviewsStar(props.productReviewData.reviewListStatistics.rateAverage)}
                                            <span
                                                className="panel-fiche__collapse__title__avis__nb">({props.productReviewData.reviewListStatistics.total})</span>
                                        </div>
                                        : null
                                }
                            </div>
                            <span className="icon-expand icon-sm"></span>
                        </a>
                        {
                            (props.productReviewData) ? renderdestockedProductReviews() : null
                        }

                        <span className="panel-fiche__collapse__border"></span>
                    </div>
                    <div className="panel-fiche__article">
                        <h1 className="panel-fiche__article__title panel-fiche__title2">Comment acheter cet article
                            ?</h1>
                        <div className="panel-fiche__article__list">
                            <div className="panel-fiche__article__list-item">
                                <div className="panel-fiche__article__list-item-icon">
                                    <img src="/Kawa/Sources/Mobilev2/images/pin.svg"/>
                                </div>
                                <div className="panel-fiche__article__list-item-txt ">
                                    <span className="words1">Rendez-vous dans votre magasin</span><br/>
                                    <span className="words2"> {props.shopAndWharehouseData.shopInfos.name}</span><br/>
                                    <span className="words3">pour commander votre produit.</span>
                                </div>
                            </div>
                        </div>
                        <div className="panel-fiche__article__list">
                            <div className="panel-fiche__article__list-item">
                                <div className="panel-fiche__article__list-item-icon">
                                    <img src="/Kawa/Sources/Mobilev2/images/euro.svg"/>
                                </div>
                                <div className="panel-fiche__article__list-item-txt">
                                    <span className="words1">Réglez votre achat</span>
                                    <span className="words3"> en magasin</span>
                                </div>
                            </div>
                        </div>
                        <div className="panel-fiche__article__list">
                            <div className="panel-fiche__article__list-item">
                                <div className="panel-fiche__article__list-item-icon">
                                    <img src="/Kawa/Sources/Mobilev2/images/produits.svg"/>
                                </div>
                                <div className="panel-fiche__article__list-item-txt">
                                    <span className="words1">Repartez </span>
                                    <span className="words3">avec votre produit</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {renderShop()}
        </Fragment>
    )
}

export default DestockedProductOverlay;