import ApiCaller from './ApiCaller/apiCaller'
export const fetchInseeCodeByZipCodeAndCity = (zipCode ,city) => {
    return new Promise((resolve, reject) => {
        const url =  isNaN(city) ? "/Localizations/InseeCodes?ZipeCode=" + zipCode  + "&City=" + city : "/Localizations/InseeCodes?ZipeCode=" + zipCode ;
        ApiCaller.get(url)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/Localizations/InseeCodes?ZipeCode=" + zipCode + "&City=" + city + " Api responded failed : " + error)
                reject(error)
            });
    });
};
export const getLocationByCity = (cityNameFragment) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Localizations/InseeCodes?City=" + cityNameFragment)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/Localizations/InseeCodes?City=" + cityNameFragment + " Api responded failed : " + error)
                reject(error)
            });
    });
};
export const getLocationByZipCode = (cityNameFragment) => {
    return new Promise((resolve, reject) => {
        ApiCaller.get("/Localizations/InseeCodes?ZipeCode=" + cityNameFragment)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('ERROR', "/Localizations/InseeCodes?ZipeCode=" + cityNameFragment + " Api responded failed : " + error)
                reject(error)
            });
    });
};


