import {ADDRESS_CHECKED, UPDATE_POPIN_DISPLAY, UPDATE_RESPONSE} from '../actionsTypes'

const initialState = {

    showRNVPBlock: false,
    validateFormAndSubmitFunction: null,
    redirectToIfRNVPNotValidate: null,
    billingAddressData: null,

    errorResponse: 0,
    geoPopin : "hidePopin"

};


const GeolocationReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case ADDRESS_CHECKED:
            let newState = {
                ...state,
                showRNVPBlock: action.payload.showRNVPBlock,
                validateFormAndSubmitFunction: action.payload.validateFormAndSubmitFunction,
                redirectToIfRNVPNotValidate: (action.payload.redirectToIfRNVPNotValidate) ?
                    action.payload.redirectToIfRNVPNotValidate : null
            }
            if(action.payload.type){
                newState.type = action.payload.type
            }
            if (action.payload.billingAddressData)
                newState.billingAddressData = action.payload.billingAddressData
            return newState
        case UPDATE_RESPONSE:
            return {
                ...state,
                errorResponse : action.errorResponse,
            }
        case UPDATE_POPIN_DISPLAY:
            return {
                ...state,
                geoPopin : action.geoPopin
        }
        default:
            return state;
    }

};

export default GeolocationReducer;
